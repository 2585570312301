import { default as RsrController } from './rsr.controller';
export const moduleName = 'helpPanelRst';
// jscs:disable requireCapitalizedConstructors
// angular requires directives to be lowercase
export default class helpPanelRcda {
  constructor() {
    this.restrict = 'EA';
    this.template = require('./rsr.jade');
    this.controller = RsrController;
    this.controllerAs = 'rsr';
    this.scope = true;
    this.bindToController = true;
  }

  static factory() {
    return new helpPanelRsr();
  }
}
