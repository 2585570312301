import { default as AskTheExpertModalController } from './asktheexpert_modal.controller';
export const moduleName = 'modalAskTheExpert';
// jscs:disable requireCapitalizedConstructors
// angular requires directives to be lowercase
export default class modalAskTheExpert {
  constructor() {
    this.restrict = 'EA';
    this.template = require('./asktheexpert_modal.jade');
    this.controller = AskTheExpertModalController;
    this.controllerAs = 'askTheExpertModal';
    this.scope = true;
    this.bindToController = true;
  }

  static factory() {
    return new modalAskTheExpert();
  }
}
