export const name = 'DashboardSettingsController';
export default function DashboardSettingsController(
  $mdDialog,
  rnPreferences,
  dashboardSettings,
  $gzAnalytics,
  $translate,
  isSiteDashboard,
  displaySites
) {
  //noinspection BadExpressionStatementJS
  'ngInject';

  /* jshint validthis: true */
  var vm = this;
  vm.isSiteDashboard = isSiteDashboard;
  vm.displaySites = displaySites;
  vm.sending = false;

  vm.editForm = null;
  vm.save = save;
  vm.cancel = cancel;

  vm.dashboardIntervalLabel = (vm.isSiteDashboard ? $translate.instant('home.home.Site_AutoAdvance') : $translate.instant('home.home.Dashboard_AutoAdvance'));
  vm.cardIntervalLabel = (vm.isSiteDashboard ? $translate.instant('home.home.Site_PageAdvance') : $translate.instant('home.home.Dashboard_CardAdvance'));

  vm.$onInit = onInit;
  return;
  ////////////////

  function onInit() {
  }

  function save() {
    vm.errorMessageKey = null;

    if (vm.dashboardSettings.dashboardInterval < 1) {
      vm.dashboardSettings.dashboardInterval = 0;
    }
    if (vm.dashboardSettings.cardInterval < 1) {
      vm.dashboardSettings.cardInterval = 0;
    }
    rnPreferences.setSingleObject('home', 'dashboardSettings', vm.dashboardSettings);
    $mdDialog.hide(vm.dashboardSettings);

    $gzAnalytics.eventSave('edit dashboard preferences');
  }

  function cancel() {
    $mdDialog.cancel();
    $gzAnalytics.eventCancel('edit dashboard preferences');
  }
}
