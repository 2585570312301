import {
  ACCOUNT_TYPE,
  LP_DEVICE_FAMILY,
} from "./card-definitions";

export const dashboardDefinitions = {
  [ACCOUNT_TYPE.NONE]: {
    [LP_DEVICE_FAMILY.NONE]: {
      appId: 'lp2',
      type: ACCOUNT_TYPE.NONE,
      intro: {
        walkMeId: 'HP PrintOS Overview',
        userMessageId: 'home.home.LF_TutorialWelcome',
        messageId: 'home.home.Orphan_TutorialWelcome',
      },
      highlightSection: {
        nameKey: 'home.home.HighlightSection',
        cards: [
          {cardId: 'myAccount'}
        ],
      },
      sections: [],
    }
  },
  [ACCOUNT_TYPE.EXTERNAL]: {
    [LP_DEVICE_FAMILY.NONE]: {
      appId: 'lp2',
      type: ACCOUNT_TYPE.NONE,
      intro: {
        walkMeId: 'Home dashboard overview',
        userMessageId: 'home.home.LF_TutorialWelcome',
        messageId: 'home.home.Orphan_TutorialWelcome',
      },
      highlightSection: {
        nameKey: 'home.home.HighlightSection',
        cards: [
          {cardId: 'myAccount'}
        ],
      },
      sections: [],
    }
  },
  [ACCOUNT_TYPE.PSP]: {
    [LP_DEVICE_FAMILY.LF]: {
      appId: 'lp2',
      type: ACCOUNT_TYPE.PSP,
      family: LP_DEVICE_FAMILY.LF,
      intro: {
        walkMeId: 'PrintOS Basic Tutorial for Large Format Printing',
        // walkMeId: 'Home dashboard overview',
        userMessageId: 'home.home.LF_TutorialWelcome',
        messageId: 'home.home.LF_TutorialMessage',
      },
      highlightSection: {
        nameKey: 'home.home.HighlightSection',
        cards: [
          {cardId: 'whatsNext'},
        ],
      },
      sections: [
        {
          nameKey: 'home.home.LF_ReportsSection',
          layout: [50,50],
          isGroup: true,
          cards: [
            {cardId: 'latexStatusSummary'},
            {cardId: 'latexPrintVolume'},
            {cardId: 'latexEfficiency'},
          ]
        },
        {
          nameKey: 'home.home.MyCardsSection',
          layout: [50,50],
          cards: [
            {cardId: 'latexColorConfig'},
            {cardId: 'latexSupportCases'},
            {cardId: 'learnMyCourses'},
            {cardId: 'applicationCenter'},
            {cardId: 'myAccount'},
          ]
        },
      ],
    },
    [LP_DEVICE_FAMILY.INDIGO]: {
      appId: 'lp2',
      type: ACCOUNT_TYPE.PSP,
      family: LP_DEVICE_FAMILY.INDIGO,
      highlightSection: {
        nameKey: 'home.home.HighlightSection',
        cards: [
          {cardId: 'whatsNext'},
        ],
      },
      sections: [
        {
          nameKey: 'home.home.Indigo_SiteSection',
          layout: [50,50],
          isGroup: true,
          cards: [
            {cardId: 'indigoPressPerformance'},
            {cardId: 'indigoVolume'},
            {cardId: 'indigoSitePerformance'},
            {cardId: 'indigoRanking'},
          ]
        },
        {
          nameKey: 'home.home.MyCardsSection',
          layout: [50,50],
          cards: [
            {cardId: 'indigoSCLink'},
            {cardId: 'indigoSupplyOrders'},
            {cardId: 'jobs'},
            {cardId: 'indigoKnowledgeZone'},
            {cardId: 'box'},
            {cardId: 'siteFlow'},
            {cardId: 'learnMyCourses'},
            {cardId: 'myAccount'},
          ]
        },
      ],
    },
    [LP_DEVICE_FAMILY.THREE_D]: {
      appId: 'lp2',
      type: ACCOUNT_TYPE.PSP,
      family: LP_DEVICE_FAMILY.THREE_D,
      highlightSection: {
        nameKey: 'home.home.HighlightSection',
        cards: [
          {cardId: 'whatsNext'},
        ],
      },
      sections: [
        {
          nameKey: 'home.home.MyCardsSection',
          layout: [50,50],
          cards: [
            {cardId: 'appAnalytics'},
            {cardId: 'threeDSmartUptimeKit'},
            {cardId: '3dSCLink'},
            {cardId: 'bolt'},
            {cardId: 'jobHistory'},
            {cardId: 'threeDSupportCases'},
            {cardId: 'my3DDevices'},
            {cardId: '3DDevices'},
            {cardId: 'maintenance'},
            {cardId: 'maintenancePromo'},
            {cardId: '3dKnowledgeZone'},
            {cardId: 'learnMyCourses'},
            {cardId: 'myAccount'},
            {cardId: 'my3DServices'},
          ],
        }
      ],
    },
    [LP_DEVICE_FAMILY.PAGEWIDE]: {
      appId: 'lp2',
      type: ACCOUNT_TYPE.PSP,
      family: LP_DEVICE_FAMILY.PAGEWIDE,
      highlightSection: {
        nameKey: 'home.home.HighlightSection',
        cards: [
          {cardId: 'whatsNext'},
        ],
      },
      sections: [
        {
          nameKey: 'home.home.MyCardsSection',
          layout: [50,50],
          cards: [
            {cardId: 'myPageWideDevices'},
            {cardId: 'pageWideSmartUptimeKit'},
            {cardId: 'pageWideServiceNow'},
            {cardId: 'pageWideSupportCases'},
            {cardId: 'pwpKnowledgeZone'},
            {cardId: 'box'},
            {cardId: 'learnMyCourses'},
            {cardId: 'siteFlow'},
            {cardId: 'myAccount'},
          ],
        },
      ],
    },
    [LP_DEVICE_FAMILY.SCITEX]: {
      appId: 'lp2',
      type: ACCOUNT_TYPE.PSP,
      family: LP_DEVICE_FAMILY.SCITEX,
      highlightSection: {
        nameKey: 'home.home.HighlightSection',
        cards: [
          {cardId: 'whatsNext'},
        ],
      },
      sections: [
        {
          nameKey: 'home.home.MyCardsSection',
          layout: [50,50],
          cards: [
            {cardId: 'scitexSitePerformance'},
            {cardId: 'scitexSmartUptimeKit'},
            {cardId: 'scitexServiceNow'},
            {cardId: 'scitexSupportCases'},
            {cardId: 'scitexKnowledgeZone'},
            {cardId: 'box'},
            {cardId: 'siteFlow'},
            {cardId: 'learnMyCourses'},
            {cardId: 'myAccount'},
          ],
        },
      ],
    },
    [LP_DEVICE_FAMILY.NONE]: {
      appId: 'lp2',
      type: ACCOUNT_TYPE.PSP,
      family: LP_DEVICE_FAMILY.NONE,
      highlightSection: {
        nameKey: 'home.home.HighlightSection',
        cards: [
          {cardId: 'whatsNext'},
        ],
      },
      sections: [
        {
          nameKey: 'home.home.MyCardsSection',
          layout: [50,50],
          cards: [
            {cardId: 'scitexSmartUptimeKit'},
            {cardId: 'pageWideSmartUptimeKit'},
            {cardId: 'threeDSmartUptimeKit'},
            {cardId: 'box'},
            {cardId: 'siteFlow'},
            {cardId: 'learnMyCourses'},
            {cardId: 'myAccount'},
          ],
        },
      ],
    },
    'DEFAULT': {
    },
  },
  [ACCOUNT_TYPE.RESELLER]: {
    [LP_DEVICE_FAMILY.LF]: {
      appId: 'lp2',
      type: ACCOUNT_TYPE.RESELLER,
      highlightSection: {
        nameKey: 'home.home.HighlightSection',
        cards: [
          {cardId: 'latexServiceCenter'},
        ],
      },
      sections: [
        {
          nameKey: 'home.home.MyCustomers',
          layout: [50,50],
          isGroup: true,
          cards: [
            {cardId: 'resellerSummary'},
            {cardId: 'sharedAccounts'},
          ],
        },
        {
          nameKey: 'home.home.MyCardsSection',
          layout: [50,50],
          cards: [
            {cardId: 'applicationCenter'},
            {cardId: 'learnMyCourses'},
            {cardId: 'myAccount'},
            {cardId: 'whatsNext'},
          ],
        },
      ],
    },
    [LP_DEVICE_FAMILY.INDIGO]: {
      appId: 'lp2',
      type: ACCOUNT_TYPE.RESELLER,
      highlightSection: {
        nameKey: 'home.home.HighlightSection',
        cards: [
          {cardId: 'indigoSCLink'},
        ],
      },
      sections: [
        {
          nameKey: 'home.home.ScoresSection',
          layout: [50,50],
          cards: [
            {cardId: 'indigoCustomerScores'},
          ],
        },
        {
          nameKey: 'home.home.MyCustomers',
          layout: [50,50],
          isGroup: true,
          cards: [
            {cardId: 'resellerSummary'},
            {cardId: 'sharedAccounts'},
          ],
        },
        {
          nameKey: 'home.home.MyCardsSection',
          layout: [50,50],
          cards: [
            {cardId: 'channelPsps'},
            {cardId: 'indigoKnowledgeZone'},
            {cardId: 'learnMyCourses'},
            {cardId: 'myAccount'},
            {cardId: 'whatsNext'},
          ],
        },
      ],
    },
    'DEFAULT': {
      appId: 'lp2',
      type: ACCOUNT_TYPE.RESELLER,
      highlightSection: {
        nameKey: 'home.home.HighlightSection',
        cards: [
          {cardId: 'whatsNext'},
        ],
      },
      sections: [
        {
          nameKey: 'home.home.MyCustomers',
          layout: [50,50],
          isGroup: true,
          cards: [
            {cardId: 'resellerSummary'},
            {cardId: 'sharedAccounts'},
          ],
        },
        {
          nameKey: 'home.home.MyCardsSection',
          layout: [50,50],
          cards: [
            {cardId: 'applicationCenter'},
            {cardId: 'indigoKnowledgeZone'},
            {cardId: '3dKnowledgeZone'},
            {cardId: 'learnMyCourses'},
            {cardId: 'myAccount'},
          ],
        },
      ],
    },
  },
  [ACCOUNT_TYPE.HP]: {
    'DEFAULT': {
      appId: 'lp2',
      type: ACCOUNT_TYPE.HP,
      highlightSection: {
        nameKey: 'home.home.HighlightSection',
        cards: [
          {cardId: 'whatsNext'},
        ],
      },
      sections: [
        {
          nameKey: 'home.home.MyCardsSection',
          layout: [50, 50],
          cards: [
            {cardId: 'hpQuickLinks'},
            {cardId: 'indigoKnowledgeZone'},
            {cardId: 'scitexKnowledgeZone'},
            {cardId: 'pwpKnowledgeZone'},
            {cardId: '3dKnowledgeZone'},
            {cardId: 'learnMyCourses'},
            {cardId: 'myAccount'},
          ],
        },
      ],
    },
  },
  [ACCOUNT_TYPE.GENERIC]: {
    'DEFAULT': {
      appId: 'lp2',
      type: ACCOUNT_TYPE.GENERIC,
      highlightSection: {
        nameKey: 'home.home.HighlightSection',
        cards: [
          {cardId: 'whatsNext'},
        ],
      },
      sections: [
        {
          nameKey: 'home.home.MyCardsSection',
          layout: [50, 50],
          cards: [
            {cardId: 'hpGenericOrg'},
            {cardId: 'myAccount'},
          ],
        },
      ],
    },
  },
};
