import {LP_DEVICE_FAMILY} from 'src/components/landing-page/cards/card-definitions';

export const name = 'hmIndigoVolume';
export default function hmIndigoVolume() {
  //noinspection BadExpressionStatementJS
  'ngInject';

  let directive = {
    restrict: 'EA',
    scope: {
      gzCard: '=',
    },
    template: require('./indigo-volume.jade'),
    controller: hmIndigoVolumeController,
    controllerAs: 'vm',
    bindToController: true
  };

  return directive;

  /** @ngInject */
  function hmIndigoVolumeController(
    $window,
    rnHttp,
    $q,
    $translate,
    $gzAnalytics,
    $timeout,
    $scope,
    $rootScope,
    moment,
    $hmPrintBeat
  ) {

    let pbDeviceInfoListener;

    let vm = this;
    vm.loading = true;
    vm.chartConfig = {
      loading: false,
      chart: {
        spacingTop: 10,
        spacingRight: 5,
        spacingLeft: 0,
        height: 220,
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        series: {
          marker: {
            lineColor: '#ffffff',
            lineWidth: 2,
            fillColor: '#0096d6',
          }
        },
      },
      title: {
        text: '',
      },
      tooltip: {
        backgroundColor: '#FFFFFF',
        delayForDisplay: 200,
        headerFormat: '',
        hideDelay: 1,
        padding: 2,
        shadow: false,
        shared: false,
        useHTML: true,
      },
      series: [
        {
          type: 'areaspline',
          lineColor: '#0096d6',
          color: {
            linearGradient: {x1: 0, x2: 0, y1: 0, y2: 1},
            stops: [
              [0, '#66c2ea'],
              [1, '#ffffff']
            ]
          },
        },
      ],
      xAxis: {
        type: 'category',
        //categories: [2,3,4,5,6],
        crosshair: {
          color: '#c1c1c1',
          width: 1,
        },
        title: {
          style: {
            fontSize: '12px',
          },
        },
        min: 0.5,
        max: 2.5,
        tickWidth: 0,
      },
      yAxis: {
        min: 0,
        minRange: 100, // Ensure the target plot line is visible
        crosshair: {
          color: '#c1c1c1',
          width: 1,
        },
        title: {
          style: {
            fontSize: '12px',
          },
        },
      },
    };

    vm.$onInit = onInit;
    return;
    ////////////////////

    function onInit() {
      vm.gzCard.API.config.setSiteCallback(siteChanged);
      let appLink = $hmPrintBeat.createLink({
        page: 'reports',
        family: 'IndigoPress',
        siteId: vm.gzCard.API.currentSite.getSite().siteId,
      });
      vm.gzCard.API.config.setAppLink(appLink);

      let promises = [];
      promises.push(getVolumes());
      $q.all(promises).then(() => {
        pbDeviceInfoListener = $rootScope.$on('$lpPBDeviceInfoUpdated', getVolumes);
        $scope.$on('$destroy', () => {
          pbDeviceInfoListener();
        });
        let unitText = $translate.instant('home.home.IndigoVolume_Impressions');
        _.set(vm.chartConfig, 'yAxis.title.text', unitText);
        _.set(vm.chartConfig, 'series[0].name', unitText);
        _.set(vm.chartConfig, 'tooltip.pointFormat', `{point.y} ${unitText}`);

        // This is a bit of a hack to ensure that the Highchart directive resizes to fit the card
        $window.addEventListener('resize', handleResize);
        $scope.$on('$destroy', () => {
          $window.removeEventListener('resize', handleResize);
        });
      })
        .finally(() => {
          vm.loading = false;
        });
    }

    function handleResize() {
      $timeout(()=> {
        vm.chartConfig.getChartObj().reflow();
      }, 500);
    }

    function getVolumes() {
      let config = {
        params: {
          unitSystem: 'Metric',
          unitBy: 'Impressions',
          bu: 'IndigoPress',
          resolution: 'Week',
          startTime: moment().subtract(4, 'weeks').format('YYYY-MM-DD'),
          endTime: moment().startOf('week').format('YYYY-MM-DD'),
          devices: vm.gzCard.API.currentSite.getPrintBeatSerialNumbersByFamily(LP_DEVICE_FAMILY.INDIGO),
        },
      };

      return rnHttp.get('PrintbeatService', '/gsb/Scoreable/v2/Print volume', config).then(resp => {
        let pressData = resp.data.data.unitEvents;
        vm.chartConfig.xAxis.categories = [];
        vm.chartConfig.series[0].data = [];

        if (pressData.length > 0) {
          // Combine all the events into an object keyed by date
          let happenedOnCounts = _.groupBy(_.flatten(_.pluck(pressData, 'events')), event => event.happenedOn);
          // Then sum the counts for each date
          _.forEach(happenedOnCounts, (events, happenedOn) => {
            happenedOnCounts[happenedOn] = events.reduce((acc, event) => {
              return acc + event.count;
            }, 0);
          });

          _.forEach(happenedOnCounts, (count, happenedOn) => {
            vm.chartConfig.xAxis.categories.push(moment(happenedOn, 'YYY-MM-DD').format('D MMM'));
            vm.chartConfig.series[0].data.push(count);
          });
        }
      })
        .catch(() => {
          vm.noData = true;
        });
    }

    function siteChanged() {
      getVolumes();
    }
  }
}
