export const name = 'HelpFeedbackDetailsDialogController';
export default function HelpFeedbackDetailsDialogController(
  // Controller angular dependencies
  topicId,
  $mdDialog,
  $gzAnalytics
) {
  'ngInject';
  // Constants

  // Variables

  // View Model
  let vm = this;
  //   VM properties
  vm.loading = true;
  //   VM methods
  vm.cancel = cancel;
  vm.submit = submit;

  vm.activate = activate;
  activate();
  return;

  ///////////////
  // Init
  function activate() {
    vm.loading = false;
  }

  ///////////////
  // VM methods

  function cancel() {
    $mdDialog.cancel();
  }

  function submit() {
    $gzAnalytics.event('Help Feedback Improve', {label: vm.analyticsTitle, feedback: vm.feedback});
    $mdDialog.hide();
  }

  ///////////////
  // Helpers
}
