import './add-card-dialog.scss';
import angularTranslate from 'angular-translate';
import ngMaterial from 'angular-material';

import * as AddCardDialogController from './add-card-dialog.controller';

export default angular.module('home.components.addCardDialog', [
  angularTranslate,
  ngMaterial
])
  .controller(AddCardDialogController.name, AddCardDialogController.default)
  .name;
