import './latex-volume.scss';

import angularTranslate from 'angular-translate';
import angularMoment from 'angular-moment';
import rhino from 'rhino';
import homePrintBeat from 'src/components/landing-page/cards/shared/print-beat/print-beat.module';

import * as hmLatexVolumeDirective from './latex-volume.directive';

export default angular.module('home.components.latexVolume', [
  angularTranslate,
  angularMoment,
  rhino,
  homePrintBeat,
])
  .directive(hmLatexVolumeDirective.name, hmLatexVolumeDirective.default)
  .name;
