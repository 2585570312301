import Highcharts from 'highcharts';

export default function setHighchartsNumberFormat(rnLocaleFormatFilter) {
  'ngInject';

  // Override the Highcharts number filter to use the browser locale string formatter
  // with the user's preferred locale.
  Highcharts.numberFormat = (num, decimals, decimalPoint, thousandsSep) => {
    return rnLocaleFormatFilter(num, decimals);
  };
}
