export const name = 'hmIndigoRanking';
export default function hpIndigoRanking() {
  //noinspection BadExpressionStatementJS
  'ngInject';

  let directive = {
    restrict: 'EA',
    scope: {
      gzCard: '=',
    },
    template: require('./indigo-ranking.jade'),
    controller: hmIndigoRankingController,
    controllerAs: 'vm',
    bindToController: true
  };

  return directive;

  /** @ngInject */
  function hmIndigoRankingController(
    $window,
    rnHttp,
    rnLocale,
    $q,
    $gzAnalytics,
    rnLocaleFormatFilter,
    $hmPrintBeat
  ) {
    let linkContext;

    let vm = this;
    vm.loading = true;
    vm.rnLocaleFormatFilter = rnLocaleFormatFilter;
    vm.goToPrintBeat = goToPrintBeat;

    vm.$onInit = onInit;
    ////////////////////

    function onInit() {
      vm.gzCard.API.config.setSiteCallback(siteChanged);
      vm.Math = $window.Math;

      linkContext = {
        page: 'weekly',
        family: 'IndigoPress',
        siteId: vm.gzCard.API.currentSite.getSite().siteId,
      };
      let appLink = $hmPrintBeat.createLink(linkContext);
      vm.gzCard.API.config.setAppLink(appLink);

      let promises = [];
      promises.push(getRankings());
      $q.all(promises).then(() => {
        vm.loading = false;
      });
    }

    function getRankings() {
      return rnLocale.getBestFit().then(locale => {
        let config = {
          params: {
            siteId: -1,
            lang: locale.value,
          }
        };

        let site = vm.gzCard.API.currentSite.getSite();
        if (site) {
          config.params.siteId = site.siteId;
        }

        return rnHttp.get('PrintbeatService', '/ranking', config).then(resp => {
          vm.rankings = resp.data;
        });
      });
    }

    function siteChanged() {
      getRankings();
    }

    function goToPrintBeat($event) {
      $gzAnalytics.eventClick('go to print beat: ranking');
      $hmPrintBeat.redirect(linkContext, $event);
    }
  }
}
