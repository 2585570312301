import './channel-psps.scss';

import rhino from 'rhino';
import angularTranslate from 'angular-translate';
import gazelleAnalytics from 'gazelle/src/core/analytics/analytics.module';

import * as hmChannelPspsDirective from './channel-psps.directive';

export default angular.module('home.components.widget.hmChannelPsps', [
  rhino,
  angularTranslate,
  'gazelle.components.go',
  gazelleAnalytics,
])
  .directive(hmChannelPspsDirective.name, hmChannelPspsDirective.default)
  .name;
