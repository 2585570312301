import './org-code-dialog.scss';
import ngMaterial from 'angular-material';
import uiRouter from 'angular-ui-router';
import rnHttp from 'rhino/src/core/http/http.module';
import angularTranslate from 'angular-translate';

import * as OrgCodeDialogController from './org-code-dialog.controller';

export default angular.module('home.components.orgCodeDialog', [
  rnHttp,
  uiRouter,
  ngMaterial,
  angularTranslate,
])
  .controller(OrgCodeDialogController.name, OrgCodeDialogController.default)
  .name;
