import './indigo-site.scss';

import rhino from 'rhino';
import angularTranslate from 'angular-translate';
// Required to trust CDN images in tests
import gazelleCore from 'gazelle/src/core/core.module';
import gazelleAnalytics from 'gazelle/src/core/analytics/analytics.module';
import gazelleGo from 'gazelle/src/components/go/go.module';
import images from 'src/images/images.module';
import homePrintBeat from 'src/components/landing-page/cards/shared/print-beat/print-beat.module';
import homeCircleProgress from 'src/components/landing-page/cards/shared/circle-progress/circle-progress.module';

import * as hmIndigoSiteDirective from './indigo-site.directive';

export default angular.module('home.components.widget.hmIndigoSite', [
  rhino,
  angularTranslate,
  gazelleCore,
  gazelleGo,
  gazelleAnalytics,
  images,
  homePrintBeat,
  homeCircleProgress,
])
  .directive(hmIndigoSiteDirective.name, hmIndigoSiteDirective.default)
  .name;
