const SUPPORTAPP_SERVICE = 'supportapp_service';
const CACHE_DEVICE_INFORMATION = 'cache/device-information';

export const moduleName = 'RcdaService';
export default class RcdaService {
  /** @ngInject */
  constructor($q, rnHttp) {
    this.$q = $q;
    this.rnHttp = rnHttp;
    this.cache = undefined;
  }

  static factory($q, rnHttp) {
    'ngInject';
    return new RcdaService($q, rnHttp);
  }

  loadCache() {
    const deferred = this.$q.defer();

    if (this.cache !== undefined) {
      deferred.resolve(this.cache);
    } else {
      this.rnHttp.get(SUPPORTAPP_SERVICE, CACHE_DEVICE_INFORMATION).then(response => {
        this.cache = response;
        deferred.resolve(response);
      }).catch(response => {
        deferred.reject(response);
      });
    }

    return deferred.promise;
  }

  getCache() {
    return this.cache;
  }

  getPressSeries() {
    return this.getCache().data.pressSeries.map(pressSeries => pressSeries.name);
  }

  getRegions() {
    return this.getCache().data.regions.map(
      region => region.name
    );
  }

  getSubRegionsFrom(targetRegion) {
    return this.getCache().data.regions.find(
      region => region.name === targetRegion
    ).subRegions.map(
      subregion => subregion.name
    );
  }

  getDistrictsFrom(targetRegion, targetSubRegion) {
    return this.getCache().data.regions.find(
      region => region.name === targetRegion
    ).subRegions.find(
      subregion => subregion.name === targetSubRegion
    ).districts.map(
      district => district.name
    );
  }

  getCountriesFrom(targetRegion, targetSubRegion, targetDistrict) {
    return this.getCache().data.regions.find(
      region => region.name === targetRegion
    ).subRegions.find(
      subregion => subregion.name === targetSubRegion
    ).districts.find(
      district => district.name === targetDistrict
    ).countries.map(
      country => country.name
    );
  }
}
