export const name = 'hmIndigoSCLink';
export default function hmIndigoSCLink(
  // Directive angular dependencies
) {
  'ngInject';

  let directive = {
    restrict: 'EA',
    scope: {
        gzCard: '=',
    },
    template: require('./indigo-sc-link.jade'),
    controller: indigoSCLinkController,
    controllerAs: 'vm',
    bindToController: true,
  };
  return directive;

  //////////////////////

  // function indigoServiceCenterLink(scope, element, attrs) {
  // }

  /** @ngInject */
  function indigoSCLinkController(
    rnHttp,
    $q,
    $window
  ) {
    // Constants

    // View Model
    let vm = this;

    //VM properties
    vm.loading = true;

    //VM methods
    vm.$onInit = onInit;
    vm.goToServiceCenter = goToServiceCenter;
    return;

    ///////////////
    // Init
    function onInit() {
      vm.loading = false;
    }

    ///////////////
    // VM methods

    function goToServiceCenter($event){
      $event.stopPropagation();
      $window.open(this.gzCard.link, '_blank');
    }
  }
}
