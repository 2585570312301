export const name = 'SettingsAboutController';

export default function SettingsAboutController(
  rnSession,
  $window,
  $stateParams,
  $location,
  $state,
  $q,
  rnPreferences
) {
  //noinspection BadExpressionStatementJS
  'ngInject';
  let vm = this;
  let myParams = {};
  let returnUrl;

  vm.loading = true;
  vm.showDetails = false;
  vm.versions = [];
  vm.toggleDetails = toggleDetails;

  vm.goBack = goBack;
  vm.goTo = goTo;

  vm.breadcrumbs = [
    {
      state: 'dashboard',
      titleKey: 'home.home.Home_title'
    },
    {
      state: 'settings',
      titleKey: 'home.home.My_Settings'
    },
    {
      state: 'about',
      titleKey: 'home.home.About'
    }
  ];

  vm.unitTest = {
    setMyParams: function(params){myParams = params;},
    setReturnUrl: function(url) {returnUrl = url;},
  };

  vm.$onInit = onInit;
  return;
  ////////////////////////
  function onInit() {
    parseParams();
    let promises = [];
    promises.push(getSystemInfo());
    promises.push(getPreferences());
    return $q.all(promises).finally(() => {
      vm.loading = false;
    });
  }

  function parseParams() {
    myParams = {
      data: $stateParams.data,
    };

    if ($stateParams.search) {
      vm.searchText = $stateParams.search;
    }
    returnUrl = $stateParams.returnUrl;
    if (returnUrl) {
      returnUrl = $window.decodeURIComponent(returnUrl);
      _.set(myParams, 'data.returnUrl', returnUrl);
    }
  }

  function getSystemInfo() {
    return rnSession.systemVersions().then(data => {
      vm.versions = data;
      vm.printOSInfo = _.find(vm.versions, {name: 'printos'});
    });
  }

  function getPreferences() {
    return rnPreferences.getAll().then(prefs => {
      vm.showDetails = _.get(prefs, 'home.showAboutDetails', true);
    });
  }

  function toggleDetails() {
    rnPreferences.setSingle('home', 'showAboutDetails', vm.showDetails);
  }

  function goTo($event, state) {
    $event.stopPropagation();
    switch (state) {
      case 'terms':
        $state.go(state, myParams);
        break;
      case 'open-source':
        $state.go(state, myParams);
        break;
      default:
        break;
    }
  }

  function goBack($event) {
    $event.stopPropagation();
    if (returnUrl) {
      $window.location.href = `/api/portal/v1/go/validate?url=${$window.encodeURIComponent(returnUrl)}`;
      return;
    }

    $state.go('dashboard');
  }
}
