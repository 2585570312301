/**
 * Created by panda on 9/15/2016.
 */
import uiRouter from 'angular-ui-router';
import * as supportCaseDetailService from './support_case_detail.service';
import * as supportCaseControllerDetail from './support_case_detail.controller';
import * as contextService from '../util/context.service';

export default angular.module('appHelpPanel.support-case-detail', [
  'gazelle.components.l10n',
  'ngMaterial',
  'ngMessages',
  uiRouter
]).factory(supportCaseDetailService.moduleName, supportCaseDetailService.default)
  .factory(contextService.moduleName, contextService.default)
  .controller(supportCaseControllerDetail.moduleName, supportCaseControllerDetail.default)
  .name;
