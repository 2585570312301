export const name = 'hmLatexServiceCenter';
export default function hmLatexServiceCenter() {
  //noinspection BadExpressionStatementJS
  'ngInject';

  let directive = {
    restrict: 'EA',
    scope: {
      gzCard: '=',
    },
    template: require('./latex-service-center.jade'),
    controller: hmLatexServiceCenterController,
    controllerAs: 'vm',
    bindToController: true
  };

  return directive;

  /** @ngInject */
  function hmLatexServiceCenterController(
    $window,
    rnHttp,
    $q,
    $gzAnalytics
  ) {

    let vm = this;
    vm.loading = true;

    vm.customers = '--';
    vm.printers = '--';
    vm.maintenance = '--';
    vm.responseTime = '--';

    vm.$onInit = onInit;
    return;
    ////////////////////

    function onInit() {
      let promises = [];
      promises.push(getData());
      $q.all(promises).then(() => {
      })
        .finally(() => {
          vm.loading = false;
        });
    }

    function getData() {
      let config = {
        params: {}
      };

      return rnHttp.get('reseller-portal', '/api/v1/dashboard/stats', config).then(resp => {
        let stats = resp.data;
        vm.customers = _.get(resp, 'data.result.urgentCustomers', '--');
        vm.printers = _.get(resp, 'data.result.printersInError', '--');
        vm.maintenance = _.get(resp, 'data.result.maintenancePending', '--');
        vm.responseTime = _.get(resp, 'data.result.averageTimeToSolution', '--');
      }).catch(error => {
        console.log('Failed to get LF Dashboard information for Configuration Center card');
      });
    }
  }
}
