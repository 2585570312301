import rhino from 'rhino';
import angularMoment from 'angular-moment';
import angularTranslate from 'angular-translate';
import './site-flow.scss';
import gazelleAnalytics from 'gazelle/src/core/analytics/analytics.module';
import images from 'src/images/images.module';

import * as hmSiteFlowDirective from './site-flow.directive';
import * as hmSiteFlowService from './site-flow.service';

export default angular.module('home.components.widget.siteFlow', [
  rhino,
  angularMoment,
  angularTranslate,
  'gazelle.components.go',
  gazelleAnalytics,
  images
])
  .directive(hmSiteFlowDirective.name, hmSiteFlowDirective.default)
  .service(hmSiteFlowService.name, hmSiteFlowService.default)
  .name;
