export const name = 'AccountsDeleteController';
export default function AccountsDeleteController(
  $mdDialog,
  $gzAnalytics
) {
  //noinspection BadExpressionStatementJS
  'ngInject';

  /* jshint validthis: true */
  var vm = this;

  vm.deleteAccount = deleteAccount;
  vm.cancel = cancel;

  vm.activate = activate;
  activate();
  return;
  ////////////////

  function activate() {
  }

  function deleteAccount() {
    $mdDialog.hide(true);
    $gzAnalytics.eventDelete('delete account');
  }

  function cancel() {
    $mdDialog.cancel();
    $gzAnalytics.eventCancel('delete account');
  }
}
