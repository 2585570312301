export const name = 'SubscriptionsEditController';
export default function SubscriptionsEditController(
  $log,
  rnNotificationService,
  $analytics,
  $q,
  $translate,
  rnPreferences,
  hmNotifications,
  rnHttp,
  $mdDialog,
  $state,
  $stateParams,
  $location,
  $window,
  $mdMedia,
  $gzAnalytics,
  rnBlue,
  $sce
) {
  //noinspection BadExpressionStatementJS
  'ngInject';

  /* jshint validthis: true */

  const STATE_FILTER = 'stateFilter';
  const ENTITY_FILTER = 'entityFilter';

  const STATE_FILTER_EVENT = 'filter - state';
  const ITEM_FILTER_EVENT = 'filter - item';
  const VALUE_FILTER_EVENT = 'filter - value';

  const NOTIFICATIONS = 'notifications';
  const BROWSER_NOTIFICATIONS = 'browserNotifications';
  const NOTIFICATIONS_BROWSER_NOTIFICATIONS = 'notifications.browserNotifications';

  const STRING_CONDITIONS = [
    { value: 'beginsWith' },
    { value: 'endsWith' },
    { value: 'contains' },
    { value: 'equals' }
  ];

  const NUMBER_CONDITIONS = [
    { value: 'greaterThan' },
    { value: 'greaterThanOrEquals' },
    { value: 'lessThan' },
    { value: 'lessThanOrEquals' },
    { value: 'equals' }
  ];

  // Event descriptions contain flags to show which delivery methods are supported.
  // Sadly, these names do not match the names of the delivery name for every name
  // (in particular, "web" is used for "browser"). So, we have to map these names here.
  const DELIVERY_METHOD_SUPPORTED_NAME = {
    browser: 'web',
    email: 'email',
    mobile: 'mobile',
  };

  let vm = this;
  let subscriptions = [];
  let npService = hmNotifications;
  let hasValueFilters = false;
  let myParams = {};
  let returnUrl;

  /// controller methods ///
  //////////////////////////
  vm.toggleShowSubscribed = toggleShowSubscribed;
  vm.applicationHasTopics = applicationHasTopics;
  vm.toggleSubscription = toggleSubscription;
  vm.hasDestinations = hasDestinations;
  vm.toggleDeliveryMethod = toggleDeliveryMethod;
  vm.showMobileOnboarding = showMobileOnboarding;
  vm.hasStateFilter = hasStateFilter;
  vm.updateStates = updateStates;
  vm.hasEntityFilter = hasEntityFilter;
  vm.toggleAll = toggleAll;
  vm.toggleSingle = toggleSingle;
  vm.updateFilters = updateFilters;
  vm.addFilter = addFilter;
  vm.hasFilters = hasFilters;
  vm.getSummary = getSummary;
  vm.removeFilter = removeFilter;
  vm.close = close;
  vm.getSubDisplayTitle = getSubDisplayTitle;
  vm.isDrillDownMode = isDrillDownMode;
  vm.clearSelection = clearSelection;
  vm.webNotificationsChanged = webNotificationsChanged;
  vm.webNotificationsHelpClicked = webNotificationsHelpClicked;
  vm.hasAnyAppSubscriptions = hasAnyAppSubscriptions;

  /// controller variables ///
  ////////////////////////////
  let mobileAppsThatHaveBeenShown = [];
  vm.isLoading = true;
  vm.showSubscribedOnly = false;
  vm.applications = [];
  vm.subscriptionClicked = subscriptionClicked;
  vm.deliveryMethods = [];
  vm.supportsValueFilters = false;
  vm.radioValue = 'ALWAYS_SEND';
  vm.isMobileAppInstalled = false;
  vm.webNotifications = {
    auth: 'default',
    enabled: false,
    helpEnabled: false,
    prefs: null
  };
  vm.breadcrumbs = [
    {
      state: 'dashboard',
      titleKey: 'home.home.Home_title'
    },
    {
      state: 'settings',
      titleKey: 'home.home.My_Settings'
    },
    {
      state: 'subscriptions-edit',
      titleKey: 'home.home.Notification_Subscriptions'
    }
  ];
  vm.submitting = false;

  vm.unitTest = {
    parseParams: parseParams,
    setupDeliveryMethods: setupDeliveryMethods,
    setupStates: setupStates,
    cleanStateFilter: cleanStateFilter,
    resetSubscriptionState: resetSubscriptionState,
    getHasValueFilters: function() {return hasValueFilters;},
    setHasValueFilters: function(value) {hasValueFilters = value;},
    subscriptionEventForAnalytics: subscriptionEventForAnalytics,
    analyticsStateFilter: analyticsStateFilter,
    analyticsItemFilter: analyticsItemFilter,
    analyticsValueFilter: analyticsValueFilter,
    setCurrentSubscription: setCurrentSubscription,
    loadPreferences: loadPreferences,
    getShowMobile: function() {return mobileAppsThatHaveBeenShown;},
    setShownMobile: function(value) {mobileAppsThatHaveBeenShown = value;},
  };

  vm.$onInit = onInit;
  return;


  function onInit() {
    parseParams();
    vm.errorKey = null;
    loadPreferences();
    let promises = [];
    promises.push(loadSubscriptions());
    $q.all(promises)
      .catch(() => {
        vm.errorKey = 'notification.notification.Subscription_Load_Error';
      })
      .finally(() => {
        vm.isLoading = false;
        initConditions();
      });
  }

  function loadSubscriptions() {
    return npService.loadSubscriptions().then(data => {
      subscriptions = data.userSubscriptionList;
      return npService.loadApplications().then(data => {
        vm.applications = data.applicationList;
        return getApplicationData();
      });
    });
  }

  function initConditions() {
    setConditionDisplayStrings(STRING_CONDITIONS);
    setConditionDisplayStrings(NUMBER_CONDITIONS);
  }

  function setConditionDisplayStrings(conditions) {
    _.forEach(conditions, (condition) => {
      switch (condition.value) {
        case 'beginsWith':
          _.set(condition, 'display', $translate.instant('notification.notification.BEGINS_WITH'));
          break;
        case 'endsWith':
          _.set(condition, 'display', $translate.instant('notification.notification.ENDS_WITH'));
          break;
        case 'contains':
          _.set(condition, 'display', $translate.instant('notification.notification.CONTAINS'));
          break;
        case 'equals':
          _.set(condition, 'display', $translate.instant('notification.notification.EXACT'));
          break;
        case 'greaterThan':
          _.set(condition, 'display', $translate.instant('notification.notification.GREATER_THAN'));
          break;
        case 'greaterThanOrEquals':
          _.set(condition, 'display', $translate.instant('notification.notification.GREATER_THAN_OR_EQUAL'));
          break;
        case 'lessThan':
          _.set(condition, 'display', $translate.instant('notification.notification.LESS_THAN'));
          break;
        case 'lessThanOrEquals':
          _.set(condition, 'display', $translate.instant('notification.notification.LESS_THAN_OR_EQUAL'));
          break;
      }
    });
  }
  // destinations ------------------------------------------------------------------------------------------------------

  function setupDeliveryMethods() {
    vm.deliveryMethods = [];

    let eventDesc = getApplicationSubscription(vm.subscription);
    vm.subscription.deliveryMethods.forEach(deliveryMethod => {
      let supportedName = DELIVERY_METHOD_SUPPORTED_NAME[deliveryMethod.name];
      if (eventDesc[supportedName]) {
        // Delivery method supported.
        vm.deliveryMethods.push(deliveryMethod);
      }
    });
  }

  function hasDestinations() {
    return vm.subscription ? vm.subscription.deliveryMethods && vm.subscription.deliveryMethods.length > 0 : false;
  }

  function setupStates() {
    vm.stateFilter = _.find(vm.subscription.filters, {name: STATE_FILTER});
    if (vm.stateFilter && vm.stateFilter.list) {
      for(let i=0; i < vm.stateFilter.list.length; i++) {
        let state = vm.stateFilter.list[i];
        _.set(state, 'internal.title', $translate.instant('notification.notification.' + state.stateName));
      }
    }
  }

  function hasEntityFilter() {
    if(vm.subscription && vm.subscription.filters) {
      return (_.find(vm.subscription.filters, {name: "entityFilter"}) !== undefined);
    }

    return false;
  }

  function hasStateFilter() {
    if(vm.subscription && vm.subscription.filters) {
      return (_.find(vm.subscription.filters, {name: "stateFilter"}) !== undefined);
    }

    return false;
  }

  function toggleDeliveryMethod(deliveryMethod) {
    if (deliveryMethod.enabled) {
      // Currently enabled, so trying to disable. Ensure there's another enabled dest.
      let enabledDeliveryMethods = _.reduce(vm.deliveryMethods, (sum, method) => {
        if (method.enabled) {
          sum++;
        }
        return sum;
      }, 0);

      if (enabledDeliveryMethods === 1) {
        $mdDialog.show(
          $mdDialog.alert()
            .parent(angular.element(document.body))
            .targetEvent(null)
            .clickOutsideToClose(false)
            .escapeToClose(true)
            .title($translate.instant('home.home.Destination_Error'))
            .textContent($translate.instant('home.home.Error_At_Least_One_Destination'))
            .ok($translate.instant('uxf.uxf.Dialog_OK'))
        );
        return $q.resolve();
      }
    }

    deliveryMethod.enabled = !deliveryMethod.enabled;
    $analytics.eventTrack("notification destination changed", {category: 'notification', label: `${vm.subscription.eventDescriptionId}: ${deliveryMethod.name} - ${deliveryMethod.enabled}`});
    if (deliveryMethod.enabled) {
      if (deliveryMethod.canCreate()) {
        return deliveryMethod.create().then(({data: success}) => {
          // TODO: how do we report a failure here?
          updateSubscription();

          if (!success && deliveryMethod.name === 'mobile' && !vm.subscription.mobile) {
            vm.showMobileOnboarding();
          }
        });
      }
    } else {
      return deliveryMethod.destroy().then(() => {
        updateSubscription();
      });
    }
  }

  function showMobileOnboarding() {
    if (mobileAppsThatHaveBeenShown.indexOf(vm.subscription.mobileAppName) === -1) {
      $analytics.eventTrack("mobile app onboarding dialog shown", {category: 'notification'});
      mobileAppsThatHaveBeenShown.push(vm.subscription.mobileAppName);
      $mdDialog.show({
        template: require('./mobile_onboarding.jade'),
        controller: 'MobileOnboardingController',
        bindToController: true,
        controllerAs: 'vm',
        locals: {
          subscription: vm.subscription
        }
      });
    }
  }

  function updateSubscription() {
    vm.submitting = true;
    return rnNotificationService.getSubscription(vm.subscription._links.getSubscription).then(subscription => {
      _.remove(subscriptions, {eventDescriptionId: subscription.eventDescriptionId});
      subscriptions.push(subscription);
      setSubscription(subscription);
    }).finally(() => {
      vm.submitting = false;
    });
  }

  function setSubscription(subscription) {
    vm.subscription = subscription;
    setupFilters();
  }

  // states ------------------------------------------------------------------------------------------------------------

  function cleanStateFilter(subscription) {
    let sFilter = _.find(subscription.filters, {name: STATE_FILTER});
    if (!sFilter) {
      return;
    }

    for(let i=0; i<sFilter.list.length; i++) {
      if (sFilter.list[i].internal) {
        delete sFilter.list[i].internal;
      }
    }
  }

  // subscriptions -----------------------------------------------------------------------------------------------------

  function subscriptionClicked(subscription, $event) {
    if (vm.submitting) {
      return;
    }
    if ($event) {
      $event.stopPropagation();
    }

    if (_.get(subscription, 'internal.subscribed', false)) {
      if (_.get(subscription, 'internal.expanded', false)) {
        return; // Subscription is already open
      }
      resetSubscriptionState();
      setCurrentSubscription(subscription);
      collapseSubscriptions(subscription);
      _.set(subscription, 'internal.expanded', true);

      vm.drillDown = true;
    }
    else {
      clearSelection();
    }
  }

  function isDrillDownMode() {
    return !$mdMedia('gt-xs');
  }

  function clearSelection() {
    if (vm.subscription) {
      let found = getApplicationSubscription(vm.subscription);
      if (found) {
        _.set(found, 'internal.expanded', false);
      }
      resetSubscriptionState();
      vm.subscription = undefined;
    }
    vm.drillDown = false;
  }

  function collapseSubscriptions(subscription) {
    _.forEach(vm.applications, (application) => {
      _.forEach(application.subscriptions, (sub) => {
        _.set(sub, 'internal.expanded', false);
      });
    });
  }

  function getApplicationSubscription(subscription) {
    let found;
    for (let i=0; i < vm.applications.length; i++) {
      found = _.find(vm.applications[i].subscriptions, {eventDescriptionName: subscription.eventDescriptionId});
      if (found) {
        break;
      }
    }

    return found;
  }

  function setCurrentSubscription(subscription) {
    setSubscription(_.find(subscriptions, {eventDescriptionId: subscription.eventDescriptionName}));
  }

  function toggleSubscription(application, subscription, $event) {
    vm.submitting = true;
    if(subscription.internal.subscribed) {
      return addSubscription(application, subscription);
    }
    else {
      return removeSubscription(application, subscription);
    }
    //subscription.internal.subscribed = !subscription.internal.subscribed;
  }

  function addSubscription(application, subscription) {
    return rnNotificationService.addSubscription(subscription._links.subscribe).then(subscription => {
      vm.submitting = false;
      $log.debug("subscription added successfully:");
      subscriptions.push(subscription);
      let found = _.find(application.subscriptions, {eventDescriptionName: subscription.eventDescriptionId});
      if (found) {
        found.internal.subscribed = true;
        subscriptionClicked(found); // Open the details
      }
      $analytics.eventTrack(subscriptionEventForAnalytics(true, subscription), {category: 'notification', label: subscription.eventDescriptionId});
    })
      .catch(error => {
        vm.submitting = false;
        ga('send', 'exception', {
          exDescription: subscriptionEventForAnalytics(true, subscription) + " failed: " + _.get(error, 'data.smsError.message', 'unknown')
        });
      });
  }

  function removeSubscription(application, subToRemove) {
    let removeId = subToRemove.eventDescriptionName;
    let subscription = _.find(subscriptions, {eventDescriptionId: removeId});
    if (subscription) {
      _.remove(subscriptions, {eventDescriptionId: removeId});
      return rnNotificationService.removeSubscription(subscription._links.delete).then(() => {
        vm.submitting = false;
        $log.debug("subscription removed successfully:", subscription);
        let found = _.find(application.subscriptions, {eventDescriptionName: removeId});
        if (found) {
          _.set(found, 'internal.subscribed', false);
          _.set(found, 'internal.expanded', false);
          delete found.internal.clonedValues;
        }
        if (vm.subscription) {
          let old = getApplicationSubscription(vm.subscription);
          _.set(old, 'internal.expanded', false);
          vm.subscription = undefined;

        }
        resetSubscriptionState();
        $analytics.eventTrack(subscriptionEventForAnalytics(false, subscription), {category: 'notification', label: subscription.eventDescriptionId});
      })
        .catch(error => {
          vm.submitting = false;
          ga('send', 'exception', {
            exDescription: subscriptionEventForAnalytics(false, subscription) + " failed: " + _.get(error, 'data.smsError.message', 'unknown')
          });
        });
    }
    return ($q.resolve());
  }

  function toggleShowSubscribed() {
    return rnPreferences.setSingle(NOTIFICATIONS, 'showSubscribedOnly', vm.showSubscribedOnly);
  }

  // items -------------------------------------------------------------------------------------------------------------

  function toggleAll() {
    if (vm.entityFilter) {
      _.forEach(vm.entityFilter.list, function(entity) {
        entity.selected = vm.selectAll;
      });
      updateItems();
    }
  }

  function toggleSingle(update = false) {
    if (vm.entityFilter) {
      let selectedCount = 0;

      for(let i=0; i<vm.entityFilter.list.length; i++) {
        if (vm.entityFilter.list[i].selected) {
          selectedCount++;
        }
      }

      vm.selectAll = (selectedCount === vm.entityFilter.list.length);
      if (update) {
        updateItems();
      }
    }
  }

  function setupItems() {
    vm.entityFilter = _.find(vm.subscription.filters, {name: ENTITY_FILTER});
    toggleSingle();
  }

  // values ------------------------------------------------------------------------------------------------------------

  function setupValues() {
    if (vm.supportsValueFilters) {
      let hasFilters = false;
      let promises = [];
      for (let filterIndex = 0; filterIndex < vm.subscription.filters.length; filterIndex++) {
        let filter = vm.subscription.filters[filterIndex];
        if (filter.list.length > 0) {
          hasFilters = true;
        }
        _.set(filter, 'internal.display', $translate.instant('notification.notification.' + filter.subLabel));

        if (filter.type === "subValueStringFilterList") {
          if (filter.values) {
            promises.push(getValueList(filter));
          }
          filter.internal.conditions = STRING_CONDITIONS;
        }
        else {
          filter.internal.conditions = NUMBER_CONDITIONS;
        }
      }

      if (hasFilters) {
        vm.radioValue = 'FILTER';
      }

      if (promises.length > 0) {
        $q.all(promises)
          .then(() => {

          });
      }
    }
  }

  function getValueList(filter) {
    return rnHttp.get(filter.values.service, filter.values.path).then((resp) => {
      let data = resp.data;
      let list = _.get(data, filter.values.listRef);
      let idRef = 'id';
      let displayRef = 'display';
      _.set(filter, 'internal.values', []);

      if (filter.values.idRef) {
        idRef = filter.values.idRef;
      }

      if (filter.values.displayRef) {
        displayRef = filter.values.displayRef;
      }

      for (let i = 0; i < list.length; i++) {
        let item = list[i];
        let value = {
          id: _.get(item, idRef),
          display: _.get(item, displayRef)
        };

        let found = _.find(filter.list, {method: 'equals', value: value.id});
        if (found) {
          _.set(value, 'selected', true);
        }
        filter.internal.values.push(value);
      }
    });
  }

  function hasFilters() {
    let hasFilters = false;

    if (vm.subscription.filters) {
      for (let i = 0; i < vm.subscription.filters.length; i++) {
        let filter = vm.subscription.filters[i];
        if (filter.list.length > 0) {
          hasFilters = true;
          break;
        }
      }
    }

    return hasFilters;
  }

  function getSummary(filter, listItem) {
    let data = [];
    data.push(filter.internal.display);
    let summaryKey = '';
    data.push(listItem.value);
    switch (listItem.method) {
      case 'beginsWith':
        summaryKey = 'BeginsWith_Summary';
        break;
      case 'endsWith':
        summaryKey = 'EndsWith_Summary';
        break;
      case 'contains':
        summaryKey = 'Contains_Summary';
        break;
      case 'equals':
        summaryKey = 'Equals_Summary';
        break;
      case 'greaterThan':
        summaryKey = 'GreaterThan_Summary';
        break;
      case 'greaterThanOrEquals':
        summaryKey = 'GreaterThanOrEqual_Summary';
        break;
      case 'lessThan':
        summaryKey = 'LessThan_Summary';
        break;
      case 'lessThanOrEquals':
        summaryKey = 'LessThanOrEqual_Summary';
        break;
    }

    return $translate.instant('notification.notification.' + summaryKey, data);
  }

  function addFilter() {
    for (let filterIndex = 0; filterIndex < vm.subscription.filters.length; filterIndex++) {
      let filter = vm.subscription.filters[filterIndex];
      if (filter.internal && filter.internal.values) {
        filter.internal.clonedValues = _.cloneDeep(filter.internal.values);
        _.remove(filter.internal.clonedValues, {selected: true});
      }
    }

    $mdDialog.show({
      parent: angular.element(document.body),
      clickOutsideToClose: false,
      escapeToClose: true,
      template: require('./add-filter-dialog.jade'),
      controller: 'AddFilterDialogController',
      bindToController: true,
      controllerAs: 'vm',
      locals: {
        filters: vm.subscription.filters
      }
    }).then(function (filter) {
      if (filter.internal.listItem.method === 'equals' && filter.internal.clonedValues && filter.internal.clonedValues.length > 0) {
        for (let valueIndex = 0; valueIndex < filter.internal.clonedValues.length; valueIndex++) {
          let value = filter.internal.clonedValues[valueIndex];
          if (value.selected) {
            let newFilter = {
              method: filter.internal.listItem.method,
              value: value.id
            };

            filter.list.push(newFilter);

            let found = _.find(filter.internal.values, {id: value.id});
            if (found) {
              found.selected = true;
            }
          }
        }
      }
      else {
        let myFilter = {
          method: filter.internal.listItem.method,
          value: filter.internal.listItem.value
        };
        filter.list.push(myFilter);
      }

      delete filter.internal.listItem.value;

      updateFilters();
    });
  }

  function removeFilter(filter, listItem) {
    if (listItem.method === 'equals' && filter.internal.values) {
      let found = _.find(filter.internal.values, {id: listItem.value});
      if (found) {
        delete found.selected;
      }
    }

    _.remove(filter.list, {method: listItem.method, value: listItem.value});
    updateFilters();
  }

  // persist functions -------------------------------------------------------------------------------------------------

  function updateStates() {
    let copy = _.cloneDeep(vm.subscription);
    cleanStateFilter(copy);

    return rnNotificationService.updateFilter(copy._links.updateFilter, copy.filters).then(subscription => {
      setSubscription(subscription);
      updateSubscription();
      $analytics.eventTrack(STATE_FILTER_EVENT, {category: 'notification', label: analyticsStateFilter(subscription)});
    })
      .catch(error => {
        ga('send', 'exception', {
          exDescription: STATE_FILTER_EVENT + " failed: " + _.get(error, 'data.smsError.message', 'unknown')
        });
      });
  }

  function updateItems() {
    let copy = _.cloneDeep(vm.subscription);
    cleanStateFilter(copy);

    return rnNotificationService.updateFilter(copy._links.updateFilter, copy.filters).then(subscription => {
      setSubscription(subscription);
      updateSubscription();
      $analytics.eventTrack(ITEM_FILTER_EVENT, {category: 'notification', label: analyticsItemFilter(subscription)});
    })
      .catch((error) => {
        ga('send', 'exception', {
          exDescription: ITEM_FILTER_EVENT + " failed: " + _.get(error, 'data.smsError.message', 'unknown')
        });
      });
  }

  function updateFilters() {
    if (vm.radioValue === 'ALWAYS_SEND') {
      for (let i = 0; i < vm.subscription.filters.length; i++) {
        let filter = vm.subscription.filters[i];
        filter.list.splice(0, filter.list.length);
        if (_.get(filter, 'internal.values', undefined)) {
          _.forEach(filter.internal.values, (value) => {
            delete value.selected;
          });
        }
      }
    }

    let link = vm.subscription._links.updateFilter;

    return rnNotificationService.updateFilter(link, vm.subscription.filters).then(subscription => {
      setSubscription(subscription);
      updateSubscription();
      $analytics.eventTrack(VALUE_FILTER_EVENT, {category: 'notification', label: analyticsValueFilter(subscription)});
    })
      .catch(error => {
        ga('send', 'exception', {
          exDescription: VALUE_FILTER_EVENT + " failed: " + _.get(error, 'data.smsError.message', 'unknown')
        });
      });
  }

  // general -----------------------------------------------------------------------------------------------------------

  function loadPreferences() {
    return rnPreferences.getAll()
      .then(function(prefs) {
        if(prefs.notifications && prefs.notifications.showSubscribedOnly) {

          let showSubscribed = prefs.notifications.showSubscribedOnly;
          if (showSubscribed) {
            showSubscribed = (showSubscribed === "true");
          }

          if(showSubscribed) {
            vm.showSubscribedOnly = true;
          }
          else {
            vm.showSubscribedOnly = false;
          }
        }
        vm.webNotifications.prefs = _.get(prefs, NOTIFICATIONS_BROWSER_NOTIFICATIONS, null);
        updateWebNotifications();
      });
  }

  function getApplicationData() {
    let promises = [];
    for(let i=0; i<vm.applications.length; i++) {
      let application = vm.applications[i];
      _.set(application, 'internal.title', $translate.instant('notification.notification.' + application.name));
      application.internal.expanded = true;
      promises.push(getApplicationEvents(application));
    }

    return $q.all(promises);
  }

  function getApplicationEvents(application) {
    return rnNotificationService.getEventDescriptionsByApplication(application._links.getEventDescriptions).then(data => {
      application.subscriptions = _.reduce(data.eventDescriptionList, (appSubscriptions, subscription) => {
        if (!_.has(subscription, '_links.subscribe')) {
          // Filter out events we can't subscribe to
          return appSubscriptions;
        }

        _.set(subscription, 'internal.title', $translate.instant('notification.notification.' + subscription.title));
        let found = _.find(subscriptions, {eventDescriptionId: subscription.eventDescriptionName});
        _.set(subscription, 'internal.subscribed', found !== undefined);

        let description = translateDefault(`notification.notification.${subscription.description}`);
        if (description) {
          _.set(subscription, 'internal.description', description);
        }

        appSubscriptions.push(subscription);
        return appSubscriptions;
      }, []);
    });
  }

  function applicationHasTopics(application) {
    if (vm.showSubscribedOnly) {
      if (!application.subscriptions) {
        return false;
      }

      let found = false;
      for(let i=0; i < application.subscriptions.length; i++) {
        if (application.subscriptions[i].internal.subscribed) {
          found = true;
          break;
        }
      }
      return found;
    }
    else {
      if (application.subscriptions) {
        return (application.subscriptions.length > 0);
      }

      return false;
    }
  }

  function setupFilters() {
    if (vm.subscription.filters) {
      for (let filterIndex = 0; filterIndex < vm.subscription.filters.length; filterIndex++) {
        let filter = vm.subscription.filters[filterIndex];
        if (filter.type === 'subValueStringFilterList' || filter.type === 'subValueIntegerFilterList') {
          vm.supportsValueFilters = true;
          if (filter.list && filter.list.length > 0) {
            hasValueFilters = true;
          }
        }
        _.set(filter, 'internal.display', $translate.instant('notification.notification.' + filter.subLabel));
      }
    }

    setupDeliveryMethods();
    setupStates();
    setupItems();
    setupValues();
  }

  function resetSubscriptionState() {
    vm.supportsValueFilters = false;
    hasValueFilters = false;
    vm.stateFilter = null;
    vm.entityFilter = null;
    vm.selectAll = false;
    vm.radioValue = 'ALWAYS_SEND';
    vm.errorKey = null;
    vm.radioOptions = [
      { display: $translate.instant('notification.notification.Send_All'), value: 'ALWAYS_SEND'},
      { display: $translate.instant('notification.notification.Filter_Values'), value: 'FILTER'},
    ];
  }

  function subscriptionEventForAnalytics(subscribe, subscription) {
    let event = subscribe ? 'subscribe -' : 'unsubscribe -';
    if (subscription.browser && subscription.email && subscription.mobile) {
      event += ' all';
    }
    else {
      if (subscription.browser) {
        event += ' browser';
      }
      if (subscription.email) {
        event += ' email';
      }
      if (subscription.mobile) {
        event += ' mobile';
      }
    }

    return event;
  }

  function analyticsStateFilter(subscription) {
    let label = "";
    let stateFilter = _.find(subscription.filters, {name: STATE_FILTER});
    if (stateFilter) {
      label = subscription.eventDescriptionId;
      _.forEach(stateFilter.list, function (filter) {
        label += '|' + $translate.instant('notification.notification.' + filter.stateName);
        label += '|' + filter.transitionOut;
      });
    }
    return label;
  }

  function analyticsItemFilter(subscription) {
    let label = subscription.eventDescriptionId;
    if(vm.entityFilter){
      let selectList = _.filter(vm.entityFilter.list, function(entity) { return entity.selected === true; });
      let selectedCount = 0;
      if(selectList){
        selectedCount = selectList.length;
      }
      label += '|EF ' + selectedCount + '/' + vm.entityFilter.list.length;
    }

    return label;
  }

  function analyticsValueFilter(subscription) {
    let label = subscription.eventDescriptionId;
    _.forEach(subscription.filters, function(filter) {
      if (filter.subLabel) {
        _.forEach(filter.list, function(listItem) {
          label += '|' + filter.subLabel;
          label += '--' + listItem.method;
          label += '--' + listItem.value;
        });
      }
    });

    return label;
  }

  function parseParams() {
    myParams = {
      data: $stateParams.data,
    };

    returnUrl = $stateParams.returnUrl;
    if (returnUrl) {
      returnUrl = $window.decodeURIComponent(returnUrl);
      _.set(myParams, 'data.returnUrl', returnUrl);
    }
  }

  function close($event) {
    $event.stopPropagation();
    if (returnUrl) {
      $window.location.href = `/api/portal/v1/go/validate?url=${$window.encodeURIComponent(returnUrl)}`;
      return;
    }

    $state.go('settings', myParams);
  }

  function getSubDisplayTitle() {
    if (vm.subscription) {
      for (let i = 0; i < vm.applications.length; i++) {
        if (vm.subscription.applicationId === vm.applications[i].id) {
          for (let j = 0; j < vm.applications[i].subscriptions.length; j++) {
            if (vm.subscription.eventDescriptionId === vm.applications[i].subscriptions[j].eventDescriptionName) {
              return vm.applications[i].subscriptions[j].internal.title;
            }
          }
        }
      }
    }
    return null;
  }

  function webNotificationsChanged() {
    $window.Notification.requestPermission(permission => {
      // The way the Notifications API works is the prompt for permission will only occur when Notifications.permission
      // has the value of 'default'. When the user makes a decision to grant or deny, any subsequent call to requestPermission()
      // will return with the original response and without a prompt. Once the user manually sets the permission level back
      // to default the prompt will appear once again.
      vm.webNotifications.auth = permission;
      return rnPreferences.getAll().then(prefs => {
        vm.webNotifications.prefs = _.get(prefs, NOTIFICATIONS_BROWSER_NOTIFICATIONS, null);
        updateWebNotifications(true);
      });
    });
  }

  function webNotificationsHelpClicked() {
    vm.webNotifications.helpEnabled = !vm.webNotifications.helpEnabled;
  }

  function updateWebNotifications(changed = false) {
    vm.webNotifications.auth = $window.Notification.permission;

    switch (vm.webNotifications.auth) {
      // If permission is not granted then we need to switch the toggle back to false
      case 'default':
      case 'denied':
        vm.webNotifications.enabled = false;
        // Until the user grants permissions there is no need to set preferences to false
        if (vm.webNotifications.prefs !== null) {
          return rnPreferences.setSingle(NOTIFICATIONS, BROWSER_NOTIFICATIONS, false);
        }
        $gzAnalytics.event('notifications api authorization change', {label: vm.webNotifications.auth});
        break;
      case 'granted':
        if (!changed) {
          // If this is not a change use the preferences to set the toggle value
          // Using not equal to false also covers the case for integrating Notifications phase 2, when users who have
          // already granted permission but who do not yet have a 'browser notifications' preference set to true.
          let enabled = vm.webNotifications.prefs !== 'false';
          vm.webNotifications.enabled = enabled;
          return rnPreferences.setSingle(NOTIFICATIONS, BROWSER_NOTIFICATIONS, enabled);
        }
        else {
          $gzAnalytics.event('notifications api preference change', {label: vm.webNotifications.enabled});
          return $printos.v1.preferences.$printos.v1.preferences.setPreferenceAsync('browserNotifications', vm.webNotifications.enabled, 'notifications').then(() => {
            $printos.v1.client.sendEvent('webNotificationsRequest');
          });
        }
    }
  }

  function hasAnyAppSubscriptions() {
    return _.some(vm.applications, app => {
      return app.subscriptions.length > 0;
    });
  }

  function translateDefault(locKey, defaultValue) {
    let translation = $translate.instant(locKey);
    if (translation === locKey) {
      // Missing translation.  Return default string.
      return defaultValue;
    }
    return $sce.trustAsHtml(translation);
  }
}
