export default function Composition() {
  /*
    Note: this object must be used as a mixin
  */
  return {
    bindDependencies(dependencies) {
      _.keys(dependencies).forEach(key => this[key] = dependencies[key]);
    }
  };
}
