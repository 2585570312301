export const name = 'TextPromptDialogController';
export default function TextPromptDialogController(
  // Controller angular dependencies
  $mdDialog,
  rnHttp,
  $q
) {
  'ngInject';
  // Constants

  // Variables
  let initialValue;
  let validators;

  // View Model
  let vm = this;
  //   VM properties
  vm.loading = true;
  vm.valid = false;

  //   VM methods
  vm.cancel = cancel;
  vm.submit = submit;
  vm.disable = disable;

  vm.activate = activate;
  activate();
  return;

  ///////////////
  // Init
  function activate() {
    initialValue = vm.value;
    if (vm.regexKey) {
      vm.regex = validator(vm.regexKey);
    }
    vm.loading = false;
  }

  ///////////////
  // VM methods
  function disable() {
    if (initialValue === vm.value) {
      return true;
    }

    return !vm.valid;
  }

  function cancel() {
    $mdDialog.cancel();
  }

  function submit() {
    $mdDialog.hide(vm.value);
  }

  ///////////////
  // Helpers
  function validator(id) {
    return $printos.v1.client.getValidator(id);
  }

}
