import './learn-my-courses.scss';

import * as hmLearnMyCoursesDirective from './learn-my-courses.directive';
import angularMoment from 'angular-moment';
import angularTranslate from 'angular-translate';
import ngMaterial from 'angular-material';
import configTrustedResources from '../../trust-images.config';

export default angular.module('home.components.learnMyCourses', [
  angularMoment,
  angularTranslate,
  ngMaterial
])
  .directive(hmLearnMyCoursesDirective.name, hmLearnMyCoursesDirective.default)
  .config(configTrustedResources)
  .name;
