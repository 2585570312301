export const name = 'hmLearnMyCourses';

export default function hmLearnMyCourses(
  // Directive angular dependencies
) {
  'ngInject';

  let directive = {
    restrict: 'EA',
    scope: {
      gzCard: '=',
    },
    template: require('./learn-my-courses.jade'),
    controller: learnMyCoursesController,
    controllerAs: 'vm',
    bindToController: true,
  };
  return directive;

  //////////////////////

  /** @ngInject */
  function learnMyCoursesController(
    // Controller angular dependencies
    $q,
    $translate,
    $rootScope,
    $scope,
    $window,
  ) {
    // Constants

    // Variables

    // View Model
    let vm = this;
    vm.data = {
      totalCourse: '--',
      totalCompletedCourse: '--',
      totalInProgressCourse: '--',
      totalNotStartedCourse: '--',
    };

    vm.goToLearn = goToLearn;
    vm.$onInit = onInit;
    vm.loading = true;
    return;

    function onInit() {
      //vm.gzCard.headerAction.clicked = handleHelpClicked;
      $scope.$on('$destroy', () => {
      });

      let learnApp = $window.$printos.v1.app.getAppById('university');
      vm.gzCard.API.config.setAppLink(learnApp._links.self);

      return $window.$printos.v1.http.post('learn_api', 'api/learn/Manager/LearnUserDashboardData').then(resp => {
        vm.data = resp.data;
      })
        .catch(e => {
          console.warn('Error fetching My Courses card data', e);
          return $q.resolve();
        })
        .finally(() => {
          vm.loading = false;
        });
    }


    function goToLearn($event) {
      $event.stopPropagation();
      $window.$printos.v1.app.goToApp('university');
    }
  }
}
