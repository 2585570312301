export const name = 'hmLatexSupport';
export default function hmLatexSupport(
  // Directive angular dependencies
) {
  'ngInject';

  let directive = {
    restrict: 'EA',
    scope: {
      gzCard: '=',
    },
    template: require('./latex-support.jade'),
    link: latexSupportLink,
    controller: latexSupportController,
    controllerAs: 'vm',
    bindToController: true,
  };
  return directive;

  //////////////////////

  function latexSupportLink(scope, element, attrs) {
  }

  /** @ngInject */
  function latexSupportController(
    // Controller angular dependencies
  ) {
    // Constants

    // Variables

    // View Model
    let vm = this;

    vm.$onInit = onInit;
    return;

    function onInit() {
    }
  }
}
