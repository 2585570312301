export const name = 'hmColorConfig';
export default function hmColorConfig(
  // Directive angular dependencies
) {
  'ngInject';

  let directive = {
    restrict: 'EA',
    scope: {
      gzCard: '=',
    },
    template: require('./color-config.jade'),
    link: colorConfigLink,
    controller: colorConfigController,
    controllerAs: 'vm',
    bindToController: true,
  };
  return directive;

  //////////////////////

  function colorConfigLink(scope, element, attrs) {
  }

  /** @ngInject */
  function colorConfigController(
    // Controller angular dependencies
    rnHttp,
    $q,
    rnOrganization,
    $gzGo
  ) {
    // Constants

    // Variables

    // View Model
    let vm = this;
    let organization;
    vm.loading = true;
    vm.data = [];
    vm.$onInit = onInit;
    vm.goToApp = goToApp;

    return;

    function onInit() {
      let promises = [];
      promises.push(getOrganization());
      $q.all(promises).then(() => {
        return getData();
      }).finally(() => {
          vm.loading = false;
      });
    }

    function getOrganization(){
      return rnOrganization.getOrganization().then(function(org) {
        organization = org;
      });
    }

    function getData(){
      let input = { 'org_id': organization.data.organizationId, 'site_id': vm.gzCard.API.currentSite.getSite().siteId };
      let config = {
        withCredentials: true,
        // On some stacks this API doesn't exist, and returns the "not found" page.
        // Prevent HTML from returning by restricting the type to json
        // (in which case the call with succeed but the data will be null).
        responseType: 'json',
      };
      return rnHttp.post('color-config','/api/v1/device/dashboard', input, config).then(resp => {
        if(resp && resp.data) {
          vm.data = resp.data;
        }
      })
       .catch(error => {
        $q.resolve([]);
      });
    }

    function goToApp($event){
      $event.stopPropagation();
      $gzGo.toContext('colorConfigWithSite', vm.gzCard.API.currentSite.getSite().siteId);
    }
  }
}
