export default function mixin(...factories) {
  const mixedClass = function() {};
  mixedClass.contructor = {};
  factories.forEach(factory => {
    const obj = factory();
    for (let prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        mixedClass.prototype[prop] = obj[prop];
      }
    }
  });
  return mixedClass;
}
