import './feedback.scss';
import uiRouter from 'angular-ui-router';

import { moduleName as FeedbackControllerName, default as FeedbackController } from './feedback.controller';
import { moduleName as FeedbackControllerModalName, default as FeedbackModalController } from './feedback_modal.controller';
import { moduleName as contextServiceName, default as ContextService } from '../util/context.service';
import { moduleName as feedbackName, default as FeedbackDirective } from './feedback.directive';
import { moduleName as feedbackModalName, default as FeedbackModalDirective } from './feedback_modal.directive';
import rnLocale from 'rhino/src/components/locale/locale.module';
import rnHttp from 'rhino/src/core/http/http.module';
import rnPreferences from 'rhino/src/components/preferences/preferences.module';
import rnUser from 'rhino/src/components/user/user.module';

export default angular.module('appHelpPanel.feedback', [
  'gazelle.components.l10n',
  'ngMaterial',
  'ngMessages',
  'gcs.feedbackService',
  'angulartics',
  'ngFileUpload',
  rnLocale,
  rnHttp,
  rnPreferences,
  rnUser,
  uiRouter
])
  .controller(FeedbackControllerName, FeedbackController)
  .controller(FeedbackControllerModalName, FeedbackModalController)
  .factory(contextServiceName, ContextService)
  .directive(feedbackName, FeedbackDirective)
  .directive(feedbackModalName, FeedbackModalDirective)
  .name;
