export const name = 'WelcomeOrgController';
export default function WelcomeOrgController(
  // Controller angular dependencies
  rnHttp,
  rnClient,
  rnRegistry,
  $gzProfileImage,
  $translate,
  $gzAnalytics,
  $window,
  $state,
  $stateParams,
  $timeout,
  $q
) {
  'ngInject';
  // Constants
  const ANALYTICS_CATEGORY = 'Welcome Organization';

  // Variables
  let cc;
  let validators;

  // View Model
  let vm = this;

  //   VM properties
  vm.loading = true;
  vm.jobFunctionValid = false;
  vm.playerAPI = {};

  //   VM methods
  vm.changeProfileImage = changeProfileImage;
  vm.editHPAccount = editHPAccount;
  vm.disableSubmit = disableSubmit;
  vm.submit = submit;
  vm.validator = validator;
  vm.done = redirect;

  vm.$onInit = onInit;
  return;

  ///////////////
  // Init
  function onInit() {
    let promises = [];
    promises.push(getClientConfig());
    promises.push(getHpidAccountUrl());
    return $q.all(promises).then(() => {
    })
      .finally(() => {
        $gzAnalytics.event('Welcome Organization - Show', {category: ANALYTICS_CATEGORY});
        vm.loading = false;
      });
  }

  function getClientConfig() {
    return rnClient.getConfig().then(resp => {
      cc = resp;
      vm.orgName = cc.context.name;
      vm.currentProfileImageSrc = _.get(cc, 'entity.persona.image', null);

      vm.jobTitle = _.get(cc, 'entity.persona.jobTitle');
      vm.jobFunctions = _.get(cc, 'entity.persona.myTypes', []);
      vm.jobFunctionOptions = [];
      let personaTypes = _.get(cc, 'entity.persona.types', []);
      personaTypes.forEach(pt => {
        if (pt !== 'Other' && pt !== 'NoValue') {
          vm.jobFunctionOptions.push({
            value: pt,
            display: $translate.instant(`uxf.uxf.Persona_${pt}`),
          });
        }
      });

      vm.jobFunctionOptions = vm.jobFunctionOptions.sort((a,b) => {
        return a.display.trim().toLocaleLowerCase().localeCompare(b.display.trim().toLocaleLowerCase());
      });

      //Special case move Other and NoValue to end
      if (personaTypes.includes('Other')) {
        vm.jobFunctionOptions.push({
          value: 'Other',
          display: $translate.instant('uxf.uxf.Persona_Other'),
        });
      }

      // if (personaTypes.includes('NoValue')) {
      //   vm.jobFunctionOptions.push({
      //     value: 'NoValue',
      //     display: $translate.instant('uxf.uxf.Persona_NoValue'),
      //   });
      // }

      initDisplayName();
    }).catch(error => {
      vm.errorKey = 'home.home.GettingStartedError';
    });
  }

  function validator(id) {
    return $printos.v1.client.getValidator(id);
  }

  function getHpidAccountUrl() {
    rnRegistry.getKey('hpidAccountUrl').then(value => {
      vm.hpidAccountUrl = value;
    });
  }

  function getCurrentProfileImageFromServer(cache = true) {
    return $window.$printos.v1.client.getConfigAsync().then(resp => {
      cc = resp;
      $timeout(() => {
        vm.currentProfileImageSrc = _.get(cc, 'entity.persona.image', null);
      });
    });
  }

  function initDisplayName() {
    let displayName = _.get(cc, 'entity.persona.displayName', null);
    let firstName = _.get(cc, 'entity.persona.firstName', null);
    let lastName = _.get(cc, 'entity.persona.lastName', null);

    if (firstName && lastName) {
      vm.displayName = `${firstName} ${lastName}`;
    }
    else if (displayName) {
      vm.displayName = displayName;
    }
    else if (firstName) {
      vm.displayName = firstName;
    }
    else if (lastName) {
      vm.displayName = lastName;
    }
  }

  ///////////////
  // VM methods
  function disableSubmit() {
    if (vm.submitting) {
      return true;
    }

    if (!vm.jobFunctionValid) {
      return true;
    }

    return false;
  }

  function changeProfileImage($event, fromLink = false) {
    if ($event) {
      $event.stopPropagation();
    }

    $gzAnalytics.event('Profile Image - Open', {category: ANALYTICS_CATEGORY});

    $gzProfileImage.changeUserImage().then(() => {
      $gzAnalytics.event('Profile Image - Changed', {category: ANALYTICS_CATEGORY});
      getCurrentProfileImageFromServer(false);
    })
      .catch((error) => {
        //No prop
      });
  }

  function editHPAccount() {
    $gzAnalytics.event('HP Account - Open', {category: ANALYTICS_CATEGORY});
    $window.open(vm.hpidAccountUrl, '_blank');
  }

  function submit() {
    if (disableSubmit()) {
      return Promise.resolve();
    }

    vm.submitting = true;

    let persona = {
      jobTitle: vm.jobTitle ? vm.jobTitle.trim() : null,
      myTypes: vm.jobFunctions,
    };

    return rnHttp.put('portal_service', 'persona', persona, {version: 2}).then(() => {
      $gzAnalytics.event('Job Functions - Initial', { appName:'getting started', category: ANALYTICS_CATEGORY, label: persona.myTypes.join(', ') });
      //Refresh client config because data that we use elsewhere might have been updated
      return $printos.v1.client.getConfigAsync().then(() => {
        vm.videoId = getVideo();
        if (!vm.videoId) {
          redirect()
        }
      });
    })
      .catch(error => {
        vm.errorKey = 'home.home.Persona_Error';
        return Promise.resolve();
      })
      .finally(() => {
        vm.submitting = false;
      });
  }

  ///////////////
  // Helpers
  function getVideo() {
    // let bus = _.get(cc, "context.bu", []);
    //
    // let bu = bus.length > 0 ? bus[0] : undefined;
    // switch(bu) {
    //   case "LargeFormat":
    //     return "iNDtJL74qGM";
    // }
    return null;
  }

  function redirect() {
    if ($stateParams.target) {
      let target = $window.decodeURIComponent($stateParams.target);
      $window.location.href = `/api/portal/v1/go/validate?url=${$window.encodeURIComponent(target)}`;
    }
    else {
      $state.go('dashboard');
    }
  }

}
