export const name = 'AddFilterDialogController';
export default function AddFilterDialogController(
  $mdDialog,
  $log,
  $analytics,
  filters
) {
  //noinspection BadExpressionStatementJS
  'ngInject';

  var vm = this;
  vm.filter = {};
  vm.filters = [];
  vm.cancel = cancel;
  vm.submit = submit;
  vm.showValueControl = showValueControl;
  vm.allowSubmit = allowSubmit;
  vm.unitTest = {
    initListItem: initListItem
  };

  activate();

  ////////////////
  function activate() {
    vm.filter = filters[0];
    vm.validStringValue = false;
    for(let i=0; i<filters.length; i++) {
      vm.filters.push({display: filters[i].internal.display , value: filters[i]});
      initListItem(filters[i]);
    }
  }

  function initListItem(filter) {
    if (filter.internal.listItem === undefined) {
      _.set(filter.internal, 'listItem.method', 'equals');
      _.set(filter.internal, 'listItem.value', '');
    }
    else if (vm.filter.internal.listItem.method === undefined) {
      _.set(vm.filter.internal, 'listItem.method', 'equals');
    }
    else if (vm.filter.internal.listItem.value === undefined) {
      _.set(vm.filter.internal, 'listItem.value', '');
    }
  }

  function cancel() {
    $mdDialog.cancel();
  }

  function submit() {
    vm.filter.internal.listItem.value = vm.filter.internal.listItem.value.trim();
    if (allowSubmit()) {
      $mdDialog.hide(vm.filter);
    }
  }

  function allowSubmit() {
    let allow = false;
    let method = vm.filter.internal.listItem.method;
    let value = vm.filter.internal.listItem.value;

    if(!vm.validStringValue) {
      return false;
    }

    if (method !== 'equals') {
      if (value && value.trim() !== '') {
        allow = true;
      }
    }
    else {
      if (vm.filter.internal.clonedValues && vm.filter.internal.clonedValues.length > 0) {
        for (let i = 0; i < vm.filter.internal.clonedValues.length; i++) {
          if (vm.filter.internal.clonedValues[i].selected) {
            allow = true;
            break;
          }
        }
      }
      else {
        if (value && value.trim() !== '') {
          allow = true;
        }
      }
    }

    return allow;
  }

  function showValueControl() {
    let show = false;
    if (vm.filter.internal.listItem.method === 'equals') {
      if (vm.filter.internal.clonedValues && vm.filter.internal.clonedValues.length > 0) {
        show = true;
      }
    }

    return show;
  }
}
