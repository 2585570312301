export const name = 'AccountsEditController';
export default function AccountsEditController(
  rnSession,
  rnUser,
  $gzSignin,
  $translate,
  $location,
  $mdDialog,
  $state,
  $stateParams,
  $gzAnalytics,
  $window,
  $timeout,
  $q
) {
  //noinspection BadExpressionStatementJS
  'ngInject';

  let myParams = {};
  let returnUrl;

  let vm = this;
  vm.loading = true;
  vm.processing = false;
  vm.userContext = {};
  vm.userRoles = {};

  vm.deleteAccount = deleteAccount;
  vm.close = close;
  vm.unitTest = {
    parseParams: parseParams,
    getUserRoles: getUserRoles,
  };

  vm.checkBoxValue = true;
  vm.checkBoxErrors = [];
  vm.handleCheckBoxChange = handleCheckBoxChange;

  vm.textBoxValue = 'Hello there';
  vm.textBoxErrors = [];
  vm.handleTextBoxChange = handleTextBoxChange;

  vm.textAreaValue = 'Hello there';
  vm.textAreaErrors = [];
  vm.handleTextAreaChange = handleTextAreaChange;

  vm.color = 'blue';
  vm.colorErrors = [];
  vm.handleColorChange = handleColorChange;

  vm.selectValue = 'blue';
  vm.selectErrors = [];
  vm.options = [
    {
      value: 'blue',
      display: 'Blue'
    },
    {
      value: 'red',
      display: 'Red'
    },
    {
      value: 'purple',
      display: 'Purple'
    },
    {
      value: 'green',
      display: 'Green'
    },
  ];
  vm.handleSelectChange = handleSelectChange;

  vm.multiValue = ['blue', 'red'];
  vm.multiErrors = [];
  vm.handleMultiChange = handleMultiChange;

  vm.valid = {
    checkBox: true,
    textBox: true,
    textArea: true,
    color: true,
    select: true,
    multi: true,
  };

  vm.testVariable = 'hi';
  vm.isDisabled = isDisabled;
  vm.disabled = true;
  vm.label = 'Label Test';

  vm.$onInit = onInit;
  return;

  $timeout(() => {
    vm.label = 'Replaced Label';
  }, 5000);
  /////////////////
  function handleCheckBoxChange(test) {
    console.log(`handleCheckBoxChange - ${vm.checkBoxValue} (${vm.valid.checkBox})`);
    vm.checkBoxValue ? vm.checkBoxErrors = [] : vm.checkBoxErrors = ['Were is the love?'];
  }

  function handleTextBoxChange(test) {
    console.log(`handleTextBoxChange - ${vm.textBoxValue} (${vm.valid.textBox})`);
    vm.textBoxValue.includes('x') ? vm.textBoxErrors = ['We do not like x'] : vm.textBoxErrors = [];
  }

  function handleTextAreaChange(test) {
    console.log(`handleTextAreaChange - ${vm.textBoxValue} (${vm.valid.textBox})`);
    vm.textBoxValue.includes('x') ? vm.textBoxErrors = ['We do not like x'] : vm.textBoxErrors = [];
  }

  function handleColorChange(test) {
    console.log(`handleColorChange - ${vm.color} (${vm.valid.color})`);
    vm.color !== 'blue' ? vm.colorErrors = ['Blue is better'] : vm.colorErrors = [];
  }

  function handleSelectChange(test) {
    console.log(`handleSelectChange - ${vm.selectValue} (${vm.valid.select})`);
    vm.selectValue !== 'blue' ? vm.selectErrors = ['Blue is better'] : vm.selectErrors = [];
  }

  function handleMultiChange(test) {
    console.log(`handleMultiChange - ${vm.multiValue.join(', ')} (${vm.valid.multi})`);
    vm.multiValue.length < 1 ? vm.multiErrors = ['Select More!'] : vm.multiErrors = [];
  }

  function isDisabled() {
    return true;
  }

  function onInit() {
    parseParams();
    let promises = [];
    promises.push(getUserContext());
    promises.push(getUserRoles());
    return $q.all(promises)
      .then(() => {})
      .finally(() => {
        vm.loading = false;
      });
  }

  function getUserContext() {
    return rnSession.userContext().then(resp => {
      vm.userContext = resp;
    });
  }

  function getUserRoles(){
    vm.accounts = [];
    return rnUser.getSelectedUserRoles().then(resp => {
      vm.userRoles = _.get(resp, 'userRoles', []);
      vm.userRoles.forEach(role => {
        let found = vm.accounts.find(account => {
          return account.accountName === role.context.name;
        });

        if (found) {
          if (!found.roles.includes(role.systemRole.name)) {
            found.roles.push(role.systemRole.name);
          }
        }
        else {
          vm.accounts.push({
            accountName: role.context.name,
            accountId: role.context.id,
            roles: [ role.systemRole.name ],
          });
        }
      });

      // Sort roles for each account
      vm.accounts.forEach(account => {
        account.roles = account.roles.sort((a,b) => {
          return a.toLocaleLowerCase().localeCompare(b.toLocaleLowerCase());
        });
      });

      // Sort accounts
      vm.accounts = vm.accounts.sort((a,b) => {
        if (a.accountId === vm.userContext.id) { return -1; }
        if (b.accountId === vm.userContext.id) { return 1; }
        return a.accountName.toLocaleLowerCase().localeCompare(b.accountName.toLocaleLowerCase());
      });
    });
  }

  function deleteAccount(account) {
    $gzAnalytics.eventClick('delete account');
    $mdDialog.show({
      parent: angular.element(document.body),
      clickOutsideToClose: false,
      escapeToClose: true,
      template: require('./account-delete.jade'),
      controller: 'AccountsDeleteController',
      bindToController: true,
      controllerAs: 'vm',
      locals: {account: account}
    })
      .then(function (data) {
        let confirmed = data;
        if (confirmed) {
          rnUser.removeUserAccount(account.accountId)
            .then(() => {
              _.remove(vm.accounts, {accountId: account.accountId});
              if (vm.accounts.length === 0) {
                //no valid accounts so notify the user and sign out
                $mdDialog.show(
                  $mdDialog.alert()
                    .parent(angular.element(document.body))
                    .title($translate.instant('uxf.uxf.Signin_NoAccount_Title'))
                    .textContent($translate.instant('uxf.uxf.Signin_NoAccount_Message'))
                    .ariaLabel($translate.instant('uxf.uxf.Signin_NoAccount_Message'))
                    .ok($translate.instant('uxf.uxf.Dialog_OK'))
                )
                  .finally(function() {
                    $gzSignin.signout();
                  });
              }
              else if (account.accountId === vm.userContext.id) {
                //current context no longer valid so force them to go through sign-in process to choose context
                $mdDialog.show(
                  $mdDialog.alert()
                    .parent(angular.element(document.body))
                    .title($translate.instant('uxf.uxf.Signin_NoContext_Title'))
                    .textContent($translate.instant('uxf.uxf.Signin_NoContext_Message'))
                    .ariaLabel($translate.instant('uxf.uxf.Signin_NoContext_Message'))
                    .ok($translate.instant('uxf.uxf.Dialog_OK'))
                )
                  .finally(function() {
                    $gzSignin.signout();
                  });
              }
            })
            .catch(function(error) {
              $mdDialog.show(
                $mdDialog.alert()
                  .parent(angular.element(document.body))
                  .title($translate.instant('uxf.uxf.Account_Delete_Failure_Title'))
                  .textContent($translate.instant('uxf.uxf.Account_Delete_Failure_Message'))
                  .ariaLabel($translate.instant('uxf.uxf.Account_Delete_Failure_Message'))
                  .ok($translate.instant('uxf.uxf.Dialog_OK'))
              )
                .finally(function() {
                });
            });
        }
      })
      .catch(function (data) {
        // The user canceled, no action needed.
      })
      .finally(function (data) {
      });
  }

  function parseParams() {
    myParams = {
      data: $stateParams.data,
    };

    returnUrl = $stateParams.returnUrl || _.get(myParams, 'data.returnUrl');
    if (returnUrl) {
      returnUrl = $window.decodeURIComponent(returnUrl);
      _.set(myParams, 'data.returnUrl', returnUrl);
    }
  }

  function close($event) {
    $event.stopPropagation();
    $gzAnalytics.eventClick('close button');
    if (returnUrl) {
      $window.location.href = `/api/portal/v1/go/validate?url=${$window.encodeURIComponent(returnUrl)}`;
      return;
    }

    $state.go('dashboard');
  }
}
