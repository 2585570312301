import './indigo-sc-link.scss';

import * as hmIndigoSCLinkDirective from './indigo-sc-link.directive';
import gazelleAnalytics from 'gazelle/src/core/analytics/analytics.module';

export default angular.module('home.components.indigoSCLink', [
  gazelleAnalytics
])
  .directive(hmIndigoSCLinkDirective.name, hmIndigoSCLinkDirective.default)
  .name;
