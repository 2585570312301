export const name = 'EmailSubscriptionsController';
export default function EmailSubscriptionsController(
  // Controller angular dependencies
  $window,
  $state,
  $stateParams,
  $gzAnalytics,
  rnHttp,
  rnOrganization,
  rnFeature,
  rnClient,
  moment,
  $hmSubscriber,
  $gzTime,
  $q,
  $translate
) {
  'ngInject';

  // Constants
  const EMAIL_SERVICE = 'email_service';
  const ANALYTICS_CATEGORY = 'Email Subscriptions';

  // Variables
  let myParams = {};
  let timeZone;
  let lastHour;
  let lastDay;
  let lastSites;
  let returnUrl;
  let cc;

  // View Model
  let vm = this;
  vm.loading = true;
  vm.lfSubscriptions = [];
  vm.pwpSubscriptions = [];
  vm.includeRoutines = false;
  vm.legacyFormat = false;
  vm.breadcrumbs = [
    {
      state: 'dashboard',
      titleKey: 'home.home.Home_title'
    },
    {
      state: 'settings',
      titleKey: 'home.home.My_Settings'
    }
  ];
  vm.LF_SCHEDULES = ['PRINTBEAT_LF_WEEKLY_REPORT', 'PRINTBEAT_LF_MONTHLY_REPORT'];
  vm.PWP_SCHEDULES = ['PRINTBEAT_PWI_DAILY_REPORT', 'PRINTBEAT_PWI_WEEKLY_REPORT', 'PRINTBEAT_PWI_MONTHLY_REPORT'];

  vm.goBack = goBack;
  vm.subscriptionUpdated = subscriptionUpdated;
  vm.subscriptionSelected = subscriptionSelected;
  vm.subCategoryClicked = subCategoryClicked;
  vm.allSubCategoriesClicked = allSubCategoriesClicked;
  vm.lfSubscriptionUpdated = lfSubscriptionUpdated;
  vm.pwpSubscriptionUpdated = pwpSubscriptionUpdated;
  vm.scheduleChanged = scheduleChanged;
  vm.hasSelectedSites = hasSelectedSites;
  vm.$onInit = onInit;

  return;

  function onInit() {
    parseParams();

    cc = $window.$printos.v1.client.getConfig();
    vm.showIndigo = $window.$printos.v1.org.isIndigo();
    vm.showLF = $window.$printos.v1.org.isLF();
    vm.showPWP = $window.$printos.v1.org.isPageWide();
    vm.isRoutines = cc.features.includes('lfPrinterRoutines');

    let promises = [];
    promises.push(getIndigoSubscriptions());
    promises.push(getBackOfficeSubscriptions());
    promises.push(getTimeZone());
    promises.push(getSites());
    $q.all(promises).then(() => {
    }).catch(err => {
      vm.errorKey = 'home.home.EmailSubscriptionErrorInit';
    }).finally(() => {
      vm.loading = false;
    });

    vm.dayOptions = [
      {display: $translate.instant('home.home.Sunday'), value: 1},
      {display: $translate.instant('home.home.Monday'), value: 2},
      {display: $translate.instant('home.home.Tuesday'), value: 3},
      {display: $translate.instant('home.home.Wednesday'), value: 4},
      {display: $translate.instant('home.home.Thursday'), value: 5},
      {display: $translate.instant('home.home.Friday'), value: 6},
      {display: $translate.instant('home.home.Saturday'), value: 7},
    ];

    vm.hourOptions = [];
    for(let i=0 ;i < 24;  i++ ) {
      vm.hourOptions.push({display: getTimeDisplay(i,0), value: i})
    }
  }

  function getIndigoSubscriptions() {
    return $hmSubscriber.getIndigoSubscriptions().then(indigoSubscriptions => {
      vm.subscriptions = indigoSubscriptions;
    });
  }

  function getBackOfficeSubscriptions() {
    return $hmSubscriber.getBackOfficeSubscriptions().then(subscriptions => {
      vm.pwpSubscriptions = subscriptions[0];
      if (vm.isPWPSubscriptions) {
        vm.pwpSubscriptions = subscriptions.map(sub => {
          // unify the object types for simplifying subscriptionSelected()
          sub.id = sub.name;
          return sub;
        });
      }
      vm.lfSubscriptions = subscriptions[1];
      if (vm.isLFSubscriptions) {
        vm.lfSubscriptions = subscriptions.map(sub => {
          // unify the object types for simplifying subscriptionSelected()
          sub.id = sub.name;
          return sub;
        });
      }
    });
  }

  function getTimeZone() {
    return $gzTime.getPreferredTimeZone().then(tz => {
      timeZone = tz;
    });
  }

  function getSites() {
    vm.sites = $window.$printos.v1.org.getSites();
    return Promise.resolve(vm.sites);
  }

  function parseParams() {
    if ($stateParams.data) {
      _.set(myParams, 'data', $stateParams.data);
    }

    returnUrl = $stateParams.returnUrl || _.get(myParams, 'data.returnUrl');
    if (returnUrl) {
      returnUrl = $window.decodeURIComponent(returnUrl);
      _.set(myParams, 'data.returnUrl', returnUrl);
    }
  }

  function goBack($event) {
    $event.stopPropagation();
    $gzAnalytics.event('Email Subscription - Close', {appName: 'my settings', category: ANALYTICS_CATEGORY});

    if (returnUrl) {
      $window.location.href = `/api/portal/v1/go/validate?url=${$window.encodeURIComponent(returnUrl)}`;
      return;
    }

    $state.go('dashboard');
  }

  function subscriptionUpdated(subscription) {
    _.forEach(subscription.subCategories, subCategory => {
      subCategory.subscribed = subscription.subscribed;
    });

    subscription.allSubCategories = subscription.subscribed;

    if (subscription.subscribed) {
      $gzAnalytics.event(`Email Subscription - On`, {appName: 'my settings', category: ANALYTICS_CATEGORY, label: subscription.id});
    }
    else {
      $gzAnalytics.event(`Email Subscription - Off`, {appName: 'my settings', category: ANALYTICS_CATEGORY, label: subscription.id});
    }
    updateCategories(subscription);
  }

  function subscriptionSelected(subscription) {
    vm.subscription = subscription;
    if ((subscription.isLF || subscription.isPWP) && subscription.schedule) {
      vm.hour = lastHour = getHour(subscription.schedule.cronExp);
      vm.day = lastDay = getDay(subscription.schedule.cronExp);
      lastSites = [];
      let metadata = _.get(subscription, 'schedule.metadata');
      if (metadata) {
        if (metadata.sites) {
          vm.sites.forEach(site => {
            let selected = subscription.schedule.metadata.sites.indexOf(site.siteId) === -1 ? false : true;
            _.set(site, 'internal.selected', selected);
            if (selected) {
              lastSites.push(site.siteId);
            }
          });
        } else {
          // No sites is the equivalent of all sites
          vm.sites.forEach(site => {
            _.set(site, 'internal.selected', true);
            lastSites.push(site.siteId);
          });
        }

        vm.includeRoutines = metadata.printerRoutines ? true : false;
        vm.legacyFormat = metadata.legacyFormat ? true : false;
      }
    }
  }

  function getHour(cronExp) {
    let values = cronExp.split(' ');
    return parseInt(values[2]);
  }

  function getDay(cronExp) {
    let values = cronExp.split(' ');
    let dayExp = values[5];
    if (dayExp.indexOf('#') === -1) {
      switch (dayExp) {
        case 'SUN': return 1;
        case 'MON': return 2;
        case 'TUE': return 3;
        case 'WED': return 4;
        case 'THU': return 5;
        case 'FRI': return 6;
        case 'SAT': return 7;
        default: return 1;
      }
    }
    else {
      let dayValues = dayExp.split('#');
      return parseInt(dayValues[0]);
    }
  }

  function lfSubscriptionUpdated(subscription) {
    vm.errorKey = undefined;
    return _updateLFSubscriptionSchedule(subscription);
  }

  function _updateLFSubscriptionSchedule(subscription) {
    if (subscription.schedule) {
      $gzAnalytics.event(`Email Subscription - Off`, {appName: 'my settings', category: ANALYTICS_CATEGORY, label: subscription.type});
      return $hmSubscriber.deleteLFSchedule(subscription).then(() => {
        return getBackOfficeSubscriptions().then(() => {
          selectSubscriptionByType(subscription.type);
        });
      })
        .catch(error => {
          vm.errorKey = (subscription.subscribed ? 'home.home.EmailSubscriptionErrorOptin' : 'home.home.EmailSubscriptionErrorOptout');
        });
    }
    else {
      $gzAnalytics.event(`Email Subscription - On`, {appName: 'my settings', category: ANALYTICS_CATEGORY, label: subscription.type});
      return $hmSubscriber.createLFSchedule(subscription, vm.sites, timeZone).then(resp => {
        return getBackOfficeSubscriptions().then(() => {
          selectSubscriptionByType(resp.type);
        });
      })
        .catch(error => {
          vm.errorKey = (subscription.subscribed ? 'home.home.EmailSubscriptionErrorOptin' : 'home.home.EmailSubscriptionErrorOptout');
        });
    }
  }

  function pwpSubscriptionUpdated(subscription) {
    vm.errorKey = undefined;
    return _updatePWPSubscriptionSchedule(subscription);
  }

  function _updatePWPSubscriptionSchedule(subscription) {
    if (subscription.schedule) {
      $gzAnalytics.event(`Email Subscription - Off`, {appName: 'my settings', category: ANALYTICS_CATEGORY, label: subscription.type});
      return $hmSubscriber.deletePWPSchedule(subscription).then(() => {
        return getBackOfficeSubscriptions().then(() => {
          selectSubscriptionByType(subscription.type);
        });
      })
        .catch(error => {
          vm.errorKey = (subscription.subscribed ? 'home.home.EmailSubscriptionErrorOptin' : 'home.home.EmailSubscriptionErrorOptout');
        });
    }
    else {
      $gzAnalytics.event(`Email Subscription - On`, {appName: 'my settings', category: ANALYTICS_CATEGORY, label: subscription.type});
      return $hmSubscriber.createPWPSchedule(subscription, vm.sites, timeZone).then(resp => {
        return getBackOfficeSubscriptions().then(() => {
          selectSubscriptionByType(resp.type);
        });
      })
        .catch(error => {
          vm.errorKey = (subscription.subscribed ? 'home.home.EmailSubscriptionErrorOptin' : 'home.home.EmailSubscriptionErrorOptout');
        });
    }
  }

  function selectSubscriptionByName(name) {
    let select = vm.lfSubscriptions.find(s => {
      return s.name === name;
    });

    subscriptionSelected(select);
  }

  function selectSubscriptionByType(type) {
    let select = vm.lfSubscriptions.find(s => {
      return s.type === type;
    });

    if (!select) {
      select = vm.pwpSubscriptions.find(s => {
        return s.type === type;
      })
    }

    subscriptionSelected(select);
  }

  function getTimeDisplay(hour, minute) {
    return moment.utc(hour + ":" + minute, 'hh:mm').format('LT').toLowerCase();
  }

  function scheduleChanged() {
    let sites = [];
    vm.sites.forEach(site => {
      if (vm.selectAll || _.get(site, 'internal.selected', false)) {
        sites.push(site.siteId);
      }
    });

    // PWP Reports have hard-coded cron expressions to ensure full data sets are available
    // PWP will only get here for daily reports (can't change schedule for monthly/weekly),
    // just keeping this here as a fallback in case something else changes
    switch(vm.subscription.type) {
      case 'PRINTBEAT_LF_WEEKLY_REPORT':
        vm.subscription.schedule.cronExp = getWeeklyCronExp();
        break;
      case 'PRINTBEAT_LF_MONTHLY_REPORT':
        vm.subscription.schedule.cronExp = getMonthlyCronExp();
        break;
      case 'PRINTBEAT_PWI_DAILY_REPORT':
        vm.subscription.schedule.cronExp = getDailyCronExp();
        break;
      case 'PRINTBEAT_PWI_WEEKLY_REPORT':
        vm.subscription.schedule.cronExp = '0 0 9 ? * WED *';
        break;
      case 'PRINTBEAT_PWI_MONTHLY_REPORT':
        vm.subscription.schedule.cronExp = '0 0 9 3 * ? *';
        break;
      default:
        return Promise.resolve();
    }

    $gzAnalytics.event(`Email Subscription - Update Schedule`, {appName: 'my settings', category: ANALYTICS_CATEGORY, label: vm.subscription.type});
    vm.subscription.schedule.metadata = {
      sites: sites,
      printerRoutines: vm.includeRoutines,
      legacyFormat: vm.legacyFormat,
    };
    if (vm.LF_SCHEDULES.includes(vm.subscription.type)) {
      return $hmSubscriber.updateLFSchedule(vm.subscription.schedule).then(resp => {
        vm.subscription.schedule = resp;
      });
    } else {
      return $hmSubscriber.updatePWPSchedule(vm.subscription.schedule).then(resp => {
        vm.subscription.schedule = resp;
      });
    }
  }

  function hasSelectedSites() {
    for (let site in vm.sites) {
      for (let i = 0; i < vm.sites.length; i++ ) {
        if (_.get(vm.sites[i], 'internal.selected')) {
          return true;
        }
      }
    }

    return false;
  }

  function arraysAreEqual(arr1, arr2) {
    if (arr1.length !== arr2.length) {
      return false;
    }

    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) {
        return false;
      }
    }

    return true;
  }

  function getDailyCronExp() {
    return `0 0 ${vm.hour} * * ?`;
  }

  function getWeeklyCronExp() {
    let day;
    switch(vm.day) {
      case 1: day = 'SUN';
    }
    return `0 0 ${vm.hour} ? * ${getWeekDay()} *`;
  }

  function getWeekDay() {
    switch (vm.day) {
      case 1: return 'SUN';
      case 2: return 'MON';
      case 3: return 'TUE';
      case 4: return 'WED';
      case 5: return 'THU';
      case 6: return 'FRI';
      case 7: return 'SAT';
    }
  }

  function getMonthlyCronExp() {
    return `0 0 ${vm.hour} ? * ${vm.day}#1 *`;
  }

  function allSubCategoriesClicked(subscription) {
    _.forEach(subscription.subCategories, subCategory => {
      subCategory.subscribed = subscription.allSubCategories;
    });

    $gzAnalytics.event(`Email Subscription - Update All`, {appName: 'my settings', category: ANALYTICS_CATEGORY, label: subscription.id});
    updateCategories(subscription, true);
  }

  function subCategoryClicked(subscription) {
    let found = _.find(subscription.subCategories, subCategory => {
      return subCategory.subscribed === false;
    });

    // If Select All is checked and there's an unchecked value, uncheck Select All
    if (subscription.allSubCategories && found) {
      subscription.allSubCategories = false;
    }

    // If Select All is not checked and there are no unchecked values check Select All
    else if (!subscription.allSubCategories && !found) {
      subscription.allSubCategories = true;
    }

    $gzAnalytics.event(`Email Subscription - Update Category`, {appName: 'my settings', category: ANALYTICS_CATEGORY, label: subscription.id});
    updateCategories(subscription, true);
  }

  function updateCategories(subscription, subCategoriesChanged = false) {
    const DELETE = 1;
    const POST = 2;

    let op;

    let body = {
      "unsubscribeCategory": {
        "id": subscription.id
      }
    };

    if (subscription.subscribed) {
      if (subCategoriesChanged) {
        let subCategories = [];

        _.forEach(subscription.subCategories, s => {
          if (!s.subscribed) {
            subCategories.push(s.id);
          }
        });

        if (subCategories.length === 0) {
          op = DELETE;
        }
        else {
          op = POST;
          body.subCategories = subCategories;
        }
      }
      else {
        op = DELETE;
      }
    }
    else {
      op = POST;
    }

    if (op === POST) {
      updateOptoutPrefs(body);
    }
    else if (op === DELETE) {
      deleteOptoutPrefs(body);
    }
  }

  function updateOptoutPrefs(body) {
    return rnHttp.post(EMAIL_SERVICE, 'unsubscriber/optout', body).then(() => {
      vm.errorKey = undefined;
    }).catch(error => {
      vm.errorKey = 'home.home.EmailSubscriptionErrorOptin';
    });
  }

  function deleteOptoutPrefs(body) {
    return rnHttp.deleteWithBody(EMAIL_SERVICE, 'unsubscriber/optout', body).then(() => {
      vm.errorKey = undefined;
    }).catch(error => {
      vm.errorKey = 'home.home.EmailSubscriptionErrorOptout';
    });
  }
}
