import rhino from 'rhino';
import angularTranslate from 'angular-translate';

import './resource-manager.scss';
import * as hmResourceManagerDirective from './resource-manager.directive';
import gazelleAnalytics from 'gazelle/src/core/analytics/analytics.module';
import images from 'src/images/images.module';

export default angular.module('home.components.widget.hmResourceManager', [
  rhino,
  angularTranslate,
  'gazelle.components.go',
  gazelleAnalytics,
  images
])
  .directive(hmResourceManagerDirective.name, hmResourceManagerDirective.default)
  .name;
