export const name = 'LandingPageController';
export default function LandingPageController(
  // Controller angular dependencies
  rnClient,
  $stateParams,
  $q
) {
  'ngInject';
  // Constants

  // Variables
  let clientConfig;

  // View Model
  let vm = this;
  vm.loading = true;

  vm.$onInit = onInit;
  return;

  function onInit() {
    let promises = [];

    promises.push(initClient());
    $q.all(promises).then(() => {
      // if (clientConfig.features.indexOf('home-lp1') !== -1) {
      //   vm.version = 'LP1';
      // }
      //
      // if ($stateParams.version) {
      //   let version = $stateParams.version.toUpperCase();
      //   if (version === 'LP1' || version === 'LP2') {
      //     vm.version = $stateParams.version.toUpperCase();
      //   }
      // }
    })
      .finally(() => {
        vm.loading = false;
      });
  }

  ///////////////////////////////////////////////////////////
  // Init
  function initClient() {
    return rnClient.getConfig().then(config => {
      clientConfig = config;
    })
      .catch(error => {
        return $q.resolve();
      });
  }
}
