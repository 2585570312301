export const moduleName = 'askexpertellipsis';
export default function askexpertellipsis() {
  return (input, size) => {
    if (input === undefined || input === '') {
      return '';
    } else if (input.length <= size) {
      return input;
    }

    return input.substring(0, (size || 5)) + '...';
  };
}
