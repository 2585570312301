const queries = [
  {
    id: 'onDfe',
    and: [
      {
        or: [
          {
            columnId: 'jobProgress',
            op: 'equals',
            value: 'READY',
          },
          {
            columnId: 'jobProgress',
            op: 'equals',
            value: 'RIPPING',
          },
          {
            columnId: 'jobProgress',
            op: 'equals',
            value: 'RIPPED',
          }
        ]
      },
      {
        columnId: 'locationType',
        op: 'notEqual',
        value: 'DELETED',
      }
    ]
  },
  {
    id: 'onPress',
    and: [
      {
        or: [
          {
            columnId: 'jobProgress',
            op: 'equals',
            value: 'HELD',
          },
          {
            columnId: 'jobProgress',
            op: 'equals',
            value: 'QUEUED',
          },
          {
            columnId: 'jobProgress',
            op: 'equals',
            value: 'PRINTING',
          },
        ]
      },
      {
        columnId: 'locationType',
        op: 'notEqual',
        value: 'DELETED',
      }
    ]
  },
  {
    id: 'printing',
    and: [
      {
        columnId: 'jobProgress',
        op: 'equals',
        value: 'PRINTING',
      },
      {
        columnId: 'locationType',
        op: 'notEqual',
        value: 'DELETED',
      }
    ]
  },
  {
    id: 'errorsOnDevice',
    and: [
      {
        or: [
          {
            columnId: 'jobProgress',
            op: 'equals',
            value: 'READY',
          },
          {
            columnId: 'jobProgress',
            op: 'equals',
            value: 'RIPPING',
          },
          {
            columnId: 'jobProgress',
            op: 'equals',
            value: 'RIPPED',
          },
          {
            columnId: 'jobProgress',
            op: 'equals',
            value: 'HELD',
          },
          {
            columnId: 'jobProgress',
            op: 'equals',
            value: 'QUEUED',
          },
          {
            columnId: 'jobProgress',
            op: 'equals',
            value: 'PRINTING',
          },
        ]
      },
      {
        columnId: 'jobCondition',
        op: 'equals',
        value: 'ERROR',
      },
      {
        columnId: 'locationType',
        op: 'notEqual',
        value: 'DELETED',
      },
    ]
  },
  {
    id: 'printedToday',
    and: [
      {
          columnId: 'jobProgress',
          op: 'equals',
          value: 'PRINTED',
      },
      {
        columnId: 'jobCompleteTime',
        op: 'today'
      }
    ]
  },
];

const filters = [
  {
    id: "onDfe",
    query: getQuery("onDfe"),
    msgCat: "jobs.jobs-common",
    title: "Job_Filter_ON_DFE",
  },
  {
    id: "onPress",
    query: getQuery("onPress"),
    msgCat: "jobs.jobs-common",
    title: "Job_Filter_ON_PRESS",
  },
  {
    id: "printing",
    query: getQuery("printing"),
    msgCat: "jobs.jobs-common",
    title: "Job_Filter_PRINTING",
  },
  {
    id: "errorsOnDevice",
    query: getQuery("errorsOnDevice"),
    msgCat: "jobs.jobs-common",
    title: "Job_Filter_ERRORS_ON_DEVICE",
  },
  {
    id: "printedToday",
    query: getQuery("printedToday"),
    msgCat: "jobs.jobs-common",
    title: "Job_Filter_PRINTED_TODAY",
  },
  {
    id: "remoteDfeView",
    query: getQuery("remoteDfeView"),
    msgCat: "jobs.jobs-common",
    title: "Job_Filter_REMOTE_DFE_VIEW",
  },
  {
    id: "remotePressView",
    query: getQuery("remotePressView"),
    msgCat: "jobs.jobs-common",
    title: "Job_Filter_REMOTE_PRESS_VIEW",
  },
];

const filterCards = [
  {
    id: "onDfe",
    query: getQuery("onDfe"),
    image: "/jobs/images/active on DFEs.svg",
    msgCat: "jobs.jobs-common",
    title: "Job_Filter_ON_DFE",
    threshold: "1",
    thresholdColor: "#0096d6", // $hp-blue
  },
  {
    id: "onPress",
    query: getQuery("onPress"),
    image: "/jobs/images/active on presses.svg",
    msgCat: "jobs.jobs-common",
    title: "Job_Filter_ON_PRESS",
    threshold: "1",
    thresholdColor: "#0096d6", // $hp-blue
  },
  {
    id: "printing",
    query: getQuery("printing"),
    image: "/jobs/images/printing.svg",
    msgCat: "jobs.jobs-common",
    title: "Job_Filter_PRINTING",
    threshold: "1",
    thresholdColor: "#0096d6", // $hp-blue
  },
  {
    id: "errorsOnDevice",
    query: getQuery("errorsOnDevice"),
    image: "/jobs/images/error.svg",
    msgCat: "jobs.jobs-common",
    title: "Job_Filter_ERRORS_ON_DEVICE",
    threshold: "1",
    thresholdColor: '#F04B37', // $hp-status-red
  },
  {
    id: "printedToday",
    query: getQuery("printedToday"),
    image: "/jobs/images/Printed-today-01.svg",
    msgCat: "jobs.jobs-common",
    title: "Job_Filter_PRINTED_TODAY",
    threshold: "1",
    thresholdColor: "#0096d6", // $hp-blue
  },
];

function getQuery(id) {
  return _.find(queries, { id: id });
}

// export function getFilter(id) {
//   return _.find(filters, { id: id });
// }

export function getFilters() {
  return filters;
}

// export function getFilterCard(id) {
//   return _.find(filterCards, { id: id });
// }

export function getFilterCards() {
  return filterCards;
}
