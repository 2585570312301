export const name = 'SettingsEulaDeclineDialogController';
export default function SettingsEulaDeclineDialogController(
  $mdDialog
) {
  //noinspection BadExpressionStatementJS
  'ngInject';

  let vm = _.assign(this, {
    cancel,
    ok
  });

  ///////////////////////

  function cancel() {
    $mdDialog.cancel();
  }

  function ok() {
    $mdDialog.hide();
  }
}

