import './create-org.scss';
import angularTranslate from 'angular-translate';
import angularMaterial from 'angular-material';

import * as CreateOrgController from './create-org.controller';
import rnInstructionsService from 'rhino/src/components/device/device.module';
import rnClient from 'rhino/src/core/client/client.module';
import rnHttp from 'rhino/src/core/http/http.module';
import rnDevice from 'rhino/src/components/device/device.module';
import rnSession from 'rhino/src/components/session/session.module';
import rnLocale from 'rhino/src/components/locale/locale.module';
import rnInvitation from 'rhino/src/components/invitation/invitation.module';
import gazelleAnalytics from 'gazelle/src/core/analytics/analytics.module';

export default angular.module('home.components.createOrg', [
  rnClient,
  rnDevice,
  rnLocale,
  rnInvitation,
  rnHttp,
  rnSession,
  rnInstructionsService,
  gazelleAnalytics,
  angularTranslate,
  angularMaterial,
])
  .controller(CreateOrgController.name, CreateOrgController.default)
  .name;
