
const APPLICATIONS_SEGMENT = 'apps';
const PORTAL_SERVICE = 'portal_service';
const REQUESTS_PERMISSIONS_PATH = 'requests/types';
const SUPPORT_APP_SERVICE = 'supportapp_service';
const CONFIG_SEGMENT = 'client/config';

export const name = 'ContactController';
export default function ContactController(
  rnSession,
  $q,
  rnFeature,
  rnHttp,
  $state,
  $stateParams,
  $gzAnalytics,
  $translate,
  $location,
  $window,
) {
  //noinspection BadExpressionStatementJS
  'ngInject';

  const ANALYTICS_CATEGORY = 'My Support';

  /* jshint validthis: true */
  let myParams = {};
  let returnUrl;
  let features = [];

  let vm = this;
  vm.errorKey = '';
  vm.isUserLoggedIn = null;
  vm.breadcrumbs = [
    {
      state: 'dashboard',
      titleKey: 'home.home.Home_title'
    },
    {
      state: 'settings',
      titleKey: 'home.home.My_Settings'
    },
    {
      state: 'contact-hp',
      titleKey: 'home.home.Contact_Hp'
    }
  ];
  vm.loading = true;
  vm.showIndigoSuppliesReport = false;

  vm.getAuthenticationInfo = getAuthenticationInfo;
  vm.close = close;
  vm.goToAskTheExpert = goToAskTheExpert;
  vm.goToFeedback = goToFeedback;
  vm.goToSupportCase = goToSupportCase;
  vm.goToRCDA = goToRCDA;
  vm.goToRSR = goToRSR;
  vm.goToWow = goToWow;
  vm.goDevice = goDevice;
  vm.goIndigoDevice = goIndigoDevice;
  vm.shouldRenderRCDA = shouldRenderRCDA;
  vm.shouldRenderRSR = shouldRenderRSR;
  vm.shouldRenderWow = shouldRenderWow;
  vm.userHasCreateRCDAPermission = userHasCreateRCDAPermission;
  vm.unitTest = {
    parseParams: parseParams
  };

  vm.unitTest = {
    onInit: onInit,
  };

  vm.$onInit = onInit;
  return;
  ////////////////

  function onInit() {
    parseParams();
    initServiceCenterOptions();
    //Only to cache these calls
    rnHttp.get(PORTAL_SERVICE, APPLICATIONS_SEGMENT, { cache : true });

    getIndigoSuppliesReportKey();

    let promises = [
      getAuthenticationInfo(),
      getSupportPermissions(),
      getFeatures(),
    ];

    return $q.all(promises)
      .then((data) => {
        // result of the first promise
        vm.isUserLoggedIn = data[0];

        // result of the second promise
        vm.permissionsResponse = data[1];
      })
      .catch((e) => {
        vm.errorKey = 'home.home.LoadingError';
      }).finally(() => {
        vm.loading = false;
    });
  }

  function getAuthenticationInfo() {
    return rnSession.isAuthenticated();
  }

  function getSupportPermissions() {
    return rnHttp.get(SUPPORT_APP_SERVICE, REQUESTS_PERMISSIONS_PATH)
      .catch(() => {
          return Promise.resolve();
      });
  }

  function getFeatures() {
    return rnFeature.getFeatures().then(resp => {
      features = resp;
    });
  }

  function parseParams() {
    myParams = {
      data: $stateParams.data,
    };

    let appId;
    returnUrl = $stateParams.returnUrl;
    if (returnUrl) {
      returnUrl = $window.decodeURIComponent(returnUrl);
      _.set(myParams, 'data.returnUrl', returnUrl);
    }

    appId = _.get($location, '$$search.appId', null);

    if (appId) {
      _.set(myParams, 'data.appId', appId);
    }
  }

  function initServiceCenterOptions() {
    let apps = $printos.v1.app.getApps();
    // Indigo
    let found = apps.find(app => {
      return app.appId === 'service-now';
    });
    if (found) {
      vm.indigo = {
        display: found.groupOptions[0].name,
        // display: $translate.instant(`home.home.BU_IndigoPress`),
        url: found.appUrl,
      }
    }

    found = apps.find(app => {
      return app.appId === 'pwi-sc'
    });
    if (found) {
      // LF
      let family = found.groupOptions.find(groupOption => {
        return groupOption.family === 'LargeFormat';
      });
      if (family) {
        vm.lf = {
          display: family.name,
          // display: $translate.instant(`home.home.BU_LF`),
          url: family.groupUrl,
        }
      }

      // Scitex
      family = found.groupOptions.find(groupOption => {
        return groupOption.family === 'Scitex';
      });
      if (family) {
        vm.scitex = {
          display: family.name,
          // display: $translate.instant(`home.home.BU_ScitexPress`),
          url: family.groupUrl,
        }
      }

      // PWP
      family = found.groupOptions.find(groupOption => {
        return groupOption.family === 'PWI';
      });
      if (family) {
        vm.pwi = {
          display: family.name,
          // display: $translate.instant(`home.home.BU_IHPSPress`),
          url: family.groupUrl,
        }
      }

      // 3D
      family = found.groupOptions.find(go => {
        return go.family === 'ThreeD';
      });
      if (family) {
        vm.scitex = {
          display: $translate.instant(`home.home.BU_ThreeDPrinter`),
          url: family.groupUrl,
        };
      }
    }
  }

  function close($event) {
    $event.stopPropagation();
    $event.stopPropagation();
    if (returnUrl) {
      $window.location.href = `/api/portal/v1/go/validate?url=${$window.encodeURIComponent(returnUrl)}`;
      return;
    }

    $state.go('settings', myParams);
  }

  function goToAskTheExpert() {
    $gzAnalytics.event('PrintOS Support Clicked', {category:ANALYTICS_CATEGORY});
    $state.go('ask-the-expert', myParams);
  }

  function goToFeedback() {
    $gzAnalytics.event('Feedback Clicked', {category:ANALYTICS_CATEGORY});
    $state.go('feedback', myParams);
  }

  function goToSupportCase() {
    $gzAnalytics.event('PrintOS Support Cases Clicked', {category:ANALYTICS_CATEGORY});
    $state.go('support-case', myParams);
  }

  function goToRCDA() {
    $gzAnalytics.event('RCDA Clicked', {category:ANALYTICS_CATEGORY});
    $state.go('rcda', myParams);
  }

  function goToRSR() {
    $gzAnalytics.event('RSR Clicked', {category:ANALYTICS_CATEGORY});
    $state.go('rsr', myParams);
  }

  function userHasCreateRCDAPermission(response) {
    if (response && response.data && response.data._links) {
      return response.data._links.hasOwnProperty('createRCDA') || response.data._links.hasOwnProperty('createRCDASelf');
    }
    return false;
  }

  function shouldRenderRCDA() {
    return this.userHasCreateRCDAPermission(this.permissionsResponse);
  }

  function shouldRenderRSR() {
    return this.userHasCreateRCDAPermission(this.permissionsResponse) && vm.showIndigoSuppliesReport;
  }

  function goToWow() {
    $gzAnalytics.event('WOW Clicked', {category:ANALYTICS_CATEGORY});
    $state.go('wow', myParams);
  }

  function goDevice(app) {
    $gzAnalytics.event('Device Support Clicked', {category:ANALYTICS_CATEGORY, label: app.display});
    $window.location.href = app.url;
  }

  function goIndigoDevice(app) {
    $gzAnalytics.event('Device Support Clicked', {category:ANALYTICS_CATEGORY, label: app.display});
    $window.open(app.url, '_blank');
  }

  function shouldRenderWow() {
    let wowFeatureName = "hm-wow-survey";
    return _.includes(features, wowFeatureName);
  }

  function getIndigoSuppliesReportKey() {
    const config = {
      cache: true,
      version: 2,
    };
    rnHttp.get(PORTAL_SERVICE, CONFIG_SEGMENT, config).then(resp => {
      vm.showIndigoSuppliesReport = _.get(resp, 'data.registry.keys.indigoSuppliesReportEnabled', false)
    });
  }

}
