export const name = 'ConfirmSetDefaultAppDialogController';
export default function ConfirmSetDefaultAppDialogController(
  // Controller angular dependencies
  $mdDialog,
  app,
  errorKey
) {
  'ngInject';
  // Constants

  // Variables

  // View Model
  let vm = this;
  //   VM properties
  vm.loading = true;
  vm.app = app;
  vm.errorKey = errorKey;
  //   VM methods
  vm.cancel = cancel;
  vm.submit = submit;

  vm.activate = activate;
  activate();
  return;

  ///////////////
  // Init
  function activate() {
    vm.loading = false;
  }

  ///////////////
  // VM methods

  function cancel() {
    return $mdDialog.cancel();
  }

  function submit() {
    return $mdDialog.hide();
  }

  ///////////////
  // Helpers
}
