export const name = 'hmApplicationCenter';
export default function hmApplicationCenter() {
  //noinspection BadExpressionStatementJS
  'ngInject';

  let directive = {
    restrict: 'EA',
    scope: {
      gzCard: '=',
    },
    template: require('./application-center.jade'),
    controller: hmApplicationCenterController,
    controllerAs: 'vm',
    bindToController: true
  };

  return directive;

  /** @ngInject */
  function hmApplicationCenterController(
    $window,
    rnHttp,
    $q,
    $gzAnalytics
  ) {

    let vm = this;
    vm.loading = true;

    vm.orders = '--';
    vm.todayOrders = '--';
    vm.pending = '--';
    vm.lastMonth = '--';
    vm.thisMonth = '--';

    vm.$onInit = onInit;
    return;
    ////////////////////

    function onInit() {
      let promises = [];
      promises.push(getData());
      $q.all(promises).then(() => {
      })
        .finally(() => {
          vm.loading = false;
        });
    }

    function getData() {
      let config = {
        params: {}
      };

      return rnHttp.get('wallart', '/api/dashboard/stats', config).then(resp => {
        let stats = resp.data.result;
        vm.orders = _.get(resp.data, 'result.activeOrders', '--');
        vm.todayOrders = _.get(resp.data, 'result.activeTodayOrders', '--');
        vm.pending = _.get(resp.data, 'result.pendingApprovalOrders', '--');
        vm.lastMonth = getCurrency(_.get(resp.data, 'result.lastMonthSales', '--').replace('$', 'USD'));
        vm.thisMonth = getCurrency(_.get(resp.data, 'result.thisMonthSales', '--').replace('$', 'USD'));
      }).catch(error => {
        console.log('Failed to get LF Dashboard information for Applications Center card');
      });
    }

    function getCurrency(value) {
      let split = value.split(' ');
      if (split.length === 2) {
        split[0] = split[0].trim();
        split[1] = split[1].trim();

        return Math.round(Number(split[0])).toLocaleString('en-US', {style: 'currency', currency: split[1], minimumFractionDigits: 0});
      }
      else {
        return value === '0.0' ? '0' : value;
      }
    }
  }
}
