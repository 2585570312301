import BaseController from "../util/base.controller";

const AAA_SERVICE = 'aaa';
const HPINC_ID = '37f556cd-49d4-4a12-9753-a5c145c84606';
const HPINC_USERS_PATH = 'admin/organizations/' + HPINC_ID + '/users';
const USERS_PATH = 'users';
const LC_FILTER = 'translate';
const LC_OK = 'uxf.uxf.OK';
const LC_RCDA_CREATE_ERROR = 'home.home.RCDA_CreateError';
const LC_RCDA_CREATE_SUCCESS = 'home.home.RCDA_CreateSuccessWithID';
const LC_RCDA_NONAME = 'home.home.RCDA_NoName';
const LC_RCDA_SUCCESS_EMAIL = 'home.home.RCDA_SuccessEmail';
const REQUESTS_CREATE_PATH = 'requests';
const REQUESTS_PERMISSIONS_PATH = 'requests/types';
const SUPPORT_APP_SERVICE = 'supportapp_service';

export const moduleName = 'RcdaController';
export default class RcdaController extends BaseController {
  static factory($analytics, $filter, $log, $mdDialog, $q, $scope, $stateParams, $state, $window, RcdaService, rnHttp, ContextService) {
    'ngInject';

    return new RcdaController($analytics, $filter, $log, $mdDialog, $q, $scope, $stateParams, $state, $window,
      RcdaService, rnHttp, ContextService);
  }

  /** @ngInject */
  constructor($analytics, $filter, $log, $mdDialog, $q, $scope, $stateParams, $state, $window, RcdaService, rnHttp, ContextService) {
    super({$analytics, $filter, $log, $mdDialog, $q, $scope, $stateParams, $state, $window, RcdaService, rnHttp, ContextService});

    this.initScope($scope);
    this.activate();

    this.$window = $window;
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.$q = $q;
  }

  initScope($scope) {
    $scope.userOptions = [];
    $scope.userDisabled = false;
    $scope.regionOptions = [];
    $scope.subRegionOptions = [];
    $scope.districtOptions = [];
    $scope.countryOptions = [];
    $scope.pressOptions = [];
    $scope.valid = {
      user: true,
      region: true,
      subRegion: true,
      district: true,
      country: true,
      press: true,
    };

    $scope.selectedCountry = null;
    $scope.selectedPressSeries = null;
    $scope.selectedDistrict = null;
    $scope.selectedRegion = null;
    $scope.selectedSubRegion = null;
    $scope.selectedUser = null;

    $scope.checkingPermissions = true;
    $scope.errorMessage = null;
    $scope.isFormBlocked = false;
    $scope.isFormProcessing = false;
    $scope.showUserInvite = false;

    $scope.breadcrumbs = [
      {
        state: 'dashboard',
        titleKey: 'home.home.Home_title'
      },
      {
        state: 'settings',
        titleKey: 'home.home.My_Settings'
      },
      {
        state: 'contact-hp',
        titleKey: 'home.home.Contact_Hp'
      },
      {
        state: 'rcda',
        titleKey: 'home.home.RCDA_View'
      },
    ];
  }

  activate() {
    this.$scope.isFormProcessing = true;
    let promises = [];
    promises.push(this.checkPermissions());
    promises.push(this.RcdaService.loadCache());

    this.$q.all(promises).then(() => {
      // HACK ALERT: For some reason Angular is not detecting the change in this.$scope.userOptions.  I think it has something with the way the data is retrieved.
      // This forces the object update on the Angular thread so that the SelectBox directive picks it up.
      // Note: I really don't like the way this code is structured but we'll address that in the transition to VUE
      this.$scope.userOptions = [...this.$scope.userOptions];
      this.loadPressSeries();
      this.loadRegions();
    }).catch(() => {
      this.failure();
    }).finally(() => {
      this.$scope.isFormProcessing = false;
    });
  }

  goToContactHp(event) {
    if (event) {
      event.stopPropagation();
    }
    this.$state.go('contact-hp', {});
  }

  checkPermissions() {
    return this.rnHttp.get(SUPPORT_APP_SERVICE, REQUESTS_PERMISSIONS_PATH).then((response) => {
      if (response && response.data && response.data._links && (response.data._links.hasOwnProperty('createRCDA') || response.data._links.hasOwnProperty('createRCDASelf'))) {
        this.$scope.checkingPermissions = false;
        return this.loadAvailableUsers(response.data._links.hasOwnProperty('createRCDA'));
      } else {
        this.goToContactHp();
      }
    });
  }

  loadAvailableUsers(hasCreateRcda) {
    if(hasCreateRcda) {
      return this.loadHPIncUsers();
    } else {
      return this.loadUser();
    }
  }

  loadUser() {
    this.allUsers = {};
    return this.rnHttp.get(AAA_SERVICE, USERS_PATH).then(response => {
      if (response.data) {
        this.allUsers[response.data.userId] = response.data.displayName ? response.data.displayName : response.data.firstName + ' ' + response.data.lastName;
        this.$scope.userDisabled = true;
        this.$scope.userOptions.push({
          display: response.data.displayName ? response.data.displayName : response.data.firstName + ' ' + response.data.lastName,
          value: response.data.userId
        });
        this.selected = response.data.userId;
      }
    });
  }

  loadHPIncUsers() {
    this.allUsers = {};
    let noUser = this.$filter(LC_FILTER)(LC_RCDA_NONAME);
    const handleUsersPromise = (offset, limit) => {
        let url = HPINC_USERS_PATH + '?offset=' + offset + '&limit=' + limit;

        return this.rnHttp.get(AAA_SERVICE, url).then(response => {
          let myUsers = _.get(response, 'data.users', []);
          myUsers.forEach((user) => {
            let display = noUser;
            if (user.firstName && user.lastName) {
              display = `${user.firstName.trim()} ${user.lastName.trim()}`;
            }
            else if (user.firstName) {
              display = user.firstName.trim();
            }

            this.$scope.userOptions.push({
              display: display,
              details: user.userId,
              value: user.userId
            });
          });

          this.$scope.userOptions.sort((a,b) => {
            return a.display.toLocaleLowerCase().localeCompare(b.display.toLocaleLowerCase());
          });

          if (offset + limit < response.data.total) {
            return handleUsersPromise(offset + limit, limit);
          }
        });
    };

    return handleUsersPromise(0, 1000);
  }

  loadPressSeries() {
    this.$scope.pressOptions = [];
    this.RcdaService.getPressSeries().forEach(p => {
      this.$scope.pressOptions.push({
        display: p,
        value: p
      })
    });
  }

  loadRegions() {
    this.$scope.regionOptions = [];
    this.RcdaService.getRegions().forEach(r => {
      this.$scope.regionOptions.push({
        display: r,
        value: r
      })
    });
  }

  loadSubRegions() {
    this.$scope.subRegionOptions = [];
    this.RcdaService.getSubRegionsFrom(this.$scope.selectedRegion).forEach(sr => {
      this.$scope.subRegionOptions.push({
        display: sr,
        value: sr
      })
    });
  }

  loadDistricts() {
    this.$scope.districtOptions = [];
    this.RcdaService.getDistrictsFrom(this.$scope.selectedRegion, this.$scope.selectedSubRegion).forEach(d => {
      this.$scope.districtOptions.push({
        display: d,
        value: d
      })
    });
  }

  loadCountries() {
    this.$scope.countryOptions = [];
    this.RcdaService.getCountriesFrom(
      this.$scope.selectedRegion, this.$scope.selectedSubRegion, this.$scope.selectedDistrict
    ).forEach(c => {
      this.$scope.countryOptions.push({
        display: c,
        value: c
      })
    });
  }

  goAddUser() {
    this.$window.open('/account/#/users/add', '_blank');
  }

  disableForm() {
    if (this.$scope.isFormBlocked) {
      return true;
    }

    if (Object.values(this.$scope.valid).includes(false)) {
      return true;
    }

    return false;

  }

  changedRegion() {
    if (this.$scope.selectedRegion === 'World Wide') {
      if (this.$scope.selectedPressSeries === 'All') {
        this.$scope.selectedPressSeries = null;
      }

      if (this.$scope.pressOptions[0].value === 'All') {
        this.$scope.pressOptions = this.$scope.pressOptions.filter( e => e.value != 'All');
      }
    }
    else {
      if (this.$scope.pressOptions[0].value !== 'All') {
        this.$scope.pressOptions = [{
          display: 'All',
          value: 'All'
        }].concat(this.$scope.pressOptions);
      }
    }

    this.$scope.selectedSubRegion = null;
    this.$scope.selectedDistrict = null;
    this.$scope.selectedCountry = null;

    this.loadSubRegions();
  }

  changedSubRegion() {
    this.$scope.selectedDistrict = null;
    this.$scope.selectedCountry = null;

    this.loadDistricts();
  }

  changedDistrict() {
    this.$scope.selectedCountry = null;

    this.loadCountries();
  }

  makePostData() {
    const convertGeneralValueToEmptyString = (value) => !!value ? value : '';
    const convertPressSeriesToEmptyString = (pressSeries) => pressSeries === 'All' ? '' : pressSeries;
    const convertRegionToEmptyString = (region) => region === 'World Wide' ? '' : region;

    return {
      type: {
        id: 3,
        requestFieldTemplates: [
          {
            id: 12,
            fieldValue: {stringValue: this.$scope.selectedUser}
          },
          {
            id: 13,
            fieldValue: {stringValue: convertRegionToEmptyString(this.$scope.selectedRegion)}
          },
          {
            id: 14,
            fieldValue: {stringValue: convertGeneralValueToEmptyString(this.$scope.selectedSubRegion)}
          },
          {
            id: 15,
            fieldValue: {stringValue: convertGeneralValueToEmptyString(this.$scope.selectedDistrict)}
          },
          {
            id: 16,
            fieldValue: {stringValue: convertGeneralValueToEmptyString(this.$scope.selectedCountry)}
          },
          {
            id: 17,
            fieldValue: {stringValue: convertPressSeriesToEmptyString(this.$scope.selectedPressSeries)}
          }
        ]
      }
    };
  }

  sendPostData() {
    this.$scope.errorMessage = null;
    this.$scope.isFormBlocked = true;
    this.$scope.isFormProcessing = true;

    this.rnHttp.post(SUPPORT_APP_SERVICE, REQUESTS_CREATE_PATH, this.makePostData()).then((response) => {
      if(response.data){
        this.success(response.data.id);
      } else {
        this.failure();
      }
    });
  }

  success(rcdaId) {
    this.$scope.isFormBlocked = true;
    this.$scope.isFormProcessing = false;

    let params = {
      action: this.GA_ACTION_SUBMIT, application: this.ContextService.currentAppName(),
      page: this.currentPageName, type: 'RCDA', title: 'RCDA'
    };
    this.analytics(params);

    this.$mdDialog.show(
      this.$mdDialog.alert()
        .parent(angular.element(document.body))
        .htmlContent("<p>" + this.$filter(LC_FILTER)(LC_RCDA_CREATE_SUCCESS) + " " + rcdaId + "." + "</br>" + this.$filter(LC_FILTER)(LC_RCDA_SUCCESS_EMAIL) + "</p>")
        .ok(this.$filter(LC_FILTER)(LC_OK))
    ).then(() => {
      this.goToContactHp();
    });
  }

  failure() {
    this.$scope.isFormBlocked = false;
    this.$scope.isFormProcessing = false;
    this.$scope.errorMessage = this.$filter(LC_FILTER)(LC_RCDA_CREATE_ERROR);
  }
}
