import './lp2.scss';
import angularMoment from 'angular-moment';
import ngMaterial from 'angular-material';
import angularTranslate from 'angular-translate';
import dragDrop from 'angular-native-dragdrop';

import rnClient from 'rhino/src/core/client/client.module';
import rnHttp from 'rhino/src/core/http/http.module';
import rnDevice from 'rhino/src/components/device/device.module';
import rnOrganization from 'rhino/src/components/organization/organization.module';
import gazellePerf from 'gazelle/src/components/perf/perf.module';
import cardService from '../cards/cards.module';
import gazelleAnalytics from 'gazelle/src/core/analytics/analytics.module';
import gazelleWalkMe from 'gazelle/src/components/walkme/walkme.module';

import * as hmLp2Directive from './lp2.directive';

export default angular.module('home.components.lp2', [
  rnClient,
  rnHttp,
  rnDevice,
  rnOrganization,
  gazellePerf,
  angularMoment,
  angularTranslate,
  gazelleAnalytics,
  gazelleWalkMe,
  cardService,
  ngMaterial,
  dragDrop,
])
  .directive(hmLp2Directive.name, hmLp2Directive.default)
  .name;
