import ngMaterial from 'angular-material';

// import modules this module depends on
import * as settingsOpenSource from './open-source.controller';

// import this modules components

import './open-source.scss';

// define the module in one place and export the module name
export default angular.module('home.components.opensource', [
  ngMaterial,
  'rhino.core',
  'ui.router'
])
  .controller(settingsOpenSource.name, settingsOpenSource.default)
  .name;
