import './indigo-volume.scss';

import rhino from 'rhino';
import angularTranslate from 'angular-translate';
import gazelleAnalytics from 'gazelle/src/core/analytics/analytics.module';
import images from 'src/images/images.module';
import homePrintBeat from 'src/components/landing-page/cards/shared/print-beat/print-beat.module';

import * as hmIndigoVolumeDirective from './indigo-volume.directive';

export default angular.module('home.components.widget.hmIndigoVolume', [
  rhino,
  angularTranslate,
  'gazelle.components.go',
  gazelleAnalytics,
  images,
  homePrintBeat,
])
  .directive(hmIndigoVolumeDirective.name, hmIndigoVolumeDirective.default)
  .name;
