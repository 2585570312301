import ngMaterial from 'angular-material';
import uiRouter from 'angular-ui-router';
import angularTranslate from 'angular-translate';

import gazelle from 'gazelle';
import rhino from 'rhino';
import images from './images/images.module';
import grid from '@printos/angular1-grid';
import forms from '@printos/angular1-forms';
import spinner from '@printos/angular1-spinner';
import icon from '@printos/angular1-icon';
import button from '@printos/angular1-button';

// Import common HP PrintOS styles
import 'gazelle/src/style/hpprintos.scss';
import './home.scss';

import * as HomeController from './home.controller';

import { name as SettingsEulaControllerName } from 'components/settings/eula/eula.controller';

// Pull in all component modules here.
// In the future, look to introduce webpack split points for performance
// in the router configuration.
// See https://github.com/ay13/webpack-angular-oclazyload/blob/master/app/config.js#L43
let homeModuleNames = requireAllModules();

// Common state resolve functions
const resolve = {
  // Ensures user is authenticated
  requireAuthentication,
  // Ensures translates are loaded
  loadTranslations,
  printOSHeaderReady,
};

angular.module('home',[
  ngMaterial,
  uiRouter,
  rhino,
  gazelle,
  angularTranslate,
  'ct.ui.router.extras.future',
  images,
  grid,
  forms,
  spinner,
  icon,
  button,
  ].concat(homeModuleNames)
)
  .config(homeConfig)
  .config(configLocCatalogs)
  .run(homeRun)
  .controller(HomeController.name, HomeController.default);

function homeConfig($stateProvider, $urlRouterProvider, $logProvider, rnDebugEnabled, gzPageNotFoundState) {
  //noinspection BadExpressionStatementJS
  'ngInject';

  $logProvider.debugEnabled(rnDebugEnabled);

  // For empty routes redirect to /home
  $urlRouterProvider.when('', '/dashboard');

  // For any unmatched url, redirect to /not-found
  $urlRouterProvider.otherwise(gzPageNotFoundState.name);

  //
  // Now set up the states
  $stateProvider
    .state(gzPageNotFoundState.url, gzPageNotFoundState)
    .state('dashboard', {
      url: '/dashboard',
      params: {
        version: {
          value: undefined,
          squash: true,
        },
      },
      template: require('./components/landing-page/landing-page.jade'),
      controller: 'LandingPageController',
      controllerAs: 'vm',
      resolve,
    })
    .state('create-org', {
      url: '/create-org?invitationId&sc&model&type&sn&from',
      params: {
        invitationId: undefined,
        sc: undefined,
        model: undefined,
        type: undefined,
        sn: undefined,
        from: undefined,
        returnUrl: undefined,
      },
      template: require('./components/create-org/create-org.jade'),
      controller: 'CreateOrgController',
      controllerAs: 'vm',
      resolve,
    })
    .state('join-org', {
      url: '/join-org?orgId&oc',
      params: {
        orgId: undefined,
        oc: undefined,
      },
      template: require('./components/join-org/join-org.jade'),
      controller: 'JoinOrgController',
      controllerAs: 'vm',
      resolve,
    })
    .state('welcome-org', {
      url: '/welcome-org?{target}',
      params: {
        target: undefined,
      },
      template: require('./components/welcome-org/welcome-org.jade'),
      controller: 'WelcomeOrgController',
      controllerAs: 'vm',
      resolve,
    })
    .state('add-device', {
      url: '/add-device/{sc}?{from}',
      params: {
        sc: undefined,
        from: undefined,
      },
      template: require('./components/add-lf-device/add-lf-device.jade'),
      controller: 'AddLfDeviceController',
      controllerAs: 'vm',
      resolve,
    })
    .state('settings', {
      url: '/settings?changeHpAccount&hpAccountChanged&statusCode&subCode&returnUrl&goto',
      params: {
        changeHpAccount: undefined,
        hpAccountChanged: undefined,
        data: undefined,
        statusCode: undefined,
        subCode: undefined,
        returnUrl: undefined,
        goto: undefined,
      },
      template: require('./components/settings/settings.jade'),
      controller: 'SettingsController',
      controllerAs: 'vm',
      resolve,
    })
    .state('privacy', {
      url: '/privacy?returnUrl',
      params: {
        returnUrl: undefined,
      },
      template: require('./components/settings/privacy/privacy.jade'),
      controller: 'PrivacyController',
      controllerAs: 'vm',
      resolve,
    })
    .state('set-default-app', {
      url: '/settings/set-default-app?appId',
      params: {
        appId: undefined,
      },
      template: require('./components/settings/set-default-app/set-default-app.jade'),
      controller: 'SetDefaultAppController',
      controllerAs: 'vm',
      resolve,
    })
    .state('subscriptions-edit', {
      url: '/subscriptions-edit?returnUrl',
      params: {
        data: undefined,
        returnUrl: undefined,
      },
      template: require('./components/settings/notifications/subscriptions_edit.jade'),
      controller: 'SubscriptionsEditController',
      controllerAs: 'vm',
      resolve,
    })
    .state('notifications', {
      url: '/notifications?returnUrl',
      params: {
        data: undefined,
        returnUrl: undefined,
      },
      template: require('./components/settings/notifications/notifications.jade'),
      controller: 'NotificationsController',
      controllerAs: 'vm',
      resolve,
    })
    .state('accounts-edit', {
      url: '/accounts-edit?returnUrl',
      params: {
        data: undefined,
        returnUrl: undefined,
      },
      template: require('./components/settings/accounts/accounts_edit.jade'),
      controller: 'AccountsEditController',
      controllerAs: 'vm',
      resolve,
    })
    .state('about', {
      url: '/about?returnUrl&search',
      params: {
        data: undefined,
        returnUrl: undefined,
        search: undefined,
      },
      template: require('./components/settings/about/about.jade'),
      controller: 'SettingsAboutController',
      controllerAs: 'vm',
      resolve,
    })
    .state('terms', {
      url: '/terms?prompt&target&v',
      params: {
        prompt: undefined,
        target: undefined,
        v: undefined,
        data: undefined
      },
      template: require('./components/settings/eula/eula.jade'),
      controller: SettingsEulaControllerName,
      controllerAs: 'vm',
      resolve,
    })
    .state('open-source', {
      url: '/open-source',
      params: {
        data: undefined
      },
      template: require('./components/settings/open-source/open-source.jade'),
      controller: 'OpenSourceController',
      controllerAs: 'vm',
      resolve,
    })
    .state('contact-hp', {
      url: '/contact-hp?returnUrl',
      params: {
        data: undefined,
        returnUrl: undefined,
      },
      template: require('./components/settings/contact/contact.jade'),
      controller: 'ContactController',
      controllerAs: 'vm',
      resolve,
    })
    .state('ask-the-expert', {
      url: '/ask-the-expert?fromId&appId&subject&from',
      params: {
        data: undefined,
        from: undefined,
        fromId: undefined,
        appId: undefined,
        subject: undefined,
      },
      template: require('./components/settings/asktheexpert/asktheexpert.jade'),
      controller: 'AskTheExpertController',
      controllerAs: 'askTheExpert',
      resolve,
    })
    .state('rcda', {
      url: '/rcda',
      params: {
        data: undefined
      },
      template: require('./components/settings/rcda/rcda.jade'),
      controller: 'RcdaController',
      controllerAs: 'rcda',
      resolve,
    })
    .state('rsr', {
      url: '/rsr',
      params: {
        data: undefined
      },
      template: require('./components/settings/request-supplies-report/rsr.jade'),
      controller: 'RsrController',
      controllerAs: 'rsr',
      resolve,
    })

    .state('wow', {
      url: '/wow',
      params: {
        data: undefined
      },
      template: require('./components/settings/wow/wow.jade'),
      controller: 'WowController',
      controllerAs: 'vm',
      resolve,
    })

    .state('feedback', {
      url: '/feedback?returnUrl&appId',
      params: {
        data: undefined,
        appId: undefined,
        returnUrl: undefined
      },
      template: require('./components/settings/feedback/feedback.jade'),
      controller: 'FeedbackController',
      controllerAs: 'feedback',
      resolve,
    })
    .state('support-case', {
      url: '/support-case',
      params: {
        data: undefined
      },
      template: require('./components/settings/supportcases/support_case_list.jade'),
      controller: 'SupportCaseListController',
      controllerAs: 'vm',
      resolve,
    })
    .state('support-case-detail', {
      url: '/case/{caseId}',
      params: {
        'caseId': null,
        'title': null,
        data: undefined
      },
      template: require('./components/settings/supportcase/support_case_detail.jade'),
      controller: 'SupportCaseDetailController',
      controllerAs: 'vm',
      resolve,
    })
    .state('help', {
      url: '/help/{topicId}',
      params: {
        topicId: undefined,
      },
      template: require('./components/settings/help/help-wrapper.jade'),
      controller: 'HelpWrapperController',
      controllerAs: 'vm',
      resolve
    })
    .state('email-subscriptions', {
      url: '/email-subscriptions?returnUrl',
      params: {
        data: undefined,
        returnUrl: undefined,
      },
      template: require('./components/settings/email-subscriptions/email-subscriptions.jade'),
      controller: 'EmailSubscriptionsController',
      controllerAs: 'vm',
      resolve
    });

  return printOSHeaderReady();
}

// function requireAuthentication(rnAuthService, $state, rnApplicationView, gzPageNotFoundState) {
//   'ngInject';
//   let toState = this.self.name;
//   return rnAuthService.authenticate().then(() => {
//     rnApplicationView.hasView(toState).then(isPresent => {
//       if (!isPresent) {
//         $state.go(gzPageNotFoundState.name);
//       }
//     });
//   });
// }

function requireAuthentication(rnAuthService) {
  'ngInject';
  return rnAuthService.isClientAuthenticated();
}

function loadTranslations($translate) {
  'ngInject';
  return $translate.onReady();
}

function printOSHeaderReady() {
  return new Promise((resolve, reject) => {
    let timeout = setTimeout(() => {
      reject();
    }, 180*1000);

    if (window.$printos && window.$printos.readyState === 'complete') {
      clearTimeout(timeout);
      resolve(window.$printos);
    }
    else {
      document.addEventListener('posb-ready', () => {
        clearTimeout(timeout);
        resolve(window.$printos);
      });
    }
  });
}

function homeRun() {
  //noinspection BadExpressionStatementJS
  'ngInject';
  console.log('Home App Running');
}

function configLocCatalogs($translateProvider) {
  //noinspection BadExpressionStatementJS
  'ngInject';

  $translateProvider.useLoader('gzL10nLoader', {
    'catalogServices': {
      'application.application': 'portal_service',
      'home.home': 'portal_service',
      'devices.devices': 'portal_service',
      'market.market': 'portal_service',
      'notification.notification': 'notification_service',
      'pb.app': 'PrintbeatService',
      'pb.ranking': 'PrintbeatService',
    }
  });
}

/**
 * Require all component modules and return the module names.
 * (This uses webpack's require.context() function to find and
 * then require all module files.)
 *
 * @returns {string[]} module names
 */
function requireAllModules() {
  let requiresModule = require.context('./components', true, /\.module\.js$/);
  let scriptFiles = requiresModule.keys();
  return scriptFiles.map(script => requiresModule(script).default);
}
