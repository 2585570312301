import { default as AskTheExpertController } from './asktheexpert.controller';
export const moduleName = 'helpPanelAskTheExpert';
// jscs:disable requireCapitalizedConstructors
// angular requires directives to be lowercase
export default class helpPanelAskTheExpert {
  constructor() {
    this.restrict = 'EA';
    this.template = require('./asktheexpert.jade');
    this.controller = AskTheExpertController;
    this.controllerAs = 'askTheExpert';
    this.scope = true;
    this.bindToController = true;
  }

  static factory() {
    return new helpPanelAskTheExpert();
  }
}
