import { default as RcdaController } from './rcda.controller';
export const moduleName = 'helpPanelRcda';
// jscs:disable requireCapitalizedConstructors
// angular requires directives to be lowercase
export default class helpPanelRcda {
  constructor() {
    this.restrict = 'EA';
    this.template = require('./rcda.jade');
    this.controller = RcdaController;
    this.controllerAs = 'rcda';
    this.scope = true;
    this.bindToController = true;
  }

  static factory() {
    return new helpPanelRcda();
  }
}
