import './shared-accounts.scss';

import * as hmSharedAccountsDirective from './shared-accounts.directive';
import gazelleAnalytics from 'gazelle/src/core/analytics/analytics.module';
import gazelleDeviceStatus from 'gazelle/src/components/device-status/device-status.module';

export default angular.module('home.components.sharedAccounts', [
  gazelleAnalytics,
  gazelleDeviceStatus
])
  .directive(hmSharedAccountsDirective.name, hmSharedAccountsDirective.default)
  .name;
