import './help-wrapper.scss';
import ngMaterial from 'angular-material';
import gazelleAnalytics from 'gazelle/src/core/analytics/analytics.module';
import angularTranslate from 'angular-translate';
import helpViewer from '@printos/angular1-help-viewer';

import * as HelpWrapperController from './help-wrapper.controller';

export default angular.module('home.components.help.helpWrapper', [
  ngMaterial,
  gazelleAnalytics,
  angularTranslate,
  helpViewer,
])
  .controller(HelpWrapperController.name, HelpWrapperController.default)
  .name;
