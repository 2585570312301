export const name = 'hmIndigoPress';
export default function hmIndigoPress() {
  //noinspection BadExpressionStatementJS
  'ngInject';

  let directive = {
    restrict: 'EA',
    scope: {
      gzCard: '=',
    },
    template: require('./indigo-press.jade'),
    controller: hmIndigoPressController,
    controllerAs: 'vm',
    bindToController: true
  };

  return directive;

  /** @ngInject */
  function hmIndigoPressController(
    $window,
    rnHttp,
    rnPreferences,
    $q,
    $translate,
    $interval,
    $scope,
    $rootScope,
    $gzAnalytics,
    $hmPrintBeat
  ) {

    let site;
    let devicesSN;
    let advice;
    let updateInterval;
    let preferences = {};
    let allDevices;
    let family;
    let isMetric = false;
    let pbContext ={};
    let pbDeviceInfoListener;

    let vm = this;
    vm.loading = true;
    vm.goToDevice = goToDevice;

    vm.$onInit = onInit;
    return;
    ////////////////////

    function onInit() {
      family =  _.get(vm.gzCard, 'settings.family');
      devicesSN = vm.gzCard.API.currentSite.getPrintBeatSerialNumbersByFamily(family, true);
      if (!pbDeviceInfoListener && devicesSN.length === 0) {
        pbDeviceInfoListener = $rootScope.$on('$lpPBDeviceInfoUpdated', vm.$onInit);
        $scope.$on('$destroy', () => {
          pbDeviceInfoListener();
        });
        return;
      }

      vm.gzCard.API.config.setSiteCallback(siteChanged);
      vm.gzCard.API.config.setPBCallback(siteChanged);
      updateData();

      let promises = [];
      promises.push(getPreferences());
      promises.push(getData());
      promises.push($translate.onReady());
      $q.all(promises).then(() => {
        switch(family) {
          case 'IndigoPress':
            vm.units = 'IMPRESSIONS';
            break;
          default:
            vm.units = isMetric ? 'SQM' : 'SQF';
            break;
        }

        updateInterval = $interval(getData, 60* 60 * 1000);
        $scope.$on('$destroy', () => {
          $interval.cancel(updateInterval);
        });
      })
        .finally(() => {
          vm.loading = false;
        });
    }

    function getPreferences() {
      rnPreferences.getAll().then(resp => {
        preferences = resp;

        let unitSystem = _.get(preferences, 'general.unitSystem');
        switch(unitSystem) {
          case 'Imperial':
            isMetric = false;
            break;
          default:
            isMetric = true;
        }
      });
    }

    function getData() {
      vm.updating = true;
      site = vm.gzCard.API.currentSite.getSite();

      let promises = [];
      if (family === 'IndigoPress') {
        promises.push(pbGetAdvice());
      }
      return $q.all(promises).then(() => {
      });
    }

    function updateData() {
      let actuals = _.cloneDeep(vm.gzCard.API.currentSite.getPBActualsByFamily(family));
      let targets = _.cloneDeep(vm.gzCard.API.currentSite.getPBTargetsByFamily(family));

      // if (vm.gzCard.profile.initializing || !targets || !actuals) {
      //   return;
      // }

      let selected = _.find(actuals, {device: 'Selected'});
      if (selected) {
        _.remove(actuals, selected);
      }

      let normalizedImpressions;
      _.forEach(actuals, data => {
        if (data.device === 'Selected') {
          return;
        }

        normalizedImpressions = _.get(data, 'impressionType');

        switch(_.get(data, 'pressState.color')) {
          case 'GOOD':
            data.colorIndex = 3;
            break;
          case 'NEUTRAL':
            data.colorIndex = 4;
            break;
          case 'BAD':
            data.colorIndex = 1;
            break;
          default:
            data.colorIndex = 5;
            break;
        }
        let device = vm.gzCard.API.helpers.getDeviceByPrintBeatSerialNumber(data.device);
        data.deviceName = _.get(device, 'name', '???');
        if (_.get(data, 'pressState.state')) {
          data.stateDisplay = $translate.instant('pb.app.' + data.pressState.state);
        }
        else {
          data.stateDisplay = $translate.instant('home.home.Unknown');
        }
      });

      if (normalizedImpressions) {
        _.set(vm, 'impressionsLabel', $translate.instant('home.home.NormalizedImpressions', [normalizedImpressions]));
        _.set(vm, 'impressionsTooltip', $translate.instant('home.home.NormalizedImpressionsTooltip'));
      }
      else {
        _.set(vm, 'impressionsLabel', $translate.instant('home.home.Impressions'));
        delete(vm.impressionsTooltip);
      }

      //vm.devices = _.sortByAll(actuals, ['colorIndex', 'stateDisplay', 'deviceName']);
      allDevices = _.sortBy(actuals, 'deviceName');
      if (allDevices.length > 1) {
        let pageDisplay = [];
        _.forEach(allDevices, device => {
          pageDisplay.push(device.deviceName);
        });
        vm.gzCard.API.config.setPages(allDevices, pageChanged, pageDisplay);
        vm.isPaged = true;
      }
      else {
        vm.isPaged = false;
      }
      vm.device = allDevices[vm.gzCard.pageIndex];

      pbContext = {
        page: 'dashboard',
        family: family === 'LF' ? 'LatexPrinter' : family,
        siteId: vm.gzCard.API.currentSite.getSite().siteId,
        serialNumber: _.get(vm.device, 'device'),
      };

      let pbUrl = $hmPrintBeat.createLink(pbContext);
      vm.gzCard.API.config.setAppLink(pbUrl);

      let targetObj = getTargetObject(targets, _.get(vm.device, 'device'));
      let dayTarget = _.get(targetObj, 'shiftTarget.value');
      let nowTarget = _.get(targetObj, 'intraDailyTarget.value');
      let found = _.find(actuals, {device: _.get(vm.device, 'device')});

      let actual = _.get(found, 'value');
      let progress = 100;
      let pace = 0;
      let status = 'OK';
      if (dayTarget !== undefined && nowTarget !== undefined) {
        pace = Math.round(nowTarget / dayTarget * 100);
      }

      if (actual !== undefined) {
        if (dayTarget && actual < dayTarget) {
          progress = Math.round(actual / dayTarget * 100);
        }
      }
      else {
        progress = 0;
      }

      if (progress < pace) {
        if (pace - progress < 30) {
          status = 'WARN';
        }
        else {
          status = 'ERROR';
        }
      }

      let deviceAdvice = [];
      if (advice) {
        _.forEach(advice, item => {
          if (item.device === vm.device.device) {
            deviceAdvice.push(item);
          }
        });

        if (deviceAdvice.length > 0) {
          vm.advice = deviceAdvice[0];
        }
        else {
          vm.advice = undefined;
        }
      }

      vm.progress = progress;
      vm.progressStatus = status;
      vm.pace = pace;
      vm.isGoalSet = dayTarget !== undefined;
      vm.actual = actual;
      vm.goal = dayTarget;
    }

    function getTargetObject(list, SN) {
      let found;
      _.forEach(list, item => {
        if (item.shiftTarget.device === SN) {
          found = item;
          return false;
        }
      });
      return found;
    }

    function pbGetAdvice() {
      return rnHttp.get('PrintbeatService', 'Advisor', {
        params: {
          bu: family,
          devices: devicesSN,
          formatted: true,
          language: 'en-US'
        }
      }).then(resp => {
        advice = resp.data;
        _.forEach(advice, adviceItem => {
          let paragraphs = adviceItem.adviceText.split('<p>');
          _.set(adviceItem, 'adviceText', paragraphs[1].replace('</p>', ''));
        });
      });
    }

    function siteChanged(site) {
      updateData();
    }

    function pageChanged(page) {
      vm.device = page;
      updateData();
    }

    function goToDevice($event) {
      $gzAnalytics.eventClick('go to print beat');
      $hmPrintBeat.redirect(pbContext, $event);
    }
  }
}
