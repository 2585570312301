import rhino from 'rhino';
import angularMoment from 'angular-moment';
import angularTranslate from 'angular-translate';

import gazelleBrowser from 'gazelle/src/components/browser/browser.module';
import gazelleAnalytics from 'gazelle/src/core/analytics/analytics.module';
import images from 'src/images/images.module';

import './jobs.scss';
import * as hmJobsDirective from './jobs.directive';

export default angular.module('home.components.widget.jobs', [
  rhino,
  angularMoment,
  angularTranslate,
  'ngAnimate',
  gazelleBrowser,
  'rhino.components.locale',
  'gazelle.components.go',
  'gazelle.components.walkme',
  gazelleAnalytics,
  images
])
  .directive(hmJobsDirective.name, hmJobsDirective.default)
  .name;
