import { ACCOUNT_TYPE } from 'src/components/landing-page/cards/card-definitions';

export const name = 'hmMyAccount';
export default function hmMyAccount($log) {
  //noinspection BadExpressionStatementJS
  'ngInject';

  let directive = {
    restrict: 'EA',
    scope: {
      gzCard: '=',
    },
    template: require('./my-account.jade'),
    controller: myAccountController,
    controllerAs: 'vm',
    bindToController: true
  };

  return directive;

  /** @ngInject */
  function myAccountController(
    $window,
    rnHttp,
    rnClient,
    rnOrganization,
    rnInvitation,
    rnDcs,
    rnUser,
    $gzGo,
    $gzProfileImage,
    $q,
    $state,
    $translate,
    $timeout,
    $gzAnalytics,
    $mdToast,
    $mdDialog,
    $rootScope
  ) {
    const ANALYTICS_CATEGORY_CREATE = 'Create Organization';
    const ANALYTICS_CATEGORY_JOIN = 'Join Organization';
    const ANALYTICS_CATEGORY = 'my-account-card';

    let isReseller = false;
    let cc;
    let isExternalUser = false;

    let vm = this;
    vm.loading = true;
    vm.clientConfig = {};
    vm.myRoles = [];
    vm.userCount = '?';
    vm.deviceCount = '?';
    vm.requestCount = '?';
    vm.inviteCount = '?';
    vm.useDefaultImage = true;
    vm.openRequests = [];
    vm.openRequestsTooltip = null;

    vm.goToAccount = goToAccount;
    vm.goToUsers = goToUsers;
    vm.goToInvitations = goToInvitations;
    vm.goToAddUser = goToAddUser;
    vm.goToDevices = goToDevices;
    vm.goToRequests = goToRequests;
    vm.goToAddDevice = goToAddDevice;
    vm.goToContactHP = goToContactHP;
    vm.hasAccount = false;
    vm.canEdit = false;
    vm.canAddDevice = false;
    vm.canAddUser = false;
    vm.canEditAccount = false;
    vm.hasDevices = true;
    vm.goToCreateOrg = goToCreateOrg;
    vm.copyCode = copyCode;
    vm.emailCode = emailCode;
    vm.enterCode = enterCode;
    vm.changeOrgProfileImage = changeOrgProfileImage;

    vm.$onInit = onInit;
    ////////////////////

    function onInit() {
      cc = vm.gzCard.API.general.getClientConfig();
      vm.isOrphan = cc.context.type === 'Unknown';
      isReseller = cc.context.type === 'Channel';
      vm.isHP = cc.context.type === 'HP';
      vm.accountName = _.get(cc, 'context.name', 'NO_ACCOUNT_NAME');
      isExternalUser = vm.gzCard.API.account.isAccountType(ACCOUNT_TYPE.EXTERNAL);
      vm.hasAccount = $window.$printos.v1.app.hasApp('account');
      vm.canEdit = $window.$printos.v1.user.hasPermission('account_service', 'Create');

      vm.currentProfileImageSrc = _.get(cc, 'context.image', null);
      let promises = [];
      promises.push(getJoinRequests());
      if (!vm.isOrphan && !isExternalUser) {
        if (!vm.isHP) {
          promises.push(getOrgCode());
        }
        promises.push(getRequestsCount());
        promises.push(getUserRoles());
        promises.push(getOrganizationData());
        promises.push(getInvitationCount());
        promises.push(getDeviceLinks());
      }
      $q.all(promises).then(() => {
        $window.addEventListener('posh-join-requests-updated', handleJoinRequestsUpdated);
      })
        .finally(() => {
          vm.loading = false;
        });
    }

    function getOrgCode() {
      return rnHttp.get('sso_request', 'request/new/code').then(resp => {
        vm.pin = _.get(resp, 'data.code');
      });
    }

    function getInvitationCount() {
      let filterInvType = isReseller ? undefined : 'USER_IN_ORG';

      return rnInvitation.getAll(0,0, filterInvType).then(data => {
        vm.canAddUser = data.canCreate();
      }).catch(e => {
        return Promise.resolve();
      });
    }

    function getUserRoles(){
      return rnUser.getSelectedUserRoles().then(data => {
        let allRoles = data.userRoles;
        vm.myRoles = [];
        _.forEach(allRoles, role => {
          if (role.context.id === cc.context.id) {
            vm.myRoles.push(role.systemRole.name);
          }
        });

        if (vm.myRoles.length === 0) {
          let hpRole = _.find(allRoles, role => {
            return role.systemRole.organizationType === 'HP';
          });

          if (hpRole) {
            vm.myRoles.push(hpRole.systemRole.name);
            vm.rolesDisplay = $translate.instant('uxf.uxf.SystemRole_' + hpRole.systemRole.name);
            vm.rolesTooltip = vm.rolesDisplay;
          }
        }
        else if (vm.myRoles.length === 1) {
          vm.rolesDisplay = $translate.instant('uxf.uxf.SystemRole_' + vm.myRoles[0]);
          vm.rolesTooltip = vm.rolesDisplay;
        }
        else {
          vm.rolesDisplay = $translate.instant('home.home.MultipleRoles', [vm.myRoles.length]);
          vm.rolesTooltip = '';
          for (let i = 0; i < vm.myRoles.length; i++) {
            vm.rolesTooltip += $translate.instant('uxf.uxf.SystemRole_' + vm.myRoles[i]);
            if (i < vm.myRoles.length - 1) {
              vm.rolesTooltip += ', ';
            }
          }
        }
      });
    }

    function getRequests() {
      const supportedRequests = ['ProductAcceptProposal', 'AddUser', 'SSOJoinOrganization'];
      return rnHttp.get('sso_request', 'request/new').then(resp => {
        let requests = _.get(resp, 'data.requestList', []);
        requests = requests.filter(r => {
          if (r.status !== 'Pending') {
            return false;
          }

          if (!supportedRequests.includes(r.type)) {
            return false;
          }

          if (r.type === 'ProductAcceptProposal' && window.$printos.v1.org.getOrgId() === r.organizationId); {
            return true;
          }

          return false;
        });
        vm.requestCount = requests.length;
      });
    }

    function getJoinRequests() {
      return rnHttp.get('sso_request', 'request/addusers').then(resp => {
        vm.openRequests = _.get(resp, 'data.requestList', []);
        for (let i = 0; i < vm.openRequests.length; i++) {
          vm.openRequests[i].orgName = resp.data.organizationNames[i];
        }

        updateRequests();
      });
    }

    function handleJoinRequestsUpdated(event) {
      $rootScope.$applyAsync(() => {
        vm.openRequests = event.detail;
        updateRequests();
      });
    }

    function updateRequests() {
      if (vm.openRequests.length > 0) {
        vm.openRequestsTooltip = `<div class='request-tooltip'>`;
        vm.openRequests.forEach(r => {
          if (r.orgName) {
            vm.openRequestsTooltip += `<div class='request-row'>`;
            vm.openRequestsTooltip += `<div class='request-data'> ${r.orgName}</div>`;
            //vm.openRequestsTooltip += `<div class='request-data'> ${r.orgName}</div>`;
            vm.openRequestsTooltip += `</div>`;
          }
        });
        vm.openRequestsTooltip += `</div`;
      }
    }

    function getRequestSummary() {
      return rnHttp.get('sso_request', 'request/summary').then(resp  => {
        vm.canAcceptRequests = _.get(resp, 'data.newRequests') !== undefined;
      });
    }

    function getRequestsCount() {
      let promises = [];
      promises.push(getRequests());
      promises.push(getRequestSummary());
      return Promise.all(promises);
    }

    function getOrganizationData() {
      return rnOrganization.getOrganization().then(response => {
        vm.account = response.data;
        vm.canEditAccount = vm.account._links.update !== undefined;
        return rnHttp.get('aaa', 'organizations/summary', {params: {deviceFilter: true}}).then(({data:summary}) => {
          vm.userCount = summary.numUsers;
          vm.deviceCount = summary.numDevices;
        });
      });
    }

    function getDeviceLinks() {
      if (vm.gzCard.API.account.isAccountType(ACCOUNT_TYPE.HP) || vm.gzCard.API.account.isAccountType(ACCOUNT_TYPE.RESELLER)) {
        vm.canAddDevice = false;
        vm.hasDevices = false;
        return $q.resolve();
      }

      return rnDcs.getAll(0,0).then(data => {
        vm.canAddDevice = _.get(data, '_links.create') ? true : false;
      });
    }

    function goToAccount() {
      if (!vm.hasAccount) {
        return;
      }
      $gzAnalytics.event('Go To: Account', {category: ANALYTICS_CATEGORY});
      $window.location.href = '/account';
    }

    function goToUsers() {
      if (!vm.hasAccount) {
        return;
      }
      $gzAnalytics.event('Go To: Users', {category: ANALYTICS_CATEGORY});
      $window.location.href = '/account/#/users';
    }

    function goToDevices() {
      if (!vm.hasAccount) {
        return;
      }
      $gzAnalytics.event('Go To: Devices', {category: ANALYTICS_CATEGORY});
      $window.location.href = '/account/#/devices';
    }

    function goToRequests() {
      if (!vm.hasAccount) {
        return;
      }
      $gzAnalytics.event('Go To: Requests', {category: ANALYTICS_CATEGORY});
      $window.location.href = '/account/#/requests';
    }

    function goToInvitations() {
      if (!vm.hasAccount) {
        return;
      }
      $gzAnalytics.event('Go To: Invitations', {category: ANALYTICS_CATEGORY});
      $window.location.href = '/account/#/invitations?status=Pending';
    }

    function goToCreateOrg() {
      $gzAnalytics.event('Create Organization - Click', {category: ANALYTICS_CATEGORY_CREATE});
      $state.go('create-org');
    }

    function goToAddUser() {
      $gzAnalytics.event('Go To: Add User', {category: ANALYTICS_CATEGORY});
      $gzGo.startTask('invite-user', 'invitations/send', 'home', 'dashboard', {});
    }

    function goToAddDevice() {
      $gzAnalytics.event('Go To: Add Device', {category: ANALYTICS_CATEGORY});
      $gzGo.startTask('device-add', 'device-list/add', 'home', 'dashboard', {});
    }

    function goToContactHP() {
      $gzAnalytics.event('Go To: Contact Support', {category: ANALYTICS_CATEGORY});
      $state.go('ask-the-expert');
    }

    function enterCode() {
      $gzAnalytics.event('Join Organization - Click', {category: ANALYTICS_CATEGORY_JOIN});
      return $mdDialog.show({
        parent: angular.element(document.body),
        clickOutsideToClose: false,
        escapeToClose: true,
        template: require('./org-code/org-code-dialog.jade'),
        controller: 'OrgCodeDialogController',
        bindToController: true,
        controllerAs: 'vm',
        locals: {
        }
      }).catch(() => {
          // user cancelled
        $gzAnalytics.event('Join Organization - Cancel', {category: ANALYTICS_CATEGORY_JOIN});
        return Promise.resolve();
        });
    }

    function emailCode(event) {
      event.stopPropagation();
      $gzAnalytics.event('Join Organization - Email PIN', {category: ANALYTICS_CATEGORY, label: 'my-account-card'});
      // let baseUrl = $state.href('accept-connection', {}, {absolute: true});
      let origin = $window.location.origin;
      let appBase = '/start/';
      let route = `#/accept/code/${vm.pin}`;
      let baseUrl = `${origin}${appBase}${route}`;
      let node = document.createElement('a');
      document.body.appendChild(node);
      let body = $translate.instant('home.home.Email_Salutation') + '%0A';
      body += $translate.instant('home.home.Email_Instructions') + '%0A%0A';
      body += $translate.instant('home.home.Email_Step1', [baseUrl]) + '%0A';
      body += $translate.instant('home.home.Email_Step2') + '%0A';
      body += $translate.instant('home.home.Email_Step3', [cc.context.name]) + '%0A%0A';
      body += $translate.instant('home.home.Email_Details', [cc.context.name]) + '%0A%0A';
      body += $translate.instant('home.home.Email_Closing') + '%0A';
      body += `${cc.entity.firstName}`;
      let href = 'mailto:?';
      let subject = $translate.instant('home.home.Email_Subject', [cc.context.name]);
      href += `subject=${subject}`;
      let email = _.get(cc, 'entity.email');
      if (email) {
        href += `&cc=${email}`;
      }
      href += `&body=${body}`;

      node.href = `${href}`;
      node.click();
      document.body.removeChild(node);
    }

    function copyCode() {
      $gzAnalytics.event('Join Organization - Copy PIN', {category: ANALYTICS_CATEGORY, label: 'my-account-card'});
      let node = document.createElement('input');
      document.body.appendChild(node);
      node.value = vm.pin;
      node.focus();
      node.select();
      let result = document.execCommand('copy');
      document.body.removeChild(node);
      $mdToast.showSimple($translate.instant('home.home.CopiedToClipboard'));
    }

    function changeOrgProfileImage($event) {
      if (!vm.canEditAccount) {
        return;
      }
      if ($event) {
        $event.stopPropagation();
      }

      $gzAnalytics.event('Org Profile Image - Open', {category:ANALYTICS_CATEGORY});
      $gzProfileImage.changeOrgImage().then(() => {
        $gzAnalytics.event('Org Profile Image - Changed', {category:ANALYTICS_CATEGORY});
        getCurrentProfileImageFromServer(false);
      })
        .catch((error) => {
          //No prop
        });
    }

    function getCurrentProfileImageFromServer(cache = true) {
      return $window.$printos.v1.client.getConfigAsync().then(resp => {
        $timeout(() => {
          cc = resp;
          vm.currentProfileImageSrc = _.get(cc, 'context.image', null);
        });
      })
      .catch(() => {
        // No profile has yet been set up
        vm.currentProfileImageSrc = null;
      });
    }
  }
}
