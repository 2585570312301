import './whats-next.scss';

import angularMaterial from 'angular-material';
import angularTranslate from 'angular-translate';
import rhino from 'rhino';
import gazelleAnalytics from 'gazelle/src/core/analytics/analytics.module';
import gazelleGo from 'gazelle/src/components/go/go.module';
import rnDeviceDetector from 'rhino/src/components/device/device.module';
import gazelleWalkMe from 'gazelle/src/components/walkme/walkme.module';
import rhinoLocale from 'rhino/src/components/locale/locale.module';
import rhinoSession from 'rhino/src/components/session/session.module';
import rhinoRequest from 'rhino/src/components/request/request.module';

import * as myDirective from './whats-next.directive';

export default angular.module('home.components.widget.whatsNext', [
  angularMaterial,
  angularTranslate,
  gazelleAnalytics,
  gazelleGo,
  rnDeviceDetector,
  gazelleWalkMe,
  rhinoSession,
  rhinoRequest,
  rhinoLocale,
  rhino
])
  .directive(myDirective.name, myDirective.default)
  .name;
