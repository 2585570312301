import angularMaterial from 'angular-material';
import rnHttp from 'rhino/src/core/http/http.module';
import rnOrganization from 'rhino/src/components/organization/organization.module';
import './color-config.scss';

import * as hmColorConfigDirective from './color-config.directive';
import rnApplication from 'rhino/src/components/application/application.module';
import gazelleGo from 'gazelle/src/components/go/go.module';

export default angular.module('home.components.colorConfig', [
  'angulartics',
  'gazelle.components.go',
  rnHttp,
  rnOrganization,
  angularMaterial,
  rnApplication,
  gazelleGo
])
  .directive(hmColorConfigDirective.name, hmColorConfigDirective.default)
  .name;
