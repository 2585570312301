import { cardDefinitions } from './card-definitions';
import { dashboardDefinitions } from './dashboard-definitions';
import { LP_DEVICE_FAMILY } from "src/components/landing-page/cards/card-definitions";

export const name = '$hmCards';
export default function $hmCards(
  rnClient,
  rnHttp,
  $translate,
  $q
) {
  'ngInject';

  // Constants
  // Variables
  let cardAPI;
  let allowedCards = [];
  let applications = [];
  let dashboards = [];
  let clientConfig;
  let indexId = 0;

  // Service
  let service = {
    setAPI: setAPI,
    getWelcomeCard: getWelcomeCard,
    getDefaultDefinition: getDefaultDefinition,
    getDashboard: getDashboard,
    getDashboardDefinition: getDashboardDefinition,
    getAllCards: getAllCards,
    getNewCard: getNewCard,
    initDashboards: initDashboards,
    saveDashboard: saveDashboard,
    restoreDashboard: restoreDashboard,
    getIndexId: getIndexId,
  };

  activate();

  return service;
  //////////////////////

  function activate() {
    let promises = [];
    promises.push(initClientConfig());
    return $q.all(promises).then(() => {});
  }

  function initClientConfig() {
    return rnClient.getConfig().then(config => {
      clientConfig = config;
      applications = _.get(clientConfig, 'apps.entity', []);
    });
  }

  function setAPI(api) {
    cardAPI = api;

    allowedCards = cardDefinitions;
    if (!api.account.hasOrg()) {
      // Filter out cards that require an org context
      allowedCards = allowedCards.filter(card => {
        return !card.requireOrg;
      });
    }

    let features = _.get(clientConfig, 'features', []);
    // Filter out cards where feature flag is not enabled
    allowedCards = allowedCards.filter(card => {
      if (card.featureId && !features.includes(card.featureId)) {
       return false;
      }

      return card.allow(cardAPI);
    });
  }

  //TODO evaluate if this is vestigial
  function getWelcomeCard() {
    let cardId = 'dashboardSelector'; // used to be possibly 'promotionSelector'
    let welcomeCard = _.find(allowedCards, { cardId });
    assignCardAPI(welcomeCard);
    return welcomeCard;
  }

  function getDefaultDefinition(businessUnits, accountType, deviceFamily) {
    let definition = _.get(dashboardDefinitions, [accountType, deviceFamily], dashboardDefinitions[accountType].DEFAULT);

    definition.sections = definition.sections.filter(section => {
      if (section.featureId && !_.includes(clientConfig.features, section.featureId)) {
        return false;
      }

      return true;
    });

    return definition;
  }

  function getDashboard(businessUnits, accountType, family, dashboardId) {
    if (!family) {
      family = LP_DEVICE_FAMILY.NONE;
    }
    let definition;
    let filteredDashboards = dashboards.filter(dashboard => {
      return dashboard.type === accountType && dashboard.family === family;
    });

    if (filteredDashboards.length === 0) {
      definition = getDefaultDefinition(businessUnits, accountType, family);
      if (!definition.family) {
        definition.family = family;
      }
      return getDashboardFromDefinition(definition);
    }
    else if (filteredDashboards.length === 1) {
      definition = filteredDashboards[0];
      fillOutSavedDashboard(definition, businessUnits, accountType, family);
    }
    else {
      if (dashboardId) {
        definition = _.find(filteredDashboards, {dashboardId: dashboardId});
      }

      if (!definition) {
        definition = filteredDashboards[0];
      }
    }

    fixupDashboardDefinition(definition);
    return getDashboardFromDefinition(definition);
  }

  // This is in case we have need to rename cards
  // This will change the definition to have the new name of the card
  function fixupDashboardDefinition(definition) {
    let cards = _.get(definition, 'highlightSection.cards', []);
    patchCardIds(cards);

    definition.sections.forEach(section => {
      cards = _.get(section, 'cards', []);
      patchCardIds(cards);
    });
  }

  function patchCardIds(cards) {
    for (let i = 0; i < cards.length; i++) {
      if (cards[i].cardId === 'pageWideKnowledgeZone') {
        cards[i].cardId = 'scitexKnowledgeZone';
      }
    }
  }

  function fillOutSavedDashboard(dashboard, businessUnits, accountType, family) {
    let definition = getDefaultDefinition(businessUnits, accountType, family);
    if (definition) {
      dashboard.intro = definition.intro;
    }
  }

  function getDashboardDefinition(dashboard) {

  }

  function getAllCards(family) {
    let allAllowedCards = allowedCards.filter(card => {
      return card.cardId !== 'dashboardSelector' &&
        card.cardId !== 'promotionSelector' &&
        (!family || !card.family || card.family === family) &&
        card.include(cardAPI);
    });

    let allCards = [];
    allAllowedCards.forEach(card => {
      allCards.push(initCard(card));
    });

    allCards = allCards.sort((a, b) => {
      if (a.title.toLocaleLowerCase() < b.title.toLocaleLowerCase()) {return -1;}
      if (a.title.toLocaleLowerCase() > b.title.toLocaleLowerCase()) {return 1;}
      return 0;
    });

    return allCards;
  }

  function getNewCard(card) {
    return initCard(card);
  }

  function getDashboardFromDefinition(definition) {
    let dashboard = _.cloneDeep(definition);
    if (dashboard.highlightSection) {
      dashboard.highlightSection.cards = dashboard.highlightSection.cards.filter(card => {
        let found = _.find(allowedCards, {cardId: card.cardId});
        return found !== undefined && found.allow(cardAPI);
      });

      for (let i = 0; i < dashboard.highlightSection.cards.length; i++) {
        dashboard.highlightSection.cards[i] = initCard(dashboard.highlightSection.cards[i]);
      }
    }

    if (dashboard.sections) {
      dashboard.sections.forEach(section => {
        section.indexId = getIndexId();
        let allowedCardsForFamily = allowedCards.filter(card => {
          if (!card.family) {
            return true;
          }
          return card.family === definition.family ? definition.family : LP_DEVICE_FAMILY.NONE;
        });
        section.cards = section.cards.filter(card => {
          let found = _.find(allowedCardsForFamily, {cardId: card.cardId});
          return found !== undefined && found.include(cardAPI);
        });

        for (let i = 0; i < section.cards.length; i++) {
          section.cards[i] = initCard(section.cards[i]);
          section.cards[i].indexId = getIndexId();
        }

        let appId;
        let setApplication = true;
        section.cards.forEach(card => {
          if (!card.appId) {
            return true;
          }

          if (!appId) {
            appId = card.appId;
            return true;
          }

          if (appId !== card.appId) {
            setApplication = false;
            return false;
          }
        });

        if (appId && setApplication) {
          section.application = section.cards[0].application;
        }
      });
    }

    return dashboard;
  }

  function initCard(card) {
    let baseCard = _.find(allowedCards, {cardId: card.cardId});
    let clonedCard = _.cloneDeep(baseCard);

    clonedCard.title = $translate.instant(clonedCard.titleKey);
    // clonedCard.matchesFilter = baseCard.filter();
    clonedCard.matchesFilter = true;
    assignCardAPI(clonedCard);
    clonedCard.application = _.find(applications, {appId: clonedCard.appId});
    if (clonedCard.application) {
      // Default link to app link
      clonedCard.link = _.get(clonedCard, 'application._links.self');
    }
    else if (clonedCard.linkKey) {
      let found = clientConfig.registry.services[clonedCard.linkKey];
      if (found) {
        clonedCard.link = found.sv_url;
      }
    }
    return clonedCard;
  }

  function assignCardAPI(card) {
    card.API = Object.assign({}, cardAPI, {
      config: {
        setAppLink: cardAPI.actions.setAppLink.bind(null, card),
        setPages: cardAPI.actions.setPages.bind(null, card),
        setSiteCallback: cardAPI.actions.setSiteCallback.bind(null, card),
        setPBCallback: cardAPI.actions.setPBCallback.bind(null, card),
      }
    });

    return card;
  }

  function initDashboards() {
    return rnHttp.get('portal_service', 'dashboards', {}).then(resp => {
      dashboards = resp.data.dashboards.filter(dashboard => {
        return dashboard.appId === 'lp2';
      });
    });
  }

  function saveDashboard(dashboard) {
    let found = dashboards.find(savedDashboard => {
      return savedDashboard.family === dashboard.family && savedDashboard.type === dashboard.type;
    });

    if (found) {
      return editDashboard(getCleanDashboard(dashboard, found.id));
    }
    else {
      return createDashboard(getCleanDashboard(dashboard));
    }

    return $q.resolve();
  }

  function restoreDashboard(businessUnits, dashboard) {
    let found = dashboards.find(savedDashboard => {
      return savedDashboard.family === dashboard.family && savedDashboard.type === dashboard.type;
    });

    if (found) {
      return rnHttp.delete('portal_service', `dashboards/${found.id}`, {}).then(() => {
        dashboards = dashboards.filter(dashboard => {
          return dashboard.id !== found.id;
        });

        return getDashboard(businessUnits, dashboard.type, dashboard.family);
      });
    }
    else {
      return $q.resolve(getDashboard(businessUnits, dashboard.type, dashboard.family));
    }
  }

  function getIndexId() {
    return indexId++;
  }

  function editDashboard(dashboard) {
    return rnHttp.put('portal_service', 'dashboards/' + dashboard.id, dashboard).then(resp => {
      let savedDashboard = resp.data;
      _.remove(dashboards, {id: savedDashboard.id});
      dashboards.push(savedDashboard);
      return savedDashboard;
    });
  }

  function createDashboard(dashboard) {
    return rnHttp.post('portal_service', 'dashboards', dashboard).then(resp => {
      let newDashboard = resp.data;
      dashboards.push(newDashboard);
      return newDashboard;
    });
  }

  function getCleanDashboard(dashboard, dashboardId) {
    let cleanDashboard = {
      appId: dashboard.appId,
      family: dashboard.family,
      name: dashboard.family,
      type: dashboard.type,
      highlightSection: {
        nameKey: dashboard.highlightSection.nameKey,
        cards: [],
      },
      sections: []
    };

    if (dashboardId) {
      cleanDashboard.id = dashboardId;
    }

    cleanDashboard.highlightSection.cards = getCleanCards(dashboard.highlightSection.cards);
    dashboard.sections.forEach(section => {
      let cleanSection = {
        isGroup: section.isGroup,
        layout: section.layout,
        nameKey: section.nameKey,
      };

      if (section.name) {
        cleanSection.name = section.name;
      }

      cleanSection.cards = getCleanCards(section.cards);
      cleanDashboard.sections.push(cleanSection);
    });

    return cleanDashboard;
  }

  function getCleanCards(cards) {
    let cleanCards = [];
    cards.forEach(card => {
      let cleanCard = {
        cardId: card.cardId,
      };

      if (card.settings) {
        cleanCard.settings = _.cloneDeep(card.settings);
      }

      cleanCards.push(cleanCard);
    });

    return cleanCards;
  }
}
