import './multi-select-prompt-dialog.scss';
import ngMaterial from 'angular-material';

import * as MultiSelectPromptDialogController from './multi-select-prompt-dialog.controller';

export default angular.module('home.components.multiSelectPromptDialog', [
  ngMaterial,
])
  .controller(MultiSelectPromptDialogController.name, MultiSelectPromptDialogController.default)
  .name;
