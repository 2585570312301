import {LP_DEVICE_FAMILY} from 'src/components/landing-page/cards/card-definitions';

export const name = 'hmIndigoScore';
export default function hmIndigoScore($mdMedia) {
  //noinspection BadExpressionStatementJS
  'ngInject';

  let directive = {
    restrict: 'EA',
    scope: {
      gzCard: '=',
    },
    template: require('./indigo-score.jade'),
    controller: hmIndigoScoreController,
    controllerAs: 'vm',
    bindToController: true
  };

  return directive;

  /** @ngInject */
  function hmIndigoScoreController(
    $window,
    rnHttp,
    $q,
    $gzAnalytics,
    $translate,
    $timeout,
    $scope,
    moment,
    $hmPrintBeat
  ) {

    let vm = this;
    vm.loading = true;
    vm.techData = [];
    vm.printData = [];
    vm.suppliesData = [];
    vm.restartData = [];
    vm.availabilityData = [];
    vm.chartConfig = {
      loading: false,
      chart: {
        spacingTop: 10,
        spacingRight: 5,
        spacingLeft: 0,
        type: 'areaspline',
        height: 220,
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: !!$mdMedia('gt-xs'),
        align: 'right',
        borderWidth: 0,
        margin: 4,
        itemMarginTop: 5,
        itemMarginBottom: 5,
        itemStyle: {
          fontFamily: 'Arial',
          fontSize: '14px',
          fontWeight: 'normal',
          padding: '0px',
        },
        layout: 'vertical',
        verticalAlign: 'middle',
      },
      plotOptions: {
        series: {
          stacking: 'normal',
        },
        areaspline: {
          marker: {
            enabled: false,
          },
        }
      },
      title: {
        text: '',
      },
      tooltip: {
        backgroundColor: '#FFFFFF',
        delayForDisplay: 200,
        headerFormat: '',
        hideDelay: 1,
        padding: 0,
        shadow: false,
        shared: false,
        useHTML: true,
      },
      series: [
        {
          color: '#e9aa41',
          fillOpacity: 1,
          name: 'Technical issues',
          data: vm.techData,
        },
        {
          color: '#a13392',
          fillOpacity: 1,
          name: 'Supplies lifespan',
          data: vm.suppliesData,
        },
        {
          color: '#e64265',
          fillOpacity: 1,
          name: 'Restarts',
          data: vm.restartData,
        },
        {
          color: '#3dcace',
          fillOpacity: 1,
          name: 'Availability',
          data: vm.availabilityData,
        },
        {
          color: '#3091d5',
          fillOpacity: 1,
          name: 'Print volume',
          data: vm.printData,
        }
      ],
      xAxis: {
        min: 0.5,
        max: 2.5,
        crosshair: {
          color: '#c1c1c1',
          width: 1,
        },
        title: {
          style: {
            fontSize: '12px',
          },
        }
      },
      yAxis: {
        crosshair: {
          color: '#c1c1c1',
          width: 1,
        },
        floor: 0,
        tickInterval: 20,
        labels: {

        },
        max: 100,
        min: 0,
        softMin: 0,
        title: {
          style: {
            fontSize: '12px',
          },
        }
      },
    };

    vm.$onInit = onInit;
    return;
    ////////////////////

    function onInit() {
      vm.gzCard.API.config.setSiteCallback(siteChanged);
      let appLink = $hmPrintBeat.createLink({
        page: 'reports',
        family: 'IndigoPress',
        siteId: vm.gzCard.API.currentSite.getSite().siteId,
      });
      vm.gzCard.API.config.setAppLink(appLink);

      let promises = [];
      promises.push($translate.onReady());
      promises.push(getScores());
      $q.all(promises).then(() => {
        _.set(vm.chartConfig, 'yAxis.title.text', $translate.instant('home.home.IndigoScore_Points'));
        _.set(vm.chartConfig.series[0], 'name', $translate.instant('home.home.IndigoScore_TechnicalIssues'));
        _.set(vm.chartConfig.series[1], 'name', $translate.instant('home.home.IndigoScore_SuppliesLifespan'));
        _.set(vm.chartConfig.series[2], 'name', $translate.instant('home.home.IndigoScore_Restarts'));
        _.set(vm.chartConfig.series[3], 'name', $translate.instant('home.home.IndigoScore_Availability'));
        _.set(vm.chartConfig.series[4], 'name', $translate.instant('home.home.IndigoScore_PrintVolume'));

        // _.set(vm.chartConfig, 'xAxis.title.text', $translate.instant('home.home.IndigoScore_Weeks'));
        vm.loading = false;

        // This is a bit of a hack to ensure that the Highchart directive resizes to fit the card
        $window.addEventListener('resize', handleResize);
        $scope.$on('$destroy', () => {
          $window.removeEventListener('resize', handleResize);
        });
      });
    }

    function handleResize() {
      vm.chartConfig.legend.enabled = !!$mdMedia('gt-xs');
      $timeout(()=> {
        vm.chartConfig.getChartObj().reflow();
      }, 500);
    }

    function getScores() {
       let config = {
        params: {
          bu: 'IndigoPress',
          offset: -3,
          devices: vm.gzCard.API.currentSite.getPrintBeatSerialNumbersByFamily(LP_DEVICE_FAMILY.INDIGO),
        }
      };

      return rnHttp.get('PrintbeatService', '/gsb/weekly/overall', config).then(resp => {
        let scores = resp.data.unitEvents;
        vm.chartConfig.xAxis.categories = [];
        _.forEach(scores[0].events, event => {
          vm.chartConfig.xAxis.categories.push(moment(event.happenedOn, 'YYY-MM-DD').format('D MMM'));
        });

        _.forEach(scores, score => {
          let myData;
          switch(score.unitName) {
            case 'Technical issues':
              vm.techData.splice(0, vm.techData.length);
              myData = vm.techData;
              break;
            case 'Supplies lifespan':
              vm.suppliesData.splice(0, vm.techData.length);
              myData = vm.suppliesData;
              break;
            case 'Restarts':
              vm.restartData.splice(0, vm.techData.length);
              myData = vm.restartData;
              break;
            case 'Availability':
              vm.availabilityData.splice(0, vm.techData.length);
              myData = vm.availabilityData;
              break;
            case 'Print volume':
              vm.printData.splice(0, vm.techData.length);
              myData = vm.printData;
              break;
          }
          if (myData) {
            _.forEach(score.events, event => {
              let data = {
                name: score.unitName,
                scoreWeight: 20,
                event: event,
                y: event.value,
              };
              myData.push(data);
            });
          }
        });
      });
    }

    function siteChanged(site) {
      getScores();
    }
  }
}
