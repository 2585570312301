export const name = 'DeleteUserDialogController';
export default function DeleteUserDialogController(
  // Controller angular dependencies
  rnHttp,
  $state,
  $translate,
  $mdToast,
  $mdDialog,
  $window,
  $timeout,
  $gzAnalytics,
) {
  'ngInject';
  // Constants

  // Variables

  // View Model
  let vm = this;
  //   VM properties
  vm.loading = true;
  vm.email = null;
  vm.validEmail = false;
  vm.confirm1 = false;
  vm.confirm2 = false;
  vm.success = false;

  //   VM methods
  vm.cancel = cancel;
  vm.submit = submit;
  vm.close = close;
  vm.disableSubmit = disableSubmit;
  vm.toggleConfirm1 = toggleConfirm1;
  vm.toggleConfirm2 = toggleConfirm2;

  vm.activate = activate;
  activate();

  ///////////////
  // Init
  function activate() {
    vm.loading = false;
  }

  ///////////////
  // VM methods

  function cancel() {
    $mdDialog.cancel();
  }

  function toggleConfirm1(e) {
    vm.confirm1 = !vm.confirm1;
    e.preventDefault();
    e.stopPropagation();
  }

  function toggleConfirm2(e) {
    vm.confirm2 = !vm.confirm2;
    e.preventDefault();
    e.stopPropagation();
  }

  function disableSubmit() {
    if (vm.processing) {
      return true;
    }

    if (!vm.validEmail) {
      return true;
    }

    if (vm.email !== $window.$printos.v1.user.getUser().email) {
      return true;
    }

    return !vm.confirm1 || !vm.confirm2;
  }

  function submit() {
    if (vm.processing || disableSubmit()) {
      return;
    }
    $gzAnalytics.event('Request Delete PrintOS User - submit', {category: 'My Settings'});

    const formData = new FormData();
    const myJSON = JSON.stringify({
      caseSource: 'WEB',
      subject: 'Remove PrintOS User Account Request',
      type: 'ASK_A_QUESTION',
      preferredContactMethod: {
        contactMethodType: 'PROFILE_EMAIL'
      },
      userEntries: [
        // {
        //   userEntryType: 'EMAIL',
        //   value: vm.email,
        // },
        {
          userEntryType: "APPLICATION",
          value: 'Home'
        },
        {
          userEntryType: "APPLICATION_ID",
          value: 'home',
        },
        // {
        //   userEntryType: 'FIRST_NAME',
        //   value: $window.$printos.v1.user.getFirstName(),
        // },
        // {
        //   userEntryType: 'LAST_NAME',
        //   value: $window.$printos.v1.user.getLastName(),
        // },
        {
          userEntryType: 'COUNTRY',
          value: 'US',
        },
        // {
        //   userEntryType: 'ACCOUNT_NAME',
        //   value: $window.$printos.v1.org.getOrg().name,
        // },
        {
          userEntryType: 'DESCRIPTION',
          value: `User (${$window.$printos.v1.user.getUserId()} is requesting to completely remove their account from PrintOS`,
        },
      ],
    });
    formData.append('case', new Blob([myJSON], {type: 'application/json'}));
    $window.$printos.v1.ui.startProgress();
    return $window.$printos.v1.http.post('supportapp_service', 'cases', formData).then(() => {
      $timeout(() => {
        vm.success = true;
        $window.$printos.v1.ui.toast({
          title: $translate.instant('home.home.Privacy_Sent'),
        });
      });
      //this.config.api.resolve();
    })
      .catch(e => {
        console.log('Support Submit: Error', e);
        $timeout(() => {
          vm.errorKey = 'home.home.Privacy_Error';
        });
      })
      .finally(() => {
        $timeout(() => {
          $window.$printos.v1.ui.endProgress();
          this.processing = false;
        });
      });

  }


  function close() {
    $mdDialog.hide();
  }

  ///////////////
  // Helpers
}
