export const name = 'hmSiteFlow';
export default function hmSiteFlow() {
  //noinspection BadExpressionStatementJS
  'ngInject';

  let directive = {
    restrict: 'EA',
    scope: {
    },
    template: require('./site-flow.jade'),
    controller: siteFlowController,
    controllerAs: 'vm',
    bindToController: true
  };

  return directive;

  /** @ngInject */
  function siteFlowController(hmSiteFlow, $translate, $gzGo) {
    let vm = this;
    vm.refreshing = false;
    vm.hasData = false;
    vm.startTime = null;
    vm.endTime = null;
    vm.production = '?';
    vm.error = '?';
    vm.shipped = '?';
    vm.statusKey = null;

    //methods
    vm.navigateToInProduction = navigateToInProduction;
    vm.navigateToErrors = navigateToErrors;
    vm.navigateToShipped = navigateToShipped;

    vm.$onInit = onInit;
    return;

    ////////////////////

    function onInit() {
      refresh();
    }

    function refresh() {
      vm.refreshing = true;
      hmSiteFlow.getOrderCounts()
        .then(counts => {
          vm.production = counts.production;
          vm.shipped = counts.shipped;
          vm.error = counts.error;
          vm.hasData = true;
        })
        .catch(err => {
          vm.hasData = false;
        })
        .finally(() => vm.refreshing = false);
    }

    function navigateToInProduction($event){
      $event.stopPropagation();
      $gzGo.toContext('siteFlow-inProduction');
    }

    function navigateToErrors($event){
      $event.stopPropagation();
      $gzGo.toContext('siteFlow-errors');
    }

    function navigateToShipped($event){
      $event.stopPropagation();
      $gzGo.toContext('siteFlow-shipped');
    }
  }
}
