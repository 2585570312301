import * as options from './support-cases.grid-options';

export const name = 'hmSupportCases';
export default function hmSupportCases(
  // Directive angular dependencies
) {
  'ngInject';

  let directive = {
    restrict: 'EA',
    scope: {
      gzCard: '=',
    },
    template: require('./support-cases.jade'),
    link: supportCasesLink,
    controller: supportCasesController,
    controllerAs: 'vm',
    bindToController: true,
  };
  return directive;

  //////////////////////

  function supportCasesLink(scope, element, attrs) {
  }

  /** @ngInject */
  function supportCasesController(
    // Controller angular dependencies
    rnHttp,
    $q,
    rnClient,
    $http,
    $translate
  ) {

    // Variables
    let vm = this;
    vm.loading = true;
    vm.openCases = '--';
    vm.closedCases = '--';
    vm.cases = [];
    vm.gridOptions = options.gridOptions;
    vm.gridCallbacks = {
      grid: {
        translate: $translate.instant,
      },
      data: {
        getData: getData,
      },
      columns: {
        'status': {
          renderCell: statusRender,
        }
      },
      actions: [],
    };

    vm.$onInit = onInit;
    return;

    function onInit() {
      let promises = [];
      promises.push(getSupportCases());
      $q.all(promises).then(() => {
      })
        .finally(() => {
          setLink();
          vm.loading = false;
        });
    }

    function setLink() {
      //create new link
      const bu = _.get(vm.gzCard.settings, 'businessUnit');
      vm.gzCard.API.actions.setAppLink(vm.gzCard, "/pwi-sc/#/cases?bu=" + bu);
    }

    function getSupportCases(){
      let devices = vm.gzCard.API.account.getDevicesByFamily(_.get(vm.gzCard.settings, 'deviceFamily'));
      const bu = _.get(vm.gzCard.settings, 'businessUnit');

      let body = devices.map(device => {
        return {
          deviceId: device.deviceId,
          serialNumber: device.serialNumber,
          productNumber: device.model,
          bu: bu
        };
      });

      return rnHttp.post('ppc','/api/enablement-service/v1/cases/supportCases/summary', body).then(response => {
        if (response && response.data) {
          vm.openCases = response.data.count.open;
          vm.closedCases = response.data.count.closed;
          if (response.data.open) {
            vm.cases = response.data.open.concat(response.data.closed);
          } else {
            vm.cases = response.data.closed;
          }

          vm.cases.forEach(caseData => {
            let found = devices.find(device => {
              return device.deviceId === caseData.deviceId;
            });
            caseData.deviceName = found ? found.name : '';
          });

          vm.cases = _.sortBy(vm.cases, 'createdTime');
          vm.cases.reverse();
        }
        return vm.cases;
      }).catch(() => {
        $q.resolve([]);
      });
    }

    function getData() {
      return $q.resolve(vm.cases);
    }

    function statusRender(value){
      let status = '';
      if(value){
        value = value.toLowerCase();
        switch(value){
          case "open":
            status = '<i class="fa fa-circle gz-status-error gz-image-spacer"></i>' + value;
            break;
          default:
            status = '<i class="fa fa-circle gz-status-ok gz-image-spacer"></i>' + value;
        }
      }
      return status;
    }
  }
}
