import './latex-efficiency.scss';

import rnPreferences from 'rhino/src/components/preferences/preferences.module';
import rhinoCore from 'rhino/src/core/core.module';
import angularTranslate from 'angular-translate';
import angularMoment from 'angular-moment';
import homePrintBeat from 'src/components/landing-page/cards/shared/print-beat/print-beat.module';

import * as hmLatexEfficiencyDirective from './latex-efficiency.directive';

export default angular.module('home.components.latexEfficiency', [
  rhinoCore,
  angularMoment,
  rnPreferences,
  angularTranslate,
  homePrintBeat,
])
  .directive(hmLatexEfficiencyDirective.name, hmLatexEfficiencyDirective.default)
  .name;
