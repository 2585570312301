import {
  LP_DEVICE_FAMILY,
  LP_DEVICE_TYPE,
  ACCOUNT_TYPE,
  ENTITY_TYPE,
  LP_UNASSIGNED_SITE,
} from '../cards/card-definitions';

import {
  BUSINESS_UNIT,
} from 'rhino/src/components/start/start.service';

export const name = 'hmLp2';
export default function hmLp2(
  // Directive angular dependencies
) {
  'ngInject';

  let directive = {
    restrict: 'EA',
    scope: {
    },
    template: require('./lp2.jade'),
    link: lp2Link,
    controller: lp2Controller,
    controllerAs: 'vm',
    bindToController: true,
  };
  return directive;

  //////////////////////

  function lp2Link(scope, element, attrs) {
  }

  /** @ngInject */
  function lp2Controller(
    // Controller angular dependencies
    rnClient,
    rnHttp,
    rnDevice,
    rnPusher,
    rnOrganization,
    rnPreferences,
    moment,
    systemStatisticsService,
    $gzPerf,
    $hmCards,
    $state,
    $rootScope,
    $scope,
    $mdDialog,
    $timeout,
    $interval,
    $translate,
    $gzAnalytics,
    $gzWalkMe,
    $window,
    $mdToast,
    $q
  ) {
    // Constants
    const PB_DEVICE_FAMILIES = [
      LP_DEVICE_FAMILY.INDIGO,
      LP_DEVICE_FAMILY.LF,
      LP_DEVICE_FAMILY.SCITEX,
      LP_DEVICE_FAMILY.PAGEWIDE,
    ];

    const SERVICE_ERRORS = {
      PB_DEVICE_GET: 'home.home.SERVICE_ERROR_PB_DeviceGet',
      PB_EVENTS_CHANNEL: 'home.home.SERVICE_ERROR_PB_EventsChannel',
      PB_EVENTS: 'home.home.SERVICE_ERROR_PB_Events',
      DEVICE_STATS: 'home.home.SERVICE_ERROR_DeviceStats',
      PB_DATA: 'home.home.SERVICE_ERROR_PB_Data',
    };

    const DRAG_CLASS = {
      INSERT_ABOVE: 'lp-drag-insert-above',
      INSERT_BELOW: 'lp-drag-insert-below',
    };

    const PRINT_BEAT_PAGE_SIZE = 100;

    // Variables
    let CARD_API = {
      config: {
        // setAppLink: function (link) {setAppLink(card, link);},
        // setPages: function (pages, callback, pageDisplay) {setPages(card, pages, callback, pageDisplay);},
        // setSiteCallback: function (fn) {setSiteCallback(card, fn);},
        // setPBCallback: function (fn) {setPBCallback(card, fn);},
      },
      general: {
        getClientConfig: apiGetClientConfig,
        isCurrentFamily: isCurrentFamily
      },
      account: {
        getDevicesByType: getAllDevicesByType,
        getDevicesByFamily: getAllDevicesByFamily,
        getPrintBeatSerialNumbersByType: getPrintBeatSerialNumbersByType,
        getPrintBeatSerialNumbersByFamily: getPrintBeatSerialNumbersByFamily,
        getDevicesByOrg: getDevicesByOrg,
        getSites: apiGetSites,
        hasDeviceFamily,
        hasDeviceType,
        hasRealTimeDevicesByType: hasRealTimeDevicesByType,
        hasRealTimeDevicesByFamily: hasRealTimeDevicesByFamily,
        hasApplication: hasApplication,
        getApplication: getApplication,
        hasFeature: hasFeature,
        hasBU: hasBU,
        hasOrg: hasOrg,
        isAccountType: isAccountType,
        isPrintBeatRankingEnabled: isPrintBeatRankingEnabled,
        isAdmin: isAccountAdmin,
        getLastDeviceUpdateTime: getLastDeviceUpdateTime,
        getSharedDevices: getSharedDevices,
        getCanBuy: getCanBuy,
        getCanRun: getCanRun,
      },
      currentSite: {
        getDevicesByType: getCurrentDevicesByType,
        getDevicesByFamily: getCurrentDevicesByFamily,
        getPrintBeatSerialNumbersByType: getCurrentPrintBeatSerialNumbersByType,
        getPrintBeatSerialNumbersByFamily: getCurrentPrintBeatSerialNumbersByFamily,
        hasDeviceFamily: currentHasDeviceFamily,
        hasDeviceType: currentHasDeviceType,
        hasRealTimeDevicesByType: currentHasRealTimeDevicesByType,
        hasRealTimeDevicesByFamily: currentHasRealTimeDevicesByFamily,
        getSite: getCurrentSite,
        getPBActualsByFamily: getCurrentPBActualsByFamily,
        getPBTargetsByFamily: getCurrentPBTargetsByFamily,
      },
      helpers: {
        getDeviceByPrintBeatSerialNumber: getDeviceByPrintBeatSerialNumber,
      },
      actions: {
        changeDashboard: changeDashboard,
        setAppLink: setAppLink,
        setPages: setPages,
        setSiteCallback: setSiteCallback,
        setPBCallback: setPBCallback,
      },
      // getOrg: getOrg,
      // getRTSerialNumbers: getRTSerialNumbers,
      // getSerialNumbers: getSerialNumbers,
      // getRealSerialNumber: getRealSerialNumber,
    };
    let cc = {};
    let preferences;
    let sites = [];
    let devices = [];
    let pbDevices = [];
    let accountType;
    let entityType;
    let businessUnits;
    let printBeatSettings;
    let deviceIdsToUpdate = [];
    let needToUpdatePrintBeatData = false;
    let pbActuals = {};
    let pbTargets = {};
    let organization;
    let setupDashboardStyleDebounce = _.debounce(setupDashboardStyle, 200, {maxWait: 1000, trailing: true});
    let dragSource;
    let pbReadAccess;
    let entitlements;
    let hasPrintBeatEvents = false;
    let contexts;
    let needsPersona = false;
    let isOrphan = false;
    let isExternalUser = false;


    let forceDeviceStatisticsPromise;
    let forcePrintBeatDataPromise;
    let printBeatDataPromise;
    let deviceStatisticsPromise;
    let retryGetPrintBeatInfoPromise;
    let lastDeviceUpdateTime;
    let dragEventListener;

    // View Model
    let vm = this;
    vm.loading = true;
    vm.loadingDashboard = true;
    vm.siteId = undefined;
    vm.family = LP_DEVICE_FAMILY.NONE;
    vm.dashboardId = undefined;
    vm.showConfig = false;
    vm.showFamilyHelp = false;
    vm.showIntro = false;
    vm.serviceErrorKeys = [];

    vm.validator = validator;

    vm.nextPage = nextPage;
    vm.prevPage = prevPage;
    vm.setPage = setPage;

    vm.expandConfig = expandConfig;
    vm.collapseConfig = collapseConfig;
    vm.addSection = addSection;
    vm.editSection = editSection;
    vm.removeSection = removeSection;
    vm.addCard = addCard;
    vm.removeCard = removeCard;
    vm.restoreDefaultDashboard = restoreDefaultDashboard;
    vm.featureCardChanged = featureCardChanged;
    vm.sectionOrCardClicked = sectionOrCardClicked;
    vm.dragOverSection = dragOverSection;
    vm.dragLeaveSection = dragLeaveSection;
    vm.validateDropOnSection = validateDropOnSection;
    vm.dropOnSection = dropOnSection;
    vm.dragOverCard = dragOverCard;
    vm.dragLeaveCard = dragLeaveCard;
    vm.validateDropOnCard = validateDropOnCard;
    vm.dropOnCard = dropOnCard;
    vm.startTutorial = startTutorial;
    vm.ignoreTutorial = ignoreTutorial;
    vm.showTutorial = showTutorial;
    vm.getLinkTarget = getLinkTarget;
    vm.getSectionLink = getSectionLink;

    vm.unitTest = {
      setDragSource,
      getDevices
    };

    vm.$onInit = onInit;
    return;

    function onInit() {
      $gzPerf.viewLoadingStart();
      cc = $printos.v1.client.getConfig();
      preferences = $printos.v1.preferences.getPreferences();
      setupVariables();
      if (needsPersona) {
        $state.go('welcome-org');
      }

      if (isOrphan || isExternalUser) {
        return initOrphan().finally(() => {
          $timeout(() => {
            vm.loading = false
          });
        });
      }

      // if (isExternalUser) {
      //   return initExternalUser();
      // }
      //
      return initNormal().finally(() => {
        $timeout(() => {
          vm.loading = false;
        });
      });
    }

    ///////////////////////////////////////////////////////////
    // Init
    function initOrphan() {
      let phase1 = [];
      phase1.push(getContexts());
      phase1.push(getEntitlements());
      phase1.push(getDashboards());
      return Promise.all(phase1).then(() => {
        if (accountType === 'Unknown' && contexts.length > 0) {
          $window.$printos.v1.org.setContextAsync(contexts[0].id).then(() => {
            return initNormal().finally(() => {
              vm.loading = false;
            });
          });
        }
        setupCardAPI();
        setupWelcomeCard();

        $window.addEventListener('resize', setupDashboardStyleDebounce);
        dragEventListener = $rootScope.$on('ANGULAR_DRAG_START', setDragSource);
      })
        .finally(() => {
          // Clean up any $timeout, $interval, listeners
          $scope.$on('$destroy', () => {
          });
        });
    }

    function initNormal() {
      let phase1 = [];
      phase1.push(getDashboards());
      phase1.push(getAllDevices());
      phase1.push(getAllSites());
      phase1.push(getOrg());
      phase1.push(getEntitlements());
      phase1.push(getPrintBeatInfo());
      return Promise.all(phase1).then(() => {
        setupCardAPI();
        setupWelcomeCard();

        $window.addEventListener('resize', setupDashboardStyleDebounce);
        dragEventListener = $rootScope.$on('ANGULAR_DRAG_START', setDragSource);
      })
        .finally(() => {
          // Clean up any $timeout, $interval, listeners
          $scope.$on('$destroy', () => {
            if (forceDeviceStatisticsPromise) { $timeout.cancel(forceDeviceStatisticsPromise);}
            if (forcePrintBeatDataPromise) { $timeout.cancel(forceDeviceStatisticsPromise);}
            if (retryGetPrintBeatInfoPromise) { $timeout.cancel(retryGetPrintBeatInfoPromise);}
            if (deviceStatisticsPromise) { $interval.cancel(deviceStatisticsPromise);}
            if (printBeatDataPromise) { $interval.cancel(printBeatDataPromise);}
            dragEventListener();
          });
        });
    }

    function setupVariables() {
      let firstName = $printos.v1.user.getFirstName();
      vm.userDisplay = $printos.v1.client.sanitize( firstName ? firstName : $printos.v1.user.getFullName() );
      accountType = $printos.v1.org.getContextType();
      entityType = $printos.v1.user.getUserType();
      businessUnits = $printos.v1.org.getBusinessUnits();
      let hasPersona =  $printos.v1.user.hasPersona();
      let managingContext = $printos.v1.client.getManagingContext();
      let isGeneric = $printos.v1.org.isGeneric();
      isOrphan = $printos.v1.user.isOrphan();
      needsPersona = !isOrphan && !managingContext && !isGeneric && !hasPersona;
      let roles = $printos.v1.user.getRoles();
      let nonExternalRoles = roles.filter(r => {
        return !r.externalAccess;
      });

      isExternalUser = !isOrphan && !managingContext && nonExternalRoles.length === 0;
      if (isExternalUser) {
        accountType = ACCOUNT_TYPE.EXTERNAL;
      }
    }

    function validator(id) {
      return $printos.v1.client.getValidator(id);
    }

    function getContexts() {
      return rnHttp.get('aaa', '/users/context').then(resp => {
        contexts = _.get(resp, 'data.contexts', []);
      })
        .catch(() => {
          contexts = [];
          return Promise.resolve();
        });
    }

    function getAllDevices() {
      if (accountType === ACCOUNT_TYPE.HP || entityType === ENTITY_TYPE.DEVICE) {
        devices = [];
        return Promise.resolve();
      }

      devices = [];
      return getDevices().then(resp => {
        let promises = [];
        let pages = Math.ceil(resp.total / resp.limit);
        for (let p = 2; p <= pages; p++) {
          promises.push(getDevices(p));
        }

        return Promise.all(promises).then(() => {
          let startListeners = [];
          startListeners.push(forceDeviceStatisticsUpdate());
          startListeners.push(initDeviceEvents());
          return $q.all(startListeners).then(() => {
            // Check to see if we have any devices to update every minute
            deviceStatisticsPromise = $interval(checkForDeviceStatisticsUpdate, 60 * 1000);
            // Force devices to update after 5 minutes (reset every time we get an event)
            forceDeviceStatisticsPromise = $timeout(forceDeviceStatisticsUpdate, 5 * 60 * 1000);
          });
        });
      })
        .catch(error => {
          console.error('LP1 LOAD FAILURE - Devices', error);
          vm.errorKey = 'home.home.LP2_LoadFailure';
          devices = [];
          return Promise.resolve();
        });
    }

    function getDevices(page = 1) {
      let offset = (page - 1) * 1000;
      let limit = 1000;

      return rnDevice.getAll(offset, limit).then(resp => {
        devices = devices.concat(_.get(resp, 'devices', []));
        return resp;
      });
    }

    function getAllSites() {
      sites = $window.$printos.v1.org.getSites();
      return Promise.resolve();
    }

    function getOrg() {
      return rnOrganization.getOrganization().then(org => {
        organization = org.data;
      })
        .catch(error => {
          console.error('LP1 LOAD FAILURE - Org');
          vm.errorKey = 'home.home.LP2_LoadFailure';
          return $q.resolve();
        });
    }

    function getPreferences() {
      return rnPreferences.getAll().then(resp => {
        preferences = resp;
      })
        .catch(error => {
          console.error('LP1 LOAD FAILURE - Preferences');
          vm.errorKey = 'home.home.LP2_LoadFailure';
          return $q.resolve();
        });
    }

    function getDashboards() {
      return $hmCards.initDashboards()
        .catch(error => {
          console.error('LP1 LOAD FAILURE - Dashboards');
          vm.errorKey = 'home.home.LP2_LoadFailure';
          return $q.resolve();
        });
    }

    function getEntitlements() {
      if (isOrphan) {
        return Promise.resolve({
          applications: [],
          features: _.get(cc, 'features', [])
        });
      }

      return rnHttp.get('enforcer', 'organizations/entitlements').then(resp => {_.get(resp, 'data.features', []);
        entitlements = _.get(resp, 'data',{});
      })
        .catch(error => {
          console.error('LP1 LOAD FAILURE - Entitlements');
          vm.errorKey = 'home.home.LP2_LoadFailure';
          return $q.resolve();
        });
    }

    function getPrintBeatInfo() {
      // If we are HP or a Device we don't need to get PB devices
      if (accountType === ACCOUNT_TYPE.HP || entityType === ENTITY_TYPE.DEVICE) {
        return Promise.resolve();
      }

      return getPrintBeatSettings().then(() => {
        // If we don't have read access we don't need to get PB devices
        if (!pbReadAccess) {
          return Promise.resolve();
        }

        return getPBDevices().then(() => {
          // Merge PrintBeat data in with devices
          pbDevices.forEach(pbDevice => {
            let device = _.find(devices, {deviceId: pbDevice.deviceID});
            if (device) {
              _.set(device, 'internal.pbDevice', pbDevice);
            }
          });

          let promises = [];
          if (vm.siteId) {
            promises.push(getPrintBeatData(vm.siteId));
          }
          promises.push(initPrintBeatEvents());
          return $q.all(promises).then(() => {
            //Fire event to let cards that we now have PB Data
            $rootScope.$emit('$lpPBDeviceInfoUpdated');

            // Check to see if we have any print beat devices to update every 60 seconds
            printBeatDataPromise = $interval(checkForPrintBeatDataUpdate, 60 * 1000);
            // Force print beat update after 5 minutes (reset every time we get an event)
            forcePrintBeatDataPromise = $timeout(forcePrintBeatDataUpdate, 5 * 60 * 1000, 0, true, false);
          });
        }).catch(error => {
          // Retry in 60 seconds with the expectation that the cards will listen to the PB Devices event
          retryGetPrintBeatInfoPromise = $timeout(getPrintBeatInfo, 60 * 1000);
          return Promise.resolve();
        });

      })
        .catch(error => {
          // Retry in 60 seconds with the expectation that the cards will listen to the PB Devices event
          retryGetPrintBeatInfoPromise = $timeout(getPrintBeatInfo, 60 * 1000);
          return Promise.resolve();
        });
    }

    function getPrintBeatSettings() {
      if (printBeatSettings) {
        return Promise.resolve();
      }

      return rnHttp.get('PrintbeatService', 'settings/organization', {}).then(resp => {
        printBeatSettings = resp.data;
        pbReadAccess = true;
      })
        .catch(error => {
          let statusCode = _.get(error, 'data.smsError.statusCode');
          if (statusCode && statusCode === 401) {
            printBeatSettings = null;
            pbReadAccess = false;
          }
          else {
            console.error('LP2 LOAD FAILURE - PB Settings');
          }

          return Promise.resolve();
        });
    }

    function getPBDevices() {
      let config = {
        params: { bu: '*'},
      };

      clearServiceError(SERVICE_ERRORS.PB_DEVICE_GET);
      return rnHttp.get('PrintbeatService', 'Config/devices', config).then(resp => {
        pbDevices = _.get(resp, 'data', []);
      })
        .catch(error => {
          console.error('LP2 LOAD FAILURE - PB Devices');
          setServiceError(SERVICE_ERRORS.PB_DEVICE_GET);
          return Promise.resolve();
        });
    }

    function initDeviceEvents() {
      rnDevice.setupPusher(handleDeviceEvent, $scope);
    }

    function initPrintBeatEvents() {
      if (!pbReadAccess || hasPrintBeatEvents) {
        return $q.resolve();
      }

      clearServiceError(SERVICE_ERRORS.PB_EVENTS_CHANNEL);
      clearServiceError(SERVICE_ERRORS.PB_EVENTS);
      return rnHttp.get('PrintbeatService', 'RealtimeTracking/channel').then(response => {
        let channelName = response.data.channelName;
        if(!channelName) {
          setServiceError(SERVICE_ERRORS.PB_EVENTS_CHANNEL);
          return $q.resolve();
        }

        return rnPusher.bind(channelName, 'press_data', handlePrintBeatEvent, $scope).then(() => {
          hasPrintBeatEvents = true;
        })
          .catch(error => {
            setServiceError(SERVICE_ERRORS.PB_EVENTS);
            return $q.resolve();
          });
      })
        .catch(error => {
          setServiceError(SERVICE_ERRORS.PB_EVENTS_CHANNEL);
          return $q.resolve();
        });
    }

    function setupCardAPI() {
      $hmCards.setAPI(CARD_API);
    }

    function getWidthDivisibleBySix(num) {
      while (num % 6 !== 0) {
        num--;
      }
      return num;
    }

    function setupDashboardStyle() {
      $rootScope.$applyAsync(() => {
        let myContainer = $window.document.getElementById('size-container');
        if (!myContainer) {
          return;
        }

        const LAYOUT = {
          THREE_CARD: 3,
          TWO_CARD: 2,
          ONE_CARD: 1,
        };

        const MIN_CARD_WIDTH = 402;
        const MAX_CARD_WIDTH = 444;
        const SPACE_BETWEEN = 12;
        const SECTION_ALLOWANCE = 4; // lBorder (2px) + rBorder (2px)
        const GROUP_ALLOWANCE = 6; //lBorder (1px) + rBorder (1px) + lPad (2px) + rPad (2px)
        const SCALING_ALLOWANCE = 2; // 90% can result in outer width of something like 1353.82 so we'll subtract out a little
        const STANDARD_HEIGHT = 300;
        const SELECTOR_HEIGHT = 300;

        const MIN_3CARD_LAYOUT=MIN_CARD_WIDTH * 3 + SPACE_BETWEEN * 4 + SECTION_ALLOWANCE;
        const MAX_3CARD_LAYOUT=MAX_CARD_WIDTH * 3 + SPACE_BETWEEN * 4 + SECTION_ALLOWANCE;
        const MIN_2CARD_LAYOUT=MIN_CARD_WIDTH * 2 + SPACE_BETWEEN * 3 + SECTION_ALLOWANCE;

        let xAvailableWidth = myContainer.clientWidth;
        let xDashboardWidth = xAvailableWidth;
        let xAvailableCardSpace;
        let xAvailableGroupCardSpace;

        let xLayout = LAYOUT.ONE_CARD;
        if (xAvailableWidth >= MIN_3CARD_LAYOUT) {
          xLayout = LAYOUT.THREE_CARD;
        }
        else if (xAvailableWidth >= MIN_2CARD_LAYOUT) {
          xLayout = LAYOUT.TWO_CARD;
        }

        let xCardWidth33;
        let xCardWidth50;
        let xCardWidth66;
        let xCardWidth100;
        let xGroupedCardWidth33;
        let xGroupedCardWidth50;
        let xGroupedCardWidth66;
        let xGroupedCardWidth100;
        switch (xLayout) {
          case LAYOUT.THREE_CARD:
            xDashboardWidth = (xAvailableWidth < MAX_3CARD_LAYOUT) ? xAvailableWidth : MAX_3CARD_LAYOUT;
            xDashboardWidth = getWidthDivisibleBySix(xDashboardWidth);
            xAvailableCardSpace = xDashboardWidth - ( SPACE_BETWEEN * 2 ); // width - outer left gap - outer right gap
            xAvailableGroupCardSpace = xAvailableCardSpace - GROUP_ALLOWANCE - SPACE_BETWEEN - SCALING_ALLOWANCE; // available - 6px to keep it divisible by 6 (1px borders, 2px padding)
            xCardWidth33 = ( xAvailableCardSpace - ( SPACE_BETWEEN * 2 ) ) / 3; // available - 2 gaps
            xCardWidth50 = ( xAvailableCardSpace - SPACE_BETWEEN ) / 2; // available - 1 gap
            xCardWidth66 = ( xCardWidth33 * 2 ) + SPACE_BETWEEN;
            xCardWidth100 = xAvailableCardSpace;
            xGroupedCardWidth33 = ( xAvailableGroupCardSpace - ( SPACE_BETWEEN * 2 ) ) / 3;
            xGroupedCardWidth50 = ( xAvailableGroupCardSpace - SPACE_BETWEEN ) / 2;
            xGroupedCardWidth66 = ( xGroupedCardWidth33 * 2 ) + SPACE_BETWEEN;
            xGroupedCardWidth100 = xAvailableGroupCardSpace;
            break;
          case LAYOUT.TWO_CARD:
            xDashboardWidth = getWidthDivisibleBySix(xAvailableWidth);
            xAvailableCardSpace = xDashboardWidth - ( SPACE_BETWEEN * 3 );
            xAvailableGroupCardSpace = xAvailableCardSpace - GROUP_ALLOWANCE - SPACE_BETWEEN - SCALING_ALLOWANCE;
            xCardWidth33 = xCardWidth50 = xCardWidth66 = ( xAvailableCardSpace - SPACE_BETWEEN ) / 2;
            xCardWidth100 = xAvailableCardSpace;
            xGroupedCardWidth33 = xGroupedCardWidth50 = xGroupedCardWidth66 = ( xAvailableGroupCardSpace - SPACE_BETWEEN ) / 2;
            xGroupedCardWidth100 = xAvailableGroupCardSpace;
            break;
          default:
            xDashboardWidth = getWidthDivisibleBySix(xAvailableWidth);
            xAvailableCardSpace = xDashboardWidth - ( SPACE_BETWEEN * 2 );
            xAvailableGroupCardSpace = xAvailableCardSpace - GROUP_ALLOWANCE - SPACE_BETWEEN - SCALING_ALLOWANCE;
            xCardWidth33 = xCardWidth50 = xCardWidth66 = xCardWidth100 = xAvailableCardSpace;
            xGroupedCardWidth33 = xGroupedCardWidth50 = xGroupedCardWidth66 = xGroupedCardWidth100 = xAvailableGroupCardSpace;
        }

        // Dashboard Width
        vm.dashboardWidth = xDashboardWidth;

        // Welcome Card
        vm.welcomeCard.height = SELECTOR_HEIGHT;
        vm.welcomeCard.width = xCardWidth50;

        // Highlight Card
        let xHSectionCards = _.get(vm.dashboard, 'highlightSection.cards', []);
        if (xHSectionCards.length > 0) {
          xHSectionCards[0].height = SELECTOR_HEIGHT;
          xHSectionCards[0].width = xCardWidth50;
        }

        // Sections
        vm.dashboard.sections.forEach(section => {
          if (section.isGroup) {
            section.width = xCardWidth100;
          }

          for (let i = 0; i < section.cards.length; i++) {
            let card = section.cards[i];
            let cardsPerRow = section.layout.length < xLayout ? section.layout.length : xLayout;
            let position = i % cardsPerRow;

            if (card.fullWidth) {
              card.height = 'auto';
              card.width = xCardWidth100;
            }
            else {
              card.height = STANDARD_HEIGHT;
              switch (section.layout[position]) {
                case 33:
                  card.width = section.isGroup ? xGroupedCardWidth33 : xCardWidth33;
                  break;
                case 50:
                  card.width = section.isGroup ? xGroupedCardWidth50 : xCardWidth50;
                  break;
                case 66:
                  card.width = section.isGroup ? xGroupedCardWidth66 : xCardWidth66;
                  break;
                default:
                  card.width = section.isGroup ? xGroupedCardWidth100 : xCardWidth100;
              }
            }
          }
        });
      });
    }

    function setupWelcomeCard() {
      vm.welcomeCard = $hmCards.getWelcomeCard();
      vm.welcomeCard.width = 600;
    }

    ///////////////////////////////////////////////////////////
    // Event Handlers
    function handleDeviceEvent(event) {
      if (event && event.deviceId) {
        pushDeviceToUpdate(event.deviceId);
      }

      // Reset force update timer
      if (forceDeviceStatisticsPromise) {
        $timeout.cancel(forceDeviceStatisticsPromise);
      }

      forceDeviceStatisticsPromise = $timeout(forceDeviceStatisticsUpdate, 5 * 60 * 1000);
    }

    function handlePrintBeatEvent(event) {
      let myEvent = event;
      needToUpdatePrintBeatData = true;
    }

    function forceDeviceStatisticsUpdate() {
      return updateDeviceStatistics(devices);
    }

    function forcePrintBeatDataUpdate() {
      return updatePrintBeatData();
    }

    ///////////////////////////////////////////////////////////
    // View Model
    function prevPage(card) {
      if (card.pageIndex === 0) {
        card.pageIndex = card.pages.length - 1;
      }
      else {
        card.pageIndex--;
      }
      updatePage(card);
    }

    function nextPage(card) {
      if (card.pageIndex + 1 < card.pages.length) {
        card.pageIndex++;
      }
      else {
        card.pageIndex = 0;
      }
      updatePage(card);
    }

    function setPage(card, index) {
      card.pageIndex = index;
      updatePage(card);
    }

    function updatePage(card) {
      if (card.pageCallback) {
        card.pageCallback(card.pages[card.pageIndex]);
      }
    }

    function expandConfig() {
      $gzAnalytics.eventClick(`Dashboard - Open Config`, {label: `family: ${vm.family}`});      vm.showConfig = true;
      setupConfig();
    }

    function collapseConfig() {
      $gzAnalytics.eventClick(`Dashboard - Close Config`, {label: `family: ${vm.family}`});      vm.showConfig = true;
      sectionOrCardClicked();
      vm.showConfig = false;
    }

    function setupConfig() {
      let highlightCards = _.get(vm.dashboard, 'highlightSection.cards');
      vm.featureCard = highlightCards.length > 0 ? highlightCards[0] : undefined;

      vm.allCards = $hmCards.getAllCards(vm.family);
      vm.featureCards = vm.allCards.filter(card => {
        return !card.fullWidth;
      });
      vm.featureCardOptions = [];
      vm.featureCards.forEach( card => {
        vm.featureCardOptions.push({display: $translate.instant(card.titleKey), value: card});
      });
    }

    function addSection() {
      $mdDialog.show({
        parent: angular.element(document.body),
        targetEvent: null,
        clickOutsideToClose: false,
        escapeToClose: true,
        template: require('./add-section/add-section-dialog.jade'),
        controller: 'AddSectionDialogController',
        bindToController: true,
        controllerAs: 'vm',
        locals: {
          section: undefined,
          validator: validator,
        }
      })
        .then(newSection => {
          newSection.indexId = $hmCards.getIndexId();
          vm.dashboard.sections.push(newSection);
          saveDashboard();
          $gzAnalytics.eventSave('Dashboard - Add Section', {label: `family: ${vm.family}, isGroup: ${newSection.isGroup}`});
        })
        .catch(() => {});
    }

    function editSection(section, $event) {
      $event.stopPropagation();
      $mdDialog.show({
        parent: angular.element(document.body),
        targetEvent: null,
        clickOutsideToClose: false,
        escapeToClose: true,
        template: require('./add-section/add-section-dialog.jade'),
        controller: 'AddSectionDialogController',
        bindToController: true,
        controllerAs: 'vm',
        locals: {
          section: section,
          validator: validator,
        }
      })
        .then(newSection => {
          if (newSection.name) {
            section.name = newSection.name;
          }
          else {
            delete section.name;
          }
          section.layout = newSection.layout;
          section.isGroup = newSection.isGroup;
          $window.dispatchEvent(new $window.Event('resize'));
          saveDashboard();
          $gzAnalytics.eventSave('Dashboard - Edit Section', {label: `family: ${vm.family}, name: ${section.name}, isGroup: ${newSection.isGroup}`});
        })
        .catch(() => {});
    }

    function removeSection(section, sectionIndex, $event) {
      $event.stopPropagation();
      $mdDialog.show({
        parent: angular.element(document.body),
        targetEvent: null,
        clickOutsideToClose: false,
        escapeToClose: true,
        template: require('gazelle/src/components/confirm/confirm-dialog.jade'),
        controller: 'gzConfirmDialogController',
        bindToController: true,
        controllerAs: 'vm',
        locals: {
          titleKey: 'home.home.LP_RemoveSection',
          labelKey: 'home.home.LP_RemoveSectionLabel',
          messageKey: 'home.home.LP_RemoveSectionMessage',
          value: section.name ? section.name : $translate.instant(section.nameKey),
        }
      })
        .then(canRemove => {
          if (canRemove) {
            vm.dashboard.sections.splice(sectionIndex, 1);
            saveDashboard();
            $gzAnalytics.eventSave('Dashboard - Remove Section', {label: `family: ${vm.family}, name: ${section.name}`});
          }
        })
        .catch(() => {});
    }

    function removeCard(section, card, cardIndex, $event) {
      $event.stopPropagation();
      $mdDialog.show({
        parent: angular.element(document.body),
        targetEvent: null,
        clickOutsideToClose: false,
        escapeToClose: true,
        template: require('gazelle/src/components/confirm/confirm-dialog.jade'),
        controller: 'gzConfirmDialogController',
        bindToController: true,
        controllerAs: 'vm',
        locals: {
          titleKey: 'home.home.LP_RemoveCard',
          messageKey: 'home.home.LP_RemoveCardMessage',
          labelKey: 'home.home.LP_RemoveCardLabel',
          value: $translate.instant(card.titleKey),
        }
      })
        .then(canRemove => {
          if (canRemove) {
            section.cards.splice(cardIndex, 1);
            saveDashboard();
            $gzAnalytics.eventSave('Dashboard - Remove Card', {label: `family: ${vm.family}, card: ${card.cardId}`});
          }
        })
        .catch(() => {});
    }

    function addCard(section, $event) {
      $event.stopPropagation();
      $mdDialog.show({
        parent: angular.element(document.body),
        targetEvent: null,
        clickOutsideToClose: false,
        escapeToClose: true,
        template: require('./add-card/add-card-dialog.jade'),
        controller: 'AddCardDialogController',
        bindToController: true,
        controllerAs: 'vm',
        locals: {
          allCards: vm.allCards,
          family: vm.dashboard.family,
        }
      })
        .then(newCard => {
          let card = $hmCards.getNewCard(newCard);
          card.indexId = $hmCards.getIndexId();
          section.cards.push(card);
          saveDashboard();
          $gzAnalytics.eventSave('Dashboard - Add Card', {label: `family: ${vm.family}, card: ${card.cardId}`});
        })
        .catch(() => {});
    }

    function restoreDefaultDashboard($event) {
      $mdDialog.show({
        parent: angular.element(document.body),
        targetEvent: null,
        clickOutsideToClose: false,
        escapeToClose: true,
        template: require('gazelle/src/components/confirm/confirm-dialog.jade'),
        controller: 'gzConfirmDialogController',
        bindToController: true,
        controllerAs: 'vm',
        locals: {
          titleKey: 'home.home.LP_RestoreDefault',
          labelKey: undefined,
          value: undefined,
          messageKey: 'home.home.LP_RestoreDefaultMessage',
        }
      })
        .then(canRestore => {
          $gzAnalytics.eventSave('Dashboard - Restore', {label: `family: ${vm.family}`});
          if (canRestore) {
            $hmCards.restoreDashboard(businessUnits, vm.dashboard).then(restoredDashboard => {
              vm.dashboard = restoredDashboard;
              setupConfig();
              setupDashboardStyleDebounce();
            })
              .catch(error => {
                // TODO: error toast?
              });
          }
        })
        .catch(() => {});
    }

    function sectionOrCardClicked(item) {
      let wasSelected = _.get(item, 'selected', false);
      delete vm.dashboard.highlightSection.selected;
      vm.dashboard.sections.forEach(section => {
        delete section.selected;
        section.cards.forEach(card => {
          delete card.selected;
        });
      });

      if (item && !wasSelected) {
        item.selected = true;
      }
    }

    function featureCardChanged() {
      let currentCard = vm.dashboard.highlightSection.cards[0];
      if (!currentCard || currentCard.cardId !== vm.featureCard.cardId) {
        vm.dashboard.highlightSection.cards = [vm.featureCard];
        saveDashboard();
      }
    }

    function setDragSource(scope, $event, channel, data) {
      let dragData = data.data;
      dragSource = {
        type: dragData.type,
        index: dragData.type === 'section' ? dragData.sectionIndex : dragData.cardIndex,
        sourceElement: $event.target,
      };
    }

    function validateDropOnSection(index, data) {
      if (dragSource.type === 'card') {
        return true;
      }

      return index !== data.sectionIndex;
    }

    function dragOverSection($event) {
      let targetElement = getTargetElement($event, dragSource.type);
      if (dragSource.type === 'card') {
        setDragTargetClassBelow(targetElement);
        return;
      }

      let rect = targetElement.getBoundingClientRect();
      let offsetY = $event.clientY - rect.top;

      if (offsetY > rect.height / 2) {
        setDragTargetClassBelow(targetElement);
      }
      else {
        setDragTargetClassAbove(targetElement);
      }
    }

    function dragLeaveSection($event) {
      clearDragTargetClass(getTargetElement($event, dragSource.type));
    }

    function dropOnSection($event, sectionIndex, data) {
      let moved = false;
      let movedItem;
      if (dragSource.type === 'section') {
        let targetIndex;
        if (dragSource.dropType === DRAG_CLASS.INSERT_ABOVE) {
          if (dragSource.index < sectionIndex) {
            targetIndex = sectionIndex - 1;
          }
          else {
            targetIndex = sectionIndex;
          }
        }
        else {
          if (dragSource.index > sectionIndex) {
            targetIndex = sectionIndex + 1;
          }
          else {
            targetIndex = sectionIndex;
          }
        }

        movedItem = moveArrayItem(vm.dashboard.sections, dragSource.index, targetIndex);
        if (movedItem) {
          moved = true;
        }
      }
      else {
        if (sectionIndex === data.sectionIndex) {
          // Move within same section
          let targetSection = vm.dashboard.sections[sectionIndex];
          movedItem = targetSection.cards.splice(data.cardIndex, 1)[0];
          targetSection.cards.splice(0, 0, movedItem);
          moved = true;
        }
        else {
          // Move between sections
          let sourceSection = vm.dashboard.sections[data.sectionIndex];
          let targetSection = vm.dashboard.sections[sectionIndex];
          movedItem = sourceSection.cards.splice(data.cardIndex, 1)[0];
          targetSection.cards.splice(0, 0, movedItem);
          moved = true;
        }

        clearDragTargetClass(getTargetElement($event, 'card'));
      }

      if (moved) {
        if (dragSource.type === 'section') {
          let section = movedItem.name ? movedItem.name : movedItem.nameKey;
          $gzAnalytics.eventSave('Dashboard - Move Section to section', {label: `family: ${vm.family}, src: ${section}`});
        }
        else {
          $gzAnalytics.eventSave('Dashboard - Move Card to section', {label: `family: ${vm.family}, cardId: ${movedItem.cardId}`});
        }
        saveDashboard();
      }

      clearDragTargetClass(getTargetElement($event, 'section'));
    }

    function validateDropOnCard(sectionIndex, index, data) {
      if (dragSource.type === 'section' && dragSource.index === sectionIndex) {
        // Don't accept drag on same section
        return false;
      }

      if (dragSource.type === 'card') {
        return index !== data.index;
      }

      return true;
    }

    function dragOverCard($event) {
      let targetElement = getTargetElement($event, dragSource.type);
      let rect = targetElement.getBoundingClientRect();
      let offsetY = $event.clientY - rect.top;

      if (offsetY > rect.height / 2) {
        setDragTargetClassBelow(targetElement);
      }
      else {
        setDragTargetClassAbove(targetElement);
      }
    }

    function dragLeaveCard($event) {
      if (dragSource.type === 'section') {
        return;
      }

      clearDragTargetClass(getTargetElement($event, dragSource.type));
    }

    function dropOnCard($event, sectionIndex, cardIndex, data) {
      if (data.type === 'section') {
        dropOnSection($event, sectionIndex, data);
        return;
      }

      let moved = false;
      let movedItem;
      let targetSection;
      let targetIndex;
      if (sectionIndex === data.sectionIndex) {
        // Move within same section
        targetSection = vm.dashboard.sections[sectionIndex];
        if (dragSource.dropType === DRAG_CLASS.INSERT_ABOVE) {
          if (dragSource.index < cardIndex) {
            targetIndex = cardIndex - 1;
          }
          else {
            targetIndex = cardIndex;
          }
        }
        else {
          if (dragSource.index > cardIndex) {
            targetIndex = cardIndex + 1;
          }
          else {
            targetIndex = cardIndex;
          }
        }

        movedItem = moveArrayItem(targetSection.cards, dragSource.index, targetIndex);
        if (movedItem) {
          moved = true;
        }
      }
      else {
        // Move between sections
        targetIndex = dragSource.dropType === DRAG_CLASS.INSERT_ABOVE ? cardIndex : cardIndex + 1;
        let sourceSection = vm.dashboard.sections[data.sectionIndex];
        targetSection = vm.dashboard.sections[sectionIndex];
        movedItem = sourceSection.cards.splice(dragSource.index, 1)[0];
        targetSection.cards.splice(targetIndex, 0, movedItem);
        moved = true;
      }

      if (moved) {
        let section = targetSection.name ? targetSection.name : targetSection.nameKey;
        $gzAnalytics.eventSave('Dashboard - Card Moved', {label: `family: ${vm.family}, destination: ${section}, card: ${targetSection.cards[targetIndex].cardId}`});
        saveDashboard();
      }

      clearDragTargetClass(getTargetElement($event, 'card'));
    }

    function getTargetElement($event, type) {
      let target = $event.target;
      let index = 0;
      while (index < 10 && target) {
        if (target.attributes['drag-type']) {
          let srcType = target.attributes['drag-type'].nodeValue;
          if (srcType === type) {
            break;
          }
        }
        index++;
        target = target.parentElement;
      }

      return target;
    }

    function setDragTargetClassAbove(target) {
      if (target.classList.contains(DRAG_CLASS.INSERT_ABOVE)) {
        return;
      }

      target.classList.remove(DRAG_CLASS.INSERT_BELOW);
      target.classList.add(DRAG_CLASS.INSERT_ABOVE);

      dragSource.dropType = DRAG_CLASS.INSERT_ABOVE;
    }

    function setDragTargetClassBelow(target) {
      if (target.classList.contains(DRAG_CLASS.INSERT_BELOW)) {
        return;
      }

      target.classList.remove(DRAG_CLASS.INSERT_ABOVE);
      target.classList.add(DRAG_CLASS.INSERT_BELOW);

      dragSource.dropType = DRAG_CLASS.INSERT_BELOW;
    }

    function clearDragTargetClass(target) {
      target.classList.remove(DRAG_CLASS.INSERT_ABOVE);
      target.classList.remove(DRAG_CLASS.INSERT_BELOW);

      delete dragSource.dropType;
    }

    function moveArrayItem(myArray, fromIndex, toIndex) {
      if (fromIndex === toIndex) {
        return false;
      }

      let item = myArray.splice(fromIndex, 1)[0];
      myArray.splice(toIndex, 0, item);

      return item;
    }

    function saveDashboard() {
      return $hmCards.saveDashboard(vm.dashboard).then(() => {
        // Nothing to do right now
      })
        .catch(error => {
          // TODO: Error toast?
        })
        .finally(() => {
          setupDashboardStyleDebounce();
        });
    }

    function startTutorial(walkMeId) {
      window.$printos.v1.help.openWalkMe(walkMeId);
      // $gzWalkMe.startWalkMeByTitle(walkMeId).catch(() => {
      //   $mdToast.show($mdToast.simple().content($translate.instant('home.home.NoWalkMe')));
      // });
    }

    function ignoreTutorial() {
      $mdDialog.show({
        parent: angular.element(document.body),
        targetEvent: null,
        clickOutsideToClose: false,
        escapeToClose: true,
        template: require('gazelle/src/components/confirm/confirm-dialog.jade'),
        controller: 'gzConfirmDialogController',
        bindToController: true,
        controllerAs: 'vm',
        locals: {
          titleKey: 'home.home.Close_Intro',
          labelKey: undefined,
          value: undefined,
          messageKey: 'home.home.Close_Intro_Message',
        }
      })
        .then(canClose => {
          vm.showIntro = false;
          rnPreferences.setSingle('home', `lp2.${cc.context.id}.${vm.family}.showIntro`, false);
          if (!preferences.home) {
            preferences.home = {};
          }
          preferences.home[`lp2.${cc.context.id}.${vm.family}.showIntro`] = false;
        })
        .catch(() => {});
    }

    function showTutorial() {
      rnPreferences.deleteSingle('home', `lp2.${cc.context.id}.${vm.family}.showIntro`);
      $window.location.reload();
    }

    function getSectionLink(section) {
      if (section.cards.length === 0) {
        return;
      }

      let href = section.cards[0].link;
      for(let i = 1; i < section.cards.length; i++) {
        if (section.cards[i].link !== href) {
          return section.application._links.self;
        }
      }

      return href;
    }

    function getLinkTarget(card) {
      if (card.target) {
        return card.target;
      }

      if (!card.application) {
        return;
      }

      if (card.application.type === 'external') {
        return card.application.appId;
      }
    }

    function isCurrentFamily(family) {
      return family === vm.family;
    }


    ///////////////////////////////////////////////////////////
    // API
    function apiGetClientConfig() {
      return cc;
    }

    function getAllDevicesByType(deviceType, siteId, rtOnly = false, allOrgs = false) {
      let filteredDevices = devices;
      if (!allOrgs) {
        filteredDevices = devices.filter(d => {
          return d.organizationId === organization.organizationId;
        });
      }

      if (deviceType) {
        filteredDevices = filteredDevices.filter(device => {
          return device.type === deviceType;
        });
      }

      if (siteId) {
        if (siteId === LP_UNASSIGNED_SITE) {
          filteredDevices = filteredDevices.filter(device => {
            let deviceSiteId = _.get(device, 'site.siteId');
            return deviceSiteId === undefined;
          });
        }
        else {
          filteredDevices = filteredDevices.filter(device => {
            let deviceSiteId = _.get(device, 'site.siteId');
            return (deviceSiteId === siteId);
          });
        }
      }

      if (rtOnly) {
        filteredDevices = filteredDevices.filter(device => {
          return device.internal.pbDevice ? device.internal.pbDevice.isRtSupported : device.internal.isPBRTSupported;
          // return _.get(device, 'internal.pbDevice.isRtSupported', false);
          // return _.get(device, 'internal.isPBRTSupported', false);
        });
      }

      return filteredDevices;
    }

    function getAllDevicesByFamily(deviceFamily, siteId, rtOnly = false, allOrgs = false) {
      if (deviceFamily === LP_DEVICE_FAMILY.LF) {
        let filteredDevices = getAllDevicesByType(LP_DEVICE_TYPE.LATEX, siteId, rtOnly, allOrgs);
        filteredDevices = filteredDevices.concat(getAllDevicesByType(LP_DEVICE_TYPE.DESIGNJET, siteId, rtOnly, allOrgs));
        filteredDevices = filteredDevices.concat(getAllDevicesByType(LP_DEVICE_TYPE.TEXTILE, siteId, rtOnly, allOrgs));
        filteredDevices = filteredDevices.concat(getAllDevicesByType(LP_DEVICE_TYPE.PAGEWIDEXL, siteId, rtOnly, allOrgs));

        return filteredDevices;
      }

      if (deviceFamily === LP_DEVICE_FAMILY.OTHER) {
        let filteredDevices = getAllDevicesByType(LP_DEVICE_TYPE.HOT_FOLDER, siteId, rtOnly, allOrgs);
        filteredDevices = filteredDevices.concat(getAllDevicesByType(LP_DEVICE_TYPE.PWP_DFE, siteId, rtOnly, allOrgs));
        filteredDevices = filteredDevices.concat(getAllDevicesByType(LP_DEVICE_TYPE.INDIGO_DFE, siteId, rtOnly, allOrgs));
        filteredDevices = filteredDevices.concat(getAllDevicesByType(LP_DEVICE_TYPE.RIP, siteId, rtOnly, allOrgs));

        return filteredDevices;
      }

      return getAllDevicesByType(deviceFamily, siteId, rtOnly, allOrgs);
    }

    function getCurrentDevicesByType(deviceType, rtOnly = false, allOrgs = false) {
      return getAllDevicesByType(deviceType, vm.siteId, rtOnly, allOrgs);
    }

    function getCurrentDevicesByFamily(deviceFamily, rtOnly = false, allOrgs = false) {
      if (deviceFamily === LP_DEVICE_FAMILY.LF || deviceFamily === LP_DEVICE_FAMILY.OTHER) {
        return getAllDevicesByFamily(deviceFamily, vm.siteId);
      }

      return getAllDevicesByType(deviceFamily, vm.siteId, rtOnly, allOrgs);
    }

    function getSharedDevices() {
      return devices.filter(device => {
        return device.orgId !== cc.context.id;
      });
    }

    function getCanBuy(type, name){
      if(!entitlements.hasOwnProperty(type)){
        return false;
      }

      let product = entitlements[type].find(entitlement => entitlement.name === name);
      if(!product){
        return false;
      }

      return !!_.get(product, 'canBuy', false);
    }

    function getCanRun(type, name){
      if(!entitlements.hasOwnProperty(type)){
        return false;
      }

      let entitlement = entitlements[type].find(entitlement => entitlement.name === name);
      if(!entitlement){
        return false;
      }

      return !!_.get(entitlement, 'canRun', false);
    }

    function getDevicesByOrg(orgId) {
      return devices.filter(device => {
        return device.organizationId === orgId;
      });
    }

    function apiGetSites() {
      return sites;
    }

    function getCurrentSite() {
      if (vm.siteId === LP_UNASSIGNED_SITE) {
        return {
          siteId: LP_UNASSIGNED_SITE,
        };
      }

      return _.find(sites, {siteId: vm.siteId});
    }

    function isAccountAdmin() {
      if (accountType === 'Unknown') {
        return false;
      }

      return _.get(organization, 'data._links.update') !== undefined;
    }

    function hasOrg() {
      return _.get(cc, 'context.type', 'Unknown') !== 'Unknown';
    }

    function isAccountType(type) {
      if (type === ACCOUNT_TYPE.EXTERNAL) {
        return isExternalUser;
      }

      return type === _.get(cc, 'context.type');
    }

    function hasApplication(appId) {
      let applications = _.get(cc, 'apps.entity', []);
      let found = _.find(applications, {appId: appId});
      return found !== undefined;
    }

    function getApplication(appId) {
      let apps = _.get(cc, 'apps.entity', []);
      let app = apps.find(a => {
        return a.appId === appId;
      });

      return app;
    }

    function hasFeature(feature) {
      let features = _.get(cc, 'features', []);
      return features.indexOf(feature) !== -1;
    }

    function hasBU(businessUnit) {
      return businessUnits.indexOf(businessUnit) !== -1;
    }

    function isPrintBeatRankingEnabled() {
      return _.get(printBeatSettings, 'rankingEnable', false);
    }

    function hasRealTimeDevicesByFamily(deviceFamily, allOrgs = false) {
      if (deviceFamily === LP_DEVICE_FAMILY.LF || deviceFamily === LP_DEVICE_FAMILY.OTHER) {
        return getAllDevicesByFamily(deviceFamily, null, true).length > 0;
      }

      return getAllDevicesByType(deviceFamily, null, true, allOrgs).length > 0;
    }

    function hasRealTimeDevicesByType(deviceType, allOrgs = false) {
      return getAllDevicesByType(deviceType, null, true, allOrgs).length > 0;
    }

    function currentHasRealTimeDevicesByFamily(deviceFamily, allOrgs = false) {
      return getAllDevicesByFamily(deviceFamily, vm.siteId, true, allOrgs).length > 0;
    }

    function currentHasRealTimeDevicesByType(deviceType, allOrgs = false) {
      return getAllDevicesByType(deviceType, vm.siteId, true, allOrgs).length > 0;
    }

    function hasDeviceFamily(deviceFamily, siteId, allOrgs = false) {
      if (deviceFamily === LP_DEVICE_FAMILY.LF || deviceFamily === LP_DEVICE_FAMILY.OTHER) {
        return getAllDevicesByFamily(deviceFamily, siteId, false, allOrgs).length > 0;
      }

      return getAllDevicesByType(deviceFamily, siteId, false, allOrgs).length > 0;
    }

    function currentHasDeviceFamily(deviceFamily, allOrgs = false) {
      return hasDeviceFamily(deviceFamily, vm.siteId, allOrgs);
    }

    function hasDeviceType(deviceType, siteId, allOrgs = false) {
      return getAllDevicesByType(deviceType, siteId, false, allOrgs).length > 0;
    }

    function currentHasDeviceType(deviceType, allOrgs = false) {
      return getAllDevicesByType(deviceType, vm.siteId, false, allOrgs);
    }

    function getPrintBeatSerialNumbersByFamily(deviceFamily, siteId, rtOnly = false) {
      if (deviceFamily === LP_DEVICE_FAMILY.LF) {
        let deviceSNs = getPrintBeatSerialNumbersByType(LP_DEVICE_TYPE.LATEX, siteId, rtOnly);
        deviceSNs = deviceSNs.concat(getPrintBeatSerialNumbersByType(LP_DEVICE_TYPE.DESIGNJET, siteId, rtOnly));
        deviceSNs = deviceSNs.concat(getPrintBeatSerialNumbersByType(LP_DEVICE_TYPE.TEXTILE, siteId, rtOnly));
        deviceSNs = deviceSNs.concat(getPrintBeatSerialNumbersByType(LP_DEVICE_TYPE.PAGEWIDEXL, siteId, rtOnly));

        return deviceSNs;
      }

      return getPrintBeatSerialNumbersByType(deviceFamily, siteId, rtOnly);
    }

    function getCurrentPrintBeatSerialNumbersByFamily(deviceFamily, rtOnly = false) {
      return getPrintBeatSerialNumbersByFamily(deviceFamily, vm.siteId, rtOnly);
    }

    function getCurrentPrintBeatSerialNumbersByType(deviceType, rtOnly = false) {
      getPrintBeatSerialNumbersByType(deviceType, vm.siteId, rtOnly);
    }

    function getPrintBeatSerialNumbersByType(deviceType, siteId, rtOnly = false, allOrgs = false) {
      let myDevices = getAllDevicesByType(deviceType, siteId, rtOnly, allOrgs);
      let deviceSNs = [];

      myDevices.forEach(device => {
        let isRT = device.internal.pbDevice ? device.internal.pbDevice.isRtSupported : device.internal.isPBRTSupported;
        let pbSerialNumber = _.get(device, 'internal.pbDevice.SerialNumber');
        if (pbSerialNumber) {
          if (!rtOnly || (rtOnly && isRT)) {
            deviceSNs.push(device.internal.pbDevice.SerialNumber);
          }
        }
      });

      return deviceSNs;
    }

    function getCurrentPBActualsByFamily(deviceFamily) {
      return pbActuals[deviceFamily];
    }

    function getCurrentPBTargetsByFamily(deviceFamily) {
      return pbTargets[deviceFamily];
    }

    function getDeviceByPrintBeatSerialNumber(pbSerialNumber) {
      let split = pbSerialNumber.split('!');
      let sn = split[0];
      let model = split[1];

      let device = _.find(devices, device => {
        if (model) {
          return device.serialNumber === sn && device.model === model;
        }
        else {
          return device.serialNumber === sn;
        }
      });

      return device;
    }

    function changeDashboard(siteId, family, dashboardId) {
      // !siteId handles the HP Inc. case where there is no site
      // !family handles the case where the site has no valid families
      let siteChanged = !siteId || vm.siteId !== siteId;
      let familyChanged = vm.family !== family;
      let dashboardChanged = vm.dashboardId !== dashboardId;
      if (siteChanged || familyChanged || dashboardChanged) {
        vm.siteId = siteId;
        vm.family = family;
        vm.dashboardId = dashboardId;
        vm.loadingDashboard = true;
        delete vm.dashboard;

        // Force a digest to ensure previous section and card definitions are cleared in template.
        // (had a reference problem with track by $index when promises returned instantly without devices)
        $rootScope.$applyAsync(() => {
          let promises = [];
          if (pbDevices) {
            promises.push(getPrintBeatData(vm.siteId));
          }
          $q.all(promises).then(() => {
            vm.dashboard = $hmCards.getDashboard(businessUnits, accountType, family, dashboardId);
            vm.showIntro = true;
            let myPref = _.get(preferences, 'home');
            if (myPref) {
              let test = myPref[`lp2.${cc.context.id}.${vm.family}.showIntro`];
              vm.showIntro = test === undefined ? true : false;
            }

            setupConfig();

            setupDashboardStyleDebounce();
            if (siteId && siteChanged) {
              updateSiteCallbacks();
            }
          })
            .finally(() => {
              vm.loadingDashboard = false;
            });
        });
      }
    }

    function setPages(card, pages, callback, pageDisplay) {
      card.pages = pages;
      card.pageDisplay = pageDisplay;
      card.pageCallback = callback;
    }

    function setAppLink(card, href) {
      card.link = href;
    }

    function setSiteCallback(card, callback) {
      card.siteCallback = callback;
    }

    function setPBCallback(card, callback) {
      card.pbCallback = callback;
    }


    ///////////////////////////////////////////////////////////
    // Helpers
    function setServiceError(error) {
      if (!vm.serviceErrorKeys.includes(error)) {
        vm.serviceErrorKeys.push(error);
      }
    }

    function clearServiceError(error) {
      vm.serviceErrorKeys = vm.serviceErrorKeys.filter(e => { return e !== error; });
    }

    function checkForDeviceStatisticsUpdate() {
      if (deviceIdsToUpdate.length > 0) {
        let devicesToUpdate = [];
        deviceIdsToUpdate.forEach(deviceId => {
          let device = _.find(devices, {deviceId: deviceId});
          if (device) {
            devicesToUpdate.push(device);
          }
        });

        updateDeviceStatistics(devicesToUpdate);
      }

    }

    function updateDeviceStatistics(devices) {
      if (devices.length === 0) {
        // Nothing to do
        return $q.resolve(false);
      }

      delete vm.dataErrorKey;
      clearServiceError(SERVICE_ERRORS.DEVICE_STATS);
      return systemStatisticsService.getAllDeviceStats(devices).then(() => {
        lastDeviceUpdateTime = moment();
        $rootScope.$emit('$lpDeviceDataUpdated');
        popDevicesToUpdate(devices);
      })
        .catch(() => {
          console.error('UPDATE FAILURE - Device Statistics');
          setServiceError(SERVICE_ERRORS.DEVICE_STATS);
          return $q.resolve();
        });
    }

    function getLastDeviceUpdateTime(){
      return lastDeviceUpdateTime;
    }

    function checkForPrintBeatDataUpdate() {
      if (needToUpdatePrintBeatData) {
        updatePrintBeatData();
      }
    }

    function updatePrintBeatData() {
      if (!pbReadAccess) {
        return Promise.resolve();
      }

      delete vm.dataErrorKey;
      needToUpdatePrintBeatData = false;
      clearServiceError(SERVICE_ERRORS.PB_DATA);
      return getPrintBeatData(vm.siteId).then(() => {
        $rootScope.$emit('$lpPrintBeatDataUpdated');
      })
        .catch(error => {
          console.error(`UPDATE FAILURE - updatePrintBeatData (${vm.siteId})`, error);
          return Promise.resolve();
        });
    }

    function getPrintBeatData(siteId) {
      let promises = [];

      PB_DEVICE_FAMILIES.forEach(family => {
        pbActuals[family] = [];
        pbTargets[family] = [];

        let deviceSerialNumbers = [];
        switch(family) {
          case LP_DEVICE_FAMILY.INDIGO:
            deviceSerialNumbers = getPrintBeatSerialNumbersByType(LP_DEVICE_TYPE.INDIGO, siteId, true);
            break;
          case LP_DEVICE_FAMILY.LF:
            // family = LP_DEVICE_TYPE.LATEX;
            deviceSerialNumbers = getPrintBeatSerialNumbersByFamily(LP_DEVICE_FAMILY.LF, siteId, true);
            break;
          case LP_DEVICE_FAMILY.SCITEX:
            deviceSerialNumbers = getPrintBeatSerialNumbersByType(LP_DEVICE_TYPE.SCITEX, siteId, true);
            break;
          case LP_DEVICE_FAMILY.PAGEWIDE:
            deviceSerialNumbers = getPrintBeatSerialNumbersByType(LP_DEVICE_TYPE.PWP, siteId, true);
            break;
        }

        if (deviceSerialNumbers.length > 0) {
          promises.push(getPrintBeatActualsForFamily(family, deviceSerialNumbers));
          promises.push(getPrintBeatTargetsForFamily(family, deviceSerialNumbers));
        }
      });

      return Promise.all(promises).then(() => {
        updatePBCallbacks();
      });
    }

    function getPrintBeatActualsForFamily(family, deviceSerialNumbers) {
      let pages = Math.ceil(deviceSerialNumbers.length / PRINT_BEAT_PAGE_SIZE);
      let promises = [];
      for (let p = 0; p < pages; p++) {
        promises.push(getPrintBeatActuals(family, deviceSerialNumbers.slice(p * PRINT_BEAT_PAGE_SIZE, (p + 1) * PRINT_BEAT_PAGE_SIZE)));
      }
      return Promise.all(promises).then(responses => {
        pbActuals[family] = [];
        responses.forEach(resp => {
          pbActuals[family] = pbActuals[family].concat(resp);
        });
      });
    }

    function getPrintBeatActuals(family, deviceSerialNumbers) {
      let config = {
        params: {
          // unitSystem: isMetric ? 'Metric' : 'Imperial',
          unitSystem: 'Metric',
          item: 'Print Volume',
          unit: 'PrintedImpressions',
          bu: family === LP_DEVICE_FAMILY.LF ? LP_DEVICE_TYPE.LATEX : family,
          devices: deviceSerialNumbers,
        }
      };

      return rnHttp.get('PrintbeatService', 'RealtimeTracking/many/v2', config).then(resp => {
        return _.get(resp, 'data.data', []);
      })
        .catch(error => {
          console.error(`Update FAILURE - Print Beat Actuals (${family})`, error);
          setServiceError(SERVICE_ERRORS.PB_DATA);
          return Promise.resolve([]);
        });
    }

    function getPrintBeatTargetsForFamily(family, deviceSerialNumbers) {
      let pages = Math.ceil(deviceSerialNumbers.length / PRINT_BEAT_PAGE_SIZE);
      let promises = [];
      for (let p = 0; p < pages; p++) {
        promises.push(getPrintBeatTargets(family, deviceSerialNumbers.slice(p * PRINT_BEAT_PAGE_SIZE, (p + 1) * PRINT_BEAT_PAGE_SIZE)));
      }
      return Promise.all(promises).then(responses => {
        pbTargets[family] = [];
        responses.forEach(resp => {
          pbTargets[family] = pbTargets[family].concat(resp);
        });
      });
    }

    function getPrintBeatTargets(family, deviceSerialNumbers) {
      let config = {
        params: {
          // unitSystem: isMetric ? 'Metric' : 'Imperial',
          unitSystem: 'Metric',
          item: 'Print Volume',
          unit: 'PrintedImpressions',
          bu: family === LP_DEVICE_FAMILY.LF ? LP_DEVICE_TYPE.LATEX : family,
          devices: deviceSerialNumbers,
        }
      };

      return rnHttp.get('PrintbeatService', 'RealtimeTracking/target/many/v2/', config).then(resp => {
        if (family === LP_DEVICE_TYPE.LATEX) {
          family = LP_DEVICE_FAMILY.LF;
        }
        return _.get(resp, 'data.data', []);
      })
        .catch(error => {
          console.error(`UPDATE FAILURE - Print Beat Targets (${family})`, error);
          setServiceError(SERVICE_ERRORS.PB_DATA);
          return Promise.resolve([]);
        });
    }

    function pushDeviceToUpdate(deviceId) {
      if (deviceIdsToUpdate.indexOf(deviceId) === -1) {
        deviceIdsToUpdate.push(deviceId);
      }
    }

    function popDeviceToUpdate(deviceId) {
      let index = deviceIdsToUpdate.indexOf(deviceId);
      if (index >= 0) {
        deviceIdsToUpdate.splice(index, 1);
      }
    }

    function popDevicesToUpdate(devices) {
      devices.forEach(device => {
        popDeviceToUpdate(device.deviceId);
      });
    }

    function updateSiteCallbacks() {
      if (!vm.dashboard) {
        return;
      }

      vm.dashboard.sections.forEach(section => {
        section.cards.forEach(card => {
          if (card.siteCallback) {
            let site = sites.find(site => {
              return site.siteId === vm.siteId;
            });
            card.siteCallback(site);
          }
        });
      });
    }

    function updatePBCallbacks() {
      if (!vm.dashboard) {
        return;
      }

      vm.dashboard.sections.forEach(section => {
        section.cards.forEach(card => {
          if (card.pbCallback) {
            let site = sites.find(site => {
              return site.siteId === vm.siteId;
            });
            card.pbCallback(site);
          }
        });
      });
    }
  }
}
