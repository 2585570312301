import './welcome-org.scss';

import * as WelcomeOrgController from './welcome-org.controller';
import rnClient from 'rhino/src/core/client/client.module';
import rnHttp from 'rhino/src/core/http/http.module';
import rnRegistry from 'rhino/src/core/registry/registry.module';
import gazelleProfileImage from 'gazelle/src/components/profile-image/profile-image.module';

export default angular.module('home.components.welcomeOrg', [
  rnClient,
  rnHttp,
  rnRegistry,
  gazelleProfileImage,
])
  .controller(WelcomeOrgController.name, WelcomeOrgController.default)
  .name;
