import mixin from '../util/mixin.js';
import Composition from '../util/composition';

const Mixin = mixin(Composition);
const LOCALIZATION = 'translate';
const ROUTE_REMOVE_MESSAGE = 'admin.admin.Route_Removed';
const ROUTE_ERROR_REMOVE_MESSAGE = 'admin.admin.Route_Remove_Error';
const UNKNOWN_ERROR_MESSAGE = 'admin.admin.Route_Remove_Error_Unknown';

export const moduleName = 'AskTheExpertModalController';
export default class AskTheExpertModalController extends Mixin {
  /** @ngInject */
  constructor($mdDialog, $log, $filter, $scope, $mdToast, $rootScope) {
    super();
    this.bindDependencies(
      { $mdDialog, $log, $filter, $scope, $mdToast, $rootScope });
    this.activate();
  }

  activate() {
    this.isSending = false;
  }

  close() {
    this.$mdDialog.cancel();
  }

  static factory($mdDialog, $log, $filter, $scope, $mdToast, $rootScope) {
    'ngInject';
    return new AskTheExpertModalController($mdDialog, $log, $filter, $scope, $mdToast, $rootScope);
  }


}

