export const name = 'OrgCodeDialogController';
export default function OrgCodeDialogController(
  // Controller angular dependencies
  rnHttp,
  $state,
  $translate,
  $mdToast,
  $mdDialog
) {
  'ngInject';
  // Constants

  // Variables

  // View Model
  let vm = this;
  //   VM properties
  vm.loading = true;
  vm.validCode = false;

  //   VM methods
  vm.cancel = cancel;
  vm.submit = submit;
  vm.close = close;
  vm.disableSubmit = disableSubmit;

  vm.activate = activate;
  activate();
  return;

  ///////////////
  // Init
  function activate() {
    vm.loading = false;
  }

  ///////////////
  // VM methods

  function cancel() {
    $mdDialog.cancel();
  }

  function disableSubmit() {
    if (vm.processing) {
      return true;
    }

    return !vm.validCode;
  }

  function submit() {
    if (vm.processing || disableSubmit()) {
      return;
    }

    $mdDialog.hide();
    return window.$printos.v1.go.toContext('join-org', [], {oc: vm.code});
  }


  function close() {
    $mdDialog.hide();
  }

  ///////////////
  // Helpers
}
