import {LP_DEVICE_FAMILY} from 'src/components/landing-page/cards/card-definitions';
export const name = 'hmLatexStatus';

export default function hmLatexStatus(
  // Directive angular dependencies
) {
  'ngInject';

  let directive = {
    restrict: 'EA',
    scope: {
      gzCard: '=',
    },
    template: require('./latex-status.jade'),
    controller: latexStatusController,
    controllerAs: 'vm',
    bindToController: true,
  };
  return directive;

  //////////////////////

  /** @ngInject */
  function latexStatusController(
    // Controller angular dependencies
    $q,
    $translate,
    moment,
    $interval,
    $rootScope,
    $hmPrintBeat,
    $sce,
    $scope,
    $mdDialog
  ) {
    // Constants
    const PRINTER_INFO_PATH = 'data.generalData.printer_data.data.printer_info';
    const STATUS = Object.freeze({
      PRINTING: "PRINTING",
      READY: "READY",
      ERROR: "ERROR",
      OPTIMIZING: "OPTIMIZING",
      DISCONNECTED: "DISCONNECTED",
    });

    // Variables
    let lastUpdateListener;
    let pbContext = {};

    // View Model
    let vm = this;

    vm.goToPB = goToPB;
    vm.$onInit = onInit;
    return;

    function onInit() {
      vm.gzCard.headerAction.clicked = handleHelpClicked;
      lastUpdateListener = $rootScope.$on('$lpDeviceDataUpdated', getDeviceStatusCount);
      $scope.$on('$destroy', () => {
        lastUpdateListener();
      });
      pbContext = {
        family: 'LatexPrinter',
        siteId: vm.gzCard.API.currentSite.getSite().siteId,
      };

      let pbUrl = $hmPrintBeat.createLink(pbContext);
      vm.gzCard.API.config.setAppLink(pbUrl);

      getDeviceStatusCount();
      vm.loading = false;
    }

    function getDeviceStatusCount(){
      //Get LF devices
      vm.devices = vm.gzCard.API.currentSite.getDevicesByFamily(LP_DEVICE_FAMILY.LF);

      //Last updated time
      vm.lastDeviceStatUpdate = vm.gzCard.API.account.getLastDeviceUpdateTime();

      //Filter LF devices based on status
      let computedStatusPath = `${PRINTER_INFO_PATH}.computed_status`;
      vm.printingDevices = vm.devices.filter(device => _.get(device, computedStatusPath) === STATUS.PRINTING);
      vm.readyDevices = vm.devices.filter(device => _.get(device, computedStatusPath) === STATUS.READY);
      vm.errorDevices = vm.devices.filter(device => _.get(device, computedStatusPath) === STATUS.ERROR);
      vm.optimizingDevices = vm.devices.filter(device => _.get(device, computedStatusPath) === STATUS.OPTIMIZING);
      vm.disconnectedDevices = vm.devices.filter(device => {
        let computedStatus = _.get(device, computedStatusPath);
        return computedStatus === STATUS.DISCONNECTED || computedStatus === undefined;
      });

      //Error Case
      // Sort error devices so latest occurring error is at the end
      vm.errorDevices = vm.errorDevices.sort(latestDeviceUpdateFirst);
      vm.disconnectedDevices = vm.disconnectedDevices.sort(latestDeviceUpdateFirst);

      if(vm.errorDevices.length > 0){
        vm.lastErrorDevice = vm.errorDevices[0];
        let lastErrorDate = _.get(vm.lastErrorDevice, `${PRINTER_INFO_PATH}.last_update_at`);
        if (lastErrorDate) {
          vm.lastErrorDate = moment(lastErrorDate);
        }

        vm.errorInfo =  $sce.trustAsHtml(_.get(vm.lastErrorDevice,`${PRINTER_INFO_PATH}.status_message`, $translate.instant('home.home.LFStatus_UnknownDeviceMessage')));
      }
      else if (vm.disconnectedDevices.length > 0) {
        vm.lastDisconnectedDevice = vm.disconnectedDevices[0];
        let lastTime = _.get(vm.lastDisconnectedDevice, `${PRINTER_INFO_PATH}.last_update_at`);
        vm.lastDisconnectedDate = lastTime ? moment(lastTime) : null;
      }
    }

    function goToPB($event){
      $event.stopPropagation();
      $hmPrintBeat.redirect(pbContext, $event);
    }

    function handleHelpClicked(event) {
      event.preventDefault();
      event.stopPropagation();
      $mdDialog.show({
        parent: angular.element(document.body),
        clickOutsideToClose: true,
        escapeToClose: true,
        template: require('./latex-status-help.jade'),
        bindToController: true,
        controllerAs: 'vm',
        locals: {
        },
        controller: function DialogController($mdDialog) {
          let vm = this;
          vm.closeDialog = ($event) => {
            $event.stopPropagation();
            $mdDialog.hide();
          };
        }
      });
    }

    function latestDeviceUpdateFirst(aDevice, bDevice) {
      let aUpdate = moment(_.get(aDevice, `${PRINTER_INFO_PATH}.last_update_at`, 0));
      let bUpdate = moment(_.get(bDevice, `${PRINTER_INFO_PATH}.last_update_at`, 0));
      if (aUpdate.isAfter(bUpdate)) {
        return -1;
      } else if (aUpdate.isSame(bUpdate)) {
        return 0;
      } else {
        return 1;
      }
    }
  }
}
