export const name = 'hmMaintenancePromo';
export default function hmMaintenancePromo() {
  //noinspection BadExpressionStatementJS
  'ngInject';

  let directive = {
    restrict: 'EA',
    scope: {
      gzCard: '=?',
    },
    template: require('./maintenance-promo.jade'),
    controller: maintenancePromoController,
    controllerAs: 'vm',
    bindToController: true
  };

  return directive;

  /** @ngInject */
  function maintenancePromoController(
    $gzGo
  ) {

    let vm = this;
    vm.goToMarketplace = goToMarketplace;
    return;

    function goToMarketplace($event) {
      $event.stopPropagation();
      $gzGo.toContext('market-product', '3dcenterpremium');
    }
  }
}
