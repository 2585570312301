import './indigo-score.scss';

import rhino from 'rhino';
import angularTranslate from 'angular-translate';
import angularMaterial from 'angular-material';
import highchartsNg from 'highcharts-ng';
import gazelleAnalytics from 'gazelle/src/core/analytics/analytics.module';
import homePrintBeat from 'src/components/landing-page/cards/shared/print-beat/print-beat.module';

import * as hmIndigoScoreDirective from './indigo-score.directive';

export default angular.module('home.components.widget.hmIndigoScore', [
  rhino,
  angularTranslate,
  angularMaterial,
  highchartsNg,
  'gazelle.components.go',
  gazelleAnalytics,
  homePrintBeat,
])
  .directive(hmIndigoScoreDirective.name, hmIndigoScoreDirective.default)
  .name;
