import './add-section-dialog.scss';
import angularTranslate from 'angular-translate';
import ngMaterial from 'angular-material';

import * as AddSectionDialogController from './add-section-dialog.controller';

export default angular.module('home.components.addSectionDialog', [
  angularTranslate,
  ngMaterial,
])
  .controller(AddSectionDialogController.name, AddSectionDialogController.default)
  .name;
