module.exports = {
  cards: [
    {
      "id": 1,
      "plan_id": 1,
      "status": "active",
      "card": {
        "locale": "en-US",
        "layout": "basic",
        "icon": "https://s3-us-west-2.amazonaws.com/sulu-content/images/sulu_review_icon.svg",
        "message": "View your Active 3D Now Service Status",
        "action": {
          "label": "See Your Usage",
          "href": "https://3dpaas.daily.gsbprint.net/"
        }
      }
    },
    {
      "id": 2,
      "plan_id": 1,
      "status": "active",
      "card": {
        "locale": "en-US",
        "layout": "basic",
        "icon": "https://s3-us-west-2.amazonaws.com/sulu-content/images/sulu_icon.svg",
        "message": "View your Active 3D Now Service Status",
        "action": {
          "label": "See Your Usage",
          "href": "https://3dpaas.daily.gsbprint.net/"
        }
      }
    },
    {
      "id": 23,
      "plan_id": 1,
      "status": "setup",
      "card": {
        "locale": "en-US",
        "layout": "basic",
        "icon": "https://s3-us-west-2.amazonaws.com/sulu-content/images/sulu_icon.svg",
        "message": "Welcome to your <strong>HP 3D Services</strong> account!",
        "action": {
          "label": "Complete Assessment",
          "href": "https://3dpaas.daily.gsbprint.net/setup/customers/41/edit"
        }
      }
    },
    {
      "id": 26,
      "plan_id": 1,
      "status": "setup",
      "card": {
        "locale": "en-US",
        "layout": "basic",
        "icon": "https://s3-us-west-2.amazonaws.com/sulu-content/images/sulu_icon.svg",
        "message": "Your assessment is being reviewed",
        "action": {
          "label": "View Your Assessment",
          "href": "https://3dpaas.daily.gsbprint.net/setup/customers/47/edit"
        }
      }
    }
  ]
};
