export const name = 'hmIndigoSite';
export default function hmIndigoSite() {
  //noinspection BadExpressionStatementJS
  'ngInject';

  let directive = {
    restrict: 'EA',
    scope: {
      gzCard: '=',
    },
    template: require('./indigo-site.jade'),
    controller: hmIndigoSiteController,
    controllerAs: 'vm',
    bindToController: true
  };

  return directive;

  /** @ngInject */
  function hmIndigoSiteController(
    rnPreferences,
    $q,
    $translate,
    $gzAnalytics,
    $hmPrintBeat
  ) {

    const DEVICES_PER_PAGE = 4;

    let preferences = {};
    let allDevices;
    let family;
    let isMetric = false;
    let pbContext = {};

    let vm = this;
    vm.loading = true;
    vm.progress = 55;
    vm.progressStatus = 'OK';
    vm.pace = 50;

    vm.goToDevice = goToDevice;
    vm.goToSite = goToSite;

    vm.$onInit = onInit;
    return;
    ////////////////////

    function onInit() {
      family =  _.get(vm.gzCard, 'settings.family');
      vm.gzCard.API.config.setSiteCallback(siteChanged);
      vm.gzCard.API.config.setPBCallback(siteChanged);
      updateData();

      let promises = [];
      promises.push(getPreferences());
      promises.push($translate.onReady());
      $q.all(promises).then(() => {
        switch(family) {
          case 'IndigoPress':
            vm.units = 'IMPRESSIONS';
            break;
          default:
            vm.units = isMetric ? 'SQM' : 'SQF';
            break;
        }

        pbContext = {
          page: 'dashboard',
          family,
          siteId: _.get(vm.gzCard.API.currentSite.getSite(), 'siteId', '-1'),
        };
        let siteURL = $hmPrintBeat.createLink(pbContext);
        vm.gzCard.API.config.setAppLink(siteURL);
      })
        .finally(() => {
          vm.loading = false;
        });
    }

    function getPreferences() {
      rnPreferences.getAll().then(resp => {
        preferences = resp;

        let unitSystem = _.get(preferences, 'general.unitSystem');
        switch(unitSystem) {
          case 'Imperial':
            isMetric = false;
            break;
          default:
            isMetric = true;
        }
      });
    }

    function updateData() {
      let actuals = _.cloneDeep(vm.gzCard.API.currentSite.getPBActualsByFamily(family));
      let targets = _.cloneDeep(vm.gzCard.API.currentSite.getPBTargetsByFamily(family));

      let dayTarget = _.get(targets, '[0].shiftTarget.value');
      let nowTarget = _.get(targets, '[0].intraDailyTarget.value');
      let selected = _.find(actuals, {device: 'Selected'});
      if (selected) {
        _.remove(actuals, selected);
      }

      let normalizedImpressions;
      _.forEach(actuals, data => {
        normalizedImpressions = _.get(data, 'impressionType');
        let deviceActual = data.value;
        let targetObject = getTargetObject(targets, data.device);
        let deviceTarget = targetObject.intraDailyTarget.value;
        let compare = deviceActual / deviceTarget * 100;
        if (compare < 70) {
          if (compare < 50) {
            _.set(data, 'internal.deviceStatus', 'ERROR');
          }
          else {
            _.set(data, 'internal.deviceStatus', 'WARN');
          }
        }
        else {
          _.set(data, 'internal.deviceStatus', 'GOOD');
        }

        switch(_.get(data, 'pressState.color')) {
          case 'GOOD':
            data.colorIndex = 3;
            break;
          case 'NEUTRAL':
            data.colorIndex = 4;
            break;
          case 'BAD':
            data.colorIndex = 1;
            break;
          default:
            data.colorIndex = 5;
            break;
        }

        let device = vm.gzCard.API.helpers.getDeviceByPrintBeatSerialNumber(data.device);
        data.deviceName = _.get(device, 'name');

        if (_.get(data, 'pressState.state')) {
          data.stateDisplay = $translate.instant('pb.app.' + data.pressState.state);
        }
        else {
          data.stateDisplay = $translate.instant('home.home.Unknown');
        }
      });

      if (normalizedImpressions) {
        _.set(vm, 'impressionsLabel', $translate.instant('home.home.NormalizedImpressions', [normalizedImpressions]));
        _.set(vm, 'impressionsTooltip', $translate.instant('home.home.NormalizedImpressionsTooltip'));
      }
      else {
        _.set(vm, 'impressionsLabel', $translate.instant('home.home.Impressions'));
        delete(vm.impressionsTooltip);
      }

      let actual = _.get(selected, 'value');
      let progress = 100;
      let pace = 0;
      let status = 'OK';
      if (dayTarget !== undefined && nowTarget !== undefined) {
        pace = Math.round(nowTarget / dayTarget * 100);
      }

      if (actual !== undefined) {
        if (dayTarget && actual < dayTarget) {
          progress = Math.round(actual / dayTarget * 100);
        }
      }
      else {
        progress = 0;
      }

      if (progress < pace) {
        if (pace - progress < 10) {
          status = 'WARN';
        }
        else {
          status = 'ERROR';
        }
      }

      //vm.devices = _.sortByAll(actuals, ['colorIndex', 'stateDisplay', 'deviceName']);
      allDevices = _.sortBy(actuals, 'deviceName');
      if (allDevices.length > DEVICES_PER_PAGE) {
        let numPages = Math.ceil(allDevices.length / DEVICES_PER_PAGE);
        let pages = [];
        for (let i = 0; i < numPages; i++) {
          let start = i * DEVICES_PER_PAGE;
          let end = start + DEVICES_PER_PAGE;
          pages[i] = allDevices.slice(start, end);
        }

        vm.gzCard.API.config.setPages(pages, pageChanged);
        vm.devices = pages[0];
        vm.isPaged = true;
      }
      else {
        vm.devices = allDevices;
        vm.isPaged = false;
      }

      vm.progress = progress;
      vm.progressStatus = status;
      vm.pace = pace;
      vm.isGoalSet = dayTarget !== undefined;
      vm.actual = actual;
      vm.goal = dayTarget;
    }

    function getTargetObject(list, SN) {
      let found;
      _.forEach(list, item => {
        if (item.shiftTarget.device === SN) {
          found = item;
          return false;
        }
      });
      return found;
    }

    function siteChanged(site) {
      updateData();
    }

    function pageChanged(page) {
      vm.devices = page;
    }

    function goToDevice($event, sn) {
      $gzAnalytics.eventClick('go to print beat: device');
      let pbUpdatedContext = _.assign({}, {serialNumber: sn}, pbContext);
      $hmPrintBeat.redirect(pbUpdatedContext, $event);
    }

    function goToSite($event) {
      $gzAnalytics.eventClick('go to print beat: site');
      $hmPrintBeat.redirect(pbContext, $event);
    }
  }
}
