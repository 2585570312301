import uiRouter from 'angular-ui-router';
import './asktheexpert.scss';
import { moduleName as AskTheExpertControllerName, default as AskTheExpertController } from './asktheexpert.controller';
import { moduleName as AskTheExpertModalControllerName, default as AskTheExpertModalController } from './asktheexpert_modal.controller';
import { moduleName as ContextServiceName, default as ContextService } from '../util/context.service';
import { moduleName as askTheExpertName, default as AskTheExpert } from './asktheexpert.directive';
import { moduleName as askTheExpertModalName, default as AskTheExpertModal } from './asktheexpert_modal.directive';
import { moduleName as textAreaAutoSizeName, default as TextAreaAutoSize }
  from '../util/textarea_auto_size.directive';
import { moduleName as askexpertellipsisName, default as askexpertellipsis } from './asktheexpert.filter.ellipsis';

export default angular.module('appHelpPanel.ask-the-expert', [
  'gazelle.components.l10n',
  'ngMaterial',
  'ngMessages',
  'gcs.Questions',
  'ngFileUpload',
  'angulartics',
  'gcs.AskaQuestion',
  uiRouter
]).controller(AskTheExpertControllerName, AskTheExpertController)
  .controller(AskTheExpertModalControllerName, AskTheExpertModalController)
  .factory(ContextServiceName, ContextService)
  .directive(askTheExpertName, AskTheExpert)
  .directive(askTheExpertModalName, AskTheExpertModal)
  .directive(textAreaAutoSizeName, TextAreaAutoSize)
  .filter(askexpertellipsisName, askexpertellipsis)
  .name;
