import './indigo-ranking.scss';

import rhino from 'rhino';
import angularTranslate from 'angular-translate';
import gazelleAnalytics from 'gazelle/src/core/analytics/analytics.module';
import homePrintBeat from 'src/components/landing-page/cards/shared/print-beat/print-beat.module';

import * as hmIndigoRankingDirective from './indigo-ranking.directive';

export default angular.module('home.components.widget.hmIndigoRanking', [
  rhino,
  angularTranslate,
  'gazelle.components.go',
  gazelleAnalytics,
  homePrintBeat,
])
  .directive(hmIndigoRankingDirective.name, hmIndigoRankingDirective.default)
  .name;
