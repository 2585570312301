import angularMaterial from 'angular-material';
import angularTranslate from 'angular-translate';

import './threeD-services.scss';
import rnApplication from 'rhino/src/components/application/application.module';
import gazelleAnalytics from 'gazelle/src/core/analytics/analytics.module';

import configTrustedResources from './trust-images.config';
import * as threeDServicesDirective from './threeD-services.directive';

export default angular.module('home.components.widget.threeDServices', [
  'angulartics',
  'gazelle.components.go',
  'ngSanitize',
  angularMaterial,
  angularTranslate,
  rnApplication,
  gazelleAnalytics
])
  .config(configTrustedResources)
  .directive(threeDServicesDirective.name, threeDServicesDirective.default)
  .name;
