export const name = 'AddSectionDialogController';
export default function AddSectionDialogController(
  // Controller angular dependencies
  section,
  $translate,
  $mdDialog
) {
  'ngInject';
  // Constants

  // Variables

  // View Model
  let vm = this;
  vm.loading = true;
  //   VM properties
  //   VM methods
  vm.submit = submit;
  vm.arrayIsSame = arrayIsSame;
  vm.disableSubmit = disableSubmit;
  vm.layoutOptions = [
    {
      displayHtml: '<div class="layout-container"><div class="layout-segment segment50">50%</div><div class="layout-segment segment50">50%</div></div>',
      value: {
        id: '50-50',
        layout: [50,50]
      }
    },
    {
      displayHtml: '<div class="layout-container"><div class="layout-segment segment66">66%</div><div class="layout-segment segment33">33%</div></div>',
      value: {
        id: '66-33',
        layout: [66,33]
      }
    },
    {
      displayHtml: '<div class="layout-container"><div class="layout-segment segment33">33%</div><div class="layout-segment segment66">66%</div></div>',
      value: {
        id: '33-66',
        layout: [33,66]
      }
    },
    {
      displayHtml: '<div class="layout-container"><div class="layout-segment segment33">33%</div><div class="layout-segment segment33">33%</div><div class="layout-segment segment33">33%</div></div>',
      value: {
        id: '33-33-33',
        layout: [33,33,33]
      }
    },
  ];

  vm.activate = activate;
  activate();
  return;

  ///////////////
  // Init
  function activate() {
    vm.validName = false
    if (vm.section) {
      vm.section = _.cloneDeep(vm.section);
      vm.name = vm.section.name ? vm.section.name : null;
      vm.editMode = true;
      if (!vm.name && vm.section.nameKey) {
        vm.name = $translate.instant(vm.section.nameKey);
      }
      let found = vm.layoutOptions.find(o => {
        return arrayIsSame(o.value.layout, vm.section.layout);
      });
      vm.selected = found ? found.value : null;
    }
    else {
      vm.editMode = false;
      vm.selected = vm.layoutOptions[0].value;
      vm.name = '';
      vm.section = {};
    }

    vm.loading = false;
  }

  ///////////////
  // VM methods
  function submit() {
    let section = {
      name: vm.name.trim(),
      layout: vm.selected.layout,
      cards: [],
      isGroup: vm.section.isGroup
    };

    if (vm.section.nameKey && vm.name === $translate.instant(vm.section.nameKey)) {
      delete section.name;
    }

    $mdDialog.hide(section);
  }

  function disableSubmit() {
    if (!vm.validName) {
      return true;
    }

    return false;
  }

  function arrayIsSame(array1, array2) {
    let sectionArray = _.get(vm, 'section.layout', []);
    if (!Array.isArray(array1) || !Array.isArray(array2)) {
      return false;
    }

    if (array1.length !== array2.length) {
      return false;
    }

    let isSame = true;
    for (let i = 0; i < array1.length; i++) {
      if (array1[i] !== array2[i]) {
        isSame = false;
        break;
      }
    }

    return isSame;
  }
  ///////////////
  // Helpers
}
