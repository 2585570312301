import './eula.scss';

import ngMaterial from 'angular-material';
import uiRouter from 'angular-ui-router';
import gazelleAnalytics from 'gazelle/src/core/analytics/analytics.module';
import gazelleSignin from 'gazelle/src/components/signin/signin.module';

import * as eulaController from './eula.controller';
import * as declineDialogController from './decline-dialog.controller';

// define the module in one place and export the module name
export default angular.module('home.components.eula', [
  ngMaterial,
  uiRouter,
  gazelleAnalytics,
  gazelleSignin,
])
  .controller(eulaController.name, eulaController.default)
  .controller(declineDialogController.name, declineDialogController.default)
  .name;
