import angularTranslate from 'angular-translate';
import gazelleDeviceStatus from 'gazelle/src/components/device-status/device-status.module';
import gazelleAnalytics from 'gazelle/src/core/analytics/analytics.module';

import './sharedDevices.scss';

import * as hmSharedDevicesDirective from './sharedDevices.directive';

export default angular.module('home.components.widget.sharedDevices', [
  angularTranslate,
  gazelleDeviceStatus,
  gazelleAnalytics
])
  .directive(hmSharedDevicesDirective.name, hmSharedDevicesDirective.default)
  .name;
