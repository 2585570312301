const activeJobs = require('./test/active_jobs_summary_api_response');

export const name = 'hmJobHistory';
export default function hmJobHistory() {
  //noinspection BadExpressionStatementJS
  'ngInject';

  let directive = {
    restrict: 'EA',
    scope: {
      gzCard: '=?',
    },
    template: require('./job-history.jade'),
    controller: jobHistoryController,
    controllerAs: 'vm',
    bindToController: true
  };

  return directive;

  /** @ngInject */
  function jobHistoryController(
    rnHttp,
    $translate,
    $window,
    $q,
    $interval,
    $scope
  ) {

    let pollerPromise;
    let vm = this;
    vm.loading = true;
    vm.jobs = [];
    vm.actionUrl = null;
    vm.getStatusIcon = getStatusIcon;
    vm.goToJobHistory = goToJobHistory;
    vm.isJobComplete = isJobComplete;
    vm.getJobPercentage = getJobPercentage;
    vm.getDeviceIcon = getDeviceIcon;
    vm.getJobName = getJobName;
    vm.selectRow = selectRow;

    const LAYOUT_ACTIVE_JOBS_SUMMARY = 'ACTIVE_JOBS_SUMMARY';
    const LAYOUT_JOBS_SUMMARY = 'JOBS_SUMMARY';
    vm.LAYOUT_ACTIVE_JOBS_SUMMARY = LAYOUT_ACTIVE_JOBS_SUMMARY;
    vm.LAYOUT_JOBS_SUMMARY = LAYOUT_JOBS_SUMMARY;

    vm.$onInit = onInit;
    return;

    function onInit() {
      vm.gzCard.API.config.setSiteCallback(siteChanged);
      let promises = [];
      promises.push(getJobsHistory());

      $q.all(promises).then(() => {
        vm.gzCard.API.config.setAppLink(vm.actionUrl);
        pollerPromise = $interval(getJobsHistory, 120 * 1000);
        $scope.$on('$destroy', () => {
          if (pollerPromise) { $interval.cancel(pollerPromise); }
        });
      })
        .finally(() => {
          vm.loading = false;
        });
    }

    // This is used for the job-summary layout of the job-history card
    function getStatusIcon(status) {
      if(status === "succeeded") {
        return "https://cdn.printos.com/images/general/material/check_circle_filled.svg";
      }

      return "https://cdn.printos.com/images/general/material/cancel_filled.svg";
    }

    function isJobComplete(status) {
      const { name } = status;
      return name === 'JobCompletedSucceeded' || name === 'JobCooled';
    }

    function getJobPercentage(status) {
      const { progress } = status;
      return progress || 0;
    }

    function getDeviceIcon(type) {
      switch(type) {
        case 'printer':
          return 'https://cdn.printos.com/images/home/3d/printer.svg';
        case 'processing_station':
          return 'https://cdn.printos.com/images/home/3d/processing_station.svg';
        case 'build_unit':
          return 'https://cdn.printos.com/images/home/3d/build_unit.svg';
        case 'cooling_unit':
          return 'https://cdn.printos.com/images/home/3d/cooling_unit.svg';
        case 'auto_unpacker_station':
          return 'https://cdn.printos.com/images/home/3d/auto_unpacker_station.svg';
        default:
          return 'https://cdn.printos.com/images/home/3d/printer.svg';
      }
    }

    function getDeviceNames() {
      let devices = vm.gzCard.API.account.getDevicesByType("ThreeDPrinter");

      _.forEach(vm.jobs, job => {
        let device = _.find(devices, { deviceId: job.aaa_device_id });
        let deviceName = _.get(device, 'name', '');
        _.set(job, 'internal.deviceName', deviceName);
      });
    }

    function getJobsHistory() {
      let config = {
        params: {
          site: _.get(vm.gzCard.API.currentSite.getSite(), 'siteId', -1),
        },
      };

      return rnHttp.get('3d_sms_portal', '/jobs/cards', config)
        .then(resp => {
          vm.jobs = _.get(resp, 'data.cards[0].summary.jobs', []);
          vm.actionUrl = _.get(resp, 'data.cards[0].action.destination','');
          vm.layout = _.get(resp, 'data.cards[0].layout', LAYOUT_JOBS_SUMMARY).toUpperCase();
          switch(vm.layout) {
            case LAYOUT_ACTIVE_JOBS_SUMMARY:
              vm.subtitle = $translate.instant('home.home.ThreeDActive_Job_History');
              vm.jobs = vm.jobs.sort((a, b) => {
                return new Date(b.start_date).getTime() - new Date(a.start_date).getTime();
              });
              break;
            case LAYOUT_JOBS_SUMMARY:
              vm.subtitle = $translate.instant('home.home.ThreeDJob_History');
              vm.jobs = vm.jobs.sort((a, b) => {
                return new Date(b.start_date).getTime() - new Date(a.start_date).getTime();
              });
              getDeviceNames();
              break;
          }

          return Promise.resolve();
        })
        .catch(errorResponse => {
          //resetting jobs value to handle API error cases
          vm.jobs = undefined;
          return $q.resolve();
        });
    }

    function siteChanged() {
      getJobsHistory();
    }

    function goToJobHistory($event) {
      $event.stopPropagation();
      if ($event.ctrlKey) {
        $window.open(vm.actionUrl, '_blank');
      } else {
        $window.location.href = vm.actionUrl;
      }
    }

    function getJobName(job) {
      const id = job.job_id;
      return _.get(job, 'name', id);
    }

    function selectRow(job) {
      if (job.job_id === vm.selectedId) {
        delete vm.selectedId;
        return;
      }

      vm.selectedId = job.job_id;
    }
  }
}
