import rhino from 'rhino';
import angularTranslate from 'angular-translate';

import './job-history.scss';
import * as hmJobHistoryDirective from './job-history.directive';

export default angular.module('home.components.widget.jobHistory', [
  rhino,
  angularTranslate,
])
  .directive(hmJobHistoryDirective.name, hmJobHistoryDirective.default)
  .name;
