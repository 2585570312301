export const name = 'hmAppBolt';
export default function hmAppBolt($log, $gzGo) {
  //noinspection BadExpressionStatementJS
  'ngInject';

  let directive = {
    restrict: 'EA',
    transclude: true,
    scope: {
    },
    template: require('./app-bolt.jade'),
    controller: appBoltController,
    controllerAs: 'vm',
    bindToController: true,
  };

  return directive;

  /** @ngInject */
  function appBoltController($translate, $gzAnalytics) {
    let vm = this;
    vm.goToBoltApp = goToBoltApp;

    vm.$onInit = onInit;
    return;

    function onInit() {
    }

    function goToBoltApp($event){
      $event.stopPropagation();
      $gzGo.toContext('bolt');
      $gzAnalytics.eventClick('Go to Bolt(go3d) app');
    }
  }
}
