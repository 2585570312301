export const name = 'hmMaintenance';
export default function hmMaintenance() {
  //noinspection BadExpressionStatementJS
  'ngInject';

  let directive = {
    restrict: 'EA',
    scope: {
      gzCard: '=?',
    },
    template: require('./maintenance.jade'),
    controller: maintenanceController,
    controllerAs: 'vm',
    bindToController: true
  };

  return directive;

  /** @ngInject */
  function maintenanceController(
    rnHttp,
    $translate,
    $window,
    $q,
    $log
  ) {

    let vm = this;
    vm.loading = true;
    vm.tasks = [];
    vm.actionUrl = null;
    vm.goToMaintenance = goToMaintenance;

    vm.$onInit = onInit;
    return;

    function onInit() {
      vm.gzCard.API.config.setSiteCallback(siteChanged);
      let promises = [];
      promises.push(getMaintenanceTasks());

      $q.all(promises).finally(() => {
        getDeviceNames();
        vm.gzCard.API.config.setAppLink(vm.actionUrl);
      })
        .finally(() => {
          vm.loading = false;
        });
    }

    function getDeviceNames() {
      let devices = vm.gzCard.API.account.getDevicesByType("ThreeDPrinter");
      _.forEach(vm.tasks, task => {
        let device = _.find(devices, { deviceId: task.device_id });
        if (device) {
          _.set(task, 'internal.deviceName', device.name);
        }
      });
    }

    function getMaintenanceTasks(){
      let config = {
        params: {
          site: _.get(vm.gzCard.API.currentSite.getSite(), 'siteId', -1),
        },
      };
      return rnHttp.get('3d_sms_portal', '/devices/maintenance/cards', config)
        .then(res => {
          $log.debug("sc-widget-getMaintenance()");
          let maintenanceTasks = _.get(res.data, 'cards[0].summary.tasks', []);
          vm.actionUrl = _.get(res.data, 'cards[0].action.destination','');

          vm.tasks = maintenanceTasks.sort((a, b) => {
            return new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime();
          });
        })
        .catch(errorResponse => {
          $log.debug("fail to get maintenance widget data:", errorResponse);
          //Assigning to undefined to differentiate API failures
          vm.tasks = undefined;
          return $q.resolve();
        });
    }

    function siteChanged() {
      getMaintenanceTasks();
    }

    function goToMaintenance($event) {
      $event.stopPropagation();
      if ($event.ctrlKey) {
        $window.open(vm.actionUrl, '_blank');
      } else {
        $window.location.href = vm.actionUrl;
      }
    }
  }
}
