import * as $hmCards from './cards.service';
import angularTranslate from 'angular-translate';

import rnApplication from 'rhino/src/components/application/application.module';

export default angular.module('home.components.cards', [
  rnApplication,
  angularTranslate
])
  .factory($hmCards.name, $hmCards.default)
  .name;
