import mixin from '../util/mixin.js';
import Composition from '../util/composition';

const Mixin = mixin(Composition);

export const moduleName = 'FeedbackModalController';
export default class FeedbackModalController extends Mixin {
  /** @ngInject */
  constructor($mdDialog, $log, $filter, $state, $scope, $mdToast, $rootScope) {
    super();
    this.bindDependencies(
      { $mdDialog, $log, $filter, $state, $scope, $mdToast, $rootScope });
    this.$state = $state;
    this.activate();
  }

  activate() {
    this.isSending = false;
  }

  close() {
    this.$state.go('contact-hp');
    this.$mdDialog.cancel();
  }

  goToMySupportCases() {
    this.$state.go('support-case');
    this.$mdDialog.cancel();
  }

  static factory($mdDialog, $log, $filter, $state, $scope, $mdToast, $rootScope) {
    'ngInject';
    return new FeedbackModalController($mdDialog, $log, $filter, $state, $scope, $mdToast, $rootScope);
  }


}
