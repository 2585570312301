export const name = 'EditHPAccountDialogController';
export default function EditHPAccountDialogController(
  // Controller angular dependencies
  url,
  $sce,
  $mdDialog
) {
  'ngInject';
  // Constants

  // Variables

  // View Model
  let vm = this;
  //   VM properties
  vm.loading = true;
  //   VM methods
  vm.cancel = cancel;
  vm.close = close;

  vm.activate = activate;
  activate();
  return;

  ///////////////
  // Init
  function activate() {
    vm.loading = false;
    vm.trustedSrc = $sce.trustAsResourceUrl(vm.url);
  }

  ///////////////
  // VM methods

  function cancel() {
    $mdDialog.cancel();
  }

  function close() {
    $mdDialog.hide();
  }

  ///////////////
  // Helpers
}
