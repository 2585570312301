import BaseController from '../util/base.controller';
import BrowserHelper from '../util/browser_helper';

const LOCALIZATION = 'translate';
const SUCCESS_TITLE = 'uxf.uxf.Success_Title';
const SUCCESS_MESSAGE = 'uxf.uxf.Feedback_Send_Success';
const ERROR_TITLE = 'uxf.uxf.Error_Title';
const ERROR_MESSAGE = 'uxf.uxf.Feedback_Send_Error';
const ERROR_CONNECTION_MESSAGE = 'uxf.uxf.Feedback_Send_Error';
const OK = 'uxf.uxf.OK';
const FEEDBACK_AREA = 12;
const DEFAULT_PAGE_NAME = '';
const EVENT_PREFERENCES_UPDATED = 'rnPreferencesUpdated';
const HELP_VIEW_CHANGED = 'Help panel view changed';
const LABEL_EMAIL = 'uxf.uxf.Email';
const LABEL_PHONE = 'uxf.uxf.Phone';
const LABEL_PHONE_DISABLED = 'uxf.uxf.PhoneDisabled';

export const moduleName = 'FeedbackController';
export default class FeedbackController extends BaseController {
  /** @ngInject */
  constructor(feedbackService, $mdDialog, $filter, $scope, $analytics, ContextService, $rootScope, $window, Upload, $log, $state, $stateParams, $q) {
    super({ feedbackService, $mdDialog, $filter, $scope, $analytics, ContextService, $rootScope, $window, Upload, $log, $state, $stateParams, $q });
    this.isSending = false;
    this.isNotEnglish = false;
    this.hasCountry = false;
    this.phoneContactMeEnabled = false;
    this.activate($scope);
    this.browserHelper = BrowserHelper.factory($log, $window);
    this.$window = $window;
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.$q = $q;
    this.selectedCountry = null;
    this.validApplication = false;
  }

  activate($scope) {
    this.isLoading = true;
    this.validIsoCode = false;
    this.validTitle = false;
    this.validFeedback = false;
    this.parseParams();
    this.$rootScope.$on(EVENT_PREFERENCES_UPDATED, () => {
      this.getLanguage();
    });

    this.$rootScope.$on(HELP_VIEW_CHANGED, () => {
      this.getUserCountry();
      //this.getCountries();
    });

    this.$scope.applicationList = this.$window.$printos.v1.app.getApps()
      .filter(a => {
        return !(a.type === 'external' || a.type === 'internal' || a.type === 'group' || a.type === 'partner' || !_.get(a, 'allowApplicationSwitcher', true));
      })
      .map(a => {
        return {
          display: a.loc.name,
          value: a
        }
      })
      .sort((a,b) => {
        return a.display.toLocaleLowerCase().localeCompare(b.display.toLocaleLowerCase());
      });
    
    let appIdParam = this.$stateParams.appId || _.get(this.$stateParams, 'data.appId');
    if (appIdParam) {
      const found = this.$scope.applicationList.find(a => {
        return a.value.appId === appIdParam;
      });
      this.selectedApplication = this.$scope.selectedApplication = found.value;
    }

    let promises = [];
    promises.push(this.getLanguage());
    promises.push(this.getUserCountry());
    promises.push(this.getCountries());
    promises.push(this.feedbackService.getUrlSend().then(url => {
      this.urlSend = url;
    }));

    this.$q.all(promises).then(() => {
      this.hpidSettingsUrl = window.$printos.v1.client.getRegistryValue('hpidAccountUrl');
      $scope.formInfo = { };
      $scope.data = {};
      $scope.breadcrumbs = [
        {
          state: 'dashboard',
          titleKey: 'home.home.Home_title'
        },
        {
          state: 'settings',
          titleKey: 'home.home.My_Settings'
        },
        {
          state: 'contact-hp',
          titleKey: 'home.home.Contact_Hp'
        },
        {
          state: 'feedback',
          titleKey: 'uxf.uxf.View_Feedback'
        },
      ];
      this.currentPageName = this.getCurrentPageName();//this.ContextService.currentPageName();
      this.currentApplicationName = this.ContextService.currentAppName();
      this.clearForm();
    }).finally(() => {
      this.isLoading = false;
    });
  }

  changeAllowToContact() {
      this.$scope.formInfo.preferredContact = this.preferredContactList[0].value;
  }

  cancel() {
    this.clearForm();
    //this.$scope.sendFeedbackForm.$setPristine();
    //this.$scope.sendFeedbackForm.$setUntouched();
  }

  disableSend() {
    if (!this.validIsoCode || !this.validTitle || !this.validFeedback || !this.validApplication || this.isSending) {
      return true;
    }

    return false;
  }

  sendFeedback() {
    this.isSending = true;
    this.feedbackService.getUserInfo().then(userInfo => {
      let data = {
        case: this.Upload.jsonBlob(this.prepareFormInfoToSend(this.$scope, userInfo.data))
      };

      this.Upload.upload({
        url: this.urlSend,
        data: data
      }).then(response => {
        this.showSuccessfulMessageSent();
        this.sendFormValuesToGoogleAnalytics('subject', this.$scope.formInfo.title);
        this.sendFormValuesToGoogleAnalytics('country', _.find(this.$scope.countryList, country => {
          return country.value && country.value.isoCode === this.$scope.selectedCountry.isoCode;
        }).value.englishName);
        this.clearForm();
        //this.$scope.sendFeedbackForm.$setPristine();
        //this.$scope.sendFeedbackForm.$setUntouched();
        const params = {
          action: this.GA_ACTION_SUBMIT, application: this.ContextService.currentAppName(),
          page: this.currentPageName, type: 'Feedback to HP', title: 'Feedback to HP'
        };
        this.analytics(params);
      }, error => {
        this.handleSendQuestionError(error);
      }).finally(() => this.isSending = false);
    }, error => {
      this.handleSendQuestionError(error);
    });
  }

  sendFormValuesToGoogleAnalytics(field, value) {
    const eventTrack = 'Feedback: ' + field;
    const event = {
      category: 'help',
      action: 'Feedback: ' + field,
      label: value
    };

    this.$analytics.eventTrack(eventTrack, event);
  }

  handleSendQuestionError(error) {
    let err = !!error && !!error.smsError && !!error.smsError.message ? error.smsError.message :
      this.$filter(LOCALIZATION)(ERROR_MESSAGE);
    if (!this.$window.navigator.onLine) { // Verify connection previously
      err += ' ' + this.$filter(LOCALIZATION)(ERROR_CONNECTION_MESSAGE);
    }
    this.errorMessageKey = err;
    let params = {action: this.GA_ACTION_VIEW, application: this.ContextService.currentAppName(),
      page: this.currentPageName, type: 'Feedback to HP Error', title: 'Feedback to HP Error', error: error};
    this.analytics(params);
  }

  isFormEmpty() {
    const subject = this.$scope.formInfo.title;
    const description = this.$scope.formInfo.feedback;

    const isSubjectEmpty = subject.trim().length === 0;
    const isDescriptionEmpty = description.trim().length === 0;

    return  isSubjectEmpty || isDescriptionEmpty;
  }

  prepareFormInfoToSend($scope, userInfo) {
    const browserInfo = this.browserHelper.getBrowserInfo();
    const osInfo = this.browserHelper.getOSInfo();

    let preferredContactParam = $scope.formInfo.preferredContact;
    if (!$scope.formInfo.returnFeedback) {
      preferredContactParam = {id: 'NO_CONTACT'};
    }

    const sendParams = {
      caseSource: "WEB",
      subject: $scope.formInfo.title,
      type: "FEEDBACK_TO_HP",
      preferredContactMethod: {
        contactMethodType: preferredContactParam.id
      },
      userSystemInfo: {browser: browserInfo.objbrowserName, browserVersion: browserInfo.objfullVersion, osVersion: osInfo},
      userEntries: [
        {
          userEntryType: "DESCRIPTION",
          value: $scope.formInfo.feedback
        },
        {
          userEntryType: "APPLICATION",
          value: $scope.selectedApplication.loc.name
        },
        {
          userEntryType: "FEELING",
          value: $scope.formInfo.sentiment
        },
        {
          userEntryType: "ALLOW_CONTACT",
          value: $scope.formInfo.returnFeedback
        },
        {
          userEntryType: "COUNTRY",
          value: $scope.selectedCountry.isoCode
        },
      ]
    };

    return sendParams;
  }

  clearForm() {
    this.errorMessageKey = '';
    let $scope = this.$scope;
    $scope.formInfo = { title: '', feedback: '', returnFeedback: false, sentiment: 0, preferredContact: this.preferredContactList[0].value.id};

    // Known issue, md-maxlength does not update on model change
    // https://github.com/angular/material/issues/1870
    this.oneHundredMaxLength = 110;
    this.$window.setTimeout(() => {
      this.oneHundredMaxLength = 100;
    }, 0);
    this.thirtyThousandMaxLength = 30010;
    this.$window.setTimeout(() => {
      this.thirtyThousandMaxLength = 30000;
    }, 0);
  }

  showErrorMessage(message) {
    let errorTitle = this.$filter(LOCALIZATION)(ERROR_TITLE);
    this.showMessage(errorTitle, message, this.$state, this.myParams.data);
  }

  showSuccessMessage(message) {
    let successTitle = this.$filter(LOCALIZATION)(SUCCESS_TITLE);
    this.showMessage(successTitle, message, this.$state, this.myParams.data);
  }

  showMessage(title, message, state, data) {
    let ok = this.$filter(LOCALIZATION)(OK);
    this.$mdDialog.show(
      this.$mdDialog.alert()
        .parent(angular.element(document.body))
        .title(title)
        .textContent(message)
        .ariaLabel(title)
        .ok(ok)
    ).then(function() {
      state.go('contact-hp', {data: data});
    });
  }

  showSuccessfulMessageSent() {
    const modal = this.generateModal(require('./feedback_modal.jade'), 'FeedbackModalController');
    this.$mdDialog.show(modal).then(() => {
      this.$mdDialog.hide();
    }).catch(() => {}).finally(() => {this.$mdDialog.cancel();});
  }

  generateModal(template, controllerName) {
    return {
      parent: angular.element(document.body),
      template,
      controller: controllerName,
      bindToController: true,
      controllerAs: 'vm',
    };
  }

  getCurrentPageName() {
    if (!!this.$state && !!this.$state.current) {
      return this.$state.current.name;
    }
    else {
      return DEFAULT_PAGE_NAME;
    }
  }

  getLanguage() {
    return this.feedbackService.getPreferenceLanguage().then(response => {
      this.language = response;
      let englishLocales = ['en-US','en-GB'];
      this.isNotEnglish =  englishLocales.indexOf(this.language.value) < 0;

      return this.feedbackService.getUserInfo().then(userInfo => {
        if (!this.isNotEnglish && userInfo.data.primaryPhone) {
          this.loadContactPreferredMethodWithPhone();
        } else {
          this.loadContactPreferredMethodWithoutPhone();
        }
      });

    });
  }

  loadContactPreferredMethodWithPhone() {
    let contactName = `${this.$filter(LOCALIZATION)(LABEL_EMAIL)}`;
    this.preferredContactList = [{display: contactName , value: {id: 'PROFILE_EMAIL', name: contactName}}];
    contactName = `${this.$filter(LOCALIZATION)(LABEL_PHONE)}`;
    this.preferredContactList.push({display: contactName, value: {id: 'PROFILE_PHONE', name: contactName}});
    this.phoneContactMeEnabled = true;
  }

  loadContactPreferredMethodWithoutPhone() {
    let contactName = `${this.$filter(LOCALIZATION)(LABEL_EMAIL)}`;
    this.preferredContactList = [{display: contactName , value: {id: 'PROFILE_EMAIL', name: contactName}}];
    this.phoneContactMeEnabled = false;
  }


  getUserCountry() {
    return this.feedbackService.getUserInfo().then(userInfo => {
      if(userInfo.data && userInfo.data.primaryAddress && userInfo.data.primaryAddress.country){
        this.hasCountry = userInfo.data.primaryAddress.hasOwnProperty('country');
        this.selectedCountry = {isoCode: userInfo.data.primaryAddress.country};
        this.$scope.selectedCountry = this.selectedCountry;
        if(this.hasCountry) {
          this.validIsoCode = true;
        }
      }
    });
  }

  getCountries() {
    return this.feedbackService.getCountries()
      .then(countries => {
        if (countries) {
          this.$scope.countryList = [];
          countries.forEach( country => {
            this.$scope.countryList.push({
              display: country.localizedName,
              value: country
            })
          });

          this.$scope.countryList.sort( (a, b) =>
            (a.display.toLocaleLowerCase() < b.display.toLocaleLowerCase()) ? -1 : (a.display.toLocaleLowerCase() > b.display.toLocaleLowerCase()) ? 1 : 0);
        }
        return this.$q.resolve();
      });
  }

  countryChanged(){
    this.$scope.selectedCountry = this.selectedCountry;
  }

  applicationChanged() {
    this.$scope.selectedApplication = this.selectedApplication;
  }

  static factory(feedbackService, $mdDialog, $filter, $scope, $analytics, ContextService, $rootScope, $window, Upload, $log, $state, $stateParams, $q) {
    'ngInject';
    return new FeedbackController(feedbackService, $mdDialog, $filter, $scope, $analytics, ContextService, $rootScope, $window, Upload, $log, $state, $stateParams, $q);
  }

  parseParams() {
    this.myParams(this.$stateParams.data);
  }

  myParams(data) {
    this.myParams = {};
    if (data) {
      this.myParams.data = data;
    }
    else {
      return this.myParams.data;
    }
  }

  goToContactHp($event) {
    $event.stopPropagation();
    let data = this.myParams.data;
    this.$state.go('contact-hp', {data: data});
  }
}
