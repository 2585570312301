import {
  BUSINESS_UNIT,
} from 'rhino/src/components/start/start.service';

export const name = 'hmSmartUptimeKit';
export default function hmSmartUptimeKit(
  // Directive angular dependencies
) {
  'ngInject';

  let directive = {
    restrict: 'EA',
    scope: {
      gzCard: '=',
    },
    template: require('./smart-uptime-kit.jade'),
    link: smartUptimeKitLink,
    controller: smartUptimeKitController,
    controllerAs: 'vm',
    bindToController: true,
  };

  return directive;

  //////////////////////

  function smartUptimeKitLink(scope, element, attrs) {
  }

  /** @ngInject */
  function smartUptimeKitController(
    // Controller angular dependencies
    rnHttp,
    rnClient,
    $q,
    $gzGo
  ) {
    // Constants
    const SUTK_SERVICE = 'ppc';
    const buForPath = {
      "pwp": "PWP",
      "lfi": "Scitex",
      "3d": "ThreeD"
    };

    // Variables
    let clientConfig = null;
    let bu = null;
    let kits = {};
    let myPages = [];
    let pageDisplay = [];

    // View Model
    let vm = this;
    vm.hasData = true;

    vm.$onInit = onInit;
    vm.searchAddPart = searchAddPart;

    return;

    function onInit() {
      vm.loading = true;

      bu = _.get(vm.gzCard.settings, 'businessUnit');

      getClientConfig()
        .then(config => {
          clientConfig = config;
          return getKits();
        })
        .then(response => {

          // Init kits
          kits = response.data;

          // This API usually returns duplicates so let's remove and also just return the appropriate BU kit types
          let kitTypes = _.uniq(kits.kitTypes, 'kitTypeId');
          kits.kitTypes = _.find(kitTypes, type => {
            return type.kitTypeName === bu;
          });

          if (!kits.kitTypes) {
            vm.hasData = false;
            return;
          }

          return getSummary(kits.kitTypes.kitTypeId);
        })
        .then(() => {
          if (vm.hasData) {
            initPages();
            pageChanged(myPages[0]);
            vm.gzCard.API.config.setPages(myPages, pageChanged, pageDisplay);
          }
        })
        .catch(error => {
          //vm.errorKey = 'home.home.SUTK_Initialization_Error';
          vm.hasData = false;
        })
        .finally(() => {
          setLink();
          vm.loading = false;
        });
    }

    function setLink() {
      //create new link
      const bu = _.get(vm.gzCard.settings, 'businessUnit');
      vm.gzCard.API.actions.setAppLink(vm.gzCard, "/pwi-sc/#/support-dashboard?bu=" + buForPath[bu]);
    }

    function pageChanged(page) {
      vm.page = page;
    }

    function getClientConfig() {
      return rnClient.getConfig();
    }

    function initConfig() {
      let config = {
        headers: {
          'printOSOrganizationID': _.get(clientConfig, 'context.id'),
          'If-Modified-Since': undefined,
          'Cache-Control': undefined
        }
      };

      return config;
    }

    function getKits() {
      let config = initConfig();

      return rnHttp.get(SUTK_SERVICE, 'api/sutk-service/v1/kits', config);
    }

    function getSummary(kitTypeId) {
      let config = initConfig();
      config.headers.kitTypeId = String(kitTypeId);

      return rnHttp.get(SUTK_SERVICE, 'api/sutk-service/v1/summary', config)
        .then(response => {
          kits.kitTypes.summaries = response.data;
        });
    }

    function initPages() {
      _.forEach(kits.kitTypes.summaries.utkLocationSummary, summary => {

        // Round to nearest int
        summary.inventoryFitRate = Math.round(summary.inventoryFitRate);

        myPages.push(summary);
        pageDisplay.push(summary.utkLocationName);
      });
    }

    function searchAddPart($event) {
      $event.stopPropagation();

      // Due to Angular constraints Go conexts only support a single parameter so the one we pass will contain the rest
      // of the required path

      let path = buForPath[bu] + "/" + vm.page.utkLocationId + "/parts";

      $gzGo.toContext('sutk-search', path);
    }
  }
}
