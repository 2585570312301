export const name = 'WowController';
export default function WowController(
  $q,
  rnHttp,
  $mdDialog,
  $translate,
  $state,
  $gzAnalytics
) {
 // noinspection BadExpressionStatementJS
  'ngInject';

  const SUPPORTAPP_SERVICE = "supportapp_service";
  const WOW_SURVEY_INFORMATION = "wow/csv";
  let features = [];
  let vm = this;

  vm.payload = {
    generalInformation:{
      hpRepresentativeName:{id: 'hpRepName', text: 'HP Representative Name', value: '',},
      hpRepresentativeEmail:{id: 'hpRepEmail', text: 'HP Representative Email', value: '',},
      customerName:{id: 'customerName', text: 'Customer Name', value: '',},
      customerEmail:{id: 'customerEmail',text: 'Customer Email', value: '',},
      companyName:{id: 'company',text: 'Company Name', value: '',},
      country:{id: 'country', text: 'Country', value: '',},
      numberOfEmployees:{id: 'numberOfEmployees', text: 'Number of Employees', value: '',},
      indigoCustomer:{id: 'isIndigo', text: 'Are you an Indigo Customer?', value: '',},
      customerSince:{id: 'customerSince', text: 'If yes, since when?', value: '',},
      comments: {id: 'comments', text: "Comments", value: ''},
    },
    productionFloor: {
      productionInfo: {
        averageJobsPerDay:{id: 'averageJobsPerDay', text: 'What is your total average number of jobs per day?', value: '',},
        amountOfDigitalPresses:{id: 'amountOfDigitalPresses', text: 'How many Digital Presses do you have?', value: '',},
        amountOfAnaloguePresses:{id: 'amountOfAnaloguePresses', text: 'How many Analogue Presses do you have?', value: '',},
        percentOfIncomeFromDigitalProductionVsAnalogue:{id: 'percentIncomeDigitalvsAnalogue', text: 'What approximate percentage of your income is coming from digital production (vs Analogue)?', value: '',},
        jobsPerDayOnAnaloguePresses:{id: 'jobsPerDayOnAnaloguePresses', text: 'How many jobs per day are printed on your Analogue Presses?', value: '',},
        averageHourlyRatePrePressPlusPressOperators:{id: 'averagePrePressPlusOperatorsHourly', text: 'What is your Average hourly rate (Pre press and Press operators)?', value: '',},
        typicalSLA:{id: 'typicalSLA', text: 'What is your typical SLA For a job (business days)?', value: '',},
        percentOfProductionManagedByPFManagementSystem:{id: 'percentProductionManagedByFloorManagementSystem', text: 'What percentage of your production is managed by your production floor management system?', value: '',},
      },
      templatedCustomJobsPerDay: {
        numberOfTemplatedJobs:{id: 'numberOfTemplatedJobs', text: 'number of templated jobs', value: '',},
        numberOfCustomJobs:{id: 'numberOfCustomJobs', text: 'number of custom jobs', value: '',},
        manHoursPerDay:{id: 'manHoursPerDay', text: 'man hours per day', value: '',},
      },
    },
    productionProcesses:{
      estimatedAndQuoting:{
        numberOfAutomatedJobsPerDay:{id: 'estimatedAndQuotingAutomatedJobsDaily', text: 'Automated job per day', value: '',},
        numberOfManualJobsPerDay:{id: 'estimatedAndQuotingManualJobsDaily', text: 'Manual jobs per day', value: '',},
        manHoursPerDay:{id: 'estimatedAndQuotingManHoursDaily', text: 'man hours per day', value: '',},
        automationImportance:{id: 'estimatedAndQuotingAutomationImportance', text: 'Automation Importance', value: '',},
        automatedSatisfaction:{id: 'estimatedAndQuotingAutomationSatisfaction', text: 'Automation Satisfaction', value: '',},
      },
      receivingJobs:{
        numberOfAutomatedJobsPerDay:{id: 'receivingJobsAutomatedJobsDaily', text: 'Automated job per day', value: '',},
        numberOfManualJobsPerDay:{id: 'receivingJobsManualJobsDaily', text: 'Manual jobs per day', value: '',},
        manHoursPerDay:{id: 'receivingJobsManHoursDaily', text: 'man hours per day', value: '',},
        automationImportance:{id: 'receivingJobsAutomationImportance', text: 'Automation Importance', value: '',},
        automatedSatisfaction:{id: 'receivingJobsAutomationSatisfaction', text: 'Automation Satisfaction', value: '',},
      },
      preparing:{
        numberOfAutomatedJobsPerDay:{id: 'preparingAutomatedJobsDaily', text: 'Automated job per day', value: '',},
        numberOfManualJobsPerDay:{id: 'preparingManualJobsDaily', text: 'Manual jobs per day', value: '',},
        manHoursPerDay:{id: 'preparingManHoursDaily', text: 'man hours per day', value: '',},
        automationImportance:{id: 'preparingAutomationImportance', text: 'Automation Importance', value: '',},
        automatedSatisfaction:{id: 'preparingAutomationSatisfaction', text: 'Automation Satisfaction', value: '',},
      },
      cuttingAndCreasing:{
        numberOfAutomatedJobsPerDay:{id: 'cuttingAndCreasingAutomatedJobsDaily', text: 'Automated job per day', value: '',},
        numberOfManualJobsPerDay:{id: 'cuttingAndCreasingManualJobsDaily', text: 'Manual jobs per day', value: '',},
        manHoursPerDay:{id: 'cuttingAndCreasingManHoursDaily', text: 'man hours per day', value: '',},
        automationImportance:{id: 'cuttingAndCreasingAutomationImportance', text: 'Automation Importance', value: '',},
        automatedSatisfaction:{id: 'cuttingAndCreasingAutomationSatisfaction', text: 'Automation Satisfaction', value: '',},
      },
      embellishments:{
        numberOfAutomatedJobsPerDay:{id: 'embellishmentsAutomatedJobsDaily', text: 'Automated job per day', value: '',},
        numberOfManualJobsPerDay:{id: 'embellishmentsManualJobsDaily', text: 'Manual jobs per day', value: '',},
        manHoursPerDay:{id: 'embellishmentsManHoursDaily', text: 'man hours per day', value: '',},
        automationImportance:{id: 'embellishmentsAutomationImportance', text: 'Automation Importance', value: '',},
        automatedSatisfaction:{id: 'embellishmentsAutomationSatisfaction', text: 'Automation Satisfaction', value: '',},
      },
      bindingAndFolding:{
        numberOfAutomatedJobsPerDay:{id: 'bindingAndFoldingAutomatedJobsDaily', text: 'Automated job per day', value: '',},
        numberOfManualJobsPerDay:{id: 'bindingAndFoldingManualJobsDaily', text: 'Manual jobs per day', value: '',},
        manHoursPerDay:{id: 'bindingAndFoldingManHoursDaily', text: 'man hours per day', value: '',},
        automationImportance:{id: 'bindingAndFoldingAutomationImportance', text: 'Automation Importance', value: '',},
        automatedSatisfaction:{id: 'bindingAndFoldingAutomationSatisfaction', text: 'Automation Satisfaction', value: '',},
      },
      dispatchingAndShipping:{
        numberOfAutomatedJobsPerDay:{id: 'dispatchingAndShippingAutomatedJobsDaily', text: 'Automated job per day', value: '',},
        numberOfManualJobsPerDay:{id: 'dispatchingShippingManualJobsDaily', text: 'Manual jobs per day', value: '',},
        manHoursPerDay:{id: 'dispatchingAndShippingManHoursDaily', text: 'man hours per day', value: '',},
        automationImportance:{id: 'dispatchingAndShippingAutomationImportance', text: 'Automation Importance', value: '',},
        automatedSatisfaction:{id: 'dispatchingAndShippingAutomationSatisfaction', text: 'Automation Satisfaction', value: '',},
      },
      orderTracking:{
        numberOfAutomatedJobsPerDay:{id: 'orderTrackingAutomatedJobsDaily', text: 'Automated job per day', value: '',},
        numberOfManualJobsPerDay:{id: 'orderTrackingManualJobsDaily', text: 'Manual jobs per day', value: '',},
        manHoursPerDay:{id: 'orderTrackingManHoursDaily', text: 'man hours per day', value: '',},
        automationImportance:{id: 'orderTrackingAutomationImportance', text: 'Automation Importance', value: '',},
        automatedSatisfaction:{id: 'orderTrackingAutomationSatisfaction', text: 'Automation Satisfaction', value: '',},
      },
      reprints:{
        numberOfAutomatedJobsPerDay:{id: 'reprintsAutomatedJobsDaily', text: 'Automated job per day', value: '',},
        numberOfManualJobsPerDay:{id: 'reprintsManualJobsDaily', text: 'Manual jobs per day', value: '',},
        manHoursPerDay:{id: 'reprintsManHoursDaily', text: 'man hours per day', value: '',},
        automationImportance:{id: 'reprintsAutomationImportance', text: 'Automation Importance', value: '',},
        automatedSatisfaction:{id: 'reprintsAutomationSatisfaction', text: 'Automation Satisfaction', value: '',},
      },

    },
  };
  vm.textBoxErrors = [];
  vm.valid = {
    checkBox: true,
    textBox: true,
    textArea: true,
    color: true,
    hpRepName: true,
    hpRepEmail: true,
    customerName: true,
    customerEmail: true,
    company: true,
    country: true,
    numberOfEmployees: true,
    isIndigo: true,
    customerSince: true,
    comments: true,
    averageJobsPerDay: true,
    amountOfDigitalPresses: true,
    amountOfAnaloguePresses: true,
    percentIncomeDigitalvsAnalogue: true,
    jobsPerDayOnAnaloguePresses: true,
    averagePrePressPlusOperatorsHourly: true,
    typicalSLA: true,
    percentProductionManagedByFloorManagementSystem: true,
    numberOfTemplatedJobs: true,
    numberOfCustomJobs: true,
    manHoursPerDay: true,
    estimatedAndQuotingAutomatedJobsDaily: true,
    estimatedAndQuotingManualJobsDaily: true,
    estimatedAndQuotingManHoursDaily: true,
    estimatedAndQuotingAutomationImportance: true,
    estimatedAndQuotingAutomationSatisfaction: true,
    receivingJobsAutomatedJobsDaily: true,
    receivingJobsManualJobsDaily: true,
    receivingJobsManHoursDaily: true,
    receivingJobsAutomationImportance: true,
    receivingJobsAutomationSatisfaction: true,
    preparingAutomatedJobsDaily: true,
    preparingManualJobsDaily: true,
    preparingManHoursDaily: true,
    preparingAutomationImportance: true,
    preparingAutomationSatisfaction: true,
    cuttingAndCreasingAutomatedJobsDaily: true,
    cuttingAndCreasingManualJobsDaily: true,
    cuttingAndCreasingManHoursDaily: true,
    cuttingAndCreasingAutomationImportance: true,
    cuttingAndCreasingAutomationSatisfaction: true,
    embellishmentsAutomatedJobsDaily: true,
    embellishmentsManualJobsDaily: true,
    embellishmentsManHoursDaily: true,
    embellishmentsAutomationImportance: true,
    embellishmentsAutomationSatisfaction: true,
    bindingAndFoldingAutomatedJobsDaily: true,
    bindingAndFoldingManualJobsDaily: true,
    bindingAndFoldingManHoursDaily: true,
    bindingAndFoldingAutomationImportance: true,
    bindingAndFoldingAutomationSatisfaction: true,
    dispatchingAndShippingAutomatedJobsDaily: true,
    dispatchingAndShippingManualJobsDaily: true,
    dispatchingAndShippingManHoursDaily: true,
    dispatchingAndShippingAutomationImportance: true,
    dispatchingAndShippingAutomationSatisfaction: true,
    orderTrackingAutomatedJobsDaily: true,
    orderTrackingManualJobsDaily: true,
    orderTrackingManHoursDaily: true,
    orderTrackingAutomationImportance: true,
    orderTrackingAutomationSatisfaction: true,
    reprintsAutomatedJobsDaily: true,
    reprintsManualJobsDaily: true,
    reprintsManHoursDaily: true,
    reprintsAutomationImportance: true,
    reprintsAutomationSatisfaction: true,
  };

  vm.emailValidator = {
    expression: "^[A-Za-z0-9._%+-]+@[A-Za-z0-9._-]+\\.[a-zA-Z]{2,24}$",
    message: "Invalid email address",
  };

  vm.numberValidation = {
    expression: "^[0-9]+$",
    message: "Number is required",
  };

  vm.oneThroughFiveValidation = {
    expression: "^[1-5]",
    message: "Number 1-5 is required",
  };

  vm.stringValidation = {
    expression: "^[\\p{L}\\p{N} &'.,_-]+$",
    message: "Proper string is required",
    flags: "u",
  };

  //"^[a-zA-Z0-9.,?() \n-]+$"
  //"^[\\p{L}\\p{N} &@\".,()?-]+$"
  //Original ^(?u)[\\p{L}\\p{N} &@".,()?-]+$
  vm.noSpecialCharacters = {
    expression: "^[a-zA-Z0-9.,?() \n-]+$",
    message: "No Special Characters: , . () ? - allowed"
  };

  vm.submitDisableCheck = {

  }

  vm.userInfo = null;
  vm.getUserInfo = getUserInfo;
  vm.loading = true;
  vm.sending = false;
  vm.disabled = disabled;

  vm.submit = submit;
  vm.cancel = goBack;
  vm.csv = [];

  vm.$onInit = onInit;
  return;
  ///////////////////

  function goBack() {
    $state.go('contact-hp');
  }

  function onInit()
  {
    let promises = [];
    promises.push(getUserInfo());

    $q.all(promises).then(() => {
      vm.loading = false;
    });

  }

  function submit() {
    vm.errorKey = null;
    vm.sending = true;

    objectToCSVList(vm.payload.generalInformation);
    objectToCSVList(vm.payload.productionFloor.productionInfo);
    objectToCSVList(vm.payload.productionFloor.templatedCustomJobsPerDay);
    objectToCSVList(vm.payload.productionProcesses.estimatedAndQuoting, 'Estimating and Quoting');
    objectToCSVList(vm.payload.productionProcesses.receivingJobs, 'Recieving Jobs');
    objectToCSVList(vm.payload.productionProcesses.preparing, 'Preparing');
    objectToCSVList(vm.payload.productionProcesses.cuttingAndCreasing, 'Cutting and Creasing');
    objectToCSVList(vm.payload.productionProcesses.embellishments, 'Embellishments');
    objectToCSVList(vm.payload.productionProcesses.bindingAndFolding, 'Binding and Folding');
    objectToCSVList(vm.payload.productionProcesses.dispatchingAndShipping, 'Dispatching and Shipping');
    objectToCSVList(vm.payload.productionProcesses.orderTracking, 'Order Tracking');
    objectToCSVList(vm.payload.productionProcesses.reprints, 'Reprints');

    sendSurvey(vm.csv);
  }

  function sendSurvey(csv){
    vm.sending = true;
    return rnHttp.post(SUPPORTAPP_SERVICE, WOW_SURVEY_INFORMATION, {csv: csv}).then(response => {
      console.log("rnHttp POST Response: " + response);
      handleSuccess(response);
    }).catch(errorResp => {
      handleError(errorResp);
      console.log("rnHttp Post Error: " + errorResp);
    }).finally(() =>{
      vm.sending = false;
    });
  }

  function handleSuccess(resp) {
    let textContent = "Sending You Back to PrintOS Support";
    reasonCode(resp);

    $mdDialog.show(
      $mdDialog.alert()
        .clickOutsideToClose(false)
        .title($translate.instant('Survey Completed and Sent'))
        .textContent(textContent)
        .ok($translate.instant('uxf.uxf.Dialog_OK'))
    ).then(() => {
      $state.go('contact-hp');
    });
  }

  function handleError(resp) {
    setErrorMessage(resp);

    $mdDialog.show(
      $mdDialog.alert()
        .clickOutsideToClose(false)
        .title($translate.instant('Survey Failed to Send'))
        .textContent(vm.errorKey)
        .ok($translate.instant('uxf.uxf.Dialog_OK'))
    );
  }

  function reasonCode(resp){
    let reasonCode = _.get(resp,'data.reasonCode',0);
    if(reasonCode !== 0) {
      if(reasonCode === 1000) {
        console.log("Email Reason User Not Found");
        vm.errorKey = "Email Reason User Not Found";
        handleError(resp);
      }
      else if(reasonCode === 1100) {
        console.log("Email Address Blocked");
        vm.errorKey = "Email Address Blocked";
        handleError(resp);
      }
      else if(reasonCode === 1200) {
        console.log("Email Sent Count Exceeded Threshold");
        vm.errorKey = "Email Sent Count Exceeded Threshold";
        handleError(resp);
      }
    }
  }

  function setErrorMessage(resp){
    //$gzAnalytics.event('Fail to Send and Submit Survey', {category: ANALYTICS_CATEGORY});
    vm.sending = false;
    //vm.progress.createOrg.status = 'ERROR';

    let smsError = _.get(resp, 'data.smsError');

    if (smsError) {
      switch (smsError.statusCode) {
        case 400:
          vm.errorKey = 'Bad Request';
           break;
        case 401:
        case 403:
          vm.errorKey = "Unauthorized";
          break;
        case 404:
          vm.errorKey = smsError.message;
          //vm.errorKey = 'Path Not Found';
          break;
        case 502:
          vm.errorKey = 'Bad Gateway';
          break;
        case 503:
          vm.errorKey = "Internal Server Error";
          break;
        default:
          vm.errorKey = "Unknown Error";
          break;
      }
    }
  }

  function disabled(){
    if(vm.sending){
      return true;
    }
    if(Object.values(vm.valid).includes(false)){
      return true;
    }
    return false;
  }

  function objectToCSVList(object, label) {
    //let strList = [];
    let str = "";
    for (let key in object) {
      for (let key2 in object[key]) {
        //str += '"';
        str += '"';
        if(label && key2 === 'text')
        {
          str += label + " ";
        }
        str += object[key][key2] + '"';
        if (key2 === 'id' || key2 === 'text') {
          str += ',';
        }
      }
      vm.csv.push(str);
      str = "";
    }
  }

  function getUserInfo() {
    return rnHttp.get('aaa', 'users/validate').then(response => {
      vm.userInfo = response.data.user;
      vm.payload.generalInformation.hpRepresentativeName.value = vm.userInfo.firstName + " " + vm.userInfo.lastName;
      vm.payload.generalInformation.hpRepresentativeEmail.value = vm.userInfo.primaryEmail;
    });
  }

 }
