import rhino from 'rhino';
import angularTranslate from 'angular-translate';

import './app-analytics.scss';
import * as hmAppAnalyticsDirective from './app-analytics.directive';

export default angular.module('home.components.widget.appAnalytics', [
  rhino,
  angularTranslate,
])
  .directive(hmAppAnalyticsDirective.name, hmAppAnalyticsDirective.default)
  .name;
