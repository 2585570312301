export const name = 'RestoreDefaultsDialogController';
export default function RestoreDefaultsDialogController(
  // Controller angular dependencies
  family
) {
  'ngInject';
  // Constants

  // Variables

  // View Model
  let vm = this;
  vm.submit = submit;
  //   VM properties
  //   VM methods

  vm.activate = activate;
  activate();
  return;

  ///////////////
  // Init
  function activate() {
  }

  ///////////////
  // VM methods
  function submit() {

  }
  ///////////////
  // Helpers
}
