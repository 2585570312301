import './account-summary.scss';

import * as hmAccountSummaryDirective from './account-summary.directive';
import gazelleAnalytics from 'gazelle/src/core/analytics/analytics.module';

export default angular.module('home.components.accountSummary', [
  gazelleAnalytics
])
  .directive(hmAccountSummaryDirective.name, hmAccountSummaryDirective.default)
  .name;
