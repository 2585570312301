export const name = 'hmGenericOrg';
export default function hmGenericOrg() {
  //noinspection BadExpressionStatementJS
  'ngInject';


  let directive = {
    restrict: 'EA',
    scope: {
      gzCard: '=',
    },
    template: require('./generic-org.jade'),
    controller: hpGenericOrgController,
    controllerAs: 'vm',
    bindToController: true
  };

  return directive;

  /** @ngInject */
  function hpGenericOrgController(
    $gzGo,
    rnUser,
    rnInvitation,
    $q
  ) {
    let vm = this;
    vm.loading = true;

    vm.gotoUsers = gotoUsers;
    vm.addUser = addUser;

    vm.$onInit = onInit;
    return;
    ////////////////////

    function onInit() {
      let promises = [];
      promises.push(getUsers());
      promises.push(getInvitations());
      return $q.all(promises).finally(() => {
        vm.loading = false;
      });
    }

    function getUsers() {
      return rnUser.getAll(0, 0).then(data => {
        vm.userCount = data.total;
      }).catch(error => {
        vm.errorKey = 'account.account.User_Retrieve_Error_Unkonwn';
      });
    }

    function getInvitations() {
      return rnInvitation.getAll(null, 0).then(data => {
        vm.isAddAvailable = data.canCreate();
      }).catch(() => {
        vm.isAddAvailable = false;
      });
    }

    function gotoUsers() {
      $gzGo.toContext('account-users');
    }

    function addUser($event){
      $event.stopPropagation();
      $gzGo.startTask('invite-user', 'invitations/send', 'home', 'dashboard', {});
    }
  }
}
