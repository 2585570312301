import angularTranslate from 'angular-translate';

import './generic-org.scss';
import * as hmGenericOrgDirective from './generic-org.directive';
import gazelleAnalytics from 'gazelle/src/core/analytics/analytics.module';
import rhinoUser from 'rhino/src/components/user/user.module';
import rhinoInvitation from 'rhino/src/components/invitation/invitation.module';

export default angular.module('home.components.widget.genericOrg', [
  rhinoUser,
  rhinoInvitation,
  angularTranslate,
  'gazelle.components.go',
  gazelleAnalytics
])
  .directive(hmGenericOrgDirective.name, hmGenericOrgDirective.default)
  .name;
