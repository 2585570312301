export const name = 'hmStatusWidget';
export default function hStatusWidget($log) {
  //noinspection BadExpressionStatementJS
  'ngInject';


  let directive = {
    restrict: 'EA',
    scope: {
      value: '=',
      color: '@',
      label: '@'
    },
    template: require('./statusWidget.jade'),
    link: statusWidgetLink,
    controller: statusWidgetController,
    controllerAs: 'vm',
    bindToController: true
  };

  return directive;

  function statusWidgetLink(scope, element, attrs, controllers, transcludeFn) {
  }

  /** @ngInject */
  function statusWidgetController() {
    let vm = this;

    vm.refresh = refresh;

    vm.$onInit = onInit;
    return;

    ////////////////////

    function onInit() {
      // TODO: Don't refresh on activate but rather 'onShow'
      refresh();
    }

    function refresh() {
      //TODO:
    }

    // Adapted from print.beat.client.controller
    function getStatus() {
      //TODO: determine status color
    }
  }

}
