export default class BaseController {
  constructor(dependencies) {
    this.bindDependencies(dependencies);
    this.ANALYTICS_CATEGORY = 'help';
    this.GA_ACTION_VIEW = 'viewed';
    this.GA_ACTION_CLICK = 'clicked';
    this.GA_ACTION_CLOSE = 'closed';
    this.GA_ACTION_SUBMIT = 'submitted';
    this.GA_TYPE_PDF = 'pdf';
    this.GA_TYPE_URL = 'url';
    this.GA_TYPE_VIDEO = 'video';
    this.GA_TYPE_WALKME = 'walkthruweb';
    this.GA_TYPE_TOPIC = 'topic';
  }

  bindDependencies(dependencies) {
    _.keys(dependencies).forEach(key => this[key] = dependencies[key]);
  }

  analytics(params) {
    // category (required): acronym for application name
    // action (required): acronym for executed action, like video clicked
    // label (optional): more info about the action
    // value (optional): positive integer like a goal value for analytics
    // Non i18n format that we want for label: APPLICATION | PAGE | TYPE | ANONYMOUS | TITLE

    const [ action, application, page, type, title, error ] = [
      !!params.action ? params.action : this.GA_ACTION_CLICK,
      !!params.application ? params.application : '-',
      !!params.page ? params.page : '-',
      !!params.type ? params.type : '-',
      !!params.title ? params.title : '-',
      !!params.error ? params.error : null
    ];
    const eventTrack = type + ' ' + action;
    const event = {
      category: this.ANALYTICS_CATEGORY,
      action: type + ' ' + action,
      label: 'app:' + application + '|page:' + page + '|title:' + title
    };

    if (error === null) {
      this.$analytics.eventTrack(eventTrack, event);
    } else {
      let smsError = error.smsError;
      if (smsError === undefined && error.data !== undefined) {
        smsError = error.data.smsError;
      }
      ga('send', 'exception', {
        exDescription: eventTrack + ' failed: ' + _.get(smsError, 'message', 'unknown')});
    }
  }

  unbindEnterEvent(evt) {
    if (!!evt && ((!!evt.keyCode && evt.keyCode === 13) || (!!evt.which && evt.which === 13))) {
      evt.stopPropagation();
      evt.preventDefault();
    }
  }
}
