import './scitex-pwp-service-now.scss';

import * as hmScitexPwpServiceNowDirective from './scitex-pwp-service-now.directive';
import gazelleAnalytics from 'gazelle/src/core/analytics/analytics.module';

export default angular.module('home.components.scitexPwpServiceNow', [
  gazelleAnalytics
])
  .directive(hmScitexPwpServiceNowDirective.name, hmScitexPwpServiceNowDirective.default)
  .name;
