import './dashboard-selector.scss';

import * as hmDashboardSelector from './dashboard-selector.directive';
import gazelleProfileImage from 'gazelle/src/components/profile-image/profile-image.module';
import rnHttp from 'rhino/src/core/http/http.module';
import rnSession from 'rhino/src/components/session/session.module';
import rnUser from 'rhino/src/components/user/user.module';
import gzGo from 'gazelle/src/components/go/go.module';
import gazelleAnalytics from 'gazelle/src/core/analytics/analytics.module';
import angularMoment from 'angular-moment';

import configTrustedResources from '../../trust-images.config';


export default angular.module('home.components.dashboard-selector', [
  gazelleProfileImage,
  rnHttp,
  rnSession,
  rnUser,
  gzGo,
  gazelleAnalytics,
  angularMoment,
])
  .directive(hmDashboardSelector.name, hmDashboardSelector.default)
  .config(configTrustedResources)
  .name;
