import {
  BUSINESS_UNIT,
} from 'rhino/src/components/start/start.service';

export const DEFAULT_SITE_ID = "DEFAULT_SITE_ID";

export const CARD_CATEGORY = {
  INDIGO: 'HP Indigo',
  LF: 'HP Large Format',
  SCITEX: 'HP Scitex',
  PAGEWIDE: 'HP PageWide',
  HP3D: 'HP 3D',
  PC: 'HP Production Center',
};

export const BU = {
  INDIGO: 'Indigo',
  SCITEX: 'Scitex',
  PWI: 'PWI',
  LF: 'LargeFormat',
};

export const ACCOUNT_TYPE = {
  PSP: 'PSP',
  RESELLER: 'Channel',
  HP: 'HP',
  GENERIC: 'Generic',
  EXTERNAL: 'External',
  NONE: 'Unknown',
};

export const ENTITY_TYPE = {
  USER: 'User',
  DEVICE: 'Device',
};

export const APPLICATION = {
  PRINTBEAT: 'printbeat',
  BOX: 'box',
  SITE_FLOW: 'production',
  JOBS: 'jobs',
  KNOWLEDGE_ZONE: 'information-center',
  LF_SERVICE_CENTER: 'reseller-portal',
  ACCOUNT: 'account',
  IMPOSER: 'imposer',
  COMPOSER: 'composer',
  INFORMATION_CENTER: 'information-center',
  RESOURCE_MANAGER: 'resourcemanager',
  SERVICE_CALLS: 'service-calls',
  PWI_SERVICE_CENTER: 'pwi-sc',
  HP3D_PAAS: '3dpaas',
  WALLART: 'wallart',
  GO3D: 'go3d',
  HP3D_DATA: '3ddata',
  HP3D_MAINTENANCE: '3dmaintenance',
  HP3D_DEVICES: '3ddevices',
  COLOR_CONFIG: 'color-config',
  SERVICE_NOW: 'service-now'
};

export const LP_DEVICE_FAMILY = {
  LF: 'LF',
  INDIGO: 'IndigoPress',
  THREE_D: 'ThreeDPrinter',
  SCITEX: 'ScitexPress',
  PAGEWIDE: 'IHPSPress',
  PRODUCTION_CENTER: 'ProductionCenter',
  FINISHING: 'FinishingDevice',
  OTHER: 'OtherDevices',
  NONE: 'LP_None',
  ALL: 'LP_All',
};

export const LP_DEVICE_TYPE = {
  LATEX: 'LatexPrinter', //
  INDIGO: 'IndigoPress', //
  SCITEX: 'ScitexPress', //
  PWP: 'IHPSPress', //
  TEXTILE: 'TextilePrinter', //
  DESIGNJET: 'DesignjetPrinter', //
  PAGEWIDEXL: 'PageWideXLPrinter', //
  THREE_D: 'ThreeDPrinter', //
  PC: 'ProductionCenter', //
  FINISHING: 'FinishingDevice',
  OTHER: 'Other', //
  HOT_FOLDER: 'HotFolder',
  PWP_DFE: 'IHPSDFE',
  INDIGO_DFE: 'IndigoDFE',
  RIP: 'RIP',
  THREE_D_CONTROLLER: 'ThreeDController', //
};

export const FEATURE = {
  SUTK: 'smart-uptime-kit',
  UNIFIED_SC: 'serviceCenterUnifiedXP',
  TWOINONE_RESELLER: '2in1Reseller',
  LEARN_PREMIUM: 'learn-premium',
  COVERAGE_PLANS: 'lf-coverage-plans',
  LF_JOBS_API: 'pb_jobs_api_lf',
  LF_SOLUTION: 'lf-solution',
  COLOR_BEAT: 'Color-Beat',
  OEE: 'oee',
  JOBS_SDK: 'jobs-sdk',
};

export const LP_UNASSIGNED_SITE = 'LP_UNASSIGNED_SITE';

// cardId: unique identifier for card (human readable)
// title: non-localized value to use for card title (ignored when titleKey)
// titleKey: key to use for card title
// category: category to show when businessUnit = 'All' to differentiate from cards from other BUs (ignored when categoryKey)
// categoryKey: key to use when businessUnit = 'All' to differentiate from cards from other BUs
// appId: identify which application to associate with this card (will show app icon and use for default link)
// type: directive name, used to load card content
// settings: open object to pass settings into the card (optional)
// pageIndex: current page shown, used when card supports multiple pages (default = 0)
// familyContexts: which device family contexts can we show this card (default = all)
export const cardDefinitions = [
  {
    cardId: 'dashboardSelector',
    type: 'hm-dashboard-selector',
    borderless: true,
    settings: {},
    pageIndex: 0,
    allow: () => true,
    include: () => true,
  },
  {
    cardId: 'indigoSitePerformance',
    type: 'hm-indigo-site',
    appId: 'printbeat',
    category: CARD_CATEGORY.INDIGO,
    family: LP_DEVICE_FAMILY.INDIGO,
    titleKey: 'home.home.Card_SitePerformance',
    requireOrg: true,
    settings: {
      family: 'IndigoPress'
    },
    pageIndex: 0,
    allow(api) {
      return api.account.isAccountType(ACCOUNT_TYPE.PSP) &&
        api.account.hasApplication(APPLICATION.PRINTBEAT) &&
        api.account.hasRealTimeDevicesByFamily(LP_DEVICE_FAMILY.INDIGO);
    },
    include(api) {
      return api.currentSite.hasRealTimeDevicesByFamily(LP_DEVICE_FAMILY.INDIGO);
    },
  },
  {
    cardId: 'indigoPressPerformance',
    type: 'hm-indigo-press',
    appId: 'printbeat',
    category: CARD_CATEGORY.INDIGO,
    family: LP_DEVICE_FAMILY.INDIGO,
    titleKey: 'home.home.Card_PressPerformance',
    requireOrg: true,
    settings: {
      family: 'IndigoPress'
    },
    pageIndex: 0,
    allow(api) {
      return api.account.isAccountType(ACCOUNT_TYPE.PSP) &&
        api.account.hasApplication(APPLICATION.PRINTBEAT) &&
        api.account.hasRealTimeDevicesByFamily(LP_DEVICE_FAMILY.INDIGO);
    },
    include(api) {
      return api.currentSite.hasRealTimeDevicesByFamily(LP_DEVICE_FAMILY.INDIGO);
    },
  },
  {
    cardId: 'indigoScore',
    type: 'hm-indigo-score',
    appId: 'printbeat',
    category: CARD_CATEGORY.INDIGO,
    family: LP_DEVICE_FAMILY.INDIGO,
    titleKey: 'home.home.Card_IndigoScore',
    requireOrg: true,
    pageIndex: 0,
    allow(api) {
      return api.account.isAccountType(ACCOUNT_TYPE.PSP) &&
        api.account.hasApplication(APPLICATION.PRINTBEAT) &&
        api.account.hasDeviceFamily(LP_DEVICE_FAMILY.INDIGO);
    },
    include(api) {
      return api.currentSite.hasDeviceFamily(LP_DEVICE_FAMILY.INDIGO);
    },
  },
  {
    cardId: 'indigoVolume',
    type: 'hm-indigo-volume',
    appId: 'printbeat',
    category: CARD_CATEGORY.INDIGO,
    family: LP_DEVICE_FAMILY.INDIGO,
    titleKey: 'home.home.Card_IndigoVolume',
    requireOrg: true,
    pageIndex: 0,
    allow(api) {
      return api.account.isAccountType(ACCOUNT_TYPE.PSP) &&
        api.account.hasApplication(APPLICATION.PRINTBEAT) &&
        api.account.hasDeviceFamily(LP_DEVICE_FAMILY.INDIGO);
    },
    include(api) {
      return api.currentSite.hasDeviceFamily(LP_DEVICE_FAMILY.INDIGO);
    },
  },
  {
    cardId: 'indigoRanking',
    type: 'hm-indigo-ranking',
    appId: 'printbeat',
    category: CARD_CATEGORY.INDIGO,
    family: LP_DEVICE_FAMILY.INDIGO,
    titleKey: 'home.home.Card_IndigoRanking',
    requireOrg: true,
    pageIndex: 0,
    allow(api) {
      return api.account.isPrintBeatRankingEnabled() &&
        api.account.isAccountType(ACCOUNT_TYPE.PSP) &&
        api.account.hasApplication(APPLICATION.PRINTBEAT) &&
        api.account.hasRealTimeDevicesByFamily(LP_DEVICE_FAMILY.INDIGO);
    },
    include(api) {
      return api.currentSite.hasRealTimeDevicesByFamily(LP_DEVICE_FAMILY.INDIGO);
    },
  },
  // {
  //   cardId: 'indigoSupplyOrders',
  //   type: 'hm-indigo-supply-orders',
  //   appId: 'pwi-sc',
  //   category: CARD_CATEGORY.INDIGO,
  //   family: LP_DEVICE_FAMILY.INDIGO,
  //   titleKey: 'home.home.Indigo_SupplyOrders',
  //   requireOrg: true,
  //   pageIndex: 0,
  //   allow(api) {
  //     return api.account.isAccountType(ACCOUNT_TYPE.PSP) &&
  //       api.account.hasDeviceFamily(LP_DEVICE_FAMILY.INDIGO);
  //   },
  //   include(api) {
  //     return api.currentSite.hasDeviceFamily(LP_DEVICE_FAMILY.INDIGO);
  //   },
  // },
  {
    cardId: 'latexStatusSummary',
    type: 'hm-latex-status',
    appId: 'printbeat',
    category: CARD_CATEGORY.LF,
    family: LP_DEVICE_FAMILY.LF,
    titleKey: 'home.home.Card_Devices',
    requireOrg: true,
    pageIndex: 0,
    headerAction: {
      iconName: 'general/veneer/help-o',
      labelKey: 'home.home.LFStatus_HelpTitle',
    },
    allow(api) {
      return api.account.isAccountType(ACCOUNT_TYPE.PSP) &&
        api.account.hasDeviceFamily(LP_DEVICE_FAMILY.LF);
    },
    include(api) {
      return api.currentSite.hasDeviceFamily(LP_DEVICE_FAMILY.LF);
    },
  },
  {
    cardId: 'latexPrintVolume',
    titleKey: 'home.home.Card_PrintVolume',
    category: CARD_CATEGORY.LF,
    family: LP_DEVICE_FAMILY.LF,
    appId: 'printbeat',
    type: 'hm-latex-volume',
    requireOrg: true,
    pageIndex: 0,
    allow(api) {
      return api.account.isAccountType(ACCOUNT_TYPE.PSP) &&
        api.account.hasApplication(APPLICATION.PRINTBEAT) &&
        api.account.hasRealTimeDevicesByFamily(LP_DEVICE_FAMILY.LF);
    },
    include(api) {
      return api.currentSite.hasRealTimeDevicesByFamily(LP_DEVICE_FAMILY.LF);
    },
  },
  {
    cardId: 'latexEfficiency',
    titleKey: 'home.home.Card_Efficiency',
    category: CARD_CATEGORY.LF,
    appId: 'printbeat',
    type: 'hm-latex-efficiency',
    requireOrg: true,
    pageIndex: 0,
    family: LP_DEVICE_FAMILY.LF,
    allow(api) {
      return api.account.isAccountType(ACCOUNT_TYPE.PSP) &&
        api.account.hasApplication(APPLICATION.PRINTBEAT) &&
        api.account.hasRealTimeDevicesByFamily(LP_DEVICE_FAMILY.LF);
    },
    include(api) {
      return api.currentSite.hasRealTimeDevicesByFamily(LP_DEVICE_FAMILY.LF);
    },
  },
  {
    cardId: 'learnMyCourses',
    titleKey: 'home.home.Card_LearnMyCourses',
    //category: CARD_CATEGORY.LF,
    appId: 'university',
    type: 'hm-learn-my-courses',
    requireOrg: false,
    pageIndex: 0,
    allow(api) {
      return api.account.hasApplication('university');
    },
    include: () => true,
  },
  {
    cardId: 'webToPrint',
    titleKey: 'home.home.Card_Web2Print',
    //category: CARD_CATEGORY.LF,
    appId: 'web2print',
    type: 'hm-web-to-print',
    requireOrg: false,
    pageIndex: 0,
    allow(api) {
      return api.account.hasApplication('web2print');
    },
    include: () => true,
  },
  // {
  //   cardId: 'latexPressPerformance',
  //   titleKey: 'home.home.Card_PrinterPerformance',
  //   category: CARD_CATEGORY.LF,
  //   appId: 'printbeat',
  //   type: 'hm-indigo-press',
  //   settings: {
  //     family: LP_DEVICE_FAMILY.LF,
  //   },
  //   requireOrg: true,
  //   pageIndex: 0,
  //   family: LP_DEVICE_FAMILY.LF,
  //   allow(api) {
  //     return api.account.isAccountType(ACCOUNT_TYPE.PSP) &&
  //       api.account.hasApplication(APPLICATION.PRINTBEAT) &&
  //       api.account.hasRealTimeDevicesByFamily(LP_DEVICE_FAMILY.LF);
  //   },
  //   include(api) {
  //     return api.currentSite.hasRealTimeDevicesByFamily(LP_DEVICE_FAMILY.LF);
  //   },
  // },
  // {
  //   cardId: 'myLatexDevices',
  //   titleKey: 'home.home.FamilyCardTitle_LatexPrinterLP1',
  //   category: CARD_CATEGORY.LF,
  //   appId: 'account',
  //   type: 'my-devices',
  //   settings: {
  //     family: LP_DEVICE_FAMILY.LF,
  //   },
  //   requireOrg: true,
  //   pageIndex: 0,
  //   family: LP_DEVICE_FAMILY.LF,
  //   allow(api) {
  //     return api.account.isAccountType(ACCOUNT_TYPE.PSP) &&
  //       api.account.hasDeviceFamily(LP_DEVICE_FAMILY.LF);
  //   },
  //   include(api) {
  //     return api.currentSite.hasDeviceFamily(LP_DEVICE_FAMILY.LF);
  //   },
  // },
  {
    cardId: 'channelPsps',
    category: CARD_CATEGORY.INDIGO,
    titleKey: 'home.home.ChannelPsps',
    appId: 'account',
    type: 'hm-channel-psps',
    pageIndex: 0,
    requireOrg: true,
    family: LP_DEVICE_FAMILY.INDIGO,
    allow(api) {
      return api.account.isAccountType(ACCOUNT_TYPE.RESELLER) &&
        api.account.hasBU(BUSINESS_UNIT.Indigo);
    },
    include: () => true,
  },
  {
    cardId: 'applicationCenter',
    titleKey: 'application.application.wallart_Name',
    appId: 'wallart',
    type: 'hm-application-center',
    pageIndex: 0,
    requireOrg: true,
    allow(api) {
      return api.account.hasApplication(APPLICATION.WALLART) && !api.account.isAccountType(ACCOUNT_TYPE.HP);
    },
    include: () => true,
  },
  {
    cardId: 'scitexSitePerformance',
    titleKey: 'home.home.Card_SitePerformance',
    category: CARD_CATEGORY.SCITEX,
    appId: 'printbeat',
    type: 'hm-indigo-site',
    settings: {
      family: 'ScitexPress'
    },
    requireOrg: true,
    pageIndex: 0,
    family: LP_DEVICE_FAMILY.SCITEX,
    allow(api) {
      return api.account.isAccountType(ACCOUNT_TYPE.PSP) &&
        api.account.hasApplication(APPLICATION.PRINTBEAT) &&
        api.account.hasRealTimeDevicesByFamily(LP_DEVICE_FAMILY.SCITEX);
    },
    include(api) {
      return api.currentSite.hasRealTimeDevicesByFamily(LP_DEVICE_FAMILY.SCITEX);
    },
  },
  {
    cardId: 'myScitexDevices',
    titleKey: 'home.home.FamilyCardTitle2',
    category: CARD_CATEGORY.SCITEX,
    appId: 'account',
    type: 'my-devices',
    settings: {
      family: 'ScitexPress'
    },
    requireOrg: true,
    pageIndex: 0,
    family: LP_DEVICE_FAMILY.SCITEX,
    allow(api) {
      return api.account.isAccountType(ACCOUNT_TYPE.PSP) &&
        api.account.hasDeviceFamily(LP_DEVICE_FAMILY.SCITEX);
    },
    include(api) {
      return api.currentSite.hasDeviceFamily(LP_DEVICE_FAMILY.SCITEX);
    },
  },
  {
    cardId: 'pageWideSitePerformance',
    titleKey: 'home.home.Card_SitePerformance',
    category: CARD_CATEGORY.PAGEWIDE,
    appId: 'printbeat',
    type: 'hm-indigo-site',
    settings: {
      family: 'IHPSPress'
    },
    requireOrg: true,
    pageIndex: 0,
    family: LP_DEVICE_FAMILY.PAGEWIDE,
    allow(api) {
      return api.account.isAccountType(ACCOUNT_TYPE.PSP) &&
        api.account.hasApplication(APPLICATION.PRINTBEAT) &&
        api.account.hasRealTimeDevicesByFamily(LP_DEVICE_FAMILY.PAGEWIDE);
    },
    include(api) {
      return api.currentSite.hasRealTimeDevicesByFamily(LP_DEVICE_FAMILY.PAGEWIDE);
    },
  },
  {
    cardId: 'myPageWideDevices',
    titleKey: 'home.home.FamilyCardTitle2',
    category: CARD_CATEGORY.PAGEWIDE,
    appId: 'account',
    type: 'my-devices',
    settings: {
      family: 'IHPSPress'
    },
    requireOrg: true,
    pageIndex: 0,
    family: LP_DEVICE_FAMILY.PAGEWIDE,
    allow(api) {
      return api.account.isAccountType(ACCOUNT_TYPE.PSP) &&
        api.account.hasDeviceFamily(LP_DEVICE_FAMILY.PAGEWIDE);
    },
    include(api) {
      return api.currentSite.hasDeviceFamily(LP_DEVICE_FAMILY.PAGEWIDE);
    },
  },
  {
    cardId: 'my3DDevices',
    titleKey: 'home.home.FamilyCardTitle2',
    category: CARD_CATEGORY.HP3D,
    appId: 'account',
    type: 'my-devices',
    settings: {
      family: 'ThreeDPrinter'
    },
    requireOrg: true,
    pageIndex: 0,
    family: LP_DEVICE_FAMILY.THREE_D,
    allow(api) {
      return api.account.isAccountType(ACCOUNT_TYPE.PSP) &&
        api.account.hasDeviceFamily(LP_DEVICE_FAMILY.THREE_D) &&
        !api.account.hasApplication(APPLICATION.HP3D_DEVICES);
    },
    include(api) {
      return api.currentSite.hasDeviceFamily(LP_DEVICE_FAMILY.THREE_D);
    },
  },
  {
    cardId: 'bolt',
    titleKey: 'home.home.ThreeD_CostCalculator',
    category: CARD_CATEGORY.HP3D,
    appId: 'go3d',
    type: 'hm-app-bolt',
    settings: {
      family: 'ThreeDPrinter'
    },
    requireOrg: true,
    pageIndex: 0,
    family: LP_DEVICE_FAMILY.THREE_D,
    allow(api) {
      return api.account.hasApplication(APPLICATION.GO3D);
    },
    include: () => true,
  },
  // {
  //   cardId: 'jobs',
  //   titleKey: 'home.home.JobsTitle',
  //   appId: 'jobs',
  //   type: 'hm-jobs',
  //   pageIndex: 0,
  //   family: LP_DEVICE_FAMILY.INDIGO,
  //   allow(api) {
  //     return api.account.isAccountType(ACCOUNT_TYPE.PSP) &&
  //       api.account.hasApplication(APPLICATION.JOBS);
  //   },
  //   include: () => true,
  // },
  {
    cardId: 'box',
    titleKey: 'home.home.Card_Box',
    appId: 'box',
    type: 'hm-print-box',
    requireOrg: true,
    pageIndex: 0,
    businessUnits: [],
    allow(api) {
      return api.account.isAccountType(ACCOUNT_TYPE.PSP) &&
        api.account.hasApplication(APPLICATION.BOX);
    },
    include: () => true,
  },
  {
    cardId: 'indigoKnowledgeZone',
    category: CARD_CATEGORY.INDIGO,
    titleKey: 'Knowledge Zone',
    appId: 'information-center',
    type: 'hm-knowledge-zone',
    requireOrg: true,
    pageIndex: 0,
    family: LP_DEVICE_FAMILY.INDIGO,
    allow(api) {
      return api.account.hasApplication(APPLICATION.KNOWLEDGE_ZONE);
    },
    include(api) {
      return api.currentSite.hasDeviceFamily(LP_DEVICE_FAMILY.INDIGO) ||
        api.account.isAccountType(ACCOUNT_TYPE.HP) ||
        (api.account.isAccountType(ACCOUNT_TYPE.RESELLER) && api.account.hasBU(BUSINESS_UNIT.Indigo));
    },
  },
  {
    // Sadly, the Scitex card was misnomered as "pageWideKnowledgeZone". Not changing now because of saved dashboards.
    cardId: 'scitexKnowledgeZone',
    category: CARD_CATEGORY.SCITEX,
    titleKey: 'Knowledge Zone',
    appId: 'information-center',
    type: 'hm-knowledge-zone',
    requireOrg: true,
    pageIndex: 0,
    family: LP_DEVICE_FAMILY.SCITEX,
    allow(api) {
      return api.account.hasApplication(APPLICATION.KNOWLEDGE_ZONE);
    },
    include(api) {
      return api.currentSite.hasDeviceFamily(LP_DEVICE_FAMILY.SCITEX) ||
        api.account.isAccountType(ACCOUNT_TYPE.HP) ||
        (api.account.isAccountType(ACCOUNT_TYPE.RESELLER) && api.account.hasBU(BUSINESS_UNIT.Scitex));
    },
  },
  // Currently no recommended KZ documents for PWP
  {
    cardId: 'pwpKnowledgeZone',
    category: CARD_CATEGORY.PAGEWIDE,
    titleKey: 'Knowledge Zone',
    appId: 'information-center',
    type: 'hm-knowledge-zone',
    requireOrg: true,
    pageIndex: 0,
    family: LP_DEVICE_FAMILY.PAGEWIDE,
    allow(api) {
      return api.account.hasApplication(APPLICATION.KNOWLEDGE_ZONE);
    },
    include(api) {
      return api.currentSite.hasDeviceFamily(LP_DEVICE_FAMILY.PAGEWIDE) ||
        api.account.isAccountType(ACCOUNT_TYPE.HP) ||
        (api.account.isAccountType(ACCOUNT_TYPE.RESELLER) && api.account.hasBU(BUSINESS_UNIT.IHPS));
    },
  },
  {
    cardId: '3dKnowledgeZone',
    category: CARD_CATEGORY.HP3D,
    titleKey: 'Knowledge Zone',
    appId: 'information-center',
    type: 'hm-knowledge-zone',
    pageIndex: 0,
    family: LP_DEVICE_FAMILY.THREE_D,
    allow(api) {
      return api.account.hasApplication(APPLICATION.KNOWLEDGE_ZONE);
    },
    include(api) {
      return api.currentSite.hasDeviceFamily(LP_DEVICE_FAMILY.THREE_D) ||
        api.account.isAccountType(ACCOUNT_TYPE.HP) ||
        (api.account.isAccountType(ACCOUNT_TYPE.RESELLER) && api.account.hasBU(BUSINESS_UNIT.ThreeD));
    },
  },
  {
    cardId: 'siteFlow',
    titleKey: 'home.home.SiteFlow',
    appId: 'production',
    type: 'hm-site-flow',
    requireOrg: true,
    pageIndex: 0,
    family: [],
    allow(api) {
      return api.account.isAccountType(ACCOUNT_TYPE.PSP) &&
        api.account.hasApplication(APPLICATION.SITE_FLOW);
    },
    include: () => true,
  },
  {
    cardId: 'latexServiceCenter',
    category: CARD_CATEGORY.LF,
    titleKey: 'home.home.ServiceCenter',
    appId: 'reseller-portal',
    type: 'hm-latex-service-center',
    requireOrg: true,
    pageIndex: 0,
    family: LP_DEVICE_FAMILY.LF,
    allow(api) {
      return api.account.hasApplication(APPLICATION.LF_SERVICE_CENTER) &&
        !api.account.hasFeature(FEATURE.TWOINONE_RESELLER);
    },
    include: () => true,
  },
  {
    cardId: 'my3DServices',
    titleKey: 'home.home.ThreeD_Services',
    category: CARD_CATEGORY.HP3D,
    appId: '3dpaas',
    type: 'hm-three-d-services',
    settings: {
      family: 'ThreeDPrinter'
    },
    requireOrg: true,
    pageIndex: 0,
    family: LP_DEVICE_FAMILY.THREE_D,
    allow(api) {
      return api.account.hasApplication(APPLICATION.HP3D_PAAS);
    },
    include: () => true,
  },
  // {
  //   cardId: 'resourceManager',
  //   titleKey: 'home.home.RM',
  //   // category: CATEGORY_LF,
  //   appId: 'resourcemanager',
  //   type: 'hm-resource-manager',
  //   pageIndex: 0,
  //   businessUnits: ['All', BUSINESS_UNIT.LF, BUSINESS_UNIT.ThreeD, BUSINESS_UNIT.Indigo, BUSINESS_UNIT.Scitex, BUSINESS_UNIT.IHPS],
  //   allow: () => { return false; },
  //   include: () => { return false; },
  // },
  {
    cardId: 'whatsNext',
    titleKey: 'home.home.TipsAndTricks',
    appId: 'home',
    type: 'hm-whats-next',
    settings: {
      pages: ['noAccountDevice']
    },
    requireOrg: true,
    pageIndex: 0,
    allow(api) {
      return true;
    },
    include: () => true,
  },
  {
    cardId: 'myAccount',
    titleKey: 'home.home.MyAccount',
    appId: 'account',
    type: 'hm-my-account',
    pageIndex: 0,
    allow(api) {
      return true;
    },
    include: () => true,
  },
  {
    cardId: 'hpQuickLinks',
    titleKey: 'home.home.HPQuickLinks',
    appId: 'admin',
    type: 'hm-quick-links',
    requireOrg: true,
    pageIndex: 0,
    allow(api) {
      return api.account.isAccountType(ACCOUNT_TYPE.HP);
    },
    include: () => true,
  },
  {
    cardId: 'hpGenericOrg',
    titleKey: 'home.home.HPGenericOrg',
    appId: 'account',
    type: 'hm-generic-org',
    requireOrg: true,
    pageIndex: 0,
    allow(api) {
      return api.account.isAccountType(ACCOUNT_TYPE.GENERIC);
    },
    include: () => true,
  },
  {
    cardId: 'myPCDevices',
    titleKey: 'home.home.FamilyCardTitle_PC',
    appId: 'account',
    type: 'my-devices',
    settings: {
      family: 'ProductionCenter'
    },
    requireOrg: true,
    pageIndex: 0,
    allow(api) {
      return api.account.isAccountType(ACCOUNT_TYPE.PSP) &&
        api.account.hasDeviceFamily(LP_DEVICE_FAMILY.PRODUCTION_CENTER);
    },
    include(api) {
      return api.currentSite.hasDeviceFamily(LP_DEVICE_FAMILY.PRODUCTION_CENTER);
    },
  },
  {
    cardId: 'myFinishingDevices',
    titleKey: 'home.home.FamilyCardTitle_Finishing',
    //categoryKey: 'home.home.Category_Finishing',
    appId: 'account',
    type: 'my-devices',
    settings: {
      family: 'FinishingDevice'
    },
    requireOrg: true,
    pageIndex: 0,
    allow(api) {
      return api.account.isAccountType(ACCOUNT_TYPE.PSP) &&
        api.account.hasDeviceFamily(LP_DEVICE_FAMILY.FINISHING);
    },
    include(api) {
      return api.currentSite.hasDeviceFamily(LP_DEVICE_FAMILY.FINISHING);
    },
  },
  {
    cardId: 'myOtherDevices',
    titleKey: 'home.home.FamilyCardTitle_Other2',
    //categoryKey: 'home.home.Category_Other',
    appId: 'account',
    type: 'my-devices',
    settings: {
      family: 'OtherDevices'
    },
    requireOrg: true,
    pageIndex: 0,
    allow(api) {
      return api.account.isAccountType(ACCOUNT_TYPE.PSP) &&
        api.account.hasDeviceFamily(LP_DEVICE_FAMILY.OTHER);
    },
    include(api) {
      return api.currentSite.hasDeviceFamily(LP_DEVICE_FAMILY.OTHER);
    },
  },
  {
    cardId: 'pageWideSmartUptimeKit',
    titleKey: 'home.home.SUTK_Title',
    category: CARD_CATEGORY.PAGEWIDE,
    appId: 'pwi-sc',
    type: 'hm-smart-uptime-kit',
    settings: {
      businessUnit: 'pwp'
    },
    requireOrg: true,
    pageIndex: 0,
    family: LP_DEVICE_FAMILY.PAGEWIDE,
    allow(api) {
      return api.account.isAccountType(ACCOUNT_TYPE.PSP) &&
        api.account.hasApplication(APPLICATION.PWI_SERVICE_CENTER) &&
        api.account.hasFeature(FEATURE.SUTK) &&
        (api.account.hasDeviceFamily(LP_DEVICE_FAMILY.PAGEWIDE) || api.account.hasBU(BUSINESS_UNIT.IHPS));
    },
    include: () => true,
  },
  {
    cardId: 'scitexSmartUptimeKit',
    titleKey: 'home.home.SUTK_Title',
    category: CARD_CATEGORY.SCITEX,
    appId: 'pwi-sc',
    type: 'hm-smart-uptime-kit',
    settings: {
      businessUnit: 'lfi'
    },
    requireOrg: true,
    pageIndex: 0,
    family: LP_DEVICE_FAMILY.SCITEX,
    allow(api) {
      return api.account.isAccountType(ACCOUNT_TYPE.PSP) &&
        api.account.hasApplication(APPLICATION.PWI_SERVICE_CENTER) &&
        api.account.hasFeature(FEATURE.SUTK) &&
        (api.account.hasDeviceFamily(LP_DEVICE_FAMILY.SCITEX) || api.account.hasBU(BUSINESS_UNIT.Scitex));
    },
    include: () => true,
  },
  {
    cardId: 'threeDSmartUptimeKit',
    titleKey: 'home.home.SUTK_Title',
    category: CARD_CATEGORY.HP3D,
    appId: 'pwi-sc',
    type: 'hm-smart-uptime-kit',
    settings: {
      businessUnit: '3d'
    },
    requireOrg: true,
    pageIndex: 0,
    family: LP_DEVICE_FAMILY.THREE_D,
    allow(api) {
      return api.account.isAccountType(ACCOUNT_TYPE.PSP) &&
        api.account.hasFeature(FEATURE.SUTK) &&
        (api.account.hasDeviceFamily(LP_DEVICE_FAMILY.THREE_D) || api.account.hasBU(BUSINESS_UNIT.ThreeD));
    },
    include: () => true,
  },
  {
    cardId: 'latexSupportCases',
    titleKey: 'home.home.ServiceCenter_SupportCases',
    category: CARD_CATEGORY.LF,
    appId: 'pwi-sc',
    type: 'hm-support-cases',
    settings: {
      deviceFamily: LP_DEVICE_FAMILY.LF,
      businessUnit: 'Latex'
    },
    requireOrg: true,
    pageIndex: 0,
    family: LP_DEVICE_FAMILY.LF,
    allow(api) {
      return api.account.isAccountType(ACCOUNT_TYPE.PSP) &&
        api.account.hasApplication(APPLICATION.PWI_SERVICE_CENTER) &&
        api.account.hasDeviceFamily(LP_DEVICE_FAMILY.LF);
    },
    include: () => true,
  },
  {
    cardId: 'scitexServiceNow',
    titleKey: 'home.home.ServiceCenter_SupportCases',
    appId: 'service-now',
    type: 'hm-scitex-pwp-service-now',
    requireOrg: true,
    pageIndex: 0,
    family: LP_DEVICE_FAMILY.SCITEX,
    allow(api) {
      return api.account.isAccountType(ACCOUNT_TYPE.PSP) &&
        api.account.hasApplication(APPLICATION.SERVICE_NOW) &&
        api.account.hasDeviceFamily(LP_DEVICE_FAMILY.SCITEX);
    },
    include: () => true,
  },
  {
    cardId: 'pageWideServiceNow',
    titleKey: 'home.home.ServiceCenter_SupportCases',
    appId: 'service-now',
    type: 'hm-scitex-pwp-service-now',
    requireOrg: true,
    pageIndex: 0,
    family: LP_DEVICE_FAMILY.PAGEWIDE,
    allow(api) {
      return api.account.isAccountType(ACCOUNT_TYPE.PSP) &&
        api.account.hasApplication(APPLICATION.SERVICE_NOW) &&
        api.account.hasDeviceFamily(LP_DEVICE_FAMILY.PAGEWIDE);
    },
    include: () => true,
  },
  {
    cardId: 'threeDSupportCases',
    titleKey: 'home.home.ServiceCenter_SupportCases',
    category: CARD_CATEGORY.HP3D,
    appId: 'pwi-sc',
    type: 'hm-support-cases',
    settings: {
      deviceFamily: LP_DEVICE_FAMILY.THREE_D,
      businessUnit: 'ThreeD'
    },
    requireOrg: true,
    pageIndex: 0,
    family: LP_DEVICE_FAMILY.THREE_D,
    allow(api) {
      return api.account.isAccountType(ACCOUNT_TYPE.PSP) &&
        api.account.hasApplication(APPLICATION.PWI_SERVICE_CENTER) &&
        !api.account.hasApplication(APPLICATION.SERVICE_NOW) &&
        api.account.hasDeviceFamily(LP_DEVICE_FAMILY.THREE_D);
    },
    include: () => true,
  },
  {
    cardId: 'latexColorConfig',
    titleKey: 'home.home.ColorConfig_ConfigurationCenter',
    appId: 'color-config',
    type: 'hm-color-config',
    requireOrg: true,
    pageIndex: 0,
    family: LP_DEVICE_FAMILY.LF,
    allow(api) {
      return api.account.hasApplication(APPLICATION.COLOR_CONFIG) &&
        api.account.hasDeviceFamily(LP_DEVICE_FAMILY.LF);
    },
    include: () => true,
  },
  {
    cardId: 'sharedAccounts',
    titleKey: 'home.home.Reseller_SharedAccounts',
    appId: 'account',
    type: 'hm-shared-accounts',
    requireOrg: true,
    pageIndex: 0,
    allow(api) {
      return api.account.isAccountType(ACCOUNT_TYPE.RESELLER);
    },
    include: () => true,
  },
  {
    cardId: 'resellerSummary',
    titleKey: 'home.home.Reseller_Summary',
    appId: 'account',
    type: 'hm-account-summary',
    requireOrg: true,
    pageIndex: 0,
    allow(api) {
      return api.account.isAccountType(ACCOUNT_TYPE.RESELLER);
    },
    include: () => true,
  },
  {
    appId: 'service-now',
    cardId: 'indigoSCLink',
    titleKey: 'home.home.ServiceNow_SC_Title',
    type: 'hm-indigo-s-c-link',
    family: LP_DEVICE_FAMILY.INDIGO,
    requireOrg: true,
    pageIndex: 0,
    allow(api) {
      return api.account.hasApplication(APPLICATION.SERVICE_NOW);
    },
    include: () => true,
  },
  {
    appId: 'service-now',
    cardId: '3dSCLink',
    titleKey: 'home.home.ServiceNow_SC_Title',
    type: 'hm-indigo-s-c-link',
    family: LP_DEVICE_FAMILY.THREE_D,
    requireOrg: true,
    pageIndex: 0,
    allow(api) {
      return api.account.hasApplication(APPLICATION.SERVICE_NOW);
    },
    include: () => true,
  },
  {
    cardId: 'indigoCustomerScores',
    titleKey: 'home.home.Indigo_Customer_Scores',
    appId: 'printbeat',
    type: 'hm-indigo-customer-scores',
    requireOrg: true,
    pageIndex: 0,
    fullWidth: true,
    family: LP_DEVICE_FAMILY.INDIGO,
    allow(api) {
      return api.account.isAccountType(ACCOUNT_TYPE.RESELLER);
    },
    include: () => true,
  },
  {
    cardId: 'appAnalytics',
    titleKey: 'home.home.ThreeD_Analytics',
    category: CARD_CATEGORY.HP3D,
    appId: '3ddata',
    type: 'hm-app-analytics',
    settings:{
      family: 'ThreeDPrinter'
    },
    requireOrg: true,
    pageIndex: 0,
    family: LP_DEVICE_FAMILY.THREE_D,
    allow(api){
      return api.account.hasApplication(APPLICATION.HP3D_DATA);
    },
    include: () => true,
  },
  {
    cardId: 'jobHistory',
    titleKey: 'home.home.ThreeD_JobHistory',
    category: CARD_CATEGORY.HP3D,
    appId: '3djobs',
    type: 'hm-job-history',
    settings:{
      family: 'ThreeDPrinter'
    },
    requireOrg: true,
    pageIndex: 0,
    family: LP_DEVICE_FAMILY.THREE_D,
    allow(api){
      return api.account.hasApplication(APPLICATION.HP3D_DATA);
    },
    include: () => true,
  },
  {
    cardId: '3DDevices',
    titleKey: 'home.home.ThreeD_Devices',
    category: CARD_CATEGORY.HP3D,
    appId: '3ddevices',
    type: 'hm-three-d-devices',
    settings:{
      family: 'ThreeDPrinter'
    },
    requireOrg: true,
    pageIndex: 0,
    family: LP_DEVICE_FAMILY.THREE_D,
    allow(api){
      return api.account.hasApplication(APPLICATION.HP3D_DEVICES);
    },
    include: () => true,
  },
  {
    cardId: 'maintenance',
    titleKey: 'home.home.ThreeD_Maintenance',
    category: CARD_CATEGORY.HP3D,
    appId: '3dmaintenance',
    type: 'hm-maintenance',
    settings:{
      family: 'ThreeDPrinter'
    },
    requireOrg: true,
    pageIndex: 0,
    family: LP_DEVICE_FAMILY.THREE_D,
    allow(api){
      return api.account.hasApplication(APPLICATION.HP3D_MAINTENANCE);
    },
    include: () => true,
  },
  {
    cardId: 'maintenancePromo',
    titleKey: 'home.home.ThreeD_Maintenance',
    category: CARD_CATEGORY.HP3D,
    featureId: '3dcenterpremiumCapable',
    appId: '3dmaintenance',
    type: 'hm-maintenance-promo',
    settings:{
      family: 'ThreeDPrinter'
    },
    requireOrg: true,
    pageIndex: 0,
    family: LP_DEVICE_FAMILY.THREE_D,
    allow(api){
       return !api.account.hasApplication(APPLICATION.HP3D_MAINTENANCE);
    },
    include: () => true,
  },
];
