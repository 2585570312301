export const name = 'AddLfDeviceController';
export default function AddLfDeviceController(
  // Controller angular dependencies
  rnHttp,
  rnClient,
  rnSession,
  rnDcs,
  rnDevice,
  $state,
  $stateParams,
  $gzAnalytics,
  $gzGo,
  $mdDialog,
  $rootScope,
  $window,
  $q
) {
  'ngInject';
  // Constants
  const ANALYTICS_CATEGORY = 'Add Device';
  const STEP = {
    'DEVICE': 1,    //Specify device info
    'PROGRESS': 2,  //Progress and Results of Adding Device
    'DEVICE_SUCCESS': 3,    //Added or Requested
    'ERROR': 4,   //Error conditions
    'JOIN': 5,  //Join Org
    'SUCCESS': 6
  };

  // Variables
  let sc;
  let contexts;
  let sites;
  let cc;
  let resellers;
  let orgCode;
  let addType;

  // View Model
  let vm = this;
  //   VM properties
  vm.loading = true;
  vm.validDeviceName = false;
  vm.validSite = false;
  vm.step = STEP.DEVICE;
  vm.formDisabled = false;
  vm.switching = false;

  //   VM methods
  vm.cancel = cancel;
  vm.close = close;
  vm.submit = submit;
  vm.disableSubmit = disableSubmit;
  vm.organizationChanged = organizationChanged;
  vm.validator = validator;
  vm.joinOrg = joinOrg;

  vm.$onInit = onInit;
  return;

  ///////////////
  // Init
  function onInit() {
    parseParams();
    return getClientConfig().then(() => {
      if (cc.context.type === 'Unknown') {
        $state.go('create-org', {sc});
        return Promise.resolve();
      }

      if (cc.context.type === 'Channel') {
        vm.errorKey = 'home.home.Error_CannotAdd';
        vm.errorActionKey = 'home.home.Error_ResellerCannotAdd';
        vm.step = STEP.ERROR;
        return Promise.resolve();
      }

      let promises = [];
      promises.push(getPermissions());
      promises.push(getDevice());
      promises.push(getContexts());
      promises.push(getSites());
      promises.push(getResellers());
      return $q.all(promises).then(() => {
        initData();
      });
    })
      .catch(error => {
      })
      .finally(() => {
        vm.loading = false;
      });
  }

  function parseParams() {
    sc = $stateParams.sc;
  }

  function getClientConfig() {
    return rnClient.getConfig().then(resp => {
      cc = resp;
    });
  }

  function getPermissions() {
    vm.canAddDevice = false;
    // TODO: use lf-gateway to check permissions instead of dcs
    return rnDcs.getAll(0, 0).then(data => {
      vm.canAddDevice = !!_.get(data, '_links.create');
    }).catch(() => {
      return Promise.resolve();
    });
  }

  function getDevice() {
    let config = {
      headers: {
        'If-Modified-Since': undefined,
        'Cache-Control': undefined
      },
      params: {
        securityCode: sc.trim().replace(/\s/g, ''),
        language: $printos.v1.preferences.getLocale(),
        getConsents: true
      },
    };

    return rnHttp.get('lf-gateway', 'device', config).then(resp => {
      if (resp.status !== 200) {
        vm.errorKey = 'home.home.Error_InvalidSecurityCode';
        return;
      }

      vm.device = {
        type: resp.data.deviceInformation.deviceType,
        model: resp.data.deviceInformation.model,
        name: `${resp.data.deviceInformation.serialNumber}`,
        serialNumber: resp.data.deviceInformation.serialNumber,
        securityCode: sc,
        firmwareType: resp.data.deviceInformation.firmwareType,
        transactionId: resp.data.deviceInformation.transactionId,
        consents: resp.data.consents ? resp.data.consents : [],
        internal: {},
      };

      rnDevice.addDisplayInfo(vm.device);
    })
    .catch(error => {
      vm.errorKey = 'home.home.Error_InvalidSecurityCode';
      vm.step = STEP.ERROR;
      return Promise.resolve();
    })
    .then(() => { return getDeviceStatus(); })
    .then(() => { return checkForSurvey(); })
    .finally(() => {
      vm.submitting = false;
    });
  }

  function getDeviceStatus() {
    let myType = encodeURI(vm.device.type);
    let myModel = encodeURI(vm.device.model);
    let mySerialNumber = encodeURI(vm.device.serialNumber);
    return rnHttp.get('sso_request', `request/new/check-device?type=${myType}&model=${myModel}&sn=${mySerialNumber}`).then(resp => {
      let status = _.get(resp, 'data.status', 'noOrganization');
      orgCode = _.get(resp, 'data.code');
      switch(status) {
        case 'noOrganization':
          addType = 'Add';
          $gzAnalytics.event('Add Device - Open', {category: ANALYTICS_CATEGORY, label: addType});
          vm.step = STEP.DEVICE;
          break;
        case 'sameOrganization':
        case 'differentOrganization':
          if (orgCode) {
            addType = 'Join Org';
            $gzAnalytics.event('Add Device - Open', {category: ANALYTICS_CATEGORY, label: addType});
            vm.step = STEP.JOIN;
          }
          else {
            addType = 'Device In Org';
            $gzAnalytics.event('Add Device - Open', {category: ANALYTICS_CATEGORY, label: addType});
            vm.errorKey = 'home.home.AddDevice_InOrgError';
            vm.errorActionKey = 'home.home.AddDevice_InOrgErrorDetails';
            vm.step = STEP.ERROR;
          }
          break;
      }
    }).catch(() => {
      vm.errorKey = 'home.home.AddDeviceError_Generic';
      vm.errorActionKey = 'home.home.AddDeviceResolution_Generic';
      vm.step = STEP.ERROR;
    });
  }

  function getContexts() {
    contexts = [];
    return getContextsHelper().then(r1 => {
      let pages = Math.ceil(r1.data.total / 10000);
      let promises = [];
      for (let p = 2; p <= pages; p++) {
        promises.push(getContextsHelper(p));
      }
      return Promise.all(promises).then(() => {
        contexts = contexts.sort((a,b) => {
          return a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase());
        });
      });
    })
      .catch(() => {
        return Promise.resolve();
      });
  }

  function getContextsHelper(page = 1) {
    let config = {
      params: {
        limit: 10000,
        offset: (page - 1) * 10000,
      },
    };
    return rnHttp.get('aaa', 'users/context', config).then(resp => {
      contexts = contexts.concat(resp.data.contexts);
      return resp;
    });
  }

  function getSites() {
    sites = $window.$printos.v1.org.getSites();
    return Promise.resolve();
  }

  function getResellers() {
    let config = {
      params: {
        offset: 0,
        limit: 250,
      }
    };
    return rnHttp.get('aaa', 'organizations/shares/', config).then(resp => {
      resellers = _.get(resp, 'data.organizations', []);
      vm.hasResellers = resellers.length > 0;
      if (vm.hasResellers) {
        vm.shareWithResellers = true;
      }
    });
  }

  function checkForSurvey() {
    let payload = {
      devices: [{
        sn: vm.device.serialNumber,
        pn: vm.device.model,
      }]
    };
    return rnHttp.post('reseller-portal', '/api/eoi_check', payload).then(resp => {
      vm.lfSurvey = _.get(resp, 'data.eofURL');
      vm.launchSurvey = true;
    })
      .catch(() => {
        return Promise.resolve();
      });
  }

  function initData() {
    vm.organizationOptions = [];
    contexts.forEach(c => {
      vm.organizationOptions.push({
        display: c.name,
        value: c,
      });
    });

    if (_.get(cc, 'context.id')) {
      let found = vm.organizationOptions.find(o => {
        return o.value.id === cc.context.id;
      });

      if (found) {
        vm.organization = found.value;
      }
    }

    vm.siteOptions = [];
    sites.forEach(site => {
      vm.siteOptions.push({
        display: site.name,
        value: site,
      });
    });

    if (vm.siteOptions.length === 1) {
      vm.site = vm.siteOptions[0].value;
    }

    vm.device.name = `${vm.device.serialNumber}`;
  }


  ///////////////
  // VM methods
  function validator(id) {
    return $printos.v1.client.getValidator(id);
  }

  function cancel() {
    returnToSender();
  }

  function close() {
    if (vm.launchSurvey && vm.lfSurvey) {
      $window.open(vm.lfSurvey);
    }


    returnToSender();
  }

  function returnToSender() {
    if( $stateParams.from ) {
      $gzGo.toContext($stateParams.from);
    } else {
      $state.go('dashboard');
    }
  }

  function joinOrg() {
    if (vm.submitting) {
      return Promise.resolve();
    }

    vm.submitting = true;
    delete vm.errorKey;

    let request = {
      type: 'AddUser',
      payload: {
        code: orgCode,
      }
    };
    return rnHttp.post('sso_request', `request/new`, request).then(() => {
      $gzAnalytics.event('Add User Request - Sent', {category: ANALYTICS_CATEGORY});
      vm.successKey = 'home.home.RequestSent';
      vm.successDetailsKey = 'home.home.RequestSent_Instructions';
      vm.step = STEP.SUCCESS;
    })
      .catch(error => {
        let statusCode = _.get(error, 'data.smsError.statusCode');
        switch (statusCode) {
          case 404:
            vm.errorKey = 'home.home.Request_ErrorInvalidCode';
            break;
          case 409:
            vm.successKey = 'home.home.RequestAlreadySent';
            vm.successDetailsKey = 'home.home.RequestSent_Instructions';
            vm.step = STEP.SUCCESS;
            return;
          default:
            vm.errorKey = 'home.home.RequestSent_Error';
        }
        $gzAnalytics.event('Add User Request - Failed', {category: ANALYTICS_CATEGORY, label:statusCode});
        vm.step = STEP.ERROR;
      })
      .finally(() => {
        vm.submitting = false;
      });
  }

  function disableSubmit() {
    if (vm.switching) {
      return true;
    }

    if (!vm.validDeviceName) {
      return true;
    }

    if (!vm.validSite) {
      return true;
    }

    return false;
  }

  function submit() {
    if (disableSubmit()) {
      return Promise.resolve();
    }

    if (vm.canAddDevice) {
      if(vm.device.firmwareType === 'DUNE' && vm.device.consents.length > 0) {

        $mdDialog.show({
          parent: angular.element(document.body),
          clickOutsideToClose: false,
          escapeToClose: true,
          template: require('./lf-consents/lf-consents.jade'),
          controller: 'LargeFormatConsentsController',
          bindToController: true,
          controllerAs: 'vm',
          locals: { device: vm.device},
        }).then(() => {
            addDevice();
        }).catch((e) => {
          // returnToSender();
        })
      } else {
        return addDevice();
      }
    }
    else {
      return addRequest();
    }
  }

  function addDevice() {
    vm.step = STEP.PROGRESS;

    vm.processing = true;
    vm.progress = {
      addDevice: {
        processing: true,
        status: undefined,
        displayKey: 'home.home.DeviceProgress_Add',
        displayValues: [vm.organization.name],
      },
    };

    if (vm.shareWithResellers) {
      vm.progress.addReseller = {
        processing: true,
        status: undefined,
        displayKey: 'home.home.DeviceProgress_Reseller',
      };
    }

    let lfgDevice = {
      deviceInformation: {
        deviceType: vm.device.type,
        model: vm.device.model,
        name: vm.device.name,
        serialNumber: vm.device.serialNumber,
        firmwareType: vm.device.firmwareType,
        transactionId: vm.device.transactionId,
        securityCode: vm.device.securityCode.trim().replace(/\s/g, ''),
        site: vm.site,
        language: $printos.v1.preferences.getLocale()
      },
      consents: vm.device.consents,
    };

    let newDevice;
    return rnHttp.post('lf-gateway', 'device', lfgDevice)
      .then(resp => {
        $gzAnalytics.event('Add Device - Success', {category: ANALYTICS_CATEGORY});
        newDevice = resp.data.deviceInformation;
        vm.progress.addDevice.processing = false;
        vm.progress.addDevice.status = 'COMPLETE';
      })
      .then(() => { return shareWithResellers(newDevice); })
      .then(() => { return $printos.v1.client.getConfigAsync(); })
      .catch(error => {
        $gzAnalytics.event('Add Device - Failed', {category: ANALYTICS_CATEGORY});
        vm.progress.addDevice.processing = false;
        vm.progress.addDevice.status = 'ERROR';
        vm.progress.addDevice.resultKey = 'home.home.AddDeviceError_Unknown';
      })
      .finally(() => {
        for (const prop in vm.progress) {
          if (vm.progress[prop].processing) {
            vm.progress[prop].processing = false;
            vm.progress[prop].status = 'ABORT';
          }
        }
        vm.processing = false;
    });
  }

  function addRequest() {
    let request = {
      type: 'AddLfDevice',
      firstName: 'Brennen',
      lastName: 'Stollfus',
      payload: {
        deviceType: vm.device.type,
        deviceModel: vm.device.model,
        serialNumber: vm.device.serialNumber,
        securityCode: sc,
        deviceName: vm.device.name,
        siteId: vm.site.siteId,
      }
    };

    return rnHttp.post('sso_request', `request/new`, request).then(() => {
      $gzAnalytics.event('Add Device Request - Sent', {category: ANALYTICS_CATEGORY});
      vm.step = STEP.DEVICE_SUCCESS;
    })
      .catch(error => {
        $gzAnalytics.event('Add Device Request - Failed', {category: ANALYTICS_CATEGORY});
        vm.step = STEP.ERROR;
        vm.errorKey = 'home.home.AddDeviceError_Generic';
        vm.errorActionKey = 'home.home.AddDeviceResolution_Generic';
        return Promise.resolve;
      })
      .finally(() => {

      });
  }

  function shareWithResellers(device) {
    if (!vm.hasResellers || !vm.shareWithResellers) {
      return Promise.resolve();
    }

    let resellersToShareWith = [];
    resellers.forEach(r => {
      resellersToShareWith.push(r.organizationId);
    });

    return rnHttp.post('aaa', `organizations/shares/devices/${device.id}`, resellersToShareWith).then(() => {
      vm.progress.addReseller.status = 'COMPLETE';
    })
      .catch(error => {
        vm.progress.addReseller.status = 'ERROR';
        return Promise.resolve();
      })
      .finally(() => {
        vm.progress.addReseller.processing = false;
      });
  }

  function organizationChanged() {
    vm.switching = true;
    vm.formDisabled = false;
    return rnSession.switchContext({id: vm.organization.id}).then(() => {
      $window.location.href = `/start/#/signin?sc=${sc}`;
    })
      .catch(() => {
        return Promise.resolve();
      });
  }

  ///////////////
  // Helpers
}
