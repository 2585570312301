export const moduleName = 'BUHelper';
export default class BUHelper {

  constructor() {
    this.BUSINESS_UNITS = [
      {type: 'Indigo', name: 'Indigo'},
      {type: 'IndigoPress', name: 'Indigo'},
      {type: 'IndigoDFE', name: 'Indigo'},
      {type: 'Scitex', name: 'Scitex'},
      {type: 'ScitexPress', name: 'Scitex'},
      {type: 'LFI', name: 'Scitex'},
      {type: 'IHPSPress', name: 'PWP'},
      {type: 'IHPSDFE', name: 'PWP'},
      {type: 'IHPS', name: 'PWP'},
      {type: 'PWP', name: 'PWP'},
      {type: 'Designjet', name: 'Designjet'},
      {type: 'DesignjetPrinter', name: 'Designjet'},
      {type: 'Latex', name: 'Latex'},
      {type: 'LatexPrinter', name: 'Latex'},
      {type: 'LFP', name: 'Latex'},
      {type: 'LF', name: 'Latex'},
      {type: 'TextilePrinter', name: 'Latex'},
      {type: 'ThreeD', name: 'ThreeD'},
      {type: 'ThreeDPrinter', name: 'ThreeD'}
    ];
  }

  static factory() {
    return new BUHelper();
  }

  getBUNameByDevice(device) {
    const bu = this.BUSINESS_UNITS.find(bu => bu.type === device.type);
    return bu ? bu.name : device.group;
  }

  getBuNames() {
    return [...new Set(this.BUSINESS_UNITS.map(bu=>bu.name))];
  }
}
