import {STATE} from 'gazelle/src/components/device-status/device-status.service';

export const name = 'hmSharedAccounts';
export default function hmSharedAccounts(
  // Directive angular dependencies
) {
  'ngInject';

  let directive = {
    restrict: 'EA',
    scope: {
      gzCard: '=',
    },
    template: require('./shared-accounts.jade'),
    link: sharedAccountsLink,
    controller: sharedAccountsController,
    controllerAs: 'vm',
    bindToController: true,
  };
  return directive;

  //////////////////////

  function sharedAccountsLink(scope, element, attrs) {
  }

  /** @ngInject */
  function sharedAccountsController(
    rnHttp,
    rnClient,
    $q,
    $gzDeviceStatus,
    $window
  ) {
    // Constants
    const DEFAULT_LIMIT = 1000;
    const SEVERITY = {
      Error: 'error',
      Warn: 'warning',
      Ready: 'ready',
      Unknown: 'unknown',
      Off: 'off',
      Ok: 'ok',
    };

    // Variables

    // View Model
    let vm = this;
    vm.loading = true;
    vm.customer = null;
    vm.customers = [];
    vm.filteredDevices = [];
    vm.includeError = true;
    vm.includeWarning = true;
    vm.includeReady = true;
    vm.includeUnknown = true;
    vm.includeStatusFilter = true;
    vm.filterDevices = filterDevices;
    vm.toggleErrorFilter = toggleErrorFilter;
    vm.toggleWarningFilter = toggleWarningFilter;
    vm.toggleOkFilter = toggleReadyFilter;
    vm.toggleUnknownFilter = toggleUnknownFilter;
    vm.goToDevices = goToDevices;

    vm.$onInit = onInit;
    return;

    ///////////////
    // Init
    function onInit() {
      getCustomers().then(() => {
        filterDevices();
      }).finally(() => {
        vm.loading = false;
      });
    }

    function getCustomers(limit = DEFAULT_LIMIT) {
      return rnHttp.get('aaa', 'organizations/grantedshares/', {params: {limit: limit}}).then(resp => {
        let customers = _.get(resp, 'data.organizations', []);
        customers.sort((a, b) => a.name.localeCompare(b.name));

        let sharedDevices = vm.gzCard.API.account.getSharedDevices();
        setupDeviceStatus(sharedDevices);

        customers.forEach(customer => {
          customer.devices = sharedDevices.filter(device => {
            return device.organizationId === customer.organizationId;
          });

          let customerObj = {display: customer.name, value: customer}
          vm.customers.push(customerObj)
        });

        if (vm.customers.length > 0) {
          vm.customer = vm.customers[0].value;
          filterDevices();
        }
      });
    }

    ///////////////
    // VM methods
    function filterDevices() {
      if (!_.get(vm.customer, 'devices')) {
        return;
      }

      vm.filteredDevices = vm.customer.devices.filter(device => deviceIncluded(device));
    }

    function deviceIncluded(device) {
      let severity = _.get(device, 'internal.status.status', SEVERITY.Unknown);
      return (vm.includeError && severity === SEVERITY.Error) ||
        (vm.includeWarning && severity === SEVERITY.Warn) ||
        (vm.includeReady && severity === SEVERITY.Ready) ||
        (vm.includeUnknown && severity === SEVERITY.Unknown);
    }

    function goToDevices($event, device) {
      $event.stopPropagation();
      $window.location.href = `/account/#/devices?deviceId=${device.deviceId}`;
    }

    function toggleErrorFilter() {
      vm.includeError = !vm.includeError;
      filterDevices();
    }

    function toggleWarningFilter() {
      vm.includeWarning = !vm.includeWarning;
      filterDevices();
    }

    function toggleReadyFilter() {
      vm.includeReady = !vm.includeReady;
      filterDevices();
    }

    function toggleUnknownFilter(){
      vm.includeUnknown = !vm.includeUnknown;
      filterDevices();
    }

    function setupDeviceStatus(devices) {
      checkStatusFilter();
      devices.forEach(device => {
        let status = $gzDeviceStatus.getDeviceStatus(device);

        _.set(device, 'internal.status', status);
      });
    }

    function checkStatusFilter() {
      return rnClient.getConfig().then(config => {
        if(config.features){
          vm.includeStatusFilter = !config.features.includes('2in1Reseller')
        }
      })
        .catch(error => {
          return $q.resolve();
        });
    }
  }
}
