/**
 * Created by panda on 9/16/2016.
 */

import support_css from './support_case_detail.scss';
import BaseController from '../util/base.controller';

const LOAD_CASE_ERROR = 'uxf.uxf.SupportCase_Load_Error';
const ACTION_UNAUTHORIZED_MESSAGE = 'uxf.uxf.SupportCase_View_Access_Error';

export const moduleName = 'SupportCaseDetailController';
export default class SupportCaseDetailController extends BaseController {

  /** @ngInject */
  constructor(rnHttp, $log, $state, $stateParams, SupportCaseDetailService) {
    super({rnHttp, $log, $state, $stateParams, SupportCaseDetailService});
    this.activate();
    this.$state = $state;
    this.$stateParams = $stateParams;
  }

  activate() {
    this.parseParams();
    this.title = this.$stateParams.title;
    this.isLoading = true;
    this.loadCase();
    this.breadcrumbs = [
      {
        state: 'dashboard',
        titleKey: 'home.home.Home_title'
      },
      {
        state: 'settings',
        titleKey: 'home.home.My_Settings'
      },
      {
        state: 'contact-hp',
        titleKey: 'home.home.Contact_Hp'
      },
      {
        state: 'support-case',
        titleKey: 'uxf.uxf.View_SupportCase'
      },
      {
        state: 'support-case-detail',
        titleKey: 'home.home.SupportCase_Case'
      }
    ];
  }

  loadCase() {
    this.SupportCaseDetailService.getCaseById(this.$stateParams.caseId).then(response => {
      this.caseResponse = this.createCaseResponse(response.data);
      this.title = this.title ? this.title : this.caseResponse.title;
      this.isLoading = false;
    }).catch(error => {
      this.isLoading = false;
      this.title = '';
      this.$log.debug(error);
      this.handleError(error);
    });
  }

  createCaseResponse(careForceCase) {
    return {
      caseId: careForceCase.careforceState.careforceNumber,
      status: careForceCase.careforceState.careforceStatus,
      title: careForceCase.subject,
      description: this.getDescription(careForceCase),
      openDate: new Date(careForceCase.createdAt),
      lastUpdate: new Date(careForceCase.careforceState.lastModifiedDate + 'Z')
    };
  }

  getDescription(currentCase){
    switch (currentCase.type){
      case 'ASK_A_QUESTION':
        return this.getDescriptionToAskAQuestion(currentCase);
      case 'REPORT_A_PROBLEM':
        return this.getDescriptionToReportAProblem(currentCase);
      case 'FEEDBACK_TO_HP':
        return this.getDescriptionToFeedbackToHP(currentCase);
      case 'ANONYMOUS_QUESTION':
        return this.getDescriptionToAnonymousQuestion(currentCase);
      default :
        return this.getDescriptionToGenericType(currentCase);
    }
  }

  getDescriptionToGenericType(currentCase){
    let description = '';
    let browser = currentCase.userSystemInfo.browser ? this.removeNull(currentCase.userSystemInfo.browser) + ' v.' + this.removeNull(currentCase.userSystemInfo.browserVersion) : '';
    currentCase.userEntries.forEach( userEntry => {
      description += userEntry.userEntryType + ': ' + this.removeNull(userEntry.value) + '\n';
    });
    description += 'Source: ' + this.removeNull(currentCase.caseSource);
    description += '\nPreferred Contact Method: ' + this.getPreferredContactMethod(currentCase.preferredContactMethod.contactMethodType);
    if(currentCase.preferredContactMethod.altContactValue){
      description += '\nAlternative Contact: ' + this.removeNull(currentCase.preferredContactMethod.altContactValue);
    }
    description += '\nUser System Information: ';
    description += '\n\tBrowser: ' + browser;
    description += '\n\tOperating System: ' + this.removeNull(currentCase.userSystemInfo.osVersion);

    return description;
  }

  getDescriptionToAskAQuestion(currentCase){
    let description = '';
    let application = currentCase.userEntries.filter(item => item.userEntryType === 'APPLICATION');
    let symptom = currentCase.userEntries.filter(item => item.userEntryType === 'SPECIFIC_SYMPTOM');
    let descriptionQuestion = currentCase.userEntries.filter(item => item.userEntryType === 'DESCRIPTION');
    let descriptionDevices = currentCase.userEntries.filter(item => item.userEntryType === 'DEVICES');
    let browser = currentCase.userSystemInfo.browser ? this.removeNull(currentCase.userSystemInfo.browser) + ' v.' + this.removeNull(currentCase.userSystemInfo.browserVersion) : '';

    description += 'Area: Ask a Question\n';
    description += 'Application: ' + (application && application[0] && application[0].value ? application[0].value : '') + '\n';
    description += symptom && symptom[0] && symptom[0].value ? 'Symptom: ' + symptom[0].value + '\n': '';
    description += 'Description: ' + (descriptionQuestion && descriptionQuestion[0] && descriptionQuestion[0].value ? descriptionQuestion[0].value : '') + '\n';
    description += descriptionDevices && descriptionDevices[0] && descriptionDevices[0].value ? 'Devices: ' + descriptionDevices[0].value + '\n' : '';
    description += 'Source: ' + this.removeNull(currentCase.caseSource)  + '\n';
    description += 'Preferred Contact Method: ' + this.getPreferredContactMethod(currentCase.preferredContactMethod.contactMethodType) + '\n';
    if(currentCase.preferredContactMethod.altContactValue){
      description += 'Alternative Contact: ' + currentCase.preferredContactMethod.altContactValue + '\n';
    }
    description += 'User System Information: ';
    description += '\n\tBrowser: ' + browser;
    description += '\n\tOperating System: ' + this.removeNull(currentCase.userSystemInfo.osVersion);

    return description;
  }

  getDescriptionToReportAProblem(currentCase){
    let description = '';
    let application = currentCase.userEntries.filter(item => item.userEntryType === 'APPLICATION');
    let descriptionQuestion = currentCase.userEntries.filter(item => item.userEntryType === 'DESCRIPTION');
    let pathToProblem = currentCase.userEntries.filter(item => item.userEntryType === 'PATH_TO_PROBLEM');
    let expectedOutcome = currentCase.userEntries.filter(item => item.userEntryType === 'EXPECTED_OUTCOME');
    let allowContact = currentCase.userEntries.filter(item => item.userEntryType === 'ALLOW_CONTACT');
    let browser = currentCase.userSystemInfo.browser ? this.removeNull(currentCase.userSystemInfo.browser) + ' v.' + this.removeNull(currentCase.userSystemInfo.browserVersion) : '';

    description += 'Area: Report a Problem\n';
    description += 'Application: ' + (application && application[0] && application[0].value ? application[0].value : '') + '\n';
    description += 'Description: ' + (descriptionQuestion && descriptionQuestion[0] && descriptionQuestion[0].value ? descriptionQuestion[0].value : '') + '\n';
    description += 'Path To Problem: ' + (pathToProblem && pathToProblem[0] && pathToProblem[0].value ? pathToProblem[0].value : '') + '\n';
    description += 'Expected Outcome: ' + (expectedOutcome && expectedOutcome[0] && expectedOutcome[0].value ? expectedOutcome[0].value : '') + '\n';
    description += 'Allow Contact: ' + (allowContact && allowContact[0] && allowContact[0].value ? allowContact[0].value === 'true' ? 'Yes' : 'No' : '') + '\n';
    description += 'Source: ' + this.removeNull(currentCase.caseSource)  + '\n';
    description += 'Preferred Contact Method: ' + this.getPreferredContactMethod(currentCase.preferredContactMethod.contactMethodType) + '\n';
    if(currentCase.preferredContactMethod.altContactValue){
      description += 'Alternative Contact: ' + currentCase.preferredContactMethod.altContactValue + '\n';
    }
    description += 'User System Information: ';
    description += '\n\tBrowser: ' + browser;
    description += '\n\tOperating System: ' + this.removeNull(currentCase.userSystemInfo.osVersion);

    return description;
  }

  getDescriptionToFeedbackToHP(currentCase){
    let description = '';
    let application = currentCase.userEntries.filter(item => item.userEntryType === 'APPLICATION');
    let descriptionQuestion = currentCase.userEntries.filter(item => item.userEntryType === 'DESCRIPTION');
    let feeling = currentCase.userEntries.filter(item => item.userEntryType === 'FEELING');
    let allowContact = currentCase.userEntries.filter(item => item.userEntryType === 'ALLOW_CONTACT');
    let browser = currentCase.userSystemInfo.browser ? this.removeNull(currentCase.userSystemInfo.browser) + ' v.' + this.removeNull(currentCase.userSystemInfo.browserVersion) : '';

    description += 'Area: Feedback to HP\n';
    description += 'Description: ' + (descriptionQuestion && descriptionQuestion[0] && descriptionQuestion[0].value ? descriptionQuestion[0].value : '') + '\n';
    description += 'Feeling: ' + (feeling && feeling[0] && feeling[0].value ? this.getFeeling(feeling[0].value) : '') + '\n';
    description += 'Allow Contact: ' + (allowContact && allowContact[0] && allowContact[0].value ? allowContact[0].value === 'true' ? 'Yes' : 'No' : '') + '\n';
    description += 'Source: ' + this.removeNull(currentCase.caseSource)  + '\n';
    description += 'Preferred Contact Method: ' + this.getPreferredContactMethod(currentCase.preferredContactMethod.contactMethodType) + '\n';
    if(currentCase.preferredContactMethod.altContactValue){
      description += 'Alternative Contact: ' + currentCase.preferredContactMethod.altContactValue + '\n';
    }
    description += 'User System Information: ';
    description += '\n\tBrowser: ' + browser;
    description += '\n\tOperating System: ' + this.removeNull(currentCase.userSystemInfo.osVersion);

    return description;
  }

  getDescriptionToAnonymousQuestion(currentCase){
    let description = '';


    let firstName = currentCase.userEntries.filter(item => item.userEntryType === 'FIRST_NAME');
    let lastName = currentCase.userEntries.filter(item => item.userEntryType === 'LAST_NAME');
    let accountName = currentCase.userEntries.filter(item => item.userEntryType === 'ACCOUNT_NAME');
    let descriptionQuestion = currentCase.userEntries.filter(item => item.userEntryType === 'DESCRIPTION');
    let browser = currentCase.userSystemInfo.browser ? this.removeNull(currentCase.userSystemInfo.browser) + ' v.' + this.removeNull(currentCase.userSystemInfo.browserVersion) : '';

    description += 'Area: Anonymous Question\n';
    description += 'First Name: ' + (firstName && firstName[0] && firstName[0].value ? firstName[0].value : '') + '\n';
    description += 'Last Name: ' + (lastName && lastName[0] && lastName[0].value ? lastName[0].value : '') + '\n';
    description += 'Company Name: ' + (accountName && accountName[0] && accountName[0].value ? accountName[0].value : '') + '\n';
    description += 'Description: ' + (descriptionQuestion && descriptionQuestion[0] && descriptionQuestion[0].value ? descriptionQuestion[0].value : '') + '\n';
    description += 'Source: ' + this.removeNull(currentCase.caseSource)  + '\n';
    description += 'Preferred Contact Method: ' + this.getPreferredContactMethod(currentCase.preferredContactMethod.contactMethodType) + '\n';
    if(currentCase.preferredContactMethod.altContactValue){
      description += 'Alternative Contact: ' + currentCase.preferredContactMethod.altContactValue + '\n';
    }
    description += 'User System Information: ';
    description += '\n\tBrowser: ' + browser;
    description += '\n\tOperating System: ' + this.removeNull(currentCase.userSystemInfo.osVersion);

    return description;
  }

  getFeeling(feeling){
    switch (feeling){
      case '-1':
        return 'Negative';
      case '0':
        return 'Neutral';
      case '1':
        return 'Positive';
      default :
        return feeling;
    }
  }

  getPreferredContactMethod(contact){
    switch (contact){
      case 'PROFILE_EMAIL':
        return 'Email';
      case 'PROFILE_PHONE':
        return 'Phone';
      case 'NO_CONTACT':
        return 'No Contact';
      default :
        return this.removeNull(contact);
    }
  }

  removeNull(val){
    return val? val : '';
  }

  handleError(error) {
    switch (error.status) {
      case 401:
        this.errorMessageKey = ACTION_UNAUTHORIZED_MESSAGE;
        break;
      default:
        this.errorMessageKey = LOAD_CASE_ERROR;
    }
  }

  static factory(rnHttp, $log, $state, $stateParams, SupportCaseDetailService) {
    'ngInject';
    return new SupportCaseDetailController(rnHttp, $log, $state, $stateParams, SupportCaseDetailService);
  }

  parseParams() {
    this.myParams(this.$stateParams.data);
  }

  myParams(data) {
    this.myParams = {};
    if (data) {
      this.myParams.data = data;
    }
    else {
      return this.myParams.data;
    }
  }

  goToSupportCases($event) {
    $event.stopPropagation();
    let data = this.myParams.data;
    this.$state.go('support-case', {data: data});
  }
}
