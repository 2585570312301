import validUrl from 'valid-url';
import {
  EULA_VERSION_HEADER,
  EULA_CURRENT_VERSION_HEADER,
  EULA_FUTURE_VERSION_HEADER,
  PRIVACY_VERSION_HEADER,
} from 'gazelle/src/components/user/eula/eula.controller';
import { name as declineDialogControllerName } from './decline-dialog.controller';

export const name = 'SettingsEulaController';
export default function SettingsEulaController(
  $window,
  $mdDialog,
  $state,
  $stateParams,
  $gzSignin,
  $gzAnalytics,
  rnHttp,
  rnLocale,
  $q
) {
  //noinspection BadExpressionStatementJS
  'ngInject';

  const ANALYTICS_CATEGORY = 'eula';

  let eulaVersion;
  let privacyVersion;

  let vm = _.assign(this, {
    breadcrumbs: [
      {
        state: 'dashboard',
        titleKey: 'home.home.Home_title'
      },
      {
        state: 'settings',
        titleKey: 'home.home.My_Settings'
      },
      {
        state: 'about',
        titleKey: 'home.home.About'
      },
      {
        state: 'terms',
        titleKey: 'home.home.Terms_Of_Service'
      },
    ],
    eulaText: undefined,
    errorKey: undefined,
    isPrompt: false,
    loading: true,
    submitting: false,
    version: $stateParams.v,
    isFutureVersion: false,
    isOutdatedVersion: false,
    decline,
    accept,
    goBack,
  });

  vm.$onInit = onInit;
  return;
  ///////////////////////

  function onInit() {
    if ($stateParams.prompt) {
      vm.isPrompt = true;
    }

    return rnLocale.getBestFit().then(locale => {
      return getEULA(locale)
        .then(() => {
          if (vm.isPrompt || (vm.version && vm.version !== vm.currentVersion)) {
            return getReleaseNotes(locale);
          }
        })
        .catch(resp => {
          $gzAnalytics.exception('failed to get EULA text: ' + JSON.stringify(resp));
          vm.errorKey = 'home.home.EULA_UnableToGetText';
          vm.isPrompt = false;
        })
        .finally(() => {
          vm.loading = false;
        });
    });
  }

  function decline($event) {
    return $mdDialog.show({
      template: require('./decline-dialog.jade'),
      controller: declineDialogControllerName,
      bindToController: true,
      controllerAs: 'vm',
      openFrom: $event.target,
      closeTo: $event.target,
    }).then(() => {
      $gzAnalytics.eventConfirm('decline', { category: ANALYTICS_CATEGORY });
      $gzSignin.signout();
    }).catch(() => {
      $gzAnalytics.eventCancel('decline', { category: ANALYTICS_CATEGORY });
    });
  }

  function accept($event) {
    vm.submitting = true;

    return $q.all([
      rnHttp.put('aaa', 'users/eula', { eulaVersion }),
      rnHttp.put('aaa', 'users/privacy', { privacyVersion }),
    ]).then(() => {
      $gzAnalytics.eventClick('accept', { category: ANALYTICS_CATEGORY });
      goBack($event);
    }).catch(resp => {
      $gzAnalytics.exception('failed to accept EULA: ' + JSON.stringify(resp));
      vm.errorKey = 'home.home.EULA_FailedToAccept';
      vm.submitting = false;
    });
  }

  function goBack($event) {
    $event.stopPropagation();
    if (validUrl.isWebUri($stateParams.target)) {
      $gzAnalytics.event('close', { category: ANALYTICS_CATEGORY, label: $stateParams.target });
      $window.location.href = $stateParams.target;
    }
    else {
      let targetState = _.get($stateParams, 'data.target.state', undefined);
      if (targetState) {
        $gzAnalytics.event('close', { category: ANALYTICS_CATEGORY, label: targetState });
        $state.go(targetState);
      } else {
        $gzAnalytics.event('close', { category: ANALYTICS_CATEGORY, label: 'about' });
        $state.go('about');
      }
    }
  }

  function getEULA(locale) {
    let config = {
      headers: {
        accept: 'text/html'
      }
    };
    let path = `/i18n/eula/${locale.shortName}`;
    if (vm.version) {
      path += '?v=' + vm.version;
    }
    return rnHttp.get('portal_service', path, config).then(response => {
      vm.eulaText = response.data;
      eulaVersion = response.headers(EULA_VERSION_HEADER);
      vm.currentVersion = response.headers(EULA_CURRENT_VERSION_HEADER);
      vm.futureVersion = response.headers(EULA_FUTURE_VERSION_HEADER);
      privacyVersion = response.headers(PRIVACY_VERSION_HEADER);

      try {
        let currentVersion = $window.parseFloat(vm.currentVersion);
        let futureVersion = $window.parseFloat(vm.futureVersion);
        let version = vm.version ? $window.parseFloat(vm.version) : currentVersion;

        if (version !== currentVersion && version === futureVersion) {
          vm.isFutureVersion = true;
        } else if (version < currentVersion) {
          vm.isOutdatedVersion = true;
        }
      } catch(e) {
        // no-op - could report to GA
      }
    });
  }

  function getReleaseNotes(locale) {
    let config = {
      headers: {
        accept: 'text/html'
      }
    };
    let path = `/i18n/eula/${locale.shortName}/release-notes`;
    if (vm.version) {
      path += '?v=' + vm.version;
    }
    return rnHttp.get('portal_service', path, config).then(resp => {
      vm.releaseNotes = resp.data;
    }).catch(() => {
      // no-op - release notes are optional
    });
  }
}

