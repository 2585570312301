export const name = 'hmPrintBox';
export default function hmPrintBox() {
  //noinspection BadExpressionStatementJS
  'ngInject';

  let directive = {
    restrict: 'EA',
    scope: {
    },
    template: require('./print-box.jade'),
    controller: printBoxController,
    controllerAs: 'vm',
    bindToController: true
  };

  return directive;

  /** @ngInject */
  function printBoxController(
    $log,
    rnHttp,
    $gzBrowser,
    rnLocale,
    $gzGo,
    $gzWalkMe,
    $gzAnalytics,
    $gzTime,
    $q
  ) {
    const WALK_ME_TITLE = 'Experience Box';

    let timezone;

    let vm = this;
    vm.hasDestinationAgent = false;
    vm.startSetupWalkMe = startSetupWalkMe;
    vm.refresh = refresh;

    vm.foldersToday = '?';
    vm.foldersAttention = '?';
    vm.foldersUnread = '?';
    vm.filesToday = '?';
    vm.filesSent = '?';
    vm.filesPrinted = '?';

    vm.playerAPI = {};
    vm.videoId = 'noRCwtBzDhs';
    vm.toggleVideo = toggleVideo;

    vm.loading = true;
    vm.$gzBrowser = $gzBrowser;
    vm.getUrlWithCurrentLocale = getUrlWithCurrentLocale;
    vm.navigateToBox = navigateToBox;
    vm.navigateToUnreadFilter = navigateToUnreadFilter;
    vm.navigateToTodayFilter = navigateToTodayFilter;
    vm.navigateToAlertFilter = navigateToAlertFilter;

    vm.$onInit = onInit;
    return;
    function onInit() {
      let promises = [];
      promises.push(getTimeZone());
      promises.push(initDestinations());
      $q.all(promises).then(() => {
        return refresh();
      })
      .catch(error => {
      })
      .finally(() => {
        vm.loading = false;
      });
    }

    function getTimeZone() {
      return $gzTime.getPreferredTimeZone().then(tz => {
        timezone = tz;
      });
    }

    function initDestinations(){
      return rnHttp.get('aaa', 'organizations/devices/jobsubmissionenabled').then(response => {
        vm.hasDestinationAgent = !!response.data;
      }).catch(() => {
        return $q.resolve();
      });
    }

    function refresh() {
      if (!vm.hasDestinationAgent) {
        return $q.resolve();
      }

      let requestsContainer = {
        requests: [
          {
            id: 'counts.today',
            serviceName: 'box_api',
            path: 'api/folder?view=today',
            params: {
              timezone,
            },
          },
          {
            id: 'counts.unread',
            serviceName: 'box_api',
            path: 'api/folder?view=unread',
            params: {
              timezone,
            },
          },
          {
            id: 'counts.unreadToday',
            serviceName: 'box_api',
            path: 'api/folder?view=today&view=unread',
            params: {
              timezone,
            },
          },
          {
            id: 'counts.attention',
            serviceName: 'box_api',
            path: 'api/folder?view=attention',
            params: {
              timezone,
            },
          },
          {
            id: 'counts.attentionToday',
            serviceName: 'box_api',
            path: 'api/folder?view=today&view=attention',
            params: {
              timezone,
            },
          },
          {
            id: 'box-stats',
            serviceName: 'box_api',
            path: 'api/dashboard/stats?view=today&view=attention',
            params: {
              timezone,
            },
          },
        ],
      };

      return rnHttp.post('portal_service', 'aggregator', requestsContainer).then(resp => {
        let aggregatedResponses = resp.data.responses;

        aggregatedResponses.forEach(resp => {
          if (resp.error || !(resp.status >= 200 && resp.status <= 299)) {
            $log.warn(`Box call failed: ${resp.id}`);
            return;
          }

          if (resp.id === 'box-stats') {
            _.set(vm, 'counts.newFolders', resp.data.foldersToday);
            _.set(vm, 'counts.filesSent', resp.data.filesSent);
            _.set(vm, 'counts.newFiles', resp.data.filesToday);
          } else {
            _.set(vm, resp.id, resp.data.count);
          }
        });
      });
    }

    function startSetupWalkMe($event) {
      $event.stopPropagation();
      return $gzWalkMe.startWalkMeByTitle(WALK_ME_TITLE)
        .catch(err => {
        $log.warn("Unable to get walkMe " + WALK_ME_TITLE, err);
      });
    }

    function toggleVideo() {
      vm.showVideo = !vm.showVideo;
      $gzAnalytics.eventClick('Home - Box Video', {show: vm.showVideo});

      if (vm.showVideo) {
        if (_.get(vm.playerAPI, 'play')) {
          vm.playerAPI.play();
        }
      }
      else {
        if (_.get(vm.playerAPI, 'stop')) {
          vm.playerAPI.stop();
        }
      }
    }

    function getUrlWithCurrentLocale(){
      let url = rnLocale.getUrlWithCurrentLocale('https://cdn-public.printos.com/assets/videos/training/WhatIsBox_','.mp4');
      return url;
    }

    function navigateToBox($event){
      $event.stopPropagation();
      $gzGo.toContext('box');
    }

    function navigateToUnreadFilter($event, today){
      $event.stopPropagation();
      let context = today ? 'box-unread-today' : 'box-unread';
      $gzGo.toContext(context);
    }

    function navigateToTodayFilter($event){
      $event.stopPropagation();
      $gzGo.toContext('box-today');
    }

    function navigateToAlertFilter($event, today){
      $event.stopPropagation();
      let context = today ? 'box-attention-today' : 'box-attention';
      $gzGo.toContext(context);
    }
  }
}
