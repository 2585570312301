import './text-prompt-dialog.scss';
import ngMaterial from 'angular-material';
import rnHttp from 'rhino/src/core/http/http.module';

import * as TextPromptDialogController from './text-prompt-dialog.controller';

export default angular.module('home.components.textPromptDialog', [
  rnHttp,
  ngMaterial,
])
  .controller(TextPromptDialogController.name, TextPromptDialogController.default)
  .name;
