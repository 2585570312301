import {LP_UNASSIGNED_SITE} from 'src/components/landing-page/cards/card-definitions';

export const name = 'hmThreeDDevices';
export default function hmThreeDDevices() {
  //noinspection BadExpressionStatementJS
  'ngInject';

  let directive = {
    restrict: 'EA',
    scope: {
      gzCard: '=?',
    },
    template: require('./threeD-devices.jade'),
    controller: threeDDevicesController,
    controllerAs: 'vm',
    bindToController: true
  };

  return directive;

  /** @ngInject */
  function threeDDevicesController(
    rnHttp,
    $translate,
    $window,
    $q,
    moment,
    $log,
    $scope,
    $interval
  ) {
    let pollerPromise;

    let vm = this;
    vm.loading = true;
    vm.devices = [];
    vm.actionUrl = null;
    vm.goToDevices = goToDevices;
    vm.tryTranslate = tryTranslate;
    vm.selectRow = selectRow;

    vm.$onInit = onInit;
    return;

    function onInit() {
      vm.gzCard.API.config.setSiteCallback(siteChanged);
      getDevices().then(() => {
        vm.gzCard.API.config.setAppLink(vm.actionUrl);
        pollerPromise = $interval(getDevices, 120 * 1000);
        $scope.$on('$destroy', () => {
          if (pollerPromise) { $interval.cancel(pollerPromise); }
        });
      })
        .finally(() => {
          vm.loading = false;
      });
    }

    function getDevices(){
      let config = {
        params: {
          site: _.get(vm.gzCard.API.currentSite.getSite(), 'siteId', -1),
        },
      };

      // Translate our unassigned site constant to theirs
      if (config.params.site === LP_UNASSIGNED_SITE) {
        config.params.site = 'no-site';
      }

      return rnHttp.get('3d_sms_portal', '/devices/cards', config)
        .then(res => {
          $log.debug("sc-widget-getDevices()");
          vm.devices = _.get(res.data, 'cards[0].summary.devices', []);
          vm.actionUrl = _.get(res.data, 'cards[0].action.destination','');
        })
        .catch(errorResponse => {
          $log.debug("fail to get devices widget data:", errorResponse);
          //resetting devices value to handle API error cases
          vm.devices = undefined;
          return $q.resolve();
        });
    }

    function siteChanged() {
      getDevices();
    }

    function goToDevices($event) {
      $event.stopPropagation();
      if ($event.ctrlKey) {
        $window.open(vm.actionUrl, '_blank');
      } else {
        $window.location.href = vm.actionUrl;
      }
    }

    function selectRow(device) {
      if (device.id === vm.selectedId) {
        delete vm.selectedId;
        return;
      }

      vm.selectedId = device.id;
    }

    function tryTranslate(key) {
      let translation = $translate.instant(key);
      if (translation === key) {
        return $translate.instant('home.home.ThreeD_Devices_Unknown');
      }
      return translation;
    }
  }
}
