import './join-org.scss';
import angularTranslate from 'angular-translate';
import angularMaterial from 'angular-material';

import * as JoinOrgController from './join-org.controller';

export default angular.module('home.components.joinOrg', [
  angularTranslate,
  angularMaterial,
])
  .controller(JoinOrgController.name, JoinOrgController.default)
  .name;
