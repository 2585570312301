import './latex-status.scss';

import * as hmLatexStatusDirective from './latex-status.directive';
import angularMoment from 'angular-moment';
import angularTranslate from 'angular-translate';
import ngMaterial from 'angular-material';
import homePrintBeat from 'src/components/landing-page/cards/shared/print-beat/print-beat.module';
import configTrustedResources from '../../trust-images.config';

export default angular.module('home.components.latexStatus', [
  angularMoment,
  angularTranslate,
  homePrintBeat,
  ngMaterial
])
  .directive(hmLatexStatusDirective.name, hmLatexStatusDirective.default)
  .config(configTrustedResources)
  .name;
