import rhinoDevices from 'rhino/src/components/device/device.module';
import gazelleDeviceStatus from 'gazelle/src/components/device-status/device-status.module';
import gazelleAnalytics from 'gazelle/src/core/analytics/analytics.module';

import './my-devices.scss';
import './latex/latex.scss';
import './production-center/production-center.scss';
import './color-control/color-control.scss';

import * as myDevices from './my-devices.directive';

export default angular.module('home.components.widget.my-devices', [
  rhinoDevices,
  gazelleDeviceStatus,
  gazelleAnalytics
])
  .directive(myDevices.name, myDevices.default)
  .name;
