export default function Pagination() {
  const DEFAULT_PAGE = 1;
  const DEFAULT_NUM_ITEMS = 3;
  let config;
  return {
    get PAGINATION_ROW_SELECT() {
      return [10, 50, 100, 250];
    },
    PAGINATION_INITIAL_STATE(orderName) {
      return { filter: '', order: `\'{{"${orderName}" | translate}}\'`,
        limit: this.PAGINATION_ROW_SELECT[DEFAULT_NUM_ITEMS], page: DEFAULT_PAGE };
    },
    PAGE() {
      return !!config.$scope && !!config.$scope.query && !!config.$scope.query.page ? config.$scope.query.page :
        DEFAULT_PAGE;
    },
    OFFSET(limit) {
      return (this.PAGE() - 1) * limit;
    },
    LIMIT() {
      return !!config.$scope && !!config.$scope.query && !!config.$scope.query.limit ? config.$scope.query.limit :
        this.PAGINATION_ROW_SELECT[DEFAULT_NUM_ITEMS];
    },
    setupPagination($scope, orderName, context, reloadList, options = {}) {
      config = { $scope, context, reloadList };
      $scope.selected = [];
      $scope.rowSelect = this.PAGINATION_ROW_SELECT;
      $scope.query = this.PAGINATION_INITIAL_STATE(orderName);
      $scope.onPageChange = (page, limit) => {
        const offset = (page - 1) * limit;
        return this.reloadList({ offset, limit, options });
      };
      // TODO: make a request sorting by column. Uncomment this when functionality is ready.
      //$scope.onOrderChange = order => reloadList($scope.query);
      /* TODO: make a request passing a filter. Uncomment this when functionality is ready.
       $scope.search = predicate => {
         $scope.filter = predicate;
         $scope.deferred = reloadList($scope.query);
       };*/
    },
    reloadList({ limit = this.LIMIT(), offset = -1, options = {} } = {}) {
      // This method must be called after the setupPagination
      offset = offset === -1 ? config.context.OFFSET(limit) : offset;
      this.isLoading = true;
      const reloadPromise = config.reloadList.call(config.context, offset, limit, options);
      if (!!reloadPromise) {
        reloadPromise
          .then()
          .catch(() => this.errorReloadingKey = options.loadError)
          .finally(() => this.isLoading = false);
      }
      return reloadPromise;
    },
    loadInitialPage(options = {}) {
      // Must be called after the setupPagination
      const page = DEFAULT_PAGE;
      const limit = this.LIMIT();
      const offset = (page - 1) * limit;
      return this.reloadList({ offset, limit, options });
    }
  };
}
