import './inklevel.scss';

import * as inklevelWidgetDirective from './inklevel.directive';

export default angular.module('home.components.inklevelWidget', [
])
  .config(inklevelWidgetConfig)
  .directive(inklevelWidgetDirective.name, inklevelWidgetDirective.default)
  .name;

// @ngInject
function inklevelWidgetConfig() {
}
