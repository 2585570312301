export const name = 'AddCardDialogController';
export default function AddCardDialogController(
  // Controller angular dependencies
  allCards,
  family,
  $rootScope,
  $timeout,
  $mdDialog,
  $translate,
) {
  'ngInject';
  // Constants

  // Variables

  // View Model
  let vm = this;
  vm.cardLoading = true;
  //   VM properties
  //   VM methods
  vm.submit = submit;
  vm.cardChanged = cardChanged;
  vm.nextPage = nextPage;
  vm.prevPage = prevPage;
  vm.setPage = setPage;

  vm.activate = activate;
  activate();
  return;

  ///////////////
  // Init
  function activate() {
    vm.cardOptions = [];
    if (vm.allCards && vm.allCards.length > 0) {
      vm.addCard = vm.allCards[0];
      vm.allCards.forEach( card => {
        vm.cardOptions.push({display: $translate.instant(card.titleKey), value: card});
      });
    }

    vm.cardLoading = false;
  }

  ///////////////
  // VM methods
  function submit() {
    $mdDialog.hide(_.cloneDeep(vm.addCard));
  }

  function cardChanged() {
    vm.cardLoading = true;
    $timeout(() => {
      vm.cardLoading = false;
    });
  }

  function prevPage(card) {
    if (card.pageIndex === 0) {
      card.pageIndex = card.pages.length - 1;
    }
    else {
      card.pageIndex--;
    }
    updatePage(card);
  }

  function nextPage(card) {
    if (card.pageIndex + 1 < card.pages.length) {
      card.pageIndex++;
    }
    else {
      card.pageIndex = 0;
    }
    updatePage(card);
  }

  function setPage(card, index) {
    card.pageIndex = index;
    updatePage(card);
  }

  function updatePage(card) {
    if (card.pageCallback) {
      card.pageCallback(card.pages[card.pageIndex]);
    }
  }

  ///////////////
  // Helpers
}
