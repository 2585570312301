import './edit-hp-account-dialog.scss';
import ngMaterial from 'angular-material';

import * as EditHPAccountDialogController from './edit-hp-account-dialog.controller';

export default angular.module('home.components.editHPAccountDialog', [
  ngMaterial,
  'ngSanitize'
])
  .controller(EditHPAccountDialogController.name, EditHPAccountDialogController.default)
  .name;
