export const name = 'CreateOrgController';
export default function CreateOrgController(
  // Controller angular dependencies
  rnClient,
  rnDevice,
  rnLocale,
  rnInvitation,
  rnInstructionsService,
  rnHttp,
  rnSession,
  $translate,
  $state,
  $stateParams,
  $mdDialog,
  $rootScope,
  $window,
  $gzAnalytics,
  $timeout,
  $q
) {
  'ngInject';

  // Constants
  const ANALYTICS_CATEGORY = 'Create Organization';

  // Variables
  let captchaId;
  let userInput;
  let cc;
  let invitationId;
  let invitation;
  let sc;
  let model;
  let type;
  let sn;
  let devicesToAdd = [];
  let sitesToAdd = [];
  let resellersToAdd = [];
  let resellers = [];

  let newContext;


  let site;
  let mapInput;
  let createType;
  let returnUrl;
  let fromState;

  // View Model
  let vm = this;

  //   VM properties
  vm.captchaSiteID = null;
  vm.loading = true;
  vm.processing = false;
  vm.mapOptions = {
    hideNameField: true
  };
  vm.isGenericInvite = false;
  vm.sc = null;
  vm.valid = {
    orgName: false,
  }
  vm.progress = null;
  vm.showProgress = false;

  //   VM methods
  vm.cancel = cancel;
  vm.close = close;
  vm.disableSubmit = disableSubmit;
  vm.showAddDevice = showAddDevice;

  vm.renderRecaptcha = renderRecaptcha;
  vm.executeRecaptcha = executeRecaptcha;

  vm.unitTest = {
  };

  vm.$onInit = onInit;

  ///////////////
  // Init
  function onInit() {
    cc = window.$printos.v1.client.getConfig();

    // Input
    invitationId = $stateParams.invitationId;
    vm.sc = sc = $stateParams.sc;
    model = $stateParams.model;
    type = $stateParams.type;
    sn = $stateParams.sn;
    if (vm.sc) {
      devicesToAdd = window.$printos.v1.preferences.getPreference('devicesToAdd', 'onboarding');
      if (!devicesToAdd) {
        devicesToAdd = [];
      }
    }

    let promises = [];
    promises.push(getSiteKey());
    if (invitationId) {
      promises.push(getInvitationAsync());
      promises.push(getResellersAsync());
    }

    return $q.all(promises).then(() => {

    })
      .catch(error => {
        vm.errorKey = error.errorKey ? error.errorKey : 'home.home.CreateOrg_LoadError';
        vm.errorActionKey = error.errorActionKey ? error.errorActionKey : 'home.home.CreateOrg_LoadErrorAction';
      })
      .finally(() => {
        vm.loading = false;
      });
  }

  function showAddDevice() {
    if (!window.$printos.v1.org.isPSP()) {
      return false;
    }

    if (invitation && invitation.internalId.gbu === 'Indigo' && invitation.internalId.gbuInternalId) {
      return false;
    }

    return true;
  }

  function getInvitationAsync() {
    return window.$printos.v1.http.get('email_service', `invitations/${invitationId}`).then(resp => {
      invitation = resp.data;
      if (invitation.status !== 'Pending') {
        return Promise.reject({
          errorKey: `home.home.InvitationError_${invitation.status}`,
          errorActionKey: `home.home.InvitationErrorAction_${invitation.status}`
        });
      }

      vm.isGenericInvite = invitation.invitationType === 'GENERIC_ADMIN';

      let onboardPayload = _.get(invitation, 'ext_payload.onboard', null);
      // For testing invitation payload since it is hard to test
      // onboardPayload = {
      //   devices: [
      //     {
      //       securityCode: 'b2byh97phr',
      //       firmwareType: 'ATLAS',
      //       type: 'LatexPrinter',
      //       partNumber: '3XD61A',
      //       serialNumber: 'HPJA052324',
      //     },
      //     {
      //       securityCode: 'yeh4zrk7d7',
      //       firmwareType: 'ATLAS',
      //       type: 'LatexPrinter',
      //       partNumber: 'CZ056A',
      //       serialNumber: 'HPSIM24567890443',
      //     },
      //   ],
      //   sites: [],
      //   resellers: [
      //     {
      //       organizationId: '2e1aa911-77be-4fe6-b3ba-7f78132fec59',
      //     },
      //   ],
      // };
      if (onboardPayload) {
        if (onboardPayload.devices) {
          // Store these devices in org settings to add later
          // Note: Until we have org settings implemented, store in preference
          onboardPayload.devices.forEach(d => {
            let myDevice = {
              sc: d.securityCode,
              model: d.partNumber,
              type: d.type,
              sn: d.serialNumber,
              src: 'invitation',
            };
            devicesToAdd.push(myDevice);
          });
        }

        if (onboardPayload.sites) {
          sitesToAdd = onboardPayload.sites;
        }

        if (onboardPayload.resellers) {
          resellersToAdd = onboardPayload.resellers;
        }
      }
    })
      .catch(error => {
        if (error.errorKey) {
          return Promise.reject(error);
        }

        let statusCode = _.get(error, 'smsError.statusCode');
        switch (statusCode) {
          case 404:
            return Promise.reject({
              errorKey: 'home.home.InvitationError_NotFound',
              errorActionKey: 'home.home.InvitationErrorAction_NotFound',
            });
          default:
            return Promise.reject({
              errorKey: 'home.home.InvitationError_Retrieve',
              errorActionKey: 'home.home.InvitationErrorAction_Retrieve',
            });
        }
      });
  }

  function getSiteKey() {
    return window.$printos.v1.http.get('supportapp_service', `captcha/key`, {version: 1}).then(resp => {
      vm.captchaSiteID = resp.data.key;
    });
  }

  function getResellersAsync() {
    let params = { limit: 1000 };
    let config = { params: params};

    return window.$printos.v1.http.get('aaa', 'resellers/', config).then(resp => {
      resellers = resp.data.organizations;
    }).catch(error => {
      vm.errorKey = 'home.home.GettingStartedError';
    });
  }

  ///////////////
  // VM methods
  function cancel() {
    $gzAnalytics.event('Create Organization - Cancel', {category: ANALYTICS_CATEGORY, label: createType});
    if (fromState) {
      $state.go(fromState, {returnUrl});
      return;
    }

    $state.go('dashboard');
  }

  function close() {
    if (vm.isPSP) {
      let taskConfig = {
        data: devicesToAdd
      };

      return window.$printos.v1.go.startTaskAsync('device-add', taskConfig);
    }
    else {
      $state.go('dashboard');
    }
  }

  function disableSubmit() {
    if (!vm.valid.orgName) { return true }
    return !vm.isGenericInvite && !vm.mapOptions.api.isValid();
  }

  function submit() {
    if (vm.mapOptions.api) {
      mapInput = vm.mapOptions.api.getAddress();
    }

    vm.showProgress = true;
    vm.processing = true;
    vm.progress = {};
    vm.progress.createOrg = {
      displayKey: 'home.home.CreateProgress_Org',
      displayValues: [vm.orgName],
      complete: false,
      error: false
    };
    vm.progress.addUser = {
      displayKey: 'home.home.CreateProgress_Admin',
      complete: false,
      error: false
    };
    vm.progress.switchContext =  {
      displayKey: 'home.home.CreateProgress_Context',
      complete: false,
      error: false
    };
    vm.progress.addSite = {
      displayKey: 'home.home.CreateProgress_Site',
      complete: false,
      error: false
    };
    if (invitation) {
      if (resellersToAdd && resellersToAdd.length > 0) {
        vm.progress.addResellers = {
          displayKey: 'home.home.CreateProgress_Reseller',
          complete: false,
          error: false
        };
      }

      return acceptInvitationAsync();
    }
    else {
      return createOrgAsync();
    }
  }

  function acceptInvitationAsync() {
    let payload = {
      name: vm.orgName,
      type: invitation.organizationType,
    };

    return rnInvitation.acceptInvitationWithExistingUser(`invitations/${invitation.id}/org`, 'POST', payload).then(resp => {
      $gzAnalytics.event('Accept Invite - Success', {category: ANALYTICS_CATEGORY});
      newContext = _.get(resp, 'organization.organizationId');
      vm.progress.createOrg.complete = true;
      vm.progress.addUser.complete = true;
    })
      .catch(error => {
        $gzAnalytics.event('Accept Invite - Failed', {category: ANALYTICS_CATEGORY});
        vm.progress.createOrg.complete = true;
        vm.progress.createOrg.error = true;
        vm.progress.addUser.complete = true;
        vm.progress.addUser.error = true;
        if ($window.grecaptcha) {
          $window.grecaptcha.reset(captchaId);
        }
        return Promise.reject();
      })
      .then(() => { return switchContextAsync() })
      .then(() => { return addSitesAsync() })
      .then(() => { return addResellersAsync() })
      .then(() => { return storeDevicesToAddAsync() })
      .catch(error => {
        $gzAnalytics.event('Create Organization - Failed', {category: ANALYTICS_CATEGORY});
        for (const prop in vm.progress) {
          if (!vm.progress[prop].complete) {
            vm.progress[prop].aborted = true;
          }
        }
      })
      .finally(() => {
        vm.processing = false;
      });
  }

  function createOrgAsync() {
    let request = {
      type: 'CreateOrganization',
      userInput: userInput,
      payload: {
        companyName: vm.orgName,
      }
    }

    return window.$printos.v1.http.post('sso_request', `request/new`, request).then(resp => {
      newContext = _.get(resp, 'data.data.organizationId');
      $rootScope.$applyAsync(() => {
        vm.progress.createOrg.complete = true;
        vm.progress.addUser.complete = true;
      });
    })
      .catch(() => {
        $rootScope.$applyAsync(() => {
          vm.progress.createOrg.complete = true;
          vm.progress.createOrg.error = true;
          vm.progress.addUser.complete = true;
          vm.progress.addUser.error = true;
          if ($window.grecaptcha) {
            $window.grecaptcha.reset(captchaId);
          }
          return Promise.reject();
        });
      })
      .then(() => { return switchContextAsync() })
      .then(() => { return addSitesAsync() })
      .then(() => { return storeDevicesToAddAsync() })
      .catch(error => {
        $gzAnalytics.event('Create Organization - Failed', {category: ANALYTICS_CATEGORY});
        for (const prop in vm.progress) {
          if (!vm.progress[prop].complete) {
            vm.progress[prop].aborted = true;
          }
        }
      })
      .finally(() => {
        $rootScope.$applyAsync(() => {
          vm.processing = false;
        });
      });
  }

  function switchContextAsync() {
    return window.$printos.v1.org.setContextAsync(newContext, false).then(() => {
      $rootScope.$applyAsync(() => {
        vm.isPSP = window.$printos.v1.org.isPSP();
        console.log('create-org: context success')
      });
    })
      .catch(() => {
        $rootScope.$applyAsync(() => {
          console.log('create-org: context error')
          vm.progress.switchContext.errror = true;
        });
        return Promise.reject();
      }).finally(() => {
        $rootScope.$applyAsync(() => {
          console.log('create-org: context complete')
          vm.progress.switchContext.complete = true;
        });
      })
  }

  function addSitesAsync() {
    if (invitation && invitation.invitationType === 'GENERIC_ADMIN') {
      $rootScope.$applyAsync(() => {
        console.log('create-org: add site skip (generic)')
      });
      return Promise.resolve();
    }

    if (!mapInput) {
      $rootScope.$applyAsync(() => {
        vm.progress.addSite.error = true;
        console.log('create-org: add site error (mapInput)')
      });
      return Promise.reject();
    }

    let config = {
      name: vm.orgName,
      timezone: mapInput.timezone,
      primaryAddress: mapInput.address,
    };
    config.primaryAddress.name = vm.orgName;

    return rnHttp.post('aaa', 'organizations/sites/', config).then(resp => {
      $rootScope.$applyAsync(() => {
        console.log('create-org: site complete')
        site = resp.data;
      });
    })
      .catch(error => {
        $rootScope.$applyAsync(() => {
          console.log('create-org: site error')
          vm.progress.addSite.error = true;
        });
        return Promise.reject();
      })
      .finally(() => {
        $rootScope.$applyAsync(() => {
          console.log('create-org: site complete')
          vm.progress.addSite.complete = true;
        });
      });
  }

  function addResellersAsync() {
    if (resellersToAdd.length === 0) {
      $rootScope.$applyAsync(() => {
        console.log('create-org: reseller complete (no resellers)')
      });
      return Promise.resolve();
    }

    return getResellersAsync().then(() => {
      let promises = [];
      resellersToAdd.forEach(resellerToAdd => {
        let found = findReseller(resellerToAdd.organizationId);
        if (found) {
          promises.push(addResellerAsync(found.organizationId));
        }
      });

      return $q.all(promises).finally(() => {
        $rootScope.$applyAsync(() => {
          console.log('create-org: reseller success')
        });
      });
    })
      .catch(error => {
        $rootScope.$applyAsync(() => {
          console.log('create-org: reseller error')
          vm.progress.addReseller.error = true;
        });
      })
      .finally(() => {
        $rootScope.$applyAsync(() => {
          console.log('create-org: resellers complete')
          vm.progress.addResellers.complete = true;
        });
      });
  }

  function storeDevicesToAddAsync() {
    if (devicesToAdd.length > 0) {
      return window.$printos.v1.preferences.setOrgPreferenceAsync('devicesToAdd', devicesToAdd, 'onboarding');
    }
    else {
      return Promise.resolve();
    }
  }

  function findReseller(orgId) {
    return resellers.find(reseller => {
      return reseller.organizationId === orgId;
    });
  }

  function addResellerAsync(orgId) {
    return rnHttp.post('aaa', 'organizations/shares', orgId);
  }

  function renderRecaptcha() {
    if ($window.grecaptcha) {
      captchaId = $window.grecaptcha.render('recaptchaCreateOrg', {
        sitekey: vm.captchaSiteID,
        callback: handleCaptcha,
        // Recaptcha errors are also handled in submit method
        'error-callback': handleCaptcha,
        'expired-callback': handleCaptcha,
      });
    }
  }

  function executeRecaptcha() {
    if (invitation) {
      // In this case we do not require captcha
      return submit();
    }

    if ($window.grecaptcha) {
      $window.grecaptcha.execute(captchaId);
      $gzAnalytics.eventClick('Sign up - Captcha executed');
    }
  }

  function handleCaptcha() {
    if ($window.grecaptcha) {
      userInput = $window.grecaptcha.getResponse(captchaId);
    }

    if (userInput && userInput !== '') {
      $rootScope.$applyAsync(() => {
        return submit();
      });
    }

    vm.errorKey = 'register.register.SignUp_Error_Captcha';
    vm.submitting = false;
    $gzAnalytics.event('Sign Up - captcha error');
    return Promise.resolve();
  }

  ///////////////
  // Helpers
}
