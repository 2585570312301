export const name = 'hmSiteFlow';

export default function hmSiteFlow(rnHttp, $log) {
  //noinspection BadExpressionStatementJS
  'ngInject';

  let service = {
    getOrderCounts: getOrderCounts,
  };
  return service;

  /**
   * @name orderCounts
   * @property {number} open number of open orders (currently the sum of dataready, printready, and complete orders)
   * @property {number} pending number of pending orders
   * @property {number} newToday number of orders that are new today
   * @returns {Promise.<orderCounts>}
   */
  function getOrderCounts() {
    let requestsContainer = {
      requests: [
        {
          id: 'simple-counts',
          serviceName: 'prodmgt_api',
          path: 'api/dashboard/order/summary/status',
        },
      ]
    };
    return rnHttp.post('portal_service', 'aggregator', requestsContainer).then(resp => {
      let aggregatedResponses = resp.data.responses;

      let simpleCounts = getSimpleCounts(_.find(aggregatedResponses, { id: 'simple-counts' }));
      return _.merge({}, simpleCounts);
    });
  }

  function getSimpleCounts(response) {
    if (response.error || !(response.status >= 200 && response.status <= 299)) {
      $log.warn(`Siteflow call failed: ${response.id}`);
      return;
    }

    let data = response.data;
    let counts = {
      production:
        getCount(data, 'dataready') +
        getCount(data, 'printready') +
        getCount(data, 'complete'),
      shipped: getCount(data, 'shipped'),
      error: getCount(data, 'error')
    };
    return counts;
  }

  function getCount(data, type) {
    return _.get(_.find(data, { status: type }), 'total', 0);
  }
}
