import ngMaterial from 'angular-material';

// import modules this module depends on
import angularTranslate from 'angular-translate';
import rnSession from 'rhino/src/components/session/session.module';
import rnLocale from 'rhino/src/components/locale/locale.module';
import rnHttp from 'rhino/src/core/http/http.module';
import rnPreferences from 'rhino/src/components/preferences/preferences.module';
import rnUser from 'rhino/src/components/user/user.module';
import $gzSignin from 'gazelle/src/components/signin/signin.module';
import gazelleAnalytics from 'gazelle/src/core/analytics/analytics.module';

// import this modules components
import * as accountsEditController from './accounts_edit.controller';
import * as accountsDeleteController from './account-delete.controller';
import './accounts.scss';

// define the module in one place and export the module name
export default angular.module('home.components.accounts', [
  angularTranslate,
  ngMaterial,
  rnSession,
  rnHttp,
  rnLocale,
  rnPreferences,
  rnUser,
  'ui.router',
  $gzSignin,
  gazelleAnalytics,
])
  .controller(accountsEditController.name, accountsEditController.default)
  .controller(accountsDeleteController.name, accountsDeleteController.default)
  .name;
