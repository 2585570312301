export const name = '$hmSubscriber';
export default function $hmSubscriber(
  rnFeature,
  rnOrganization,
  rnHttp,
  $gzTime,
  $translate,
  $q
) {
  'ngInject';

  // Constants
  const NO_SUBSCRIPTIONS = [];
  const NO_BACKOFFICE_SUBSCRIPTIONS = [[],[]];
  const LF_SCHEDULES = ['PRINTBEAT_LF_WEEKLY_REPORT','PRINTBEAT_LF_MONTHLY_REPORT'];
  const PWP_SCHEDULES = ['PRINTBEAT_PWI_DAILY_REPORT','PRINTBEAT_PWI_WEEKLY_REPORT','PRINTBEAT_PWI_MONTHLY_REPORT'];

  // Variables

  // Service
  let service = {
    getAllSubscriptionsCounts,
    updateLFSchedule,
    deleteLFSchedule,
    createLFSchedule,
    updatePWPSchedule,
    deletePWPSchedule,
    createPWPSchedule,
    getBackOfficeSubscriptions,
    // getPWPSubscriptions,
    // getLFSubscriptions,
    getIndigoSubscriptions,
  };
  return service;

  function getAllSubscriptionsCounts() {
    return $q.all([
      getIndigoSubscriptions(),
      // getBackOfficeSubscriptions(),
    ]).then(([indigoSubs, lfSubs, pwpSubs]) => {
      let counts = {
        available: indigoSubs.length + lfSubs.length + pwpSubs.length,
        subscribed: 0,
      };

      indigoSubs.forEach(sub => {
        if (sub.subscribed) {
          counts.subscribed++;
        }
      });

      lfSubs.forEach(sub => {
        if (sub.subscribed) {
          counts.subscribed++;
        }
      });

      pwpSubs.forEach(sub => {
        if (sub.subscribed) {
          counts.subscribed++;
        }
      });

      return counts;
    });
  }

  function getBackOfficeSubscriptions() {
    const allowedBUs = ['PWI', 'LargeFormat'];
    return rnHttp.get('aaa', 'organizations/summary', {params: {deviceFilter: true}}).then(resp => {
      if (!_.get(resp, 'data.deviceGroups', []).some(r=> allowedBUs.includes(r))) {
        return NO_BACKOFFICE_SUBSCRIPTIONS;
      }
      return _getBackOfficeScheduleSubscriptions();
    })
      .catch(error => {
        return [];
      });
  }

  function getIndigoSubscriptions() {
    return rnHttp.get('aaa', 'organizations/summary', {params: {deviceFilter: true}}).then(resp => {
      if (!_.get(resp, 'data.deviceGroups', []).includes('Indigo')) {
        return NO_SUBSCRIPTIONS;
      }

      return _getIndigoEmailSubscriptions();
    })
      .catch(error => {
        return [];
      });
  }

  // function getPWPSubscriptions() {
  //   return rnOrganization.getSummary().then(resp => {
  //     if (!_.get(resp, 'data.deviceGroups', []).includes('PWI')) {
  //       return NO_SUBSCRIPTIONS;
  //     }
  //
  //     return _getPWPSubscriptions();
  //   })
  //     .catch(error => {
  //       return [];
  //     });
  // }
  //
  // function getLFSubscriptions() {
  //   return rnOrganization.getSummary().then(resp => {
  //     if (!_.get(resp, 'data.deviceGroups', []).includes('LF')) {
  //       return NO_SUBSCRIPTIONS;
  //     }
  //
  //     return _getLFSubscriptions();
  //   })
  //     .catch(error => {
  //       return [];
  //     });
  // }

  function _getIndigoEmailSubscriptions() {
    return $q.all([
      rnHttp.get('email_service', 'unsubscriber/categories'),
      rnHttp.get('email_service', 'unsubscriber/optout'),
      rnHttp.get('email_service', 'unsubscriber/endusersnames'),
      rnHttp.get('aaa', 'organizations'),
    ]).then(([cResp, optResp, eResp, orgResp]) =>  {
      let subscriptions = [];
      let ignore = ['DAILY-PB-REPORT','PB-INSIGHTS'];
      let categories = cResp.data.filter(c => {
        return !ignore.includes(c.id);
      });
      let optOuts = optResp.data;
      let endUserNames = eResp.data;
      let org = orgResp.data;
      let indigoEndUserIds = [];
      if (org.internalIds) {
        org.internalIds.forEach(id => {
          if (id.gbu.toLowerCase() === 'indigo') {
            indigoEndUserIds.push(id);
          }
        });
      }

      let showItemFilter = indigoEndUserIds.length > 1;

      categories.forEach(c => {
        let display;
        let description;
        let scrubbedKey = 'home.home.EmailSubscription_' + c.id.replace(/-/g, '_');
        display = $translate.instant(scrubbedKey);

        if (display === scrubbedKey) {
          // If this occurs it is likely that a new subscription category was added on the server and we don't yet have a
          // mapped key in home.properties
          display = c.description;
        }

        let descKey = `${scrubbedKey}_DESC`;
        description = $translate.instant(descKey);
        if (description === descKey) {
          description = null;
        }

        let model = {
          id: c.id,
          display: display
        };

        if (description) {
          model.description = description;
        }

        model.subCategories = [];

        let optOut = (_.find(optOuts, o => {
          return o.unsubscribeCategory.id === c.id;
        }));

        // User has unsubscribed from everything
        if (optOut && optOut.subCategories.length === 0) {
          model.subscribed = false;
          model.allSubCategories = false;

          _.forEach(indigoEndUserIds, id => {
            if (endUserNames[id.gbuInternalId]) {
              let subCategory = {
                id: id.gbuInternalId,
                subscribed: false,
                display: endUserNames[id.gbuInternalId]
              };
              model.subCategories.push(subCategory);
            }
          });
        }

        // User has unsubscribed from something
        else if (optOut && optOut.subCategories.length > 0) {
          model.subscribed = true;
          model.allSubCategories = false;

          _.forEach(indigoEndUserIds, id => {
            let found = _.find(optOut.subCategories, subCategory => {
              return id.gbuInternalId === subCategory;
            });

            if (endUserNames[id.gbuInternalId]) {
              let subCategory = {
                id: id.gbuInternalId,
                subscribed: found ? false : true,
                display: endUserNames[id.gbuInternalId]
              };

              model.subCategories.push(subCategory);
            }
          });
        }

        // User has unsubscribed from nothing
        else if (!optOut) {
          model.subscribed = true;
          model.allSubCategories = true;

          _.forEach(indigoEndUserIds, id => {
            if (endUserNames[id.gbuInternalId]) {
              let subCategory = {
                id: id.gbuInternalId,
                subscribed: true,
                display: endUserNames[id.gbuInternalId]
              };
              model.subCategories.push(subCategory);
            }
          });
        }

        model.showItemFilter = showItemFilter;
        subscriptions.push(model);
      });

      return subscriptions;
    });
  }

  // function _getReports() {
  //   let reports = [];
  //   let schedules = [];
  //
  //   caches.open('reports').then( chache => {
  //     cache.addAll(['user/scheduletypes', 'user/schedule']).then( () => {
  //       console.log("Data cached ")
  //     });
  //     cache.match('user/scheduletypes').then(typeResp => {
  //       reports = _.get(typeResp, 'data.scheduleTypes', []);
  //     });
  //     cache.match('user/schedule').then(typeResp => {
  //       schedules = _.get(typeResp, 'data.userSchedules', []);
  //     });
  //   });
  //   console.log("reports = ", reports, ", schedules = ", schedules);
  //   // (rnHttp.get('platform-scheduler', 'user/scheduletypes')).then(function(response) {
  //   //   if (response.data) {
  //   //     reports = _.get(response, 'data.scheduleTypes', []);
  //   //     console.log("reports = ", reports);
  //   //   }
  //   // });
  //   return;
  // }

  // function _getPWPSubscriptions() {
  //
  // }
  //
  // function _getLFSubscriptions() {
  //
  // }

  function _getBackOfficeScheduleSubscriptions() {
    return $q.all([
      rnHttp.get('platform-scheduler', 'user/scheduletypes'),
      rnHttp.get('platform-scheduler', 'user/schedule'),
    ]).then(([typeResp, scheduleResp]) => {
      let reports = _.get(typeResp, 'data.scheduleTypes', []);
      let schedules = _.get(scheduleResp, 'data.userSchedules', []);
      //Only include report types we care about in this context
      //Eventually this should be replaced by asking scheduler for a specific CATEGORY like 'PWP_EMAIL'
      let lfReports = reports.filter(report => {
        return LF_SCHEDULES.includes(report.type);
      });

      let pwpReports = reports.filter(report => {
        return PWP_SCHEDULES.includes(report.type);
      });

      lfReports.forEach(report => {
        let found = schedules.find(s => {
          return s.type === report.type;
        });

        report.id = report.type;
        report.schedule = found;
        report.subscribed = found ? true : false;
        report.isLF = true;
        report.isPWP = false;
      });

      pwpReports.forEach(report => {
        let found = schedules.find(s => {
          return s.type === report.type;
        });

        report.id = report.type;
        report.schedule = found;
        report.subscribed = found ? true : false;
        report.isLF = false;
        report.isPWP = true;
      });

      return [pwpReports, lfReports];
    });
  }

  function updateLFSchedule(schedule) {
    let mySchedule = {
      cronExp: schedule.cronExp,
      metadata: schedule.metadata,
      timeZone: schedule.timeZone,
      type: schedule.type,
      userId: null,
      organizationId: null,
    };
    return rnHttp.put('platform-scheduler', `user/schedule`, mySchedule).then(resp => {
      return resp.data;
    });
  }

  function deleteLFSchedule(subscription) {
    return rnHttp.delete('platform-scheduler', `user/schedule/${subscription.type}`);
  }

  function createLFSchedule(subscription, sites, timeZone) {
    let mySites = [];
    sites.forEach(site => {
      mySites.push(site.siteId);
    });

    let data = {
      userId: null,
      organizationId: null,
      type: subscription.type,
      cronExp: subscription.defaultCron,
      timeZone: timeZone,
      metadata: {
        sites: mySites,
      }
    };

    return rnHttp.post('platform-scheduler', `user/schedule`, data).then(resp => {
      return resp.data;
    });
  }

  function updatePWPSchedule(schedule) {
    let mySchedule = {
      cronExp: schedule.cronExp,
      metadata: schedule.metadata,
      timeZone: schedule.timeZone,
      type: schedule.type,
      userId: null,
      organizationId: null,
    };
    return rnHttp.put('platform-scheduler', `user/schedule`, mySchedule).then(resp => {
      return resp.data;
    });
  }

  function deletePWPSchedule(subscription) {
    return rnHttp.delete('platform-scheduler', `user/schedule/${subscription.type}`);
  }

  function createPWPSchedule(subscription, sites, timeZone) {
    let mySites = [];
    sites.forEach(site => {
      mySites.push(site.siteId);
    });

    let data = {
      userId: null,
      organizationId: null,
      type: subscription.type,
      cronExp: getPWPCronExp(subscription),
      timeZone: timeZone,
      metadata: {
        sites: mySites,
      }
    };

    return rnHttp.post('platform-scheduler', `user/schedule`, data).then(resp => {
      return resp.data;
    });
  }
}

  function getPWPCronExp(subscription) {
    switch (subscription.type) {
      case 'PRINTBEAT_PWI_WEEKLY_REPORT':
        return '0 0 9 ? * WED *';
        break;
      case 'PRINTBEAT_PWI_MONTHLY_REPORT':
        return '0 0 9 3 * ? *';
        break;
      default:
        return subscription.defaultCron;
        break;
    }
  }
