import rhino from 'rhino';
import angularTranslate from 'angular-translate';

import './my-account.scss';
import * as hmMyAccountDirective from './my-account.directive';
import gazelleAnalytics from 'gazelle/src/core/analytics/analytics.module';
import gazelleProfileImage from 'gazelle/src/components/profile-image/profile-image.module';

export default angular.module('home.components.widget.myAccount', [
  rhino,
  angularTranslate,
  'gazelle.components.go',
  gazelleAnalytics,
  gazelleProfileImage
])
  .directive(hmMyAccountDirective.name, hmMyAccountDirective.default)
  .name;
