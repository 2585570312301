import BUHelper from "../../util/bu_helper";

export const moduleName = 'askaquestionService';
export default function askaquestionService(rnHttp, rnLocale, rnRegistry) {
  //noinspection BadExpressionStatementJS
  'ngInject';
  const APPLICATIONS_SEGMENT = 'apps';
  const CONFIG_SEGMENT = 'client/config';
  const TUTORIALS_SEGMENT = 'tutorials';
  const PORTAL_SERVICE = 'portal_service';
  const SUPPORT_SERVICE = 'supportapp_service';
  const GROUP_NAME = ["service-center-group"];

  const buHelper = BUHelper.factory();

  return {
    getApplications: getApplications,
    getPortalApplications: getPortalApplications,
    getDevices: getDevices,
    getPreferenceLanguage:getPreferenceLanguage,
    getUrlSend: getUrlSend,
    getCountries: getCountries,
    getCaseAvoidanceTutorials: getCaseAvoidanceTutorials,
    getCasesCategorization: getCasesCategorization
  };

  function getApplications() {
    const apps = [];
    let portalApps;
    return getApps(true).then(portalAppsResponse => {
      portalApps = !!portalAppsResponse ? portalAppsResponse : [];
      mapAppGroup(portalApps);
      portalApps.forEach(portalApp => {
        if (!!portalApp && !!portalApp.loc && (portalApp.allowApplicationSwitcher === undefined ||
        portalApp.allowApplicationSwitcher !== false) && !(portalApp.type === "group") && !portalApp.group) {
          apps.push({
            display: portalApp.loc.name,
            value: {
              id: portalApp.appId,
              description: portalApp.description,
              name: portalApp.loc.name,
              englishName: portalApp.loc.name,
              appId: portalApp.appId,
              type: portalApp.type
            }
          })
        }
      });
    return apps;
    });
  }


  function mapAppGroup(portalApps) {
    for (let j = 0; j < portalApps.length; j++ ) {
      if (GROUP_NAME.includes(portalApps[j].group)) {
        let app = {};
        for (let i = 0; i < portalApps[j].groupOptions.length; i++) {
          app = {
            appId: portalApps[j].groupOptions[i].id,
            loc: {name: portalApps[j].groupOptions[i].name},
            type: portalApps[j].type,
            description: portalApps[j].description,
            name: portalApps[j].groupOptions[i].name,
            svg: portalApps[j].svg,
            appUrl: portalApps[j].appUrl,
            _links: portalApps[j]._links
          };
          portalApps.push(app);
        }
      }
    }
  }

  function getPortalApplications(locale='') {
    return rnHttp.get(PORTAL_SERVICE, APPLICATIONS_SEGMENT + locale, { cache : true });
  }

  function getClientConfig(cache = true) {
    const config = {
      // Update the cache when not caching
      cache: cache ? true : 'refresh',
      version: 2,
    };
    return rnHttp.get(PORTAL_SERVICE, CONFIG_SEGMENT, config);
  }

  function getApps(cache = true) {
    return getClientConfig(cache).then(clientConfig => {
        return clientConfig.data.apps.entity;
    });
  }

  function getPreferenceLanguage() {
    return rnLocale.getBestFit().then(locale => {
      return locale;
    });
  }

  function getUrlSend() {
    let path = 'cases';
    return rnRegistry.getUrl(SUPPORT_SERVICE).then(url => {
      return url + path;
    });
  }

  function getCountries() {
    let localizedCountries;
    return rnLocale.getBestFit()
      .then(locale => {
      let portalCountriesOpts = {
        cache: true,
        params: {
          locale: locale.value
        }
      };
      return rnHttp.get('portal_service', 'preferences/countries', portalCountriesOpts)
        .then(response => {
          localizedCountries = response.data;
          portalCountriesOpts.params.locale = "en-US";
          return rnHttp.get('portal_service', 'preferences/countries', portalCountriesOpts);
        }).then(response => {
          localizedCountries = localizedCountries.map(localizedCountry => {
            const englishCountry = _.find(response.data, { isoCode: localizedCountry.isoCode });
            return { isoCode: localizedCountry.isoCode, localizedName: localizedCountry.localizedName, englishName: englishCountry.localizedName};
          });
          return localizedCountries;
        });
    });
  }

  function getCaseAvoidanceTutorials(application) {
    return getPreferenceLanguage().then(prefLang => {
      const config = {
        params: {
          caseAvoidanceApp: application,
          locale: prefLang.value
        }
      };
      return rnHttp.get(SUPPORT_SERVICE, TUTORIALS_SEGMENT, config);
    });
  }

  function getDevices() {
    const Devs = [];
    let config = {
      params: {
        limit: 10000,
        offset: 0,
      },
    };
    
    return rnHttp.get('aaa', 'users/context', config).then(resp => {
      return resp.data.total==0 ? Promise.reject("User is orphan") : rnHttp.get('aaa', 'organizations/devices/v2', config).then(resp => {
        resp.data.devicesWithContext[0].devices.forEach(dev => {
          Devs.push({
            display: dev.name + " - " + dev.serialNumber,
            value: {
              id: dev.deviceId,
              name: dev.name,
              model: dev.model,
              serialNumber: dev.serialNumber,
              bu: buHelper.getBUNameByDevice(dev),
              edit: {
                model: dev.model,
                serialNumber: dev.serialNumber,
                bu: buHelper.getBUNameByDevice(dev),
              }
            },
          });
        });
      return Devs;
      });
    });
  }

  function getCasesCategorization(bu, application){
    let config = {
      params: {
        bu: bu,
        application: application,
      },
    };

    return rnHttp.get(SUPPORT_SERVICE, 'casescategorization', config).then(resp => resp.data);
  }
  
}

