const ANGULAR_APP_PROPERTY = 'ng-app';
const DATA_ANGULAR_APP_PROPERTY = 'data-ng-app';
const APP_PROPERTY_SELECTOR = `[${ANGULAR_APP_PROPERTY}]`;
const DATA_APP_PROPERTY_SELECTOR = `[${DATA_ANGULAR_APP_PROPERTY}]`;
export const HOME_APP_NAME = 'home';
export const ADMIN_APP_NAME = 'admin';
export const MEDIA_LOCATOR_APP_NAME = 'ml';
export const BOX_APP_NAME = 'printBoxApp';
export const moduleName = 'ContextService';
export default class ContextService {
  constructor() { }

  isHome() {
    return this.hasSameModuleName(HOME_APP_NAME);
  }

  isAdmin() {
    return this.hasSameModuleName(ADMIN_APP_NAME);
  }

  isMediaLocator() {
    return this.hasSameModuleName(MEDIA_LOCATOR_APP_NAME);
  }

  isBox() {
    return this.hasSameModuleName(BOX_APP_NAME);
  }

  hasSameModuleName(name) {
    const appName = this.currentAppName();
    return !!appName && appName.trim().toLowerCase() === name.trim().toLowerCase();
  }

  currentAppName() {
    return angular.element(document.querySelector(APP_PROPERTY_SELECTOR))
        .attr(ANGULAR_APP_PROPERTY) || angular.element(document.querySelector(DATA_APP_PROPERTY_SELECTOR))
        .attr(DATA_ANGULAR_APP_PROPERTY);
  }

  static factory() {
    return new ContextService();
  }
}
