import ngMaterial from 'angular-material';
import uiRouter from 'angular-ui-router';

// import modules this module depends on
import angularTranslate from 'angular-translate';
import rnCore from 'rhino/src/core/core.module';
import rnHttp from 'rhino/src/core/http/http.module';
import rnSession from 'rhino/src/components/session/session.module';
import rnOrganization from 'rhino/src/components/organization/organization.module';
import rnInvitation from 'rhino/src/components/invitation/invitation.module';
import gazelleAnalytics from 'gazelle/src/core/analytics/analytics.module';

// import this modules components
import * as WowController from './wow.controller.js';

import './wow.scss';

// define the module in one place and export the module name
export default angular.module('account.wow', [
  angularTranslate,
  rnCore,
  rnSession,
  rnOrganization,
  rnInvitation,
  ngMaterial,
  uiRouter,
  gazelleAnalytics,
  rnHttp,
])
  .controller(WowController.name, WowController.default)
  .name;
