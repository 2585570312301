import gazelleAnalytics from 'gazelle/src/core/analytics/analytics.module';
import gzTime from 'gazelle/src/components/time/time.module';
import angularTranslate from 'angular-translate';
import rhinoFeature from 'rhino/src/components/feature/feature.module';
import rhinoCore from 'rhino/src/core/core.module';
import rhinoOrganization from 'rhino/src/components/organization/organization.module';
import rhinoClient from 'rhino/src/core/client/client.module';
import angularMoment from 'angular-moment';

import './email-subscriptions.scss';

import * as EmailSubscriptionsController from './email-subscriptions.controller';
import * as $hmSubscriber from './subscriber.service';

export default angular.module('home.components.emailSubscriptions', [
  gazelleAnalytics,
  angularTranslate,
  rhinoCore,
  rhinoFeature,
  rhinoOrganization,
  rhinoClient,
  angularMoment,
  gzTime,
])
  .controller(EmailSubscriptionsController.name, EmailSubscriptionsController.default)
  .factory($hmSubscriber.name, $hmSubscriber.default)
  .name;
