import rhino from 'rhino';
import angularTranslate from 'angular-translate';

import './application-center.scss';
import images from 'src/images/images.module';

import * as hmApplicationCenterDirective from './application-center.directive';
import gazelleAnalytics from 'gazelle/src/core/analytics/analytics.module';

export default angular.module('home.components.widget.hmApplicationCenter', [
  rhino,
  angularTranslate,
  'gazelle.components.go',
  gazelleAnalytics,
  images
])
  .directive(hmApplicationCenterDirective.name, hmApplicationCenterDirective.default)
  .name;
