import angularTranslate from 'angular-translate';

import './quick-links.scss';
import * as hmQuickLinksDirective from './quick-links.directive';
import gazelleAnalytics from 'gazelle/src/core/analytics/analytics.module';

export default angular.module('home.components.widget.quickLinks', [
  angularTranslate,
  'gazelle.components.go',
  gazelleAnalytics
])
  .directive(hmQuickLinksDirective.name, hmQuickLinksDirective.default)
  .name;
