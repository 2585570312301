import rhino from 'rhino';
import angularTranslate from 'angular-translate';

import './maintenance.scss';
import * as hmMaintenanceDirective from './maintenance.directive';

export default angular.module('home.components.widget.maintenance', [
  rhino,
  angularTranslate,
])
  .directive(hmMaintenanceDirective.name, hmMaintenanceDirective.default)
  .name;
