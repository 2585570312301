export const name = 'hmIndigoCustomerScores';
export default function hmIndigoCustomerScores(
  // Directive angular dependencies
) {
  'ngInject';

  let directive = {
    restrict: 'EA',
    scope: {
        gzCard: '=',
    },
    template: require('./indigo-customer-scores.jade'),
    controller: indigoCustomerScoresController,
    controllerAs: 'vm',
    bindToController: true,
  };
  return directive;

  //////////////////////

  // function indigoCustomerScoresLink(scope, element, attrs) {
  // }

  /** @ngInject */
  function indigoCustomerScoresController(
    rnHttp,
    $gzGo,
    $q,
    $window
  ) {
    // Constants

    // View Model
    let vm = this;

    //VM properties
    vm.loading = true;
    vm.diameter = 60;

    //VM methods
    vm.$onInit = onInit;
    vm.goToServiceCenter = goToServiceCenter;
    return;

    ///////////////
    // Init
    function onInit() {
      let promises = [];
      promises.push(getHighPerformers());
      promises.push(getLowPerformers());
      return Promise.all(promises).then(() => {

      })
        .finally(() => {
          vm.loading = false;
        });
    }

    ///////////////
    // Init
    function getHighPerformers() {
      vm.highPerformers = [];
      let params = {
        fromIndex: 0,
        order: 1,
        pivot: 'score',
        toIndex: 5,
      };
      return rnHttp.get('PrintbeatService', 'gsb/fleet', {params}).then(resp => {
        processPerformers(resp.data.rows, vm.highPerformers);
      });
    }

    function getLowPerformers() {
      vm.lowPerformers = [];
      let params = {
        fromIndex: 0,
        order: 0,
        pivot: 'score',
        toIndex: 5,
      };
      return rnHttp.get('PrintbeatService', 'gsb/fleet', {params}).then(resp => {
        processPerformers(resp.data.rows, vm.lowPerformers);
      });
    }

    function processPerformers(rows, dest) {
      rows.forEach(row => {
        let style = 'ok';
        if (row.score < 30) {
          style='error';
        }
        else if (row.score < 80) {
          style = 'warn';
        }
        let customer = {
          rating: row.score,
          title: row.orgName,
          style: style,
        };
        dest.push(customer);
      });
    }

    ///////////////
    // VM methods
    function goToServiceCenter($event){
      $event.stopPropagation();
      $gzGo.toContext('pb-channel-dashboard');
    }
  }
}
