import './indigo-press.scss';

import rhino from 'rhino';
import angularTranslate from 'angular-translate';
import gazelleAnalytics from 'gazelle/src/core/analytics/analytics.module';
import gazelleGo from 'gazelle/src/components/go/go.module';
import images from 'src/images/images.module';
import homePrintBeat from 'src/components/landing-page/cards/shared/print-beat/print-beat.module';

import * as hmIndigoPressDirective from './indigo-press.directive';

export default angular.module('home.components.widget.hmIndigoPress', [
  rhino,
  angularTranslate,
  gazelleGo,
  gazelleAnalytics,
  images,
  homePrintBeat,
])
  .directive(hmIndigoPressDirective.name, hmIndigoPressDirective.default)
  .name;
