import {
  LP_UNASSIGNED_SITE,
} from '../../card-definitions';

export const LINK_DEFAULTS = {
  page: 'dashboard',
};

export const name = '$hmPrintBeat';
export default function $hmPrintBeat(
  rnHttp,
  rnPreferences,
  moment,
  $window
) {
  'ngInject';

  const DATE_FORMAT = 'YYYY-MM-DD';

  const service = {
    createLink,
    redirect,
    queryLFPApi,
  };
  return service;

  function createLink(context) {
    let mergedContext = _.assign({}, LINK_DEFAULTS, context);

    let pbUrl = `/pb/#/redirect/${mergedContext.page}/${mergedContext.family}`;
    if (mergedContext.siteId === LP_UNASSIGNED_SITE) {
      // Use the Print Beat default of -1 for unassigned
      mergedContext.siteId = '-1';
    }

    if (mergedContext.siteId) {
      pbUrl += `/${mergedContext.siteId}`;
    }
    if (mergedContext.serialNumber) {
      pbUrl += `/${mergedContext.serialNumber}`;
    }
    return pbUrl;
  }

  function redirect(context, $event = {}) {
    let link = createLink(context);
    if ($event.ctrlKey) {
      $window.open(link, '_blank');
    } else {
      $window.location.href = link;
    }
  }

  function queryLFPApi(api, params) {
    return getUnitSystemPref().then(unitSystem => {
      let config = {
        params: Object.assign({
          isUTC: false,
          unitSystem,
        }, params)
      };

      if (moment.isMoment(config.params.from)) {
        config.params.from = config.params.from.format(DATE_FORMAT);
      }
      if (moment.isMoment(config.params.to)) {
        config.params.to = config.params.to.format(DATE_FORMAT);
      }

      return rnHttp.get('PrintbeatService', `gsb/lfp/graphs/${api}`, config).then(rnHttp.resolveWithData);
    });
  }

  function getUnitSystemPref() {
    return rnPreferences.getAll().then(data => {
      return _.get(data, 'general.unitSystem', 'Metric');
    });
  }
}
