import './edit-job-info-dialog.scss';
import ngMaterial from 'angular-material';
import rnHttp from 'rhino/src/core/http/http.module';
import angularTranslate from 'angular-translate';
import gazelleAnalytics from 'gazelle/src/core/analytics/analytics.module';

import * as EditJobInfoDialogController from './edit-job-info-dialog.controller';

export default angular.module('home.components.editJobInfoDialog', [
  rnHttp,
  ngMaterial,
  angularTranslate,
  gazelleAnalytics
])
  .controller(EditJobInfoDialogController.name, EditJobInfoDialogController.default)
  .name;
