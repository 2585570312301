import './confirm-dialog.scss';
import ngMaterial from 'angular-material';

import * as ConfirmDialogController from './confirm-dialog.controller';

export default angular.module('home.components.settings.setDefaultApp.confirmDialog', [
  ngMaterial,
])
  .controller(ConfirmDialogController.name, ConfirmDialogController.default)
  .name;
