import { LP_DEVICE_FAMILY } from '../../card-definitions';

export const name = 'hmLatexEfficiency';
export default function hmLatexEfficiency(
  // Directive angular dependencies
) {
  'ngInject';

  let directive = {
    restrict: 'EA',
    scope: {
      gzCard: '=',
    },
    template: require('./latex-efficiency.jade'),
    controller: latexEfficiencyController,
    controllerAs: 'vm',
    bindToController: true,
  };
  return directive;

  //////////////////////

  /** @ngInject */
  function latexEfficiencyController(
    // Controller angular dependencies
    $q,
    moment,
    $translate,
    $hmPrintBeat,
    rnHttp,
    rnPreferences,
    $window
  ) {
    // Constants
    const METRIC_UNIT_SYSTEM = 'Metric';
    // Variables
    let lpfApiParams;
    let useMetric = true;
    let pbContext = {};

    // View Model
    let vm = this;
    vm.loading = true;
    vm.units = {};
    vm.dateRange = undefined;
    vm.abbrOpts = {
      minimumFractionDigits: 0 ,
      maximumFractionDigits: 2
    };

    vm.abs = $window.Math.abs;
    vm.goToPBReport = goToPBReport;

    vm.$onInit = onInit;
    return;

    function onInit() {
      vm.dateRange = [moment.utc().subtract(7, 'days').format('D MMM'), moment.utc().subtract(1, 'days').format('D MMM')];

      lpfApiParams = {
        serial: vm.gzCard.API.currentSite.getPrintBeatSerialNumbersByFamily(LP_DEVICE_FAMILY.LF, true),
        // The period of this week constitutes the data we present
        from: moment.utc().subtract(31, 'days'),
        // PrintbeatService API uses the following date range: (start, end]
        to: moment.utc(),
        resolution: 'Day',
      };

      pbContext ={
        page: 'reports-analytics',
        family: 'LatexPrinter',
        siteId: vm.gzCard.API.currentSite.getSite().siteId,
      };
      let appLink = $hmPrintBeat.createLink(pbContext);
      vm.gzCard.API.config.setAppLink(appLink);

      return getPreferences().then(() => {
        return $q.all([
          getPrintProduction(),
          getInkConsumption(),
          getSubtrateConsumption(),
          getUptime()
        ]).finally(() => {
          vm.loading = false;
        });
      });

    }

    function getPreferences() {
      return rnPreferences.getAll().then(data => {
        useMetric = _.get(data, 'general.unitSystem') === METRIC_UNIT_SYSTEM;
        vm.units = {
          length: useMetric ? $translate.instant('uxf.uxf.Unit_SquareMeters') : $translate.instant('uxf.uxf.Unit_SquareFeet'),
          volume: $translate.instant('uxf.uxf.Unit_Liters'),
          hours: $translate.instant('uxf.uxf.Unit_Hours'),
        };
      });
    }

    function getData(category, params) {
      let mergedParams = Object.assign({}, lpfApiParams, params);
      return $hmPrintBeat.queryLFPApi(category, mergedParams).then(data => {
        let totalEventsList = _.get(data, 'data.dataMap.TotalValuesPB.eventsList', []);
        if (totalEventsList.length === 0) {
          // Reject no data with defaults handled in the catch
          return $q.reject();
        }

        let total7Day = totalEventsList.slice(-8, -1).reduce((acc, day) => {
          return acc + day.value;
        }, 0);

        let avg7Day = total7Day / 7;
        let avg30Day = _.get(data, 'data.average');
        let percentage = percentFromAverage(avg7Day, avg30Day);

        return {
          total7Day,
          avg7Day,
          avg30Day,
          percentage,
          unitSystem: data.unitSystem,
        };
      }).catch(() => {
        return {
          total7Day: null,
          avg7Day: null,
          avg30Day: null,
          percentage: null,
          unitSystem: null,
        };
      });
    }

    function getPrintProduction() {
      return getData('printprod').then(data => {
        vm.printProduction = data;
      });
    }

    function getInkConsumption() {
      return getData('ink', {unitSystem: METRIC_UNIT_SYSTEM}).then(data => {
        vm.inkConsumption = data;
      });
    }

    function getSubtrateConsumption() {
      // For substrates, we only care about the last 7 days
      let apiParams = Object.assign({}, lpfApiParams, {
        from: moment().subtract(7, 'days'),
      });

      return $hmPrintBeat.queryLFPApi('substrate', apiParams).then(data => {
        let substrateConsumption = data.data;
        let totalsEventList = _.get(substrateConsumption, 'dataMap.TotalValuesPB.eventsList', []);
        if(totalsEventList.length === 0) {
          // Reject no data with defaults handled in the catch
          return $q.reject();
        }

        let topSubstrate;
        let allTotal = 0;

        _.forEach(substrateConsumption.dataMap, substrate => {
          // This API commingles the aggregated 'TotalValuesPB' object with the individual
          // substrate objects
          if (substrate.type === 'TotalValuesPB') {
            allTotal = substrate.totalForPeriod;
          } else {
            if (!topSubstrate) {
              topSubstrate = substrate;
            }
            else {
              if (substrate.totalForPeriod > topSubstrate.totalForPeriod) {
                topSubstrate = substrate;
              }
            }
          }
        });

        let name = _.get(topSubstrate, 'type', null);
        let total = topSubstrate ? topSubstrate.totalForPeriod : null;

        let percentage = 0;
        if (allTotal !== 0) {
          percentage = Math.round( (total / allTotal) * 100 );
        }

        vm.substrateConsumption = {
          name: name,
          total: total,
          percentage: percentage,
          allTotal,
        };
      }).catch(() => {
        vm.substrateConsumption = {
          name: null,
          total: null,
          percentage: null,
          allTotal: null,
        };
      });
    }

    function getUptime() {
      // For uptime, we only care about the last 7 days
      let apiParams = Object.assign({}, lpfApiParams, {
        from: moment().subtract(7, 'days'),
      });

      return $hmPrintBeat.queryLFPApi('uptime', apiParams).then(data => {
        let totalsEventList = _.get(data, 'data.dataMap.TotalValuesPB.eventsList', []);
        if (totalsEventList.length === 0) {
          // Reject no data with defaults handled in the catch
          return $q.reject();
        }

        let totals = totalsEventList.slice(-8).reduce((acc, day) => {
          if (day.printingHours) {
            acc.printingHours += day.printingHours;
          }
          if (day.uptimeHours) {
            acc.uptimeHours += day.uptimeHours;
          }
          return acc;
        }, { printingHours: 0, uptimeHours: 0 });

        totals.percentage = 0;
        if (totals.uptimeHours !== 0) {
          totals.percentage = Math.round( (totals.printingHours / totals.uptimeHours) * 100 );
        }

        vm.uptime = totals;
      }).catch(() => {
        vm.uptime = {
          printingHours: null,
          uptimeHours: null,
          percentage: null,
        };
      });
    }

    function percentFromAverage(total, average) {
      // Special case to prevent NaN
      if (average === 0) {
        return 0;
      }

      let percentOfAvg = (total / average) * 100;
      let percentDifference = percentOfAvg - 100;
      return $window.Math.round(percentDifference);
    }

    function goToPBReport($event, type = undefined) {
      $event.stopPropagation();
      let siteId = vm.gzCard.API.currentSite.getSite().siteId;
      switch(type) {
        case 'production':
          openURL(`/pb/#/reports-analytics/printProduction?siteID=${siteId}`, $event);
          break;
        case 'printing':
          openURL(`/pb/#/reports-analytics/uptime?siteID=${siteId}`, $event);
          break;
        case 'substrate':
          openURL(`/pb/#/reports-analytics/substrateConsumption?siteID=${siteId}`, $event);
          break;
        case 'ink':
          openURL(`/pb/#/reports-analytics/inkConsumption?siteID=${siteId}`, $event);
          break;
        default:
          $hmPrintBeat.redirect(pbContext, $event);
      }
    }

    function openURL(url, $event = {}) {
      if ($event.ctrlKey) {
        $window.open(url, '_blank');
      }
      else {
        $window.location.href = url;
      }
    }
  }
}
