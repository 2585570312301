export const moduleName = 'textAreaAutoSize';
export default class TextAreaAutoSize {

  constructor() {
    this.require = '^ngModel';
    this.restrict = 'A';
    this.scope = {
      referenceTo: '@',
      addMoreHeight: '@',
      minHeight: '@'
    };
    this.controller = ['$window', '$scope', ($window, $scope) => {
      $scope.$window = $window;
    }];
  }

  link(scope, el, attrs, ctrl) {
    let maxHeight = 0;
    let element = angular.element(el)[0];
    element.style.minHeight = !!scope.minHeight ? scope.minHeight + 'px' : '';
    let initialHeight = !initialHeight ? element.offsetHeight : initialHeight;
    const plusMoreHeight = isNaN(scope.addMoreHeight) ? 0 : scope.addMoreHeight * 1;

    const expand = () => {
      const element = angular.element(el)[0];
      const nextElement = getNextElement(element);
      if (maxHeight === 0) {
        maxHeight = getDistanceBetweenElements(element, nextElement) + plusMoreHeight;
        element.style.maxHeight = Math.ceil(maxHeight) + 'px';
      }
      setElementHeight(element);
    };

    const getNextElement = (element) => {
      if (!!scope.referenceTo) {
        return angular.element(document.querySelector('#' + scope.referenceTo))[0];
      } else {
        return element.nextElementSibling;
      }
    };

    const getDistanceBetweenElements = (currentElement, nextElement) => {
      const currentElementPosition = getAxisLimits(currentElement);
      const nextElementPosition = getAxisLimits(nextElement);
      const elementStyle = scope.$window.getComputedStyle(currentElement);
      const paddingBottom = parseFloat(elementStyle.paddingBottom.replace('px', ''));
      const paddingTop = parseFloat(elementStyle.paddingTop.replace('px', ''));
      return Math.floor(nextElementPosition.y - (currentElement.clientHeight + currentElementPosition.y) +
        paddingBottom + paddingTop);
    };

    const getAxisLimits = element => {
      const dataElement = element.getBoundingClientRect();
      return {
        x: dataElement.left,
        y: dataElement.top
      };
    };

    const setElementHeight = element => {
      const elementStyle = scope.$window.getComputedStyle(element);
      const paddingBottom = parseFloat(elementStyle.paddingBottom.replace('px', ''));
      const paddingTop = parseFloat(elementStyle.paddingTop.replace('px', ''));
      const currentHeight = element.clientHeight + paddingBottom + paddingTop;
      const textWidth = getTextWidth(element);
      const newHeight = (textWidth !== 0 && currentHeight < element.scrollHeight) ? element.scrollHeight : currentHeight;

      element.style.height = (textWidth === 0) ? initialHeight + 'px' : newHeight + 'px';
      element.style.overflowY = (newHeight >= maxHeight) ? 'auto' : '';
    };

    const getTextWidth = (element) => {
      const font = scope.$window.getComputedStyle(element, null).getPropertyValue('font');
      const text = element.value;
      const canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement('canvas'));
      const context = canvas.getContext('2d');
      context.font = font;
      const metrics = context.measureText(text);
      return metrics.width;
    };

    angular.element(el).bind('keyup', () => {
      expand();
      ctrl.$render();
    });
  }

  static factory() {
    return new TextAreaAutoSize();
  }
}
