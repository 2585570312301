import {LP_DEVICE_FAMILY, ACCOUNT_TYPE, BU, FEATURE} from "../../card-definitions";

export const promoDefinitions = [
  // Example Promo
  // {
  //   id: 'somethingUnique',  //Identifier, used for analytics
  //   style: {  //ng-style compatible object applied to promotion-container
  //     "border":"1px solid #f7f7f7",
  //   },
  //   image: {
  //     url: 'url("https://cdn.printos.com/images/home/promo/dscoop.jpg")',
  //     style: {  //ng-style compatible object applied to promotion-image
  //       "background-size":"contain",
  //     }
  //   },
  //   info: {
  //     style: {  //ng-style compatible object applied to info-container
  //       "background-color":"purple",
  //     }
  //     title: {
  //       key: 'home.home.Dscoop_Promotion_Title',
  //       style: {  //ng-style compatible object applied to title
  //         "font-size":"12px",
  //       }
  //     },
  //     desc: {
  //       key: 'home.home.Dscoop_Promotion_Description',
  //       style: {  //ng-style compatible object applied to description
  //         "font-size":"12px",
  //       }
  //     },
  //   },
  //   progress: {
  //     style: {  //ng-style compatible object applied to promo-progress
  //       "background-color":"purple",
  //     }
  //   },
  //   image: 'url("https://cdn.printos.com/images/home/promo/dscoop.jpg")',
  //   backgroundColor: '#0096d6', // backdrop for title and description
  //
  //   include(api) {
  //     //Some logic that returns true or false, can be asynchronous
  //     //Where api = CARD_API in lp2.directive.js
  //     return new Promise ( (resolve, reject) => {
  //       let hasApp = api.account.hasApplication('marketplace');
  //       let canRun = api.account.getCanRun('features','white-ink-promo');
  //       let isPSP = api.account.isAccountType(ACCOUNT_TYPE.PSP);
  //       resolve(isPSP && canRun && hasApp);
  //     });
  //   },
  //   onClick(analytics, $go, $window, $state, parameters) {
  //     analytics.eventClick('promotion', {label: 'dscoop'});
  //     window.open('https://www.eiseverywhere.com/ereg/index.php?eventid=484107', '_blank'); //Open url in another window
  //     go.toContext('market-all-products', 'ideas'); //Open context within PrintOS
  //   }
  // },
  // {
  //   id: 'marketplace',  //Identifier, used for analytics
  //   style: {  //ng-style compatible object applied to promotion-container
  //     "border":"1px solid #f7f7f7",
  //     "background-color": "#b654f5",
  //     "color": "white",
  //     "text-align": "left",
  //     "align-items": "flex-start",
  //   },
  //   sections: [
  //     {
  //       icon: {
  //         path: 'https://cdn.printos.com/images/application/icons/marketplace-2.svg',
  //         style: {
  //           "color": "white",
  //           "margin": 0,
  //           "height": "64px",
  //           "width": "auto",
  //           "padding": "8px 12px",
  //         }
  //       },
  //       style: {
  //         "align-items": 'flex-start',
  //       }
  //     },
  //     {
  //       key: 'application.application.marketplace_Name',
  //       style: {
  //         "padding": "0px 12px",
  //         "font-size": "28px",
  //         "font-weight": 400,
  //         "align-items": 'flex-start',
  //       }
  //     },
  //     {
  //       key: 'home.home.Dashboard_Marketplace_Promotion_Description',
  //       style: {
  //         "padding": "12px",
  //         "font-size": "14px",
  //         "font-weight": 400,
  //         "align-items": 'flex-start',
  //       }
  //     },
  //     {
  //       style: {
  //         "background-image": "url('https://cdn.printos.com/images/home/promo/marketplace-promo-smaller.png')",
  //         "background-position":"center",
  //         "max-height": "55%",
  //         "background-repeat": "no-repeat",
  //         "background-size": "cover",
  //         "flex": 2,
  //       },
  //     },
  //   ],
  //   include(api, family) {
  //     //Some logic that returns true or false, can be asynchronous
  //     //Where api = CARD_API in lp2.directive.js
  //     return new Promise ( (resolve, reject) => {
  //       let hasApp = api.account.hasApplication('marketplace');
  //
  //       resolve(hasApp);
  //     });
  //   },
  //   onClick(analytics, $go, $window, $state, parameters, api) {
  //     let app = api.account.getApplication('marketplace');
  //     if (!app) {
  //       return;
  //     }
  //
  //     analytics.eventClick('promotion', {label: 'marketplace'});
  //     $window.location.href = app._links.self;
  //   }
  // },
  // {
  //   id: 'power-pack-general-promo',  //Identifier, used for analytics
  //   style: {  //ng-style compatible object applied to promotion-container
  //     "background-image": "url('https://cdn.printos.com/images/home/promo/power-pack/gereral-text-promo.jpg')",
  //     "background-position":"center",
  //     "background-repeat": "no-repeat",
  //     "background-size": "cover",
  //   },
  //   sections: [
  //     {
  //       style: {
  //         "flex": 1,
  //       },
  //     },
  //   ],
  //   include(api, family) {
  //     //Some logic that returns true or false, can be asynchronous
  //     //Where api = CARD_API in lp2.directive.js
  //     return new Promise ( (resolve, reject) => {
  //       let hasApp = api.account.hasApplication('marketplace');
  //       let isIndigo = window.$printos.v1.org.isIndigo();
  //       let isPSP = window.$printos.v1.org.isPSP();
  //
  //       resolve(hasApp && isIndigo && isPSP);
  //     });
  //   },
  //   onClick(analytics, $go, $window, $state, parameters, api) {
  //     let app = api.account.getApplication('marketplace');
  //     if (!app) {
  //       return;
  //     }
  //
  //     analytics.eventClick('promotion', {label: 'power-pack-general-promo'});
  //     $window.location.href = app._links.self;
  //   }
  // },
  // {
  //   id: 'power-pack-productivity-promo',  //Identifier, used for analytics
  //   style: {  //ng-style compatible object applied to promotion-container
  //     "background-image": "url('https://cdn.printos.com/images/home/promo/power-pack/productivity-text-promo.jpg')",
  //     "background-position":"center",
  //     "background-repeat": "no-repeat",
  //     "background-size": "cover",
  //   },
  //   sections: [
  //     {
  //       style: {
  //         "flex": 1,
  //       },
  //     },
  //   ],
  //   include(api, family) {
  //     //Some logic that returns true or false, can be asynchronous
  //     //Where api = CARD_API in lp2.directive.js
  //     return new Promise ( (resolve, reject) => {
  //       let hasApp = api.account.hasApplication('marketplace');
  //       let isIndigo = window.$printos.v1.org.isIndigo();
  //       let isPSP = window.$printos.v1.org.isPSP();
  //
  //       resolve(hasApp && isIndigo && isPSP);
  //     });
  //   },
  //   onClick(analytics, $go, $window, $state, parameters, api) {
  //     analytics.eventClick('promotion', {label: 'power-pack-productivity-promo'});
  //     $window.$printos.v1.go.toContext('market-product', 'productivity-pack');
  //   }
  // },
  // {
  //   id: 'power-pack-quality-promo',  //Identifier, used for analytics
  //   style: {  //ng-style compatible object applied to promotion-container
  //     "background-image": "url('https://cdn.printos.com/images/home/promo/power-pack/quality-text-promo.jpg')",
  //     "background-position":"center",
  //     "background-repeat": "no-repeat",
  //     "background-size": "cover",
  //   },
  //   sections: [
  //     {
  //       style: {
  //         "flex": 1,
  //       },
  //     },
  //   ],
  //   include(api, family) {
  //     //Some logic that returns true or false, can be asynchronous
  //     //Where api = CARD_API in lp2.directive.js
  //     return new Promise ( (resolve, reject) => {
  //       let hasApp = api.account.hasApplication('marketplace');
  //       let isIndigo = window.$printos.v1.org.isIndigo();
  //       let isPSP = window.$printos.v1.org.isPSP();
  //
  //       resolve(hasApp && isIndigo && isPSP);
  //     });
  //   },
  //   onClick(analytics, $go, $window, $state, parameters, api) {
  //     analytics.eventClick('promotion', {label: 'power-pack-quality-promo'});
  //     $window.$printos.v1.go.toContext('market-product', 'quality-pack');
  //   }
  // },
  // {
  //   id: 'power-pack-creativity-promo',  //Identifier, used for analytics
  //   style: {  //ng-style compatible object applied to promotion-container
  //     "background-image": "url('https://cdn.printos.com/images/home/promo/power-pack/creativity-text-promo.jpg')",
  //     "background-position":"center",
  //     "background-repeat": "no-repeat",
  //     "background-size": "cover",
  //   },
  //   sections: [
  //     {
  //       style: {
  //         "flex": 1,
  //       },
  //     },
  //   ],
  //   include(api, family) {
  //     //Some logic that returns true or false, can be asynchronous
  //     //Where api = CARD_API in lp2.directive.js
  //     return new Promise ( (resolve, reject) => {
  //       let hasApp = api.account.hasApplication('marketplace');
  //       let isIndigo = window.$printos.v1.org.isIndigo();
  //       let isPSP = window.$printos.v1.org.isPSP();
  //
  //       resolve(hasApp && isIndigo && isPSP);
  //     });
  //   },
  //   onClick(analytics, $go, $window, $state, parameters, api) {
  //     analytics.eventClick('promotion', {label: 'power-pack-creativity-promo'});
  //     $window.$printos.v1.go.toContext('market-product', 'creativity-pack');
  //   }
  // },
  // {
  //   id: 'power-pack-automation-promo',  //Identifier, used for analytics
  //   style: {  //ng-style compatible object applied to promotion-container
  //     "background-image": "url('https://cdn.printos.com/images/home/promo/power-pack/automation-text-promo.jpg')",
  //     "background-position":"center",
  //     "background-repeat": "no-repeat",
  //     "background-size": "cover",
  //   },
  //   sections: [
  //     {
  //       style: {
  //         "flex": 1,
  //       },
  //     },
  //   ],
  //   include(api, family) {
  //     //Some logic that returns true or false, can be asynchronous
  //     //Where api = CARD_API in lp2.directive.js
  //     return new Promise ( (resolve, reject) => {
  //       let hasApp = api.account.hasApplication('marketplace');
  //       let isIndigo = window.$printos.v1.org.isIndigo();
  //       let isPSP = window.$printos.v1.org.isPSP();
  //
  //       resolve(hasApp && isIndigo && isPSP);
  //     });
  //   },
  //   onClick(analytics, $go, $window, $state, parameters, api) {
  //     analytics.eventClick('promotion', {label: 'power-pack-automation-promo'});
  //     $window.$printos.v1.go.toContext('market-product', 'automation-pack');
  //   }
  // },
  {
    id: 'power-pack-productivity-promo',  //Identifier, used for analytics
    style: {  //ng-style compatible object applied to promotion-container
      "background-image": "url('https://cdn.printos.com/images/home/promo/power-pack/home-productivity-promo.jpg')",
      "background-position":"center",
      "background-repeat": "no-repeat",
      "background-size": "cover",
    },
    sections: [
      {
        style: {
          "flex": 1,
        },
      },
    ],
    include(api, family) {
      //Some logic that returns true or false, can be asynchronous
      //Where api = CARD_API in lp2.directive.js
      return new Promise ( (resolve, reject) => {
        let hasApp = api.account.hasApplication('marketplace');
        let isIndigo = window.$printos.v1.org.isIndigo();
        let isPSP = window.$printos.v1.org.isPSP();

        resolve(hasApp && isIndigo && isPSP);
      });
    },
    onClick(analytics, $go, $window, $state, parameters, api) {
      analytics.eventClick('promotion', {label: 'power-pack-productivity-promo'});
      $window.$printos.v1.go.toContext('market-product', 'productivity-pack');
    }
  },
  {
    id: 'power-pack-quality-promo',  //Identifier, used for analytics
    style: {  //ng-style compatible object applied to promotion-container
      "background-image": "url('https://cdn.printos.com/images/home/promo/power-pack/home-quality-promo.jpg')",
      "background-position":"center",
      "background-repeat": "no-repeat",
      "background-size": "cover",
    },
    sections: [
      {
        style: {
          "flex": 1,
        },
      },
    ],
    include(api, family) {
      //Some logic that returns true or false, can be asynchronous
      //Where api = CARD_API in lp2.directive.js
      return new Promise ( (resolve, reject) => {
        let hasApp = api.account.hasApplication('marketplace');
        let isIndigo = window.$printos.v1.org.isIndigo();
        let isPSP = window.$printos.v1.org.isPSP();

        resolve(hasApp && isIndigo && isPSP);
      });
    },
    onClick(analytics, $go, $window, $state, parameters, api) {
      analytics.eventClick('promotion', {label: 'power-pack-quality-promo'});
      $window.$printos.v1.go.toContext('market-product', 'quality-pack');
    }
  },
  {
    id: 'power-pack-creativity-promo',  //Identifier, used for analytics
    style: {  //ng-style compatible object applied to promotion-container
      "background-image": "url('https://cdn.printos.com/images/home/promo/power-pack/home-creativity-promo.jpg')",
      "background-position":"center",
      "background-repeat": "no-repeat",
      "background-size": "cover",
    },
    sections: [
      {
        style: {
          "flex": 1,
        },
      },
    ],
    include(api, family) {
      //Some logic that returns true or false, can be asynchronous
      //Where api = CARD_API in lp2.directive.js
      return new Promise ( (resolve, reject) => {
        let hasApp = api.account.hasApplication('marketplace');
        let isIndigo = window.$printos.v1.org.isIndigo();
        let isPSP = window.$printos.v1.org.isPSP();

        resolve(hasApp && isIndigo && isPSP);
      });
    },
    onClick(analytics, $go, $window, $state, parameters, api) {
      analytics.eventClick('promotion', {label: 'power-pack-creativity-promo'});
      $window.$printos.v1.go.toContext('market-product', 'creativity-pack');
    }
  },
  {
    id: 'power-pack-automation-promo',  //Identifier, used for analytics
    style: {  //ng-style compatible object applied to promotion-container
      "background-image": "url('https://cdn.printos.com/images/home/promo/power-pack/home-automation-promo.jpg')",
      "background-position":"center",
      "background-repeat": "no-repeat",
      "background-size": "cover",
    },
    sections: [
      {
        style: {
          "flex": 1,
        },
      },
    ],
    include(api, family) {
      //Some logic that returns true or false, can be asynchronous
      //Where api = CARD_API in lp2.directive.js
      return new Promise ( (resolve, reject) => {
        let hasApp = api.account.hasApplication('marketplace');
        let isIndigo = window.$printos.v1.org.isIndigo();
        let isPSP = window.$printos.v1.org.isPSP();

        resolve(hasApp && isIndigo && isPSP);
      });
    },
    onClick(analytics, $go, $window, $state, parameters, api) {
      analytics.eventClick('promotion', {label: 'power-pack-automation-promo'});
      $window.$printos.v1.go.toContext('market-product', 'automation-pack');
    }
  },
  {
    id: 'plus-plan',  //Identifier, used for analytics
    style: {  //ng-style compatible object applied to promotion-container
      "border":"1px solid #f7f7f7",
      "background": 'linear-gradient(180deg, #b9b1fc 0%, #ccc4fd 100%)',
      "color": "black"
    },
    sections: [
      {
        key: 'home.home.PlusPlan_Promotion_Title',
        style: {
          "background-color":"rgba(168, 158, 250, 1)",
          "padding": "8px",
          "font-size": "20px",
          "font-weight": 500,
        }
      },
      {
        key: 'home.home.PlusPlan_Promotion_Description',
        style: {
          "padding": "8px",
          "font-size": "14px",
          "font-weight": 400,
        }
      },
      {
        style: {
          "background-image": "url('https://cdn.printos.com/images/home/promo/plus-plan-promo-image.jpg')",
          //"background-size":"contain",
          "background-position":"center",
          //"cursor":"default",
          "max-height": "55%",
          "background-repeat": "no-repeat",
          "background-size": "cover",
          "flex": 2,
        },
      },
      {
        style: {
          "display": "flex",
          "flex-direction": "column",
          "align-items": "center",
          "justify-content": "center",
          "height": "20%",
          "box-sizing": "border-box",
          // "background-color": "#e5e2fd",
        },
        button: {
          key: 'home.home.PlusPlan_Promotion_Button',
          style: {
            "background-color": "black",
            "color": "white"
          }
        }
      }
    ],
    include(api, family) {
      //Some logic that returns true or false, can be asynchronous
      //Where api = CARD_API in lp2.directive.js
      if (!window.$printos.v1.org.isLF()) {
        return Promise.resolve(false);
      }

      let hasPPSP = window.$printos.v1.market.hasProduct('ppsp');
      let hasPPSPTrial = window.$printos.v1.market.hasProduct('ppsp-trial');
      let hasPPSPSubscription = window.$printos.v1.market.hasActiveSubscription('ppsp');
      let hasPPSPTrialSubscription = window.$printos.v1.market.hasActiveSubscription('ppsp-trial');

      let hasQualifiedProduct = hasPPSP || hasPPSPTrial;
      let isAlreadySubscribed = hasPPSPSubscription || hasPPSPTrialSubscription;
      return Promise.resolve(hasQualifiedProduct && !isAlreadySubscribed);
    },
    onClick(analytics, $go, $window, $state, parameters, api) {
      let hasPPSP = window.$printos.v1.market.hasProduct('ppsp');
      $window.$printos.v1.ga.eventTrack('home', 'Promo - Clicked', 'Promo - PPSP');
      if (hasPPSP) {
        $window.$printos.v1.go.toContext('market-product', 'ppsp');
      }
      else {
        $window.$printos.v1.go.toContext('market-product', 'ppsp-trial');
      }
    }
  },
  {
    id: 'learn-premium',  //Identifier, used for analytics
    style: {  //ng-style compatible object applied to promotion-container
      "border":"1px solid #f7f7f7",
      "background": 'linear-gradient(180deg, #b9b1fc 0%, #ccc4fd 100%)',
      "color": "black"
    },
    sections: [
      {
        key: 'home.home.LearnPremium_Promotion_Title',
        style: {
          "background-color":"rgba(168, 158, 250, 1)",
          "padding": "4px",
          "font-size": "20px",
          "font-weight": 500,
        }
      },
      {
        key: 'home.home.LearnPremium_Promotion_Description',
        style: {
          "padding": "8px",
          "font-size": "14px",
          "font-weight": 400,
        }
      },
      {
        style: {
          "background-image": "url('https://cdn.printos.com/images/home/promo/learn-promo-image.png')",
          //"background-size":"contain",
          "background-position":"center",
          //"cursor":"default",
          "max-height": "55%",
          "background-repeat": "no-repeat",
          "background-size": "cover",
          "flex": 2,
        },
      },
      {
        style: {
          "display": "flex",
          "flex-direction": "column",
          "align-items": "center",
          "justify-content": "center",
          "height": "20%",
          "box-sizing": "border-box",
          // "background-color": "#e5e2fd",
        },
        button: {
          key: 'home.home.LearnPremium_Promotion_Button',
          style: {
            "background-color": "black",
            "color": "white"
          }
        }
      }
    ],
    include(api, family) {
      //Some logic that returns true or false, can be asynchronous
      //Where api = CARD_API in lp2.directive.js
      return new Promise ( (resolve, reject) => {
        let isLFContext = window.$printos.v1.org.isLF();
        let hasApp = window.$printos.v1.app.getAppById('university') ? true : false;
        let hasFeature = api.account.hasFeature(FEATURE.LEARN_PREMIUM);
        resolve(isLFContext && hasApp && hasFeature);
      });
    },
    onClick(analytics, $go, $window, $state, parameters, api) {
      $window.$printos.v1.ga.eventTrack('home', 'Promo - Clicked', 'Promo - Learn Premium');
      $window.$printos.v1.app.goToApp('university');
    }
  },
];
