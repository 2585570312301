import {LP_DEVICE_FAMILY} from "src/components/landing-page/cards/card-definitions";

export const name = 'myDevices';
export default function myDevices($log) {
  //noinspection BadExpressionStatementJS
  'ngInject';

  let directive = {
    restrict: 'EA',
    scope: {
      gzCard: '=?',
    },
    template: require('./my-devices.jade'),
    link: myDevicesLink,
    controller: myDevicesController,
    controllerAs: 'vm',
    bindToController: true
  };

  return directive;

  function myDevicesLink(scope, element, attrs, controllers, transcludeFn) {
    controllers.init(element);
  }

  /** @ngInject */
  function myDevicesController(
    $window,
    $gzDeviceStatus,
    $translate,
    $gzAnalytics,
    $scope,
    $rootScope
  ) {

    const DEVICES_PER_PAGE = 4;

    let statusWatcher;

    let vm = this;
    vm.goToDevice = goToDevice;
    vm.init = init;
    vm.setStatusBarState = setStatusBarState;
    vm.getDeviceStatus = getDeviceStatus;
    vm.getRankAndSort = getRankAndSort;

    vm.$onInit = onInit;
    return;

    ////////////////////

    function onInit() {
      vm.family = vm.gzCard.settings.family;
      let link = '/account/#/devices';
      if (vm.family !== LP_DEVICE_FAMILY.OTHER) {
        link += '?family=' + vm.family;
      }

      vm.gzCard.API.config.setAppLink(link);

      vm.gzCard.API.config.setSiteCallback(siteChanged);

      statusWatcher = $rootScope.$on('$lpDeviceDataUpdated', () => {
        setStatusBarState();
      });
      $scope.$on('$destroy', statusWatcher);
      getRankAndSort();
    }

    function init(element) {
      // Save so we can use it later
      vm.$element = element;
    }

    function setStatusBarState() {
      // Set the containing gz-card directive's status bar to indicate the overall
      // state of the devices
      // let state = $gzDeviceStatus.getOverallStateOfMultipleDevices(vm.devices);
      getRankAndSort();
    }

    function goToDevice($event, link) {
      $event.stopPropagation();
      $window.location.href = link;
      $gzAnalytics.eventClick('view device');
    }

    function getDeviceStatus(deviceId) {
      let device = _.find(vm.devices, {'deviceId': deviceId});
      if (device) {
        return $gzDeviceStatus.getDeviceStatus(device);
      }
    }

    function getRankAndSort() {
      vm.devices = vm.gzCard.API.currentSite.getDevicesByFamily(vm.family);

      if (vm.devices.length > 0) {
        _.forEach(vm.devices, (device) => {
          device.rank = getDeviceStatus(device.deviceId).state.rank;
        });

        let mapped = vm.devices.map((el, i) => {
          return {index: i, value: el};
        });

        mapped.sort((a, b) => {
          if (a.value.rank < b.value.rank) { return -1; }
          else if (a.value.rank > b.value.rank) { return 1; }
          else {
            let aName = a.value.name.toUpperCase();
            let bName = b.value.name.toUpperCase();
            if (aName < bName) { return -1; }
            else if (aName > bName) { return 1; }
            return 0;
          }
        });

        let temp = mapped.map((el) => {
          return vm.devices[el.index];
        });

        vm.devices = temp;
      }

      if (vm.devices.length > DEVICES_PER_PAGE) {
        let numPages = Math.ceil(vm.devices.length / 4);
        let pages = [];
        let pageDisplay = [];
        for (let i = 0; i < numPages; i++) {
          let page = {};
          let start = i * DEVICES_PER_PAGE;
          let end = start + DEVICES_PER_PAGE;
          pages.push(vm.devices.slice(start, end));
          //page.title = $translate.instant('home.home.DevicePage', [start + 1, start + page.devices.length]);
          if (pages[i].length > 1) {
            pageDisplay.push($translate.instant('home.home.DevicesPage', [pages[i].length]));
          }
          else {
            pageDisplay.push($translate.instant('home.home.DevicePage', [pages[i].length]));
          }
        }

        vm.gzCard.API.config.setPages(pages, pageChanged, pageDisplay);
        vm.pageDevices = pages[0];
      }
      else {
        vm.pageDevices = vm.devices;
      }
    }

    function pageChanged(page) {
      vm.pageDevices = page;
    }

    function siteChanged(site) {
      getRankAndSort();
    }
  }

}
