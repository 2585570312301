export const name = 'inklevelWidget';

export default function inklevelWidget($log) {
  //noinspection BadExpressionStatementJS
  'ngInject';


  var directive = {
    restrict: 'EA',
    transclude: true,
    scope: {
      level: '=',
      showLabel: '<'
    },
    template: require('./inklevel.jade')
  };

  return directive;

} //deviceWidget
