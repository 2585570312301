import rhino from 'rhino';
import angularTranslate from 'angular-translate';

import './latex-service-center.scss';
import * as hmLatexServiceCenterDirective from './latex-service-center.directive';
import gazelleAnalytics from 'gazelle/src/core/analytics/analytics.module';
import images from 'src/images/images.module';
import gazelleCore from 'gazelle/src/core/core.module';

export default angular.module('home.components.widget.hmLatexServiceCenter', [
  rhino,
  angularTranslate,
  'gazelle.components.go',
  gazelleAnalytics,
  images,
  gazelleCore,
])
  .directive(hmLatexServiceCenterDirective.name, hmLatexServiceCenterDirective.default)
  .name;
