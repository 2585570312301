import rhino from 'rhino';
import angularTranslate from 'angular-translate';

import './knowledge-zone.scss';
import * as hmKnowledgeZoneDirective from './knowledge-zone.directive';
import gazelleAnalytics from 'gazelle/src/core/analytics/analytics.module';
import rhinoLocale from 'rhino/src/components/locale/locale.module';

export default angular.module('home.components.widget.hmKnowledgeZone', [
  rhino,
  angularTranslate,
  'gazelle.components.go',
  gazelleAnalytics,
  rhinoLocale
])
  .directive(hmKnowledgeZoneDirective.name, hmKnowledgeZoneDirective.default)
  .name;
