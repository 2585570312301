export const moduleName = 'questionService';
export default function questionService($http, $q, $log, rnHttp) {
  //noinspection BadExpressionStatementJS
  'ngInject';

  const SUPPORT_SERVICE = 'supportapp_service';
  const RETRIEVE_QUESTIONS_PATH = 'askexpert/questioncharacteristics/retrieveAll';
  const CREATE_QUESTION_PATH = 'cases';
  const AAA_SERVICE = 'aaa';
  const RETRIEVE_USER_INFO_PATH = 'users';

  return {
    getQuestions: getQuestions,
    createQuestion: createQuestion,
    getUserInfo: getUserInfo
  };

  ////////////////

  function getQuestions(offset, limit) {
    let config = { offset: offset,  limit: limit };
    return rnHttp.get(SUPPORT_SERVICE, RETRIEVE_QUESTIONS_PATH, config);
  }

  function createQuestion(questionData) {
    return rnHttp.post(SUPPORT_SERVICE, CREATE_QUESTION_PATH, questionData);
  }

  function getUserInfo() {
    return rnHttp.get(AAA_SERVICE, RETRIEVE_USER_INFO_PATH);
  }
}
