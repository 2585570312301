import './lf-consents.scss';

import ngMaterial from 'angular-material';
import uiRouter from 'angular-ui-router';
import angularTranslate from 'angular-translate';

import * as LargeFormatConsentsController from './lf-consents.controller';

export default angular.module('home.components.lfConsentsDialog', [
  ngMaterial,
  uiRouter,
  angularTranslate,
])
  .controller(LargeFormatConsentsController.name, LargeFormatConsentsController.default)
  .name;
