import './smart-uptime-kit.scss';
import gzGo from 'gazelle/src/components/go/go.module';

import * as hmSmartUptimeKitDirective from './smart-uptime-kit.directive';

export default angular.module('home.components.smartUptimeKit', [
  gzGo
])
  .directive(hmSmartUptimeKitDirective.name, hmSmartUptimeKitDirective.default)
  .name;
