import './feedback-details-dialog.scss';
import ngMaterial from 'angular-material';
import gazelleAnalytics from 'gazelle/src/core/analytics/analytics.module';

import * as FeedbackDetailsDialogController from './feedback-details-dialog.controller';

export default angular.module('home.components.help.feedbackDetails', [
  ngMaterial,
  gazelleAnalytics
])
  .controller(FeedbackDetailsDialogController.name, FeedbackDetailsDialogController.default)
  .name;
