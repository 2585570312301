export const name = 'hmLatexPromotion';
export default function hmLatexPromotion(
  // Directive angular dependencies
) {
  'ngInject';

  let directive = {
    restrict: 'EA',
    scope: {
    },
    template: require('./latex-promotion.jade'),
    link: latexPromotionLink,
    controller: latexPromotionController,
    controllerAs: 'vm',
    bindToController: true,
  };
  return directive;

  //////////////////////

  function latexPromotionLink(scope, element, attrs) {
  }

  /** @ngInject */
  function latexPromotionController(
    // Controller angular dependencies
  ) {
    // Constants

    // Variables

    // View Model
    let vm = this;

    vm.$onInit = onInit;
    return;

    function onInit() {
    }
  }
}
