export const name = 'hmResourceManager';
export default function hmResourceManager() {
  //noinspection BadExpressionStatementJS
  'ngInject';

  let directive = {
    restrict: 'EA',
    scope: {
      gzCard: '=',
    },
    template: require('./resource-manager.jade'),
    controller: hmResourceManagerController,
    controllerAs: 'vm',
    bindToController: true
  };

  return directive;

  /** @ngInject */
  function hmResourceManagerController(
    $window,
    rnHttp,
    $q,
    $gzAnalytics
  ) {

    let vm = this;
    vm.loading = true;

    vm.$onInit = onInit;
    return;
    ////////////////////

    function onInit() {
      let promises = [];
      // promises.push(getData());
      $q.all(promises).then(() => {
      })
        .finally(() => {
          vm.loading = false;
        });
    }

    function getData() {
      // let config = {
      //   params: {}
      // };
      //
      // return rnHttp.get('reseller-portal', '/api/dashboard/stats', config).then(resp => {
      //   let stats = resp.data;
      //   vm.customers = _.get(resp, 'data.result.urgentCustomers', '--');
      //   vm.printers = _.get(resp, 'data.result.printersInError', '--');
      //   vm.maintenance = _.get(resp, 'data.result.maintenancePending', '--');
      //   vm.responseTime = _.get(resp, 'data.result.averageTimeToSolution', '--');
      // });
    }
  }
}
