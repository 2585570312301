import * as builtInFilters from './builtin-filters';

export const name = 'hmJobs';

export default function hmJobs() {
  //noinspection BadExpressionStatementJS
  'ngInject';

  const SERVICE_NAME = 'jobs_service';
  const JOBS_PATH = 'jobs';
  const JOB_COUNTS_PATH = JOBS_PATH + '/counts?context=Job.Summary.Logical';

  let directive = {
    restrict: 'EA',
    scope: {
    },
    template: require('./jobs.jade'),
    controller: jobsController,
    controllerAs: 'vm',
    bindToController: true
  };

  return directive;

  /** @ngInject */
  function jobsController(
    $translate,
    $gzGo,
    $scope,
    $timeout,
    $window,
    moment,
    $gzAnalytics,
    rnHttp
  ) {
    const defaultFilters = ['onDfe', 'onPress', 'printing', 'errorsOnDevice'];

    let filterCountsTimer;

    let vm = this;

    vm.videoId = 'q_GXq4tLSe0';
    vm.loading = true;
    vm.isConfigured = false;
    vm.myFilters = [];
    vm.jobSearch = '';

    vm.navigateToJobs = navigateToJobs;
    vm.searchJobs = searchJobs;
    vm.getFilterColor = getFilterColor;
    // vm.getFilters = getFilters;
    vm.getFilter = getFilter;
    vm.gotoJobs = gotoJobs;
    vm.toggleVideo = toggleVideo;

    vm.$onInit = onInit;
    return;

    function onInit() {
      checkConfigured().then(() => {
        if (vm.isConfigured) {
          loadJobFilters();
          $scope.$on('$destroy', function() {
            if (filterCountsTimer) {
              $timeout.cancel(filterCountsTimer);
            }
          });

          return updateCounts();
        }
      })
        .catch(error => {
        })
        .finally(() => {
          vm.loading = false;
        });
    }

    function updateCounts(autoRefresh) {
      let start = $window.performance.now();
      let filters = {
        timeZone: moment.tz.guess(),
        filterItems: []
      };

      _.forEach(defaultFilters, filterId => {
        let filter = getFilter(filterId);
        let filterItem = {
          filterId: filter.id,
          filterQuery: filter.query,
        };
        filters.filterItems.push(filterItem);
      });

      let url = JOB_COUNTS_PATH;
      let config = {
        headers: {
        }
      };
      _.set(config.header, 'Jobs-Page-Context', name);
      if (autoRefresh) {
        _.set(config.headers, 'Jobs-Auto-Refresh', autoRefresh);
      }

      return rnHttp.post(SERVICE_NAME, url, filters, config)
        .then(resp => {
          let filterCounts = resp.data;
          _.forEach(filterCounts, filterCount => {
            let filter = getFilter(filterCount.filterId);
            if (filter) {
              filter.count = filterCount.filterCount;
            }
          });
        })
        .catch(error => {
          vm.errorKey = 'home.home.Jobs_Unknown_Error';
        })
        .finally(() => {
          filterCountsTimer = $timeout(function() { updateCounts(true); }, 5 * 60 * 1000);
          let end = $window.performance.now();
          let timeDiff = end - start;
        });
    }

    function checkConfigured() {
      let config = {
        headers: {
          'Jobs-Page-Context': name
        }
      };

      return rnHttp.post(SERVICE_NAME, JOBS_PATH + '?limit=0&includeTotal=false&includeUnfilteredTotal=false', {}, config)
        .then(resp => {
          if (resp.data && resp.data.total) {
            vm.isConfigured = true;
          }
        })
        .catch(error => {
          vm.errorKey = 'home.home.Jobs_Unknown_Error';
        });
    }

    function loadJobFilters() {
      vm.myFilters = [];
      _.forEach(builtInFilters.getFilterCards(), jobFilter => {
        if (defaultFilters.indexOf(jobFilter.id) !== -1) {
          let filter = _.cloneDeep(jobFilter);
          filter.count = '?';
          if (filter.msgCat) {
            filter.title = $translate.instant(filter.msgCat + '.' + filter.title);
          }
          vm.myFilters.push(filter);
        }
      });
    }

    function navigateToJobs($event, filter){
      $event.stopPropagation();
      $gzAnalytics.eventClick('Jobs Filter Card', { label: 'filterId: ' + filter.id });
      $gzGo.toContext('jobs-filter', filter.id);
    }

    function gotoJobs($event){
      $event.stopPropagation();
      $gzAnalytics.eventClick('Start jobs on-boarding', {});
      $gzGo.toContext('jobs-home');
    }

    function searchJobs($event){
      if ($event) {
        $event.stopPropagation();
      }
      $gzAnalytics.event('Search Job Name', { label: 'text: ' + vm.jobSearch });
      $gzGo.toContext('jobs-search', encodeURIComponent(vm.jobSearch));
    }

    function getFilterColor(filter) {
      if (filter && filter.thresholdColor && (filter.threshold !== undefined) && (filter.count !== undefined) && filter.count !== '?' &&  filter.count >= filter.threshold) {
        return filter.thresholdColor;
      }

      return '#767676'; // $hp-enabled-gray
    }

    function toggleVideo() {
      vm.showVideo = !vm.showVideo;
      $gzAnalytics.eventClick('Home - Jobs Video', {show: vm.showVideo});

      if (vm.showVideo) {
        if (_.get(vm.playerAPI, 'play')) {
          vm.playerAPI.play();
        }
      }
      else {
        if (_.get(vm.playerAPI, 'stop')) {
          vm.playerAPI.stop();
        }
      }
    }

    // function getFilters(filterIds) {
    //   let filters = [];
    //
    //   _.forEach(filterIds, filterId => {
    //     let filter = getFilter(filterId);
    //     if (filter) {
    //       filters.push(filter);
    //     }
    //   });
    //
    //   return filters;
    // }

    function getFilter(filterId) {
      let filter =  _.find(vm.myFilters, { id: filterId});
      return filter;
    }
  }
}
