export const gridOptions = {
  grid: {
    id: 'home-cases',
    sort: [{colId: 'opened', sort: 'desc'}],
    showActionMenu: false,
    allowFilterBar: false,
    allowFilters: false,
    allowDragToHide: false,
    headerHeight: 36,
    rowHeight: 32,
  },
  columns: [
    {
      id: 'product',
      type: 'text',
      path: 'deviceName',
      options: {
        width: 200,
      },
      header: {
        key: 'home.home.ServiceCenter_Product'
      },
    },
    {
      id: 'opened',
      type: 'date',
      path: 'createdTime',
      options: {
        width: 'auto',
      },
      header: {
        key: 'home.home.ServiceCenter_Opened'
      },
      date: {
        display: 'elapse',
      }
    },
    {
      id: 'status',
      type: 'text',
      path: 'status',
      options: {
        width: 'auto',
      },
      header: {
        key: 'home.home.ServiceCenter_Status'
      },
    }
  ]
};
