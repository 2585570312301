export const name = 'SetDefaultAppController';
export default function SetDefaultAppController(
  $state,
  $stateParams,
  rnPreferences,
  rnApplication,
  $gzAnalytics,
  $q,
  $mdDialog,
  $window
) {
  'ngInject';
  // Constants

  // Variables
  let appId = $stateParams.appId;
  let app;

  // View Model
  let vm = this;
  //   VM properties
  vm.loading = true;
  vm.unitTest = {
    setDefaultAppPreferences,
    setAppId(testAppId) {
      appId = testAppId;
    }
  };
  //   VM methods

  vm.$onInit = onInit;
  return;

  ///////////////
  // Init
  function onInit() {
    return setPreferenceAndConfirm();
  }

  ///////////////
  // VM methods

  ///////////////
  // Helpers
  function setPreferenceAndConfirm() {
    return rnApplication.getApplication(appId).then(foundApp => {
      app = foundApp;
      if (!app) {
        return $q.reject('home.home.SetDefaultApp_AppNotFound');
      }
    }).then(() => {
      return setDefaultAppPreferences();
    }).then(() => {
      return showDialog();
    }).catch(errorKey => {
      return showDialog(errorKey);
    });
  }

  function setDefaultAppPreferences() {
    return rnPreferences.getAll().then(prefs => {
      let appSwitcherPrefs = prefs['app-switcher'];
      if (!appSwitcherPrefs) {
        // Can't find app switcher preferences
        return $q.reject();
      }

      Object.keys(appSwitcherPrefs).forEach(key => {
        if (key.startsWith('defaultAppId')) {
          appSwitcherPrefs[key] = appId;
        }
      });

      return rnPreferences.setAll(prefs);
    }).catch(() => {
      return $q.reject('home.home.SetDefaultApp_UpdatePreferencesFailed');
    });
  }

  function showDialog(errorKey) {
    return $mdDialog.show({
      parent: angular.element(document.body),
      clickOutsideToClose: false,
      escapeToClose: true,
      template: require('./confirm/confirm-dialog.jade'),
      controller: 'ConfirmSetDefaultAppDialogController',
      bindToController: true,
      controllerAs: 'vm',
      locals: {
        app,
        errorKey,
      }
    }).then(() => {
      if (errorKey) {
        // Try again
        $gzAnalytics.event('retry - updating startup app', { category: 'settings', label: appId });
        return setPreferenceAndConfirm();
      }

      goToPreferredApp();
    }).catch(() => {
      if (errorKey) {
        // Cancel - go to home dashboard
        $gzAnalytics.eventCancel('updating startup app', { category: 'settings', label: appId });
        return $state.go('dashboard');
      }

      goToPreferredApp();
    });
  }

  function goToPreferredApp() {
    $gzAnalytics.eventConfirm('updating startup app', { category: 'settings', label: appId });
    // Go to preferred app
    $window.location.href = app._links.self;
  }
}
