import ngMaterial from 'angular-material';

// import modules this module depends on
import angularTranslate from 'angular-translate';
import rnSession from 'rhino/src/components/session/session.module';
import rnLocale from 'rhino/src/components/locale/locale.module';
import rnHttp from 'rhino/src/core/http/http.module';
import rnPreferences from 'rhino/src/components/preferences/preferences.module';
import rnFeature from 'rhino/src/components/feature/feature.module';

// import this modules components
import * as contactController from './contact.controller';
import './contact.scss';
//import rnFeature from "@printos/rhino/src/components/feature/feature.service";

// define the module in one place and export the module name
export default angular.module('home.components.contact', [
  angularTranslate,
  ngMaterial,
  rnSession,
  rnFeature,
  rnHttp,
  rnLocale,
  rnPreferences,
  'ui.router',
])
  .controller(contactController.name, contactController.default)
  .name;
