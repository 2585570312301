export const name = 'MultiSelectPromptDialogController';
export default function MultiSelectPromptDialogController(
  // Controller angular dependencies
  $mdDialog
) {
  'ngInject';
  // Constants

  // Variables
  let initialValue;

  // View Model
  let vm = this;
  //   VM properties
  vm.loading = true;
  vm.valid = false;

  //   VM methods
  vm.cancel = cancel;
  vm.submit = submit;
  vm.disable = disable;

  vm.activate = activate;
  activate();
  return;

  ///////////////
  // Init
  function activate() {
    initialValue = vm.value;
    vm.loading = false;
  }

  ///////////////
  // VM methods

  function disable() {
    return !vm.valid;
  }

  function cancel() {
    $mdDialog.cancel();
  }

  function submit() {
    $mdDialog.hide(vm.value);
  }

  ///////////////
  // Helpers
}
