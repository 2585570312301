/**
 * Created by panda on 9/19/2016.
 */

export const SUPPORTAPP_SERVICE = 'supportapp_service';
export const CASES_PATH ='/cases';

export const moduleName = 'SupportCaseListService';
export default class SupportCaseListService {
  /** @ngInject */
  constructor (rnHttp){
   this.rnHttp = rnHttp;
  }

  getAllCases(offset, limit) {
    const config = {params: {offset: offset, limit: limit}};
    return this.rnHttp.get(SUPPORTAPP_SERVICE, CASES_PATH, config);
  }

  static factory(rnHttp) {
    'ngInject';
    return new SupportCaseListService(rnHttp);
  }
}
