export const name = 'OpenSourceController';

export default function OpenSourceController(
  $state,
  $window,
  $stateParams,
  $location,
  rnHttp
) {
  //noinspection BadExpressionStatementJS
  'ngInject';
  const SMS_PORTAL = 'sms_portal';
  const SW_LICENSING = 'license/sw-license.txt';

  let vm = this;
  let myParams = {};
  let target;

  vm.loading = true;

  vm.goBack = goBack;

  vm.breadcrumbs = [
    {
      state: 'dashboard',
      titleKey: 'home.home.Home_title'
    },
    {
      state: 'settings',
      titleKey: 'home.home.My_Settings'
    },
    {
      state: 'about',
      titleKey: 'home.home.About'
    },
    {
      state: 'open-source',
      titleKey: 'uxf.uxf.Open_Source_Licensing_Button'
    }
  ];

  vm.unitTest = {
    setMyParams: function(params){myParams = params;},
    setTarget: function(t){target = t;}
  };

  vm.$onInit = onInit;
  return;
  ////////////////////////
  function onInit() {
    parseParams();
    rnHttp.get(SMS_PORTAL, SW_LICENSING).then(resp => {
      vm.content = resp.data;
    })
      .finally(() => {
        vm.loading = false;
      });
  }

  function parseParams() {
    myParams = {
      data: $stateParams.data,
    };
  }

  function goBack($event) {
    $event.stopPropagation();
    if (target) {
      $window.location.href = target;
    }
    else {
      $state.go('about', myParams);
    }
  }
}
