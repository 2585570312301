export const name = 'MobileOnboardingController';
export default function MobileOnboardingController(
  $mdDialog,
  $translate,
  subscription,
  rnLocale
) {

  //noinspection BadExpressionStatementJS
  'ngInject';

  /* jshint validthis: true */
  var vm = this;

  vm.subscription = subscription;
  vm.locale = 'en'; // default

  const requireAppStoreImg = require.context('../../../images/app-store', false, /\.svg/);
  const requireGooglePlayImg = require.context('../../../images/google-play', false, /\.png/);

  vm.activate = activate;
  vm.title = 'MobileOnboardingController';
  vm.close = close;

  vm.appStoreImg = appStoreImg;
  vm.googlePlayImg = googlePlayImg;

  vm.activate = activate;
  activate();
  return;
  ////////////////

  function activate() {
    getLocale();
    switch (subscription.mobileAppName) {
      case 'service-center':
        vm.summaryText = $translate.instant('home.home.NotificationsMobile_Summary_Reseller');
        vm.iOSLink = 'https://itunes.apple.com/us/app/hp-printos-service-center-mobile/id1239517171?mt=8';
        vm.googleLink = 'https://play.google.com/store/apps/details?id=com.merchant.reseller';
        break;
      default:
        vm.summaryText = $translate.instant('home.home.NotificationsMobile_Summary_PSP');
        vm.iOSLink = 'https://itunes.apple.com/us/app/hp-printos-for-print-service/id1091732549?ls=1&mt=8';
        vm.googleLink = 'https://play.google.com/store/apps/details?id=com.hp.printosforpsp';
        break;
    }
  }

  function getLocale() {
    return rnLocale.getBestFit()
      .then(bestFitLocale => {
        vm.locale = bestFitLocale.shortName;
      });
  }

  function close(data) {
    $mdDialog.hide();
  }

  // from welcome.controller.js
  function appStoreImg() {
    return requireAppStoreImg(`./${vm.locale}.svg`).default;
  }

  function googlePlayImg() {
    return requireGooglePlayImg(`./${vm.locale}.png`).default;
  }

}
