import data from './states.mock';
export const name = 'hmThreeDServices';
export default function hmThreeDServices() {
  //noinspection BadExpressionStatementJS
  'ngInject';

  let directive = {
    restrict: 'EA',
    transclude: true,
    scope: {
      gzCard: '=',
    },
    template: require('./threeD-services.jade'),
    controller: threeDServicesController,
    controllerAs: 'vm',
    bindToController: true,
  };

  return directive;

  /** @ngInject */
  function threeDServicesController(
    rnHttp,
    $interval,
    $scope,
    $gzAnalytics,
    $window,
    $templateCache,
    $q
  ) {

    const IMAGE_COUNT = 4;
    const CYCLE_TIME_SECONDS = 10;
    let allPages = [];
    let vm = this;
    vm.loading = true;
    vm.threedData = [];

    vm.goToSuluApp = goToSuluApp;

    vm.unitTest = {
      advanceImage: advanceImage
    };

    vm.$onInit = onInit;
    return;

    function onInit() {
      let promises = [];
      promises.push(getThreeDSubscriptions());

      $q.all(promises).then(() => {
        vm.gzCard.API.config.setSiteCallback(siteChanged);
        let site = vm.gzCard.API.currentSite.getSite();
        if (site) {
          vm.siteToAssign = site;
        }
        else {
          let sites = vm.gzCard.API.account.getSites();
          vm.siteToAssign = sites[0];
        }
        allPages = vm.threedData;
        pageChanged(allPages[0]);

        vm.gzCard.API.config.setPages(allPages, pageChanged);

        $scope.$on('$destroy', function() {
          stopCycler();
        });
      })
        .finally(() => {
          vm.loading = false;
        });
    }


    function getThreeDSubscriptions() {
      return rnHttp.get('3d_sms_portal','/subscription/subscriptions/cards').then(resp => {
        if(resp && resp.data){
          vm.threedData = resp.data.cards;

          // CORS issue! We must set `withCredentials:false` to allow for
          // S3 to return a wildcard in the `Access-Control-Allow-Origin` header.
          // However, we don't have direct control of these requests because they're handled by `md-icon`.
          // So, we'll stuff the icons into `$templateCache` for `md-icon` to retrieve them from.
          // (`withCredentials:true` is a default in rhino core.config.js from early code to allow rhino
          // to function properly in Box and other external apps. It's not feasible at this point to remove that default.)
          vm.threedData.forEach(subscription => {
            let iconUrl = subscription.card.icon;
            if (iconUrl) {
              rnHttp.simpleRequest({
                url: iconUrl,
                cache: $templateCache,
                withCredentials: false
              });
            }
          });
        }
      })
        .catch(error => {
          $q.resolve([]);
        });
    }

    function pageChanged(page) {
      vm.page = page;
      if (page && page.id === 'noAccountDevice') {
        startCycler();
      }
      else {
        stopCycler();
      }
    }

    function siteChanged(site) {
      vm.loading = true;
      activate();
    }

    function startCycler() {
      vm.cycler = $interval(advanceImage, CYCLE_TIME_SECONDS*1000);
    }

    function stopCycler() {
      if(angular.isDefined(vm.cycler)) {
        $interval.cancel(vm.cycler);
        vm.cycler = undefined;
      }
    }

    function advanceImage() {
      if (vm.imageIndex + 1 === IMAGE_COUNT) {
        vm.imageIndex = 0;
      }
      else {
        vm.imageIndex++;
      }
    }

    function goToSuluApp($event, actionLink){
      $event.stopPropagation();
      $gzAnalytics.eventClick('');
      $window.location.href = actionLink;
    }
  }
}
