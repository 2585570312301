export const name = 'LargeFormatConsentsController';
export default function LargeFormatConsentsController(
  // Controller angular dependencies
  $mdDialog,
  device
) {
  'ngInject';

  // Constants

  // View Model
  let vm = this;

  // Variables
  vm.device = device;

  //   VM methods
  vm.submit = submit;
  vm.cancel = cancel;

  activate();

  ////////////////

  function activate() {
  }

  function submit() {
    $mdDialog.hide();
  }

  function cancel() {
    $mdDialog.cancel();
  }
}
