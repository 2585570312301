import { LP_DEVICE_FAMILY } from 'src/components/landing-page/cards/card-definitions';

export const name = 'hmKnowledgeZone';
export default function hmKnowledgeZone() {
  'ngInject';

  const LP_KZ_DEVICE_FAMILY = {
    [LP_DEVICE_FAMILY.INDIGO]: 'Indigo',
    [LP_DEVICE_FAMILY.SCITEX]: 'Pwi',
    [LP_DEVICE_FAMILY.PAGEWIDE]: 'PWP',
    [LP_DEVICE_FAMILY.THREE_D]: 'ThreeD',
  };

  let directive = {
    restrict: 'EA',
    scope: {
      gzCard: '=',
    },
    template: require('./knowledge-zone.jade'),
    controller: hmKZController,
    controllerAs: 'vm',
    bindToController: true
  };

  return directive;

  /** @ngInject */
  function hmKZController(
    $window,
    rnHttp,
    $q,
    $gzAnalytics,
    rnLocale,
    $scope
  ) {

    const DOCS_PER_PAGE = 3;


    let documents = [];
    let pages = [];
    let bu;

    let vm = this;
    vm.loading = true;
    vm.myDocuments = [];
    vm.suggestions = [];
    vm.searchCallbacks = {
      suggest: getSuggestions,
    };

    vm.openDocument = openDocument;
    vm.searchTextChange = searchTextChange;
    vm.handleSuggestionChange = handleSuggestionChange;
    vm.deleteSuggestion = deleteSuggestion;
    vm.search = search;
    vm.suggest = suggest;
    vm.handleEnter = search;

    vm.$onInit = onInit;
    ////////////////////

    function onInit() {
      let family =  vm.gzCard.family;
      bu = LP_KZ_DEVICE_FAMILY[family];

      vm.gzCard.API.config.setAppLink(`/knowledge-zone/#/?businessUnit=${bu}`);

      let promises = [];
      promises.push(getDocuments());

      $q.all(promises).finally(() => {
        if (documents.length > DOCS_PER_PAGE) {
          let numPages = Math.ceil(documents.length / DOCS_PER_PAGE);
          let pages = [];
          // let pageDisplay = [];
          for (let i = 0; i < numPages; i++) {
            let start = i * DOCS_PER_PAGE;
            let end = start + DOCS_PER_PAGE;
            pages.push(documents.slice(start, end));
          }

          vm.gzCard.API.config.setPages(pages, pageChanged);
          vm.myDocuments = pages[0];
        }
        else {
          vm.myDocuments = documents;
       }

        vm.loading = false;
      });
    }

    function getDocuments() {
      return rnLocale.getBestFit().then(locale => {
        let config = {
          params: {
            lang: locale.value,
            size: 15,
            bu: bu
          }
        };
        return rnHttp.get('kcService', 'Search/home', config).then(resp => {
          if(resp.data.members){
            documents = resp.data.members;
          }
        })
          .catch(error => {
            return $q.resolve(documents);
          });
      });
    }

    function getSuggestions(searchString){
      if(!searchString){
        return [];
      }else{
        if(searchString.length > 1){
            vm.suggestions = [];
            return rnLocale.getBestFit().then(locale => {
              let config = {
                params: {
                  lang: locale.value,
                  bu: bu,
                  str: searchString
                }
              };
              return rnHttp.get('kcService', 'userdata/suggestions', config).then(resp => {
                return _.get(resp, 'data', []).map(o => {
                  return {
                    display: o.name,
                    value: o.name
                  };
                });
              })
                .catch(error => {
                  return [];
                });
            });
        }
      }
    }

    function suggest(){
      let promises = [];
      promises.push(getSuggestions(vm.searchKZ));

      return $q.all(promises).then((data) => {
        if(data){
          return data[0].data;
        }else{
          return [];
        }
      });
    }

    function deleteSuggestion(searchString){
      return rnLocale.getBestFit().then(locale => {
        let config = {
          params: {
            lang: vm.locale,
            bu: bu,
            str: searchString.name
          }
        };
        return rnHttp.delete('kcService', 'userdata/suggestions', config);
      });
    }

    function pageChanged(page) {
      vm.myDocuments = page;
    }

    function openDocument(id) {
      let found = _.find(vm.myDocuments, {id: id});
      $gzAnalytics.eventClick('KZ Card - Open Document', {label: 'id: ' + found.id + ' name: "' + found.original_name + '"'});
      $window.location.href = '/knowledge-zone/#/view/asset/' + id;
    }

    function searchTextChange(searchTxt){
      if(!searchTxt){
        return;
      }else{
        return getSuggestions(searchTxt);
      }
    }

    function handleSuggestionChange(suggestion){
      if(suggestion){
        $gzAnalytics.eventClick('KZ Card - Suggestion', {label: suggestion});
        $window.location.href = '/knowledge-zone/#/results?q=' + encodeURI(suggestion)+'&businessUnit=' + bu;
      }
    }

    function search($event) {
      if ($event.key === "Enter" ) {
        $event.preventDefault();
        $gzAnalytics.eventClick('KZ Card - Search', {label: vm.searchKZ});
        $window.location.href = '/knowledge-zone/#/results?q=' + encodeURI(vm.searchKZ) + '&businessUnit=' + bu;
      }
    }
  }
}
