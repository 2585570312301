import angularMaterial from 'angular-material';
import angularTranslate from 'angular-translate';

import './app-bolt.scss';

import rhinoLocale from 'rhino/src/components/locale/locale.module';
import gazelleAnalytics from 'gazelle/src/core/analytics/analytics.module';

import * as hmAppBoltDirective from './app-bolt.directive';

export default angular.module('home.components.widget.appBolt', [
  'angulartics',
  rhinoLocale,
  angularMaterial,
  angularTranslate,
  'gazelle.components.go',
  gazelleAnalytics
])
  .directive(hmAppBoltDirective.name, hmAppBoltDirective.default)
  .name;
