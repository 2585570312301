import * as $hmPrintBeat from './print-beat.service';

import angularMoment from 'angular-moment';
import rhino from 'rhino';

export default angular.module('home.components.dashboard.widgets.printBeat', [
  angularMoment,
  rhino
])
  .factory($hmPrintBeat.name, $hmPrintBeat.default)
  .name;
