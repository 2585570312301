export const name = 'hmLatexSiteStatus';
export default function hmLatexSiteStatus(
  // Directive angular dependencies
) {
  'ngInject';

  let directive = {
    restrict: 'EA',
    scope: {
      gzCard: '=',
    },
    template: require('./latex-site-status.jade'),
    link: latexSiteStatusLink,
    controller: latexSiteStatusController,
    controllerAs: 'vm',
    bindToController: true,
  };
  return directive;

  //////////////////////

  function latexSiteStatusLink(scope, element, attrs) {
  }

  /** @ngInject */
  function latexSiteStatusController(
    // Controller angular dependencies
  ) {
    // Constants

    // Variables

    // View Model
    let vm = this;
    vm.$onInit = onInit;

    return;

    function onInit() {
      vm.gzCard.API.config.setPages([{},{}], pageChanged);
    }

    function pageChanged(page) {
    }
  }
}
