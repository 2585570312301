export const name = 'hmNotifications';
export default function hmNotifications(
  rnNotificationService,
  rnPusher,
  $translate,
  $log,
  $q,
  moment,
  $interval
) {
  //noinspection BadExpressionStatementJS
  'ngInject';

  const PAGE_SIZE = 50;

  let initializationPromise;
  let notifications = [];
  let virtualNotifications = [];
  let counts = {
    active: 0,
    total: 0
  };
  let notificationLinks;
  let acknowledgeAllLink;

  //State
  let loading = true;
  let fetching = [];
  let serviceAvailable = true;
  let fetchTimer;

  const ANY_EVENT = 'AnyEvent';

  let DynamicNotifications = function() {
  };

  DynamicNotifications.prototype.getItemAtIndex = function(index) {
    if (virtualNotifications[index]) {
      return virtualNotifications[index];
    }

    if (index <= counts.total - 1) {
      let pageNumber = Math.floor(index / PAGE_SIZE);
      this.fetchPage_(pageNumber);
    }
  };

  DynamicNotifications.prototype.getLength = function() {
    return counts.total;
  };

  DynamicNotifications.prototype.fetchPage_ = function(pageNumber) {
    if(_.indexOf(fetching, pageNumber) > -1) {
      return;
    }

    fetching.push(pageNumber);
    let link = {
      'op': notificationLinks.getCurrentEvents.op,
      'href': notificationLinks.getCurrentEvents.href + '&limit=' + PAGE_SIZE + '&offset=' + pageNumber * PAGE_SIZE,
      'rel': notificationLinks.getCurrentEvents.rel
    };
    return rnNotificationService.loadMoreNotifications(link).then(function (notificationEvent) {
      updateTotal(notificationEvent.total);

      for(let i=0; i<notificationEvent.notificationEventList.length; i++) {
        let notification = notificationEvent.notificationEventList[i];
        let found = _.find(virtualNotifications, {userEventId: notification.userEventId});
        if (!found) {
          initNotification(notification);
          virtualNotifications.push(notification);
        }
      }
      virtualNotifications = _.sortBy(virtualNotifications, 'timestamp').reverse();
      fetching.splice(fetching.indexOf(pageNumber), 1);
    });
  };

  DynamicNotifications.prototype.insertHead_ = function(notification) {
    let found = _.find(virtualNotifications, {userEventId: notification.userEventId});
    if (!found) {
      initNotification(notification);
      virtualNotifications.unshift(notification);
      virtualNotifications = _.sortBy(virtualNotifications, 'timestamp').reverse();
      updateNotificationsCount();
    }
  };

  DynamicNotifications.prototype.remove_ = function(userEventId) {
    _.remove(virtualNotifications, {userEventId: userEventId});
    updateNotificationsCount();
  };

  let dynamicNotifications = new DynamicNotifications();

  let service = {
    isLoading: isLoading,
    counts: counts,
    getNotifications: getNotifications,
    getDynamicNotifications: getDynamicNotifications,
    getLinks: getLinks,
    acknowledge: acknowledge,
    acknowledgeAll: acknowledgeAll,
    isServiceAvailable: isServiceAvailable,
    loadNotifications: loadNotifications,
    loadSubscriptions: loadSubscriptions,
    loadApplications: loadApplications,
    unitTestOnly: {
      handleAnyEvent: handleAnyEvent,
      getVirtualNotifications: function() {return virtualNotifications;}
    }
  };

  initialize();

  return service;

  function getDynamicNotifications() {
    return dynamicNotifications;
  }

  function removeNotification(userEventId) {
    _.remove(notifications, {userEventId: userEventId});

    if (fetchTimer) {
      $interval.cancel(fetchTimer);
    }

    fetchTimer = $interval(fetchMoreNotifications, 5000, 1);
  }

  function fetchMoreNotifications() {
    fetchTimer = undefined;
    loadNotifications(PAGE_SIZE);
  }

  function insertNotification(notification) {
    let found = _.find(notifications, {userEventId: notification.userEventId});
    if (!found) {
      initNotification(notification);
      notifications.unshift(notification);
      notifications = _.sortBy(notifications, 'timestamp').reverse();
    }
  }

  function initialize() {
    initializationPromise = updateNotificationsCount().then((data) => {
      let current = _.find(data.notificationEvents, {id: 'current'});
      if (current) {
        acknowledgeAllLink = current._links.acknowledgeAll;
      }
      notificationLinks = data._links;
      setupPusher(data.privatePusherChannel);
      loading = false;
    })
      .catch(error => {
        serviceAvailable = false;
        return $q.reject(error);
      });
  }

  function isServiceAvailable() {
    return serviceAvailable;
  }

  function isLoading() {
    return loading;
  }

  function getNotifications() {
    return notifications;
  }

  function updateNotificationsCount() {
    return rnNotificationService.getNotifications(0, 0).then(data => {
      let current = _.find(data.notificationEvents, {id: 'current'});
      if (current) {
        updateTotal(current.total);
      }
      return $q.resolve(data);
    });
  }

  function loadNotifications(pageSize = 0) {
    return rnNotificationService.getNotifications(0, pageSize).then(data => {
      let current = _.find(data.notificationEvents, {id: 'current'});
      updateTotal(current.total);

      for(let i=0; i < current.notificationEventList.length; i++) {
        let notification = current.notificationEventList[i];
        let found = _.find(notifications, {userEventId: notification.userEventId});
        if (!found) {
          initNotification(notification);
          notifications.push(notification);
        }
      }
      notifications = _.sortBy(notifications, 'timestamp').reverse();
    });
  }

  function loadSubscriptions() {
    return initializationPromise.then(() => { // notificationLinks below sometimes doesn't get initialized until after this call completes

        return loadNotifications()
        .then(() => {
          return $q.resolve(notificationLinks.getSubscriptions);
        })
        .then((subscriptions) => {
          return rnNotificationService.getSubscriptions(subscriptions);
        })
        .catch((err) => {
          serviceAvailable = false;
          return $q.reject(err);
        });

    });
  }

  function loadApplications() {
    return initializationPromise.then(() => { // notificationLinks below sometimes doesn't get initialized until after this call completes

      return loadNotifications()
        .then(() => {
          return $q.resolve(notificationLinks.getApplications);
        })
        .then((applications) => {
          return rnNotificationService.getApplications(applications);
        })
        .catch((err) => {
          serviceAvailable = false;
          return $q.reject(err);
        });

    });
  }

  function setupPusher(privatePusherChannel) {
    rnPusher.bind(privatePusherChannel, ANY_EVENT, handleAnyEvent);
  }

  function handleAnyEvent(eventData) {
    $log.debug("eventData handleNewNotificationEvent:" + JSON.stringify(eventData));
    switch (eventData.eventDescriptionId) {
      case 'EVENT_DISMISSED':
        handleAcknowledgeEvent(eventData);
        break;
      case 'EVENT_DISMISSED_ALL':
        handleAcknowledgeAllEvent();
        break;
      default:
        // Assume this is a standard notification event
        handleNotificationEvent(eventData);
        break;
    }
  }

  function handleNotificationEvent(eventData) {
    updateTimeStamp(eventData);

    let notification = rnNotificationService.createNotificationRecord(eventData);
    //$log.debug("notification:",notification);
    insertNotification(notification);
    dynamicNotifications.insertHead_(notification);
  }

  function handleAcknowledgeEvent(eventData) {
    clearNotification(eventData.userEventId);
  }

  function handleAcknowledgeAllEvent() {
    clearAllNotifications();
  }

  function initNotification(notification) {
    _.set(notification, 'internal.loc.title', loadString(notification.title));
    _.set(notification, 'internal.loc.linkTitle', loadString(notification.linkTitle));
    _.set(notification, 'internal.loc.linkValue', loadString(notification.linkLabel));
    _.set(notification, 'internal.loc.subTitle', loadString(notification.subLabel));
    _.set(notification, 'internal.loc.subValue', loadString(notification.subValue, notification.subValueParams));

    if (notification.paramLabels && notification.paramLabels.length > 0 && notification.subValueParams && notification.subValueParams.length > 0) {
      _.set(notification, 'internal.params', []);
      for (let i = 0; i < notification.paramLabels.length; i++) {
        let labelId = notification.paramLabels[i];
        let value = notification.subValueParams[i];
        if (labelId && value) {
          let display = $translate.instant('notification.notification.' + labelId);

          let param = {
            display: display,
            value: value
          };

          notification.internal.params.push(param);
        }
      }
      if (notification.internal.params.length === 0) {
        delete notification.internal.params;
      }
    }
  }

  function loadString(str, params) {
    let key = 'notification.notification.' + str.trim();
    let ret = $translate.instant(key, params);
    if(ret === key) {
      ret = str;
    }
    return ret;
  }

  function updateTimeStamp(eventData) {
    //Only correct the timestamp if we haven't already somewhere else
    if (!eventData.unixTimeStamp) {
      let momentTimestamp = moment.utc(eventData.timestamp, 'MMM D, YYYY h:mm:ss A', 'en');
      eventData.timestamp = momentTimestamp.format('x');

      // Adjust for time skew between client and server
      // Most times this should be very small if at all
      let clientCurrentTime = moment.utc().valueOf();
      if (eventData.timestamp > clientCurrentTime) {
        eventData.timestamp = clientCurrentTime;
      }

      //Setting this so we know we've corrected the timestamp already
      eventData.unixTimeStamp = eventData.timestamp;
    }
  }

  function acknowledge(notification) {
    return rnNotificationService.acknowledgeNotification(notification.getLink('acknowledge')).then(function(notification) {
      $log.debug("notification acknowledge successfully:");
      clearNotification(notification.userEventId);
    });
  }

  function acknowledgeAll() {
    return rnNotificationService.acknowledgeAllNotifications(acknowledgeAllLink).then(function() {
      $log.debug(" All notifications acknowledged successfully:");
      clearAllNotifications();
    });
  }

  function clearNotification(userEventId) {
    removeNotification(userEventId);
    dynamicNotifications.remove_(userEventId);
  }

  function clearAllNotifications() {
    notifications.splice(0, notifications.length);
    virtualNotifications.splice(0, virtualNotifications.length);
    counts.total = 0;
  }

  function getLinks() {
    return notificationLinks;
  }

  function updateTotal(total) {
    counts.total = total;
  }
}
