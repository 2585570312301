import "./rcda.scss";
import rnHttp from 'rhino/src/core/http/http.module';
import uiRouter from "angular-ui-router";
import { default as ContextService, moduleName as ContextServiceName } from "../util/context.service";
import { default as Rcda, moduleName as rcdaName } from "./rcda.directive";
import { default as RcdaController, moduleName as RcdaControllerName } from "./rcda.controller";
import { default as TextAreaAutoSize, moduleName as textAreaAutoSizeName } from "../util/textarea_auto_size.directive";

export default angular.module('appHelpPanel.rcda', [
  rnHttp,
  uiRouter,
  'angulartics',
  'ngSanitize',
  'gazelle.components.l10n',
  'ngFileUpload',
  'ngMaterial',
  'ngMessages',
  'RcdaService',
]).controller(RcdaControllerName, RcdaController)
  .factory(ContextServiceName, ContextService)
  .directive(rcdaName, Rcda)
  .directive(textAreaAutoSizeName, TextAreaAutoSize)
  .name;
