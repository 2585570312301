export const name = 'hmChannelPsps';
export default function hmChannelPsps() {
  //noinspection BadExpressionStatementJS
  'ngInject';

  let directive = {
    restrict: 'EA',
    scope: {
      gzCard: '=',
    },
    template: require('./channel-psps.jade'),
    controller: hmChannelPspsController,
    controllerAs: 'vm',
    bindToController: true
  };

  return directive;

  /** @ngInject */
  function hmChannelPspsController(
    $window,
    rnHttp,
    $q,
    $translate,
    $gzAnalytics,
    $timeout,
    $scope
  ) {

    const AAA_SERVICE_NAME = 'aaa';
    const CHANNEL_COMPANIES = 'organizations/channelcompanies';

    let vm = this;
    vm.loading = true;

    vm.registeredPspList = [];
    vm.unregisteredPspList = [];
    vm.chartConfig = {
      loading: false,
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
        height: 220
      },
      credits: {
        enabled: false,
      },
      title: {
        text: ''
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: false,
            format: '<b>{point.name}</b>: {point.percentage:.1f} %',

          }
        }
      },
      series: [{
        name: 'PSPs',
        colorByPoint: true,
        data: [{
          name: 'Registered',
          y: 0,
          color: '#509137'
        }, {
          name: 'unregistered',
          y: 0,
          color: '#CCCCCC'
        },]
      }]
    };

    vm.getPspsAndDevices = getPspsAndDevices;
    vm.goToChannelPsp = goToChannelPsp;

    vm.$onInit = onInit;
    return;
    ////////////////////

    function onInit() {
      let link = '/account/#/channelpsps';
      vm.gzCard.API.config.setAppLink(link);

      let promises = [];
      promises.push(getPspsAndDevices());
      $q.all(promises).then(() => {
        _.set(vm.chartConfig.series[0], 'name', $translate.instant('home.home.PSPs'));
        _.set(vm.chartConfig.series[0].data[0], 'name', $translate.instant('home.home.Registered'));
        _.set(vm.chartConfig.series[0].data[1], 'name', $translate.instant('home.home.NotRegistered'));
        _.set(vm.chartConfig.series[0].data[0], 'y', vm.registeredPspList);
        _.set(vm.chartConfig.series[0].data[1], 'y', vm.unregisteredPspList);

        // This is a bit of a hack to ensure that the Highchart directive resizes to fit the card
        $window.addEventListener('resize', handleResize);
        $scope.$on('$destroy', () => {
          $window.removeEventListener('resize', handleResize);
        });
      })
        .finally(() => {
          vm.loading = false;
        });
    }

    function handleResize() {
      $timeout(()=> {
        vm.chartConfig.getChartObj().reflow();
      }, 500);
    }

    function getPspsAndDevices() {
      let config = {
        params: {
          offset: 0,
          limit: 250
        }
      };
      return rnHttp.get(AAA_SERVICE_NAME, CHANNEL_COMPANIES, config)
        .then(function(response) {
          if (response && response.data && response.data.companyCounts) {
            //Registered PSPs
            vm.registeredPspList = response.data.companyCounts.registered;

            // Unregistered PSPs
            vm.unregisteredPspList = response.data.companyCounts.unregistered;
          }
        })
        .catch((error) => {
          return $q.reject(error);
        });
    }

    function goToChannelPsp($event){
      $event.stopPropagation();
      $window.location.href = '/account/#/channelpsps';
      $gzAnalytics.eventClick('Dashboard Channel PSP card');
    }
  }
}
