import './add-lf-device.scss';
import uiRouter from 'angular-ui-router';

import * as AddLfDeviceController from './add-lf-device.controller';
import rnClient from 'rhino/src/core/client/client.module';
import rnHttp from 'rhino/src/core/http/http.module';
import rnDevice from 'rhino/src/components/device/device.module';
import rnSession from 'rhino/src/components/session/session.module';
import rnDcs from 'rhino/src/components/device/device.module';
import gazelleAnalytics from 'gazelle/src/core/analytics/analytics.module';
import gazelleGo from 'gazelle/src/components/go/go.module';

export default angular.module('home.components.addLfDevice', [
  rnClient,
  rnHttp,
  rnDevice,
  rnSession,
  rnDcs,
  uiRouter,
  gazelleAnalytics,
  gazelleGo,
])
  .controller(AddLfDeviceController.name, AddLfDeviceController.default)
  .name;
