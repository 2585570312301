import {
  BUSINESS_UNIT,
} from 'rhino/src/components/start/start.service';
import {
  ACCOUNT_TYPE,
  LP_DEVICE_FAMILY,
  LP_UNASSIGNED_SITE,
} from 'src/components/landing-page/cards/card-definitions';

import { promoDefinitions } from './promo-definitions.js';

export const LP_SELECTOR_FAMILIES = [
  { id: LP_DEVICE_FAMILY.LF, locSuffix: LP_DEVICE_FAMILY.LF },
  { id: LP_DEVICE_FAMILY.INDIGO, locSuffix: LP_DEVICE_FAMILY.INDIGO },
  { id: LP_DEVICE_FAMILY.THREE_D, locSuffix: LP_DEVICE_FAMILY.THREE_D },
  { id: LP_DEVICE_FAMILY.SCITEX, locSuffix: LP_DEVICE_FAMILY.SCITEX },
  { id: LP_DEVICE_FAMILY.PAGEWIDE, locSuffix: LP_DEVICE_FAMILY.PAGEWIDE },
];

export const name = 'hmDashboardSelector';
export default function hmDashboardSelector(
) {
  //noinspection BadExpressionStatementJS
  'ngInject';

  let directive = {
    restrict: 'EA',
    scope: {
      gzCard: '=?',
    },
    template: require('./dashboard-selector.jade'),
    controller: dashboardSelectorController,
    controllerAs: 'vm',
    bindToController: true,
  };
  return directive;

  //////////////////////

  /** @ngInject */
  function dashboardSelectorController(
    rnClient,
    rnHttp,
    rnUser,
    rnPreferences,
    rnSession,
    $translate,
    $timeout,
    moment,
    $gzAnalytics,
    $gzGo,
    $state,
    $window,
    $q,
    $gzProfileImage,
    $interval
  ) {
    // Constants

    // Variables
    let greetingTimer;
    let clientConfig;
    let allUserRoles;

    // View Model
    let vm = this;
    //   VM properties
    vm.loading = true;
    vm.site = null;
    vm.family = null;
    vm.sites = [];
    vm.families = [];
    vm.userDisplay = null;
    vm.greeting = null;
    vm.myRoles = [];
    vm.activePromos = [];
    vm.showHPWarning = false;
    vm.availablePromos = null;
    vm.promoIndex = 0;
    vm.promoProgress = 0;
    vm.promoRun = true;
    vm.hasOrg = false;
    vm.promosLoaded = false;
    //   VM methods
    vm.siteChanged = siteChanged;
    vm.familyChanged = familyChanged;
    vm.goToSettings = goToSettings;
    vm.changeProfileImage = changeProfileImage;
    vm.promoClicked = promoClicked;

    vm.$onInit = onInit;
    return;

    ///////////////
    // Init
    function onInit() {

      clientConfig = vm.gzCard.API.general.getClientConfig();
      vm.email = _.get(clientConfig, 'entity.email');
      vm.isExternalUser = vm.gzCard.API.account.isAccountType(ACCOUNT_TYPE.EXTERNAL);
      vm.isOrphan = _.get(clientConfig, 'context.type') === 'Unknown';
      vm.currentProfileImageSrc = _.get(clientConfig, 'entity.persona.image');
      vm.isHp = _.get(clientConfig, 'managingContext.type') === 'HP';

      let promises = [];
       if (vm.gzCard.API.account.hasOrg()) {
        vm.hasOrg = true;
        promises.push(initRoles());
        promises.push(initSitesAndFamilies());
      }
      return $q.all(promises).then(async () => {
        initPromos().finally(() => {
          if ( vm.activePromos.length > 1 ) {
            vm.activePromos.sort((a,b) => {
              return a.sortIndex - b.sortIndex;
            });
            $interval(() => {
              if (vm.promoRun) {
                vm.promoProgress += 1;

                if (vm.promoProgress > 100) {
                  vm.promoProgress = 0;
                  if (vm.promoIndex >= vm.activePromos.length - 1) {
                    vm.promoIndex = 0;
                  } else {
                    vm.promoIndex += 1;
                  }
                }
              }
            }, 100, 0, true); //100 here is the interval- instead of setting promoRun to execute stuff on the mouseOver, we could make the interval extra large
          }
        });
        vm.showHPWarning = vm.isHp && !hasRoleInCurrentContext(clientConfig, allUserRoles);
        initContextInfo();
        return changeDashboard();
      }).finally(() => {
        updateGreeting();

        vm.loading = false;
      });
    }

    function updateGreeting() {
      if (greetingTimer) {
        $timeout.cancel(greetingTimer);
        greetingTimer = undefined;
      }

      let hour = moment().hour();
      let hoursToThreshold = 0;
      let minutesToHour = 60 - moment().minute();
      if (hour < 12) {
        vm.greeting = $translate.instant('home.home.GoodMorning');
        hoursToThreshold = 12 - hour - 1;
      } else if (hour < 17) {
        vm.greeting = $translate.instant('home.home.GoodAfternoon');
        hoursToThreshold = 17 - hour - 1;
      } else {
        vm.greeting = $translate.instant('home.home.GoodEvening');
        hoursToThreshold = 24 - hour - 1;
      }

      let msToNextCheck = hoursToThreshold * 60 * 60 * 1000 + minutesToHour * 60 * 1000;
      greetingTimer = $timeout(updateGreeting, msToNextCheck);
    }

    function initRoles() {
      return $q.all([
        rnUser.getSelectedUserRoles(),
      ]).then(([rolesContainer, hpUser]) => {
        allUserRoles = rolesContainer.userRoles;

        _.forEach(allUserRoles, role => {
          if (role.context.id === clientConfig.context.id) {
            vm.myRoles.push(role.systemRole.name);
          }
        });

        if (vm.myRoles.length === 0) {
          let hpRole = _.find(allUserRoles, role => {
            return role.systemRole.organizationType === 'HP';
          });

          if (hpRole) {
            vm.myRoles.push(hpRole.systemRole.name);
            vm.rolesDisplay = $translate.instant('uxf.uxf.SystemRole_' + hpRole.systemRole.name);
            vm.rolesTooltip = vm.rolesDisplay;
          }
        } else if (vm.myRoles.length === 1) {
          vm.rolesDisplay = $translate.instant('uxf.uxf.SystemRole_' + vm.myRoles[0]);
          vm.rolesTooltip = vm.rolesDisplay;
        } else {
          vm.rolesDisplay = $translate.instant('home.home.MultipleRoles', [vm.myRoles.length]);
          vm.rolesTooltip = '';
          for (let i = 0; i < vm.myRoles.length; i++) {
            vm.rolesTooltip += $translate.instant('uxf.uxf.SystemRole_' + vm.myRoles[i]);
            if (i < vm.myRoles.length - 1) {
              vm.rolesTooltip += ', ';
            }
          }
        }
      });
    }

    function hasRoleInCurrentContext(clientConfig, allUserRoles) {
      let currentContextId = _.get(clientConfig, 'context.id');
      return _.some(allUserRoles, role => {
        let roleContextId = _.get(role, 'context.id');
        return roleContextId === currentContextId;
      });
    }

    function initSitesAndFamilies() {
      return getLastSitePref().then(siteId => {
        if (vm.gzCard.API.account.isAccountType(ACCOUNT_TYPE.HP) || vm.gzCard.API.account.isAccountType(ACCOUNT_TYPE.RESELLER)) {
          return updateFamilies();
        }

        vm.sites = _.cloneDeep(vm.gzCard.API.account.getSites());
        if (vm.gzCard.API.account.getDevicesByType(null, LP_UNASSIGNED_SITE).length > 0) {
          vm.sites.push({
            siteId: LP_UNASSIGNED_SITE,
            name: $translate.instant('home.home.UnknownSite'),
          });
        }

        vm.siteOptions = [];
        vm.sites.forEach(s => {
          vm.siteOptions.push({
            display: s.name,
            value: s
          });
        });

        if (siteId) {
          vm.site = vm.sites.find(site => {
            return site.siteId === siteId;
          });
        }

        if (!vm.site && vm.sites.length > 0) {
          vm.site = vm.sites[0];
        }

        return updateFamilies();
      });
    }

    function initContextInfo() {
      vm.accountName = _.get(clientConfig, 'context.name', '').trim();
      vm.userDisplay = getDisplayName(clientConfig.entity);
    }

    function getDisplayName(entity) {
      let name = '';
      let displayName = _.get(entity, 'persona.displayName', null);
      let firstName = _.get(entity, 'persona.firstName', null);
      let lastName = _.get(entity, 'persona.lastName', null);

      if (firstName && lastName) {
        name = `${firstName} ${lastName}`;
      }
      else if (displayName) {
        name = displayName;
      }
      else if (firstName) {
        name = firstName;
      }
      else if (lastName) {
        name = lastName;
      }
      else {
        name = $translate.instant('home.home.UnconfiguredUser');
      }

      return $window.$printos.v1.client.sanitize(name);
    }

    function initPromos() {
      vm.activePromos = [];
      vm.promoIndex = 0;

      if (vm.isOrphan || vm.isExternalUser) {
        return Promise.resolve();
      }

      let promises = [];

      let sortIndex = 0;
      promoDefinitions.forEach( promo => {
        promo.sortIndex = sortIndex++;
        const familyId = vm.family ? vm.family.id : null;
        promises.push(promo.include(vm.gzCard.API, familyId).then(include => {
          if(include){
            //console.log(`Promo - ${promo.id}`, include);
            $gzAnalytics.event('promo card included', { label: _.get(promo, 'id') });
            vm.activePromos.push(promo);
          }
        }));
      });

      return Promise.all(promises).finally(() => {
        vm.promosLoaded = true;
      });
    }

      ///////////////
      // VM methods
    function siteChanged() {
        return $q.all([
          setLastSitePref(),
          updateFamilies()
        ]).then(() => {
          return changeDashboard();
        });
      }

      function familyChanged() {
        initPromos();
        let promises = [];
        promises.push(setLastFamilyPref());
       return $q.all(promises).then(() => {
         return changeDashboard();
        });
      }

      function goToSettings($event) {
        $gzAnalytics.eventClick('Dashboard - User clicked profile settings');
        $event.stopPropagation();
        $state.go('settings');
      }

      ///////////////
      // Helpers
      function getLastSitePref() {
        return rnPreferences.getSingleObject('home', `lastSite${clientConfig.context.id}`);
      }

      function setLastSitePref() {
        return rnPreferences.setSingleObject('home', `lastSite${clientConfig.context.id}`, vm.site.siteId);
      }

      function getLastFamilyPref() {
        return rnPreferences.getSingleObject('home', `lastFamily${clientConfig.context.id}`);
      }

      function setLastFamilyPref() {
        return rnPreferences.setSingleObject('home', `lastFamily${clientConfig.context.id}`, vm.family.id);
      }

      function getFamilies(site) {
        if (vm.gzCard.API.account.isAccountType(ACCOUNT_TYPE.HP)) {
          return [];
        }

        if (vm.gzCard.API.account.isAccountType(ACCOUNT_TYPE.RESELLER)) {
          // Special case Resellers so that we drive family selection based on BUs instead of shared devices
          let families = [];
          if (vm.gzCard.API.account.hasBU(BUSINESS_UNIT.LF)) {
            families.push(_.find(LP_SELECTOR_FAMILIES, {id: LP_DEVICE_FAMILY.LF}));
          }

          if (vm.gzCard.API.account.hasBU(BUSINESS_UNIT.Indigo)) {
            families.push(_.find(LP_SELECTOR_FAMILIES, {id: LP_DEVICE_FAMILY.INDIGO}));
          }

          if (vm.gzCard.API.account.hasBU(BUSINESS_UNIT.ThreeD)) {
            families.push(_.find(LP_SELECTOR_FAMILIES, {id: LP_DEVICE_FAMILY.THREE_D}));
          }

          return families;
        }

        return LP_SELECTOR_FAMILIES.filter(family => {
          let mySiteId = _.get(site, 'siteId', null);
          return vm.gzCard.API.account.hasDeviceFamily(family.id, mySiteId);
        });
      }

    function updateFamilies() {
      return getLastFamilyPref().then(preferredFamilyId => {
        let families = getFamilies(vm.site);
        families.forEach(family => {
          family.display = $translate.instant('home.home.BU_' + family.locSuffix);
        });

        vm.families = families.sort(family => {
          return family.display.toLowerCase();
        });

        vm.familyOptions = [];
        vm.families.forEach(f => {
          vm.familyOptions.push({
            display: f.display,
            value: f
          });
        });

        vm.family = vm.families.find(family => {
          return family.id === preferredFamilyId;
        });
        if (!vm.family) {
          // preferred family not available, select first available family
          vm.family = vm.families[0];
        }
      });
    }


    function changeDashboard() {
        return vm.gzCard.API.actions.changeDashboard(_.get(vm, 'site.siteId'), _.get(vm, 'family.id', LP_DEVICE_FAMILY.NONE));
      }

      function changeProfileImage($event) {
        if ($event) {
          $event.stopPropagation();
        }
        return $gzProfileImage.changeUserImage().then(() => {
          return getCurrentProfileImageFromServer(false);
        })
          .catch((error) => {
            //No prop
          });
      }

      function promoClicked(promoIndex) {
        let parameters = {familyId: _.get(vm, 'family.id')};
        if (!vm.activePromos[promoIndex].onClick) {
          return Promise.resolve();
        }

        return vm.activePromos[promoIndex].onClick($gzAnalytics, $gzGo, $window, $state, parameters, vm.gzCard.API);
      }

    function getCurrentProfileImageFromServer(cache = true) {
      return $window.$printos.v1.client.getConfigAsync().then(cc => {
        $timeout(() => {
          vm.currentProfileImageSrc = _.get(cc, 'entity.persona.image', null);
        });
      });
    }
  }
}
