export const name = 'JoinOrgController';
export default function JoinOrgController(
  // Controller angular dependencies
  rnClient,
  rnDevice,
  rnLocale,
  rnInvitation,
  rnInstructionsService,
  rnHttp,
  rnSession,
  $translate,
  $state,
  $stateParams,
  $mdDialog,
  $rootScope,
  $window,
  $gzAnalytics,
  $timeout,
  $q
) {
  'ngInject';

  // Constants
  const ANALYTICS_CATEGORY = 'Join Organization';

  // Variables
  let captchaId;
  let userInput;
  let locale;
  let orgId;

  // View Model
  let vm = this;

  //   VM properties
  vm.oc = null;
  vm.validCode = false;
  vm.requested = false;
  vm.join = true;
  vm.create = false;
  vm.loading = true;
  vm.myOC = null;
  vm.notes = null;
  vm.errorKey = null;
  vm.processing = false;
  vm.orgName = null;

  //   VM methods
  vm.cancel = cancel;
  vm.choose = radioChanged;
  vm.choose = choose;
  vm.done = done;
  vm.submit = submit;
  vm.disableChoose = disableChoose;
  vm.disableCode = disableCode;

  vm.unitTest = {
  };

  vm.$onInit = onInit;

  ///////////////
  // Init
  function onInit() {
    vm.myOC = $stateParams.oc;
    vm.joinOptions = [
      {
        display: $translate.instant('home.home.JoinOrgOption'),
        value: true
      }
    ];
    vm.createOptions = [
      {
        display: $translate.instant('home.home.CreateOrgOption'),
        value: true
      }
    ];

    vm.loading = false;
  }

  function disableCode() {
    return !!vm.create;
  }

  function radioChanged(option) {
    if (option === 'join') {
      vm.create = null;
    }
    else {
      vm.join = null;
    }
  }

  function disableChoose() {
    if (vm.join) {
      return !vm.validCode;
    }

    return !vm.create;
  }

  function choose() {
    if (vm.join) {
      vm.myOC = vm.oc;
    }
    else {
      goCreate();
    }
  }

  function submit() {
    if (vm.processing) {
      return;
    }

    vm.requested = true;
    delete vm.errorKey;
    vm.processing = true;

    let request = {
      type: 'AddUser',
      payload: {
        code: vm.myOC,
      }
    };

    if (vm.notes) {
      request.payload.extendedPayload = {
        notes: vm.notes,
      }
    }
    return rnHttp.post('sso_request', `request/new`, request).then(resp => {
      console.log('Request submitted', resp);
      vm.orgName = resp.data.organizationName;
      let currentTracking = window.$printos.v1.preferences.getPreference('RequestsToTrack', 'onboarding');
      if (!currentTracking) {
        currentTracking = [];
      }

      currentTracking.push({
        id: resp.data.requestId,
        name: resp.data.organizationName,
      });

      vm.success = true;
      return window.$printos.v1.preferences.setPreferenceAsync('RequestsToTrack', currentTracking, 'onboarding').catch(() => {
        console.log('Request Join: Could not save tracked requests');
      });
    })
      .catch(error => {
        $rootScope.$applyAsync(() => {
          let statusCode = _.get(error, 'data.smsError.statusCode');
          switch (statusCode) {
            case 404:
              vm.errorKey = 'home.home.Request_ErrorInvalidCode';
              break;
            case 409:
              vm.errorKey = 'home.home.Request_ErrorAlreadyExists';
              break;
            default:
              vm.errorKey = 'home.home.RequestSent_Error';
          }
        });
      })
      .finally(() => {
        $rootScope.$applyAsync(() => {
          vm.processing = false;
        });
      });

  }

  function cancel() {
    goHome();
  }

  function done() {
    goHome();
  }

  function goHome() {
    window.$printos.v1.go.goHome();
  }

  function goCreate() {
    window.$printos.v1.go.toContext('create-org');
  }
  ///////////////
  // Helpers
}
