export const name = 'EditJobInfoDialogController';
export default function EditJobInfoDialogController(
  // Controller angular dependencies
  rnHttp,
  clientConfig,
  $translate,
  $mdDialog,
  $gzAnalytics,
  $q
) {
  'ngInject';
  // Constants
  const ANALYTICS_CATEGORY = 'Welcome Organization';

  // Variables
  let validators;

  // View Model
  let vm = this;
  //   VM properties
  vm.loading = true;
  vm.validTitle = false;
  vm.validFunctions = false;

  //   VM methods
  vm.cancel = cancel;
  vm.submit = submit;
  vm.disableSubmit = disableSubmit;
  vm.validator = validator;

  vm.activate = activate;
  activate();
  return;

  ///////////////
  // Init
  function activate() {
    vm.jobTitle = $printos.v1.user.getJobTitle();
    vm.jobFunctions = $printos.v1.user.getJobFunctions();
    vm.jobFunctionOptions = $printos.v1.user.getJobFunctionOptions();
    // vm.jobTitle = _.get(clientConfig, 'entity.persona.jobTitle');
    // vm.jobFunctions = _.get(clientConfig, 'entity.persona.myTypes', []);
    // vm.jobFunctionOptions = [];
    // let personaTypes = _.get(clientConfig, 'entity.persona.types', []);
    // personaTypes.forEach(pt => {
    //   if (pt !== 'Other' && pt !== 'NoValue') {
    //     vm.jobFunctionOptions.push({
    //       value: pt,
    //       display: $translate.instant(`uxf.uxf.Persona_${pt}`),
    //     });
    //   }
    // });
    //
    // vm.jobFunctionOptions = vm.jobFunctionOptions.sort((a,b) => {
    //   return a.display.trim().toLocaleLowerCase().localeCompare(b.display.trim().toLocaleLowerCase());
    // });
    //
    // //Special case move Other and NoValue to end
    // if (personaTypes.includes('Other')) {
    //   vm.jobFunctionOptions.push({
    //     value: 'Other',
    //     display: $translate.instant('uxf.uxf.Persona_Other'),
    //   });
    // }
    vm.loading = false;
  }

  function validator(id) {
    return $printos.v1.client.getValidator(id);
  }

  ///////////////
  // VM methods
  function disableSubmit() {
    if (!vm.validTitle) {
      return true;
    }

    if (!vm.validFunctions) {
      return true;
    }

    return false;
  }

  function cancel() {
    $gzAnalytics.event('Edit Job Info - Cancel', {category:ANALYTICS_CATEGORY});
    $mdDialog.cancel();
  }

  function submit() {
    if (disableSubmit()) {
      return;
    }

    let persona = {
      jobTitle: vm.jobTitle ? vm.jobTitle.trim() : null,
      myTypes: vm.jobFunctions,
    };
    let titleIsDifferent = isJobTitleDifferent(persona.jobTitle);
    let jobFunctionIsDifferent = isJobFunctionDifferent(persona.myTypes);

    if (titleIsDifferent || jobFunctionIsDifferent) {
      return rnHttp.put('portal_service', 'persona', persona, {version: 2}).then(() => {
        if (titleIsDifferent && jobFunctionIsDifferent) {
         $gzAnalytics.event('Job Title - Changed', { category:ANALYTICS_CATEGORY, label: persona.jobTitle });
          $gzAnalytics.event('Job Functions - Changed', {category:ANALYTICS_CATEGORY, label: persona.myTypes.join(', ')} );
        }
        else if (titleIsDifferent) {
          $gzAnalytics.event('Title Changed', { category:ANALYTICS_CATEGORY, label: persona.jobTitle });
        }
        else {
          $gzAnalytics.event('Job Functions - Changed', {category:ANALYTICS_CATEGORY, label: persona.myTypes.join(', ')} );
        }
        $mdDialog.hide();
      })
        .catch(error => {
          vm.errorKey = 'home.home.Persona_Error';
          return Promise.resolve();
        });
    }

    $gzAnalytics.event('Edit Job Info - No Changes', {category:ANALYTICS_CATEGORY});
    $mdDialog.hide();
  }

  ///////////////
  // Helpers
  function isJobTitleDifferent(jobTitle) {
    let original = _.get(clientConfig, 'entity.persona.jobTitle');
    let originalIsEmpty = !original || original === '';
    if (originalIsEmpty && (!jobTitle || jobTitle === '')) {
      return false;
    }

    return jobTitle !== original;
  }

  function isJobFunctionDifferent(jobFunctions) {
    let original = _.get(clientConfig, 'entity.persona.myTypes', []);
    if (original.length !== jobFunctions.length) {
      return true;
    }

    for (let i = 0; i < original.length; i++) {
      if (!jobFunctions.includes(original[0])) {
        return true;
      }
    }
  }

}
