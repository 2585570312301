import './webToPrint.scss';

import * as hmWebToPrintDirective from './webToPrint.directive';
import angularMoment from 'angular-moment';
import angularTranslate from 'angular-translate';
import ngMaterial from 'angular-material';

export default angular.module('home.components.webToPrint', [
  angularMoment,
  angularTranslate,
  ngMaterial
])
  .directive(hmWebToPrintDirective.name, hmWebToPrintDirective.default)
  .name;
