export const name = 'HelpWrapperController';
export default function HelpWrapperController(
  rnHttp,
  $gzAnalytics,
  $mdToast,
  $sce,
  $q,
  $stateParams,
  $translate,
  $window,
  rnLocale
) {
  //noinspection BadExpressionStatementJS
  'ngInject';

  /* jshint validthis: true */
  let vm = this;
  vm.loading = true;
  vm.topicId = $stateParams.topicId;

  vm.close = close;
  vm.unitTest = {};

  vm.$onInit = onInit;
  return;
  ////////////////

  function onInit() {
    let promises = [];
    promises.push(getTutorial());
    return $q.all(promises).then(() => {
      console.log('Help - Returned');
      $gzAnalytics.event('navigation timing', {label: `${vm.tutorial.analyticsTitle}`});
      $gzAnalytics.event('PrintOS Help Opened', {label: `${vm.tutorial.analyticsTitle}`});
    })
      .catch(error => {
        switch (error.status) {
          case 404:
            vm.errorKey = 'home.home.Help_RetrieveError_NotFound';
            break;
          case 401:
            vm.errorKey = 'home.home.Help_RetrieveError_Unauthorized';
            break;
          default:
            vm.errorKey = 'home.home.Help_RetrieveError_Unknown';
        }
      })
      .finally(() => {
        vm.loading = false;
      });
  }

  function getTutorial() {
    return getPreferenceLanguage().then(prefLang => {
      const config = {
        params: {
          locale: prefLang.value
        }
      };
      return rnHttp.get('supportapp_service', `tutorials/${vm.topicId}`, config).then(tutorial => {
        vm.tutorial = tutorial.data;
        //Needs to be https, not http
        vm.tutorial.content = vm.tutorial.content.replace('http:', 'https:');
        vm.tutorial.sanitizedUrl = $sce.trustAsResourceUrl(vm.tutorial.content);
      });
    });
  }

  function getPreferenceLanguage() {
    return rnLocale.getBestFit().then(locale => {
      return locale;
    });
  }

  function close() {
    $window.close();
  }
}
