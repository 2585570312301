import ngMaterial from 'angular-material';

// import modules this module depends on
import angularTranslate from 'angular-translate';
import rnSession from 'rhino/src/components/session/session.module';
import rnLocale from 'rhino/src/components/locale/locale.module';
import rnHttp from 'rhino/src/core/http/http.module';
import rnPreferences from 'rhino/src/components/preferences/preferences.module';
import rnPusher from 'rhino/src/components/pusher/pusher.module';
import rnNotification from 'rhino/src/components/notification/notification.module';
import gzGo from 'gazelle/src/components/go/go.module';
import gazelleAnalytics from 'gazelle/src/core/analytics/analytics.module';
import rnBlue from 'rhino/src/core/blue/blue.module';

// import this modules components
import * as notificationsController from './notifications.controller';
import * as subscriptionsEditController from './subscriptions_edit.controller';
import * as addFilterDialogController from './add-filter-dialog.controller';
import * as notificationService from './notifications.service';
import * as mobileOnboardingController from './mobile_onboarding.controller';

import './notifications.scss';
import './mobile_onboarding.scss';

// define the module in one place and export the module name
export default angular.module('home.components.notifications', [
  angularTranslate,
  ngMaterial,
  rnSession,
  rnHttp,
  rnLocale,
  rnPreferences,
  rnPusher,
  rnNotification,
  gzGo,
  'ui.router',
  gazelleAnalytics,
  rnBlue
])
  .factory(notificationService.name, notificationService.default)
  .controller(subscriptionsEditController.name, subscriptionsEditController.default)
  .controller(addFilterDialogController.name, addFilterDialogController.default)
  .controller(notificationsController.name, notificationsController.default)
  .controller(mobileOnboardingController.name, mobileOnboardingController.default)
  .name;
