export const moduleName = 'feedbackService';
export default function feedbackService(rnHttp, rnLocale, rnRegistry) {
  //noinspection BadExpressionStatementJS
  'ngInject';

  const SUPPORT_SERVICE = 'supportapp_service';
  const FEEDBACK_PATH = 'feedback';
  const AAA_SERVICE = 'aaa';
  const RETRIEVE_USER_INFO_PATH = 'users';

  return {
    getFeedbacks: getFeedbacks,
    createFeedback: createFeedback,
    getUserInfo: getUserInfo,
    getPreferenceLanguage: getPreferenceLanguage,
    getUrlSend: getUrlSend,
    getCountries: getCountries
  };

  ////////////////

  function getFeedbacks(offset, limit) {
    let config = { offset: offset, limit: limit };
    return rnHttp.get(SUPPORT_SERVICE, FEEDBACK_PATH, config);
  }

  function createFeedback(feedbackData) {
    return rnHttp.post(SUPPORT_SERVICE, FEEDBACK_PATH, feedbackData);
  }

  function getUserInfo() {
    return rnHttp.get(AAA_SERVICE, RETRIEVE_USER_INFO_PATH);
  }

  function getPreferenceLanguage() {
    return rnLocale.getBestFit().then(locale => {
      return locale;
    });
  }

  function getUrlSend() {
    let path = 'cases';
    return rnRegistry.getUrl(SUPPORT_SERVICE).then(url => {
      return url + path;
    });
  }

  function getCountries() {
    return rnLocale.getBestFit()
      .then(locale => {
        let portalCountriesOpts = {
          cache: true,
          params: {
            locale: locale.value
          }
        };
        return rnHttp.get('portal_service', 'preferences/countries', portalCountriesOpts)
          .then(response => response.data);
      });
  }
}
