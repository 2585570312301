import './landing-page.scss';
import angularTranslate from 'angular-translate';

import ngMaterial from 'angular-material';
import rnPreferences from 'rhino/src/components/preferences/preferences.module';
import gazelleAnalytics from 'gazelle/src/core/analytics/analytics.module';

import * as LandingPageController from './landing-page.controller';
import * as lpSettingsDialog from './lp-settings/lp-settings.controller';

export default angular.module('home.components.landingPage', [
  ngMaterial,
  rnPreferences,
  gazelleAnalytics,
  angularTranslate,
])
  .controller(LandingPageController.name, LandingPageController.default)
  .controller(lpSettingsDialog.name, lpSettingsDialog.default)
  .name;
