export const name = 'hmSharedDevices';
export default function hmSharedDevices() {
  //noinspection BadExpressionStatementJS
  'ngInject';

    let directive = {
    restrict: 'EA',
    scope: {
      gzCard: '=',
    },
    template: require('./sharedDevices.jade'),
    link: sharedDevicesLink,
    controller: sharedDevicesController,
    controllerAs: 'vm',
    bindToController: true,
  };

  return directive;

  function sharedDevicesLink(scope, element, attrs, controllers, transcludeFn) {
      controllers.init(element);
  }

  /** @ngInject */
  function sharedDevicesController(
    $gzDeviceStatus,
    $window,
    $scope,
    $rootScope
  ) {
    let statusWatcher;

    let vm = this;
    let org = vm.gzCard.API.account.getOrg(vm.gzCard.settings.organizationId);
    vm.devices = vm.gzCard.API.account.getDevicesByOrg(org.organizationId);
    vm.goToDevice = goToDevice;
    vm.init = init;
    vm.setStatusBarState = setStatusBarState;
    vm.getDeviceStatus = getDeviceStatus;
    vm.getRankAndSort = getRankAndSort;

    vm.$onInit = onInit;
    return;

    ////////////////////

    function onInit() {
      let link = '/account/#/devices?org=' + org.organizationId;
      vm.gzCard.API.config.setAppLink(link);

      statusWatcher = $rootScope.$on('$lpDeviceDataUpdated', () => {
        setStatusBarState();
      });
      $scope.$on('$destroy', statusWatcher);
      getRankAndSort();
    }

    function init(element) {
      // Save so we can use it later
      vm.$element = element;
    }

    function setStatusBarState() {
      // Set the containing gz-card directive's status bar to indicate the overall
      // state of the devices
      // let state = $gzDeviceStatus.getOverallStateOfMultipleDevices(vm.devices);
      getRankAndSort();
    }

    function goToDevice($event, link) {
      $event.stopPropagation();
      $window.location.href = link;
    }

    function getDeviceStatus(deviceId) {
      let device = _.find(vm.devices, {'deviceId': deviceId});
      if (device) {
        return $gzDeviceStatus.getDeviceStatus(device);
      }
    }

    function getRankAndSort() {
      if (vm.devices) {
        _.forEach(vm.devices, (device) => {
          device.rank = getDeviceStatus(device.deviceId).state.rank;
        });

        let mapped = vm.devices.map((el, i) => {
          return {index: i, value: el};
        });

        mapped.sort((a, b) => {
          if (a.value.rank < b.value.rank) { return -1; }
          else if (a.value.rank > b.value.rank) { return 1; }
          else {
            let aName = a.value.name.toUpperCase();
            let bName = b.value.name.toUpperCase();
            if (aName < bName) { return -1; }
            else if (aName > bName) { return 1; }
            return 0;
          }
        });

        let temp = mapped.map((el) => {
          return vm.devices[el.index];
        });

        vm.devices = temp;
      }
    }
  }

}
