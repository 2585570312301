import { LP_DEVICE_FAMILY } from 'src/components/landing-page/cards/card-definitions';
import $hmPrintBeat from 'src/components/landing-page/cards/shared/print-beat/print-beat.service';

export const name = 'hmLatexVolume';
export default function hmLatexVolume(
  // Directive angular dependencies
) {
  'ngInject';

  let directive = {
    restrict: 'EA',
    scope: {
      gzCard: '=',
    },
    template: require('./latex-volume.jade'),
    link: latexVolumeLink,
    controller: latexVolumeController,
    controllerAs: 'vm',
    bindToController: true,
  };
  return directive;

  //////////////////////

  function latexVolumeLink(scope, element, attrs) {
  }

  /** @ngInject */
  function latexVolumeController(
    $window,
    $translate,
    $scope,
    $timeout,
    $hmPrintBeat,
    rnHttp,
    rnFormat,
    moment
  ) {
    // Constants

    // Variables
    let pbContext = {};

    // View Model
    let vm = this;
    vm.loading = true;
    vm.chartConfig = {
      loading: false,
      chart: {
        spacingTop: 10,
        spacingRight: 5,
        spacingLeft: 0,
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        series: {
          marker: {
            lineColor: '#ffffff',
            lineWidth: 2,
            fillColor: '#0096d6',
          }
        },
      },
      title: {
        text: '',
      },
      tooltip: {
        backgroundColor: '#FFFFFF',
        delayForDisplay: 200,
        headerFormat: '',
        hideDelay: 1,
        padding: 2,
        shadow: false,
        shared: false,
        useHTML: true,
      },
      series: [
        {
          type: 'areaspline',
          lineColor: '#0096d6',
          color: {
            //linearGradient: {x1: 0, x2: 0, y1: 0, y2: 1},
            stops: [
              [0, '#66c2ea'],
              [1, '#ffffff']
            ]
          },
          data: [],
        },
      ],
      xAxis: {
        type: 'category',
        //categories: [2,3,4,5,6],
        crosshair: {
          color: '#c1c1c1',
          width: 1,
        },
        title: {
          style: {
            fontSize: '12px',
          },
        },
        min: 0.5,
        max: 5.5,
        tickWidth: 0,
      },
      yAxis: {
        min: 0,
        crosshair: {
          color: '#c1c1c1',
          width: 1,
        },
        title: {
          style: {
            fontSize: '12px',
          },
        },
        labels: {
          format: '{value:.2f}',
        },
        plotLines: [{
          value: 0,
          color: '#f5a527',
          width: 2,
          zIndex: 5,
          label: {
            align: 'right',
            x: -10,
            y: -8,
            useHTML: true,
            style: {
              color: '#000000',
              background: 'rgba(255, 255, 255, 0.7)',
            },
          }
        }],
      },
    };

    vm.$onInit = onInit;
    vm.goToPBReport = goToPBReport;
    return;

    function onInit() {
      // This is a bit of a hack to ensure that the Highchart directive resizes to fit the card
      $window.addEventListener('resize', handleResize);
      $scope.$on('$destroy', () => {
        $window.removeEventListener('resize', handleResize);
      });

      return updateChart().finally(() => {
        vm.loading = false;
      });
    }

    function handleResize() {
      $timeout(()=> {
        if (vm.chartConfig.getChartObj) {
          vm.chartConfig.getChartObj().reflow();
        }
      }, 500);
    }

    function getData() {
      pbContext = {
        page: 'reports-analytics',
        family: 'LatexPrinter',
        siteId: vm.gzCard.API.currentSite.getSite().siteId,
      };
      let appLink = $hmPrintBeat.createLink(pbContext);
      vm.gzCard.API.config.setAppLink(appLink);

      let params = {
        serial: vm.gzCard.API.currentSite.getPrintBeatSerialNumbersByFamily(LP_DEVICE_FAMILY.LF, true),
        from: moment.utc().subtract(31, 'days'),
        to: moment.utc(),
        resolution: 'Day',
      };
      return $hmPrintBeat.queryLFPApi('printprod', params).then(data => {
        return {
          last31Days: _.get(data, 'data.dataMap.TotalValuesPB.eventsList', []).slice(-32),
          last7Days: _.get(data, 'data.dataMap.TotalValuesPB.eventsList', []).slice(-8),
          unitSystem: data.unitSystem,
        };
      });
    }

    function updateChart() {
      return getData().then(data => {
        let unitText = data.unitSystem === 'Metric' ? $translate.instant('uxf.uxf.Unit_SquareMeters') : $translate.instant('uxf.uxf.Unit_SquareFeet');

        vm.chartConfig.yAxis.title.text = unitText;
        vm.chartConfig.tooltip.pointFormat = `{point.y:.2f} ${unitText}`;

        let average = data.last31Days.reduce((avg, day) => {
          if (parseFloat(day.value)) {
            avg += parseFloat(day.value);
          }
          return avg;
        }, 0)/(data.last31Days.length - 1);
        vm.chartConfig.yAxis.plotLines[0].value = average;
        vm.chartConfig.yAxis.plotLines[0].label.text = $translate.instant('home.home.LF_REPORTS_ThirtyDayAverage', [rnFormat.abbr(average, {fractionSize: 2}), unitText]);

        vm.chartConfig.series[0].data = data.last7Days.map(day => {
          return {
            // Currently no locale-specific format without year.
            // See https://github.com/moment/moment/issues/3341
            name: moment.utc(day.date).format('D MMM'),
            y: day.value,
          };
        });

        // Ensure 30-day average line and all data are visible
        let yAxisData = vm.chartConfig.series[0].data.concat([{ y: vm.chartConfig.yAxis.plotLines[0].value }]);
        vm.chartConfig.yAxis.minRange = yAxisData.reduce((acc, curr) => {
          return $window.Math.max(acc, curr.y);
        }, 0);
      });
    }

    function goToPBReport($event) {
      $event.stopPropagation();
      $hmPrintBeat.redirect(pbContext, $event);
    }
  }
}
