import { default as FeedbackModalController } from './feedback_modal.controller';
export const moduleName = 'modalFeedback';
// jscs:disable requireCapitalizedConstructors
// angular requires directives to be lowercase
export default class modalFeedback {
  constructor() {
    this.restrict = 'EA';
    this.template = require('./feedback_modal.jade');
    this.controller = FeedbackModalController;
    this.controllerAs = 'feedbackModal';
    this.scope = true;
    this.bindToController = true;
  }

  static factory() {
    return new modalFeedback();
  }
}
