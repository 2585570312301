/**
 * Created by panda on 9/19/2016.
 */

export const SUPPORTAPP_SERVICE = 'supportapp_service';
export const CASES_PATH ='/cases/';

export const moduleName = 'SupportCaseDetailService';
export default class SupportCaseDetailService {
  /** @ngInject */
  constructor (rnHttp){
   this.rnHttp = rnHttp;
  }

  getCaseById(caseId) {
    return this.rnHttp.get(SUPPORTAPP_SERVICE, CASES_PATH + caseId);
  }

  static factory(rnHttp) {
    'ngInject';
    return new SupportCaseDetailService(rnHttp);
  }
}
