import {
  LP_DEVICE_FAMILY,
  LP_UNASSIGNED_SITE,
  ACCOUNT_TYPE,
  DEFAULT_SITE_ID,
  FEATURE,
} from 'src/components/landing-page/cards/card-definitions';

export const name = 'hmWhatsNext';
export default function hmWhatsNext() {
  //noinspection BadExpressionStatementJS
  'ngInject';

  let directive = {
    restrict: 'EA',
    transclude: true,
    scope: {
      gzCard: '=',
    },
    template: require('./whats-next.jade'),
    controller: whatsNextController,
    controllerAs: 'vm',
    bindToController: true,
  };

  return directive;

  /** @ngInject */
  function whatsNextController(
    rnHttp,
    rnDeviceDetector,
    rnRegistry,
    rnDcs,
    $translate,
    $interval,
    $scope,
    $gzGo,
    $gzAnalytics,
    $gzWalkMe,
    $mdToast,
    $window,
    $state,
    $q,
    rnRequest,
    rnDevice,
    rnClient,
    $mdDialog,
    rnUser
  ) {
    const AAA_DEVICE_PROVISIONED_STATE = {
      unknownState: 5001,
      unmappedModel: 5002,
      duplicateModel: 5003,
      loggedIn: 5004,
      unreleasedModel: 5005,
      existsInOtherOrg: 5006,
      autoProvisionableNONE: 5007,
      autoProvisionableMANUAL: 5008,
      autoProvisionableAUTO: 5009,
    };
    let IMAGE_COUNT = 4;
    const CYCLE_TIME_SECONDS = 10;
    const NOTIFICATION_SERVICE_NAME = 'notification_service';
    const MOBILE_APPS_PATH = 'mobile-apps';
    const MAX_LIMIT = 1000;
    const DEFAULT_PAGE = 1;
    const DEFAULT_LIMIT = MAX_LIMIT;

    let canAddDevice = false;
    let allPages = [
      // {
      //   id: 'indigoDrupa',
      //   include: showIndigoDrupa,
      //   isInclude: true
      // },
      // {
      //   id: 'indigoReveal',
      //   include: showIndigoReveal,
      //   isInclude: false
      // },
      {
        id: 'lfPpspTrial',
        include: showLFPpspTrial,
        isInclude: true
      },
      {
        id: 'web2print',
        include: showWeb2Print,
        isInclude: true
      },
      {
        id: 'lfLearnPremium',
        include: showLFLearnPremium,
        isInclude: true
      },
      {
        id: 'lfLiveProduction',
        include: showLFLiveProduction,
        isInclude: true
      },
      {
        id: 'lfJobsAPI',
        include: showLFJobsAPI,
        isInclude: true
      },
      {
        id: 'suppliesAuthorization',
        getData: getSuppliesData,
        include: showSuppliesAuthorization,
        isInclude: false,
      },
      {
        id: 'noSiteDevice',
        include: showNoSiteDevices,
        isInclude: false,
      },
      {
        id: 'addIndigo',
        include: showAddIndigo,
        isInclude: false,
      },
      {
        id: 'activateIndigo',
        include: showActivateIndigo,
        isInclude: false,
      },
      {
        id: 'noAccountDevice',
        include: showNoDevices,
        isInclude: false,
      },
      {
        id: 'learnSiteFlow',
        include: showLearnSiteFlow,
        isInclude: false
      },
      {
        id: 'mobileApp',
        include: showMobileApp,
        isInclude: true
      },
      {
        id: 'inviteUsers',
        include: showInviteUsers,
        isInclude: false
      },
      // {
      //   id: 'updateProfile',
      //   isInclude: true
      // },
      {
        id: 'updateNotifications',
        include: showNotifications,
        isInclude: false,
      },
      {
        id: 'tourPrintOS',
        isInclude: true
      },
    ];
    let myPages = [];
    let clientConfig;
    let userInteraction;
    let apps;

    let vm = this;
    vm.loading = true;
    vm.submitting = false;
    vm.imageIndex = 0;
    vm.indigosToAdd = [];
    vm.indigosToActivate = [];
    vm.latexActivateDevices = [];
    vm.unassignedDevices = [];
    vm.videoId = 'iNDtJL74qGM';
    vm.siteFlowVideoId = 'Ing0Q22zodA';
    vm.userId = null;
    vm.orgId = null;
    vm.isAuthorized = null;
    vm.is3d = false;
    vm.isPpspTrialAvailable = false;

    vm.goToAddDevice = goToAddDevice;
    vm.goToAddIndigo = goToAddIndigo;
    vm.goToActivateIndigo = goToActivateIndigo;
    vm.goToSubscriptions = goToSubscriptions;
    vm.assignDeviceToSite = assignDeviceToSite;
    vm.goToAddNewDeviceToSite = goToAddNewDeviceToSite;
    vm.drupaIndigoClicked = drupaIndigoClicked;
    vm.revealIndigoClicked = revealIndigoClicked;
    vm.learnPremiumClicked = learnPremiumClicked;
    vm.liveProductionClicked = liveProductionClicked;
    vm.ppspTrialClicked = ppspTrialClicked;
    vm.web2printClicked = web2printClicked;
    vm.ppspInfoClicked = ppspInfoClicked;
    vm.jobsAPIClicked = jobsAPIClicked;
    vm.toggleVideo = toggleVideo;
    vm.launchWalkMe = launchWalkMe;
    vm.requestInformation = requestInformation;
    vm.sendMeApp = sendMeApp;
    vm.addUser = addUser;
    vm.refreshPage = refreshPage;
    vm.downloadAuthFile = downloadAuthFile;
    vm.showPressSecurityHelp = showPressSecurityHelp;
    vm.getApplicationName = getApplicationName;

    vm.unitTest = {
      advanceImage: advanceImage
    };

    vm.$onInit = onInit;

    function onInit() {
      clientConfig = vm.gzCard.API.general.getClientConfig();
      apps = _.get(clientConfig, 'apps.entity', []);
      let cardPromises = [];
      allPages.forEach(page => {
        if (page.getData) {
          cardPromises.push(page.getData());
        }
      });

      return $q.all(cardPromises).then(() => {
        vm.isPpspTrialAvailable = isPpspTrialAvailable();
        vm.is3d = _.get(clientConfig, 'context.bu', []).includes("ThreeD");

        let includePromises = [];
        _.forEach(allPages, page => {
          if (page.include) {
            includePromises.push(page.include().then(include => {
              page.isInclude = include;
            }));
          }
        });

        return $q.all(includePromises).then(() => {
          vm.gzCard.API.config.setSiteCallback(siteChanged);
          vm.siteToAssign = vm.gzCard.API.currentSite.getSite();
          if (vm.siteToAssign && vm.siteToAssign.siteId === 'LP_UNASSIGNED_SITE') {
            vm.sites = vm.gzCard.API.account.getSites();
            if (vm.sites.length > 0) {
              vm.siteToAssign = vm.sites[0];
            }
          }

          let pageDisplay = [];
          myPages = [];
          _.forEach(allPages, page => {
            switch (page.id) {
              case 'suppliesAuthorization':
                page.display = $translate.instant('home.home.WhatsNext_UpdateSuppliesAuth');
                break;
              case 'addIndigo':
                page.display = $translate.instant('home.home.WhatsNext_AddIndigo');
                break;
              case 'activateIndigo':
                page.display = $translate.instant('home.home.WhatsNext_ActivateIndigo');
                break;
              case 'noAccountDevice':
                page.display = $translate.instant('home.home.WhatsNext_NoAccountDevice');
                break;
              case 'noSiteDevice':
                page.display = $translate.instant('home.home.WhatsNext_NoSiteDevice');
                break;
              // case 'updateProfile':
              //   page.display = $translate.instant('home.home.WhatsNext_UpdateProfile');
              //   break;
              case 'updateNotifications':
                page.display = $translate.instant('home.home.WhatsNext_UpdateNotifications');
                break;
              // case 'indigoDrupa':
              //   page.display = $translate.instant('home.home.WhatsNext_Indigo_Drupa');
              //   break;
              // case 'indigoReveal':
              //   page.display = $translate.instant('home.home.WhatsNext_Indigo_Reveal');
              //   break;
              case 'tourPrintOS':
                page.display = $translate.instant('home.home.WhatsNext_TourPrintOS');
                break;
              case 'lfPpspTrial':
                page.display = $translate.instant('home.home.WhatsNext_PPSP');
                break;
              case 'web2print':
                page.display = $translate.instant('home.home.WhatsNext_Web2Print_Title');
                break;
              case 'learnSiteFlow':
                page.display = $translate.instant('home.home.WhatsNext_LearnAboutPrintOsSiteFlow');
                break;
              case 'mobileApp':
                page.display = $translate.instant('home.home.WhatsNext_GetMobileApp');
                break;
              case 'inviteUsers':
                page.display = $translate.instant('home.home.WhatsNext_InviteUser_Title');
                break;
              case 'lfLearnPremium':
                page.display = $translate.instant('home.home.WhatsNext_LFLearnPremium_Title');
                break;
              case 'lfLiveProduction':
                page.display = $translate.instant('home.home.WhatsNext_LFLiveProduction_Title');
                break;
              case 'lfJobsAPI':
                page.display = $translate.instant('home.home.WhatsNext_LFJobsAPI_Title');
                break;
            }
            if (page.isInclude) {
              myPages.push(page);
              pageDisplay.push(page.display);
            }
          });

          pageChanged(myPages[0]);

          vm.gzCard.API.config.setPages(myPages, pageChanged, pageDisplay);
          $scope.$on('$destroy', function() {
            stopCycler();
          });
        })
          .catch(e => {
            console.warn('Error checking pages', e);
          });
      })
        .finally(() => {
          vm.loading = false;
        });
    }

    function getDcsDevices() {
      if (vm.gzCard.API.account.isAccountType(ACCOUNT_TYPE.HP)) {
        return $q.resolve();
      }

      return rnDcs.getAll(0, 0).then(data => {
        canAddDevice = _.get(data, '_links.create') ? true : false;
      });
    }

    function getDeviceDetectorDevices() {
      if (vm.gzCard.API.account.isAccountType(ACCOUNT_TYPE.HP)) {
        return $q.resolve();
      }

      return rnDeviceDetector.getDevicesToAdd().then(deviceList => {
        vm.indigosToAdd = deviceList;
      });
    }

    function getClientConfig() {
      return rnClient.getConfig().then(config => {
        clientConfig = config;
      });
    }

    function getSuppliesData() {
      return rnHttp.get('deviceinformation', 'IQadPressDataController/getEndUserId')
        .then(resp => {
          vm.euids = resp.data;
          vm.euidOptions = [];
          if (vm.euids.length > 0) {
            vm.euid = vm.euids[0];
            vm.euids.forEach( euid => {
              vm.euidOptions.push({display: euid.value, value: euid})
            })
          }
        })
        .catch(error => {
          vm.euids = [];
          vm.euidOptions = [];
          return $q.resolve(false);
        });
    }

    function showAddIndigo() {
      if (vm.gzCard.API.account.isAccountType(ACCOUNT_TYPE.EXTERNAL)) {
        return $q.resolve(false);
      }

      if (!vm.gzCard.API.account.isAdmin()) {
        return $q.resolve(false);
      }

      let p = [];
      p.push(getDcsDevices());
      p.push(getDeviceDetectorDevices());

      return $q.all(p)
        .then(() => {

          _.forEach(vm.indigosToAdd, device => {
            if (device.model === undefined) {
              device.model = $translate.instant('home.home.Add_Indigo_Model_Not_Found');
            }

            //Removing duplicate device if it is already added to PrintOS
            let foundSN = _.find(vm.devices, {serialNumber: device.serialNumber});
            if(device.state === AAA_DEVICE_PROVISIONED_STATE.duplicateModel && foundSN){
              _.remove(vm.indigosToAdd, device);
            }
          });

          if (vm.indigosToAdd.length > 0) {
            vm.selectedIndigoToAdd = vm.indigosToAdd[0];
          }

          return canAddDevice && vm.indigosToAdd.length > 0;

        })
        .catch(error => {
          return $q.resolve(false);
        });
    }

    function showActivateIndigo() {
      if (vm.gzCard.API.account.isAccountType(ACCOUNT_TYPE.EXTERNAL)) {
        return $q.resolve(false);
      }

      if (!vm.gzCard.API.account.isAccountType(ACCOUNT_TYPE.PSP)) {
        return $q.resolve(false);
      }

      if (!vm.gzCard.API.account.isAdmin()) {
        return $q.resolve(false);
      }

      return rnDeviceDetector.getDevicesToActivate().then(deviceList => {
        vm.indigosToActivate = deviceList;

        return vm.indigosToActivate.length > 0;
      });
    }

    function showNoDevices() {
      if (vm.gzCard.API.account.isAccountType(ACCOUNT_TYPE.EXTERNAL)) {
        return $q.resolve(false);
      }

      return getDcsDevices().then(() => {
        return canAddDevice && vm.gzCard.API.account.getDevicesByType().length === 0;
      })
        .catch(() => {
          return $q.resolve(false);
        });
    }

    function showSuppliesAuthorization() {
      if (vm.gzCard.API.account.isAccountType(ACCOUNT_TYPE.EXTERNAL)) {
        return $q.resolve(false);
      }

      if (!window.$printos.v1.org.isIndigo()) {
        return $q.resolve(false);
      }

      return $q.resolve(vm.euids && vm.euids.length > 0);
    }

    function showNoSiteDevices() {
      if (vm.gzCard.API.account.isAccountType(ACCOUNT_TYPE.EXTERNAL)) {
        return $q.resolve(false);
      }

      // Only include if PSP
      if (!vm.gzCard.API.account.isAccountType(ACCOUNT_TYPE.PSP)) {
        return $q.resolve(false);
      }

      // Only include if admin
      if (!vm.gzCard.API.account.isAdmin()) {
        return $q.resolve(false);
      }

      // Only include if they have a site
      vm.site = vm.gzCard.API.currentSite.getSite();
      if (!vm.site) {
        return $q.resolve(false);
      }

      // Only include if this isn't the default site
      if (vm.site.siteId === DEFAULT_SITE_ID) {
        return $q.resolve(false);
      }

      // Only include if we have unassigned devices
      vm.unassignedDevices = vm.gzCard.API.account.getDevicesByType(null, LP_UNASSIGNED_SITE);
      if (vm.unassignedDevices.length === 0) {
        return $q.resolve(false);
      }
      else {
        vm.deviceToAddToSite = vm.unassignedDevices[0];
      }

      return $q.resolve(true);
    }

    function showNotifications() {
      if (vm.gzCard.API.account.isAccountType(ACCOUNT_TYPE.EXTERNAL)) {
        return $q.resolve(false);
      }

      return rnHttp.get('notification_service', 'subscription', {version: 1})
        .then(data => {
          return data.data.total === 0;
        })
        .catch(error => {
          $q.resolve(false);
        });
    }

    function showMobileApp(){
      if (vm.gzCard.API.account.isAccountType(ACCOUNT_TYPE.EXTERNAL)) {
        return $q.resolve(false);
      }

      if (window.$printos.v1.org.isHP()) {
        return $q.resolve(false);
      }

      return rnHttp.get(NOTIFICATION_SERVICE_NAME, MOBILE_APPS_PATH)
        .then(response => {
          let mobileApps = _.get(response, 'data.mobileApps', []);
           let found = mobileApps.find(ma => {return ma.enabled === false;});
           if(found && !vm.is3d){
             return $q.resolve(true);
           }
        })
        .catch(error => {
          $q.resolve(false);
        });
    }

    function showInviteUsers(page = DEFAULT_PAGE, limit = DEFAULT_LIMIT){
      if (vm.gzCard.API.account.isAccountType(ACCOUNT_TYPE.EXTERNAL)) {
        return $q.resolve(false);
      }

      let offset = (page - 1) * limit;
      return rnUser.getAll(offset, limit)
        .then((data) => {
          if(data && data.total === 1 && data.canCreate()){
           return $q.resolve(true);
          }
          return $q.resolve(false);
        })
        .catch((error) => {
          $q.resolve(false);
          vm.errorKey = 'account.account.User_Retrieve_Error_Unkonwn';
        });
    }

    function showLearnSiteFlow(){
      if (vm.gzCard.API.account.isAccountType(ACCOUNT_TYPE.EXTERNAL)) {
        return $q.resolve(false);
      }

      if (vm.gzCard.API.account.isAccountType(ACCOUNT_TYPE.HP)) {
        return $q.resolve(false);
      }

      //Todo: Remove getRequestTypes call once panda team supports non admin request cases
      let promises = [];
      promises.push(rnRequest.getRequestTypes().catch(() => {return $q.resolve()}));
      promises.push(rnDevice.getAll(0, 1000));

      return $q.all(promises).then(([types, myDevices]) => {
        if (types && types.list && types.list.length > 0) {
          if (_.find(types.list, function(o) { return o.id === 1;})) {
            let isIndigo = _.find(myDevices.devices, 'type', 'IndigoPress');
            let isScitex = _.find(myDevices.devices, 'type', 'ScitexPress');
            let isIHPS = _.find(myDevices.devices, 'type', 'IHPSPress');
            let hasSiteFlow = _.find(apps, 'appId', 'production');

            //Show card only for Indigo and PWI customers that don't already have Site Flow
            if(!hasSiteFlow && (isIndigo || isScitex || isIHPS)){
              return true;
            }
          }
        }
        return false;
      });
    }

    function showLFLearnPremium() {
      if (!vm.gzCard.API.general.isCurrentFamily(LP_DEVICE_FAMILY.LF)) {
        return $q.resolve(false);
      }

      if (!vm.gzCard.API.account.hasFeature(FEATURE.LEARN_PREMIUM)) {
        return $q.resolve(false);
      }

      return $q.resolve(true);
    }

    function isProductSupported(productCode) {
      return window.$printos.v1.http.get('product_service', 'organizations/products').then(resp => {
        let products = _.get(resp, 'data.products', []);
        let found = products.find(p => { return p.productCode === productCode });
        return found ? true : false;
      });
    }

    function showLFPpspTrial() {
      let hasPPSP = window.$printos.v1.market.hasProduct('ppsp');
      let hasPPSPTrial = window.$printos.v1.market.hasProduct('ppsp-trial');
      return Promise.resolve(hasPPSP || hasPPSPTrial);
    }

    function isPpspTrialAvailable() {
      let product = window.$printos.v1.market.getProduct('ppsp-trial');
      return product && product.trial.available;
    }

    function showWeb2Print() {
      let supportsWeb2Print = window.$printos.v1.app.getAppById('web2print') ? true : false;

      return Promise.resolve(supportsWeb2Print);
    }

    function showIndigoReveal() {
      return isProductSupported('productivity-pack');
    }

    function showIndigoDrupa() {
      return isProductSupported('productivity-pack');
    }

    function showLFLiveProduction() {
      if (!vm.gzCard.API.general.isCurrentFamily(LP_DEVICE_FAMILY.LF)) {
        return $q.resolve(false);
      }

      if (!vm.gzCard.API.account.hasFeature(FEATURE.LF_SOLUTION)) {
        return $q.resolve(false);
      }

      return $q.resolve(true);
    }

    function showLFJobsAPI() {
      if (!vm.gzCard.API.general.isCurrentFamily(LP_DEVICE_FAMILY.LF)) {
        return $q.resolve(false);
      }

      if (!vm.gzCard.API.account.hasFeature(FEATURE.LF_JOBS_API)) {
        return $q.resolve(false);
      }

      return $q.resolve(true);
    }

    function pageChanged(page) {
      // Stop the video if the user clicks a page change while it's playing
      if (userInteraction) {
        vm.playerAPI.stop();
        vm.showVideo = false;
        userInteraction = false;
      }

      vm.page = page;
      if (page.id === 'noAccountDevice') {
        startCycler();
      }
      else {
        stopCycler();
      }
    }

    function siteChanged(site) {
      vm.loading = true;
      $onInit();
    }

    function startCycler() {
      // Blindly stop to ensure we're not creating multiple cyclers
      stopCycler();
      // There are currently only two 3d images, and 4 regular images
      IMAGE_COUNT = vm.is3d ? 2 : 4;
      vm.cycler = $interval(advanceImage, CYCLE_TIME_SECONDS*1000);
    }

    function stopCycler() {
      if(angular.isDefined(vm.cycler)) {
        $interval.cancel(vm.cycler);
        vm.cycler = undefined;
      }
    }

    function advanceImage() {
      if (vm.imageIndex + 1 === IMAGE_COUNT) {
        vm.imageIndex = 0;
      }
      else {
        vm.imageIndex++;
      }
    }

    function goToAddDevice($event) {
      $event.stopPropagation();
      $gzAnalytics.eventClick('add device');
      $gzGo.startTask('device-add', 'device-list/add', 'home', 'dashboard', {});
    }

    function goToAddIndigo($event) {
      $event.stopPropagation();
      let payload = {
        returnLabel: $translate.instant('home.home.Home_title'),
        allowExitToApp: false,
        allowAppNav: false,
        device: {
          type: 'IndigoPress',
          model: vm.selectedIndigoToAdd.model,
          serialNumber: vm.selectedIndigoToAdd.serialNumber
        }
      };

      $gzAnalytics.eventClick('add device from home page', {label: vm.selectedIndigoToAdd.model ? vm.selectedIndigoToAdd.model : 'model not found'});
      $gzGo.startTask('device-add', 'devices/add', 'home', 'dashboard', payload);
    }

    function goToAddNewDeviceToSite($event) {
      $event.stopPropagation();
      let payload = {
        appId: 'home',
        allowExitToApp: false,
        allowAppNav: false,
        device: {
          siteId: vm.site.siteId,
        }
      };

      $gzAnalytics.eventClick('add device to site from home page', {label: vm.site.siteId});
      $gzGo.startTask('device-add', 'devices/add', 'home', 'dashboard', payload);
    }

    function goToActivateIndigo($event) {
      $event.stopPropagation();
      $gzAnalytics.eventClick('activate Indigo devices from home page');
      $gzGo.startTask('device-activate-indigo', 'devices/activate/indigo', 'home', 'dashboard', {appId: 'home', allowExitToApp: false, allowAppNav: false});
    }

    function goToSubscriptions($event) {
      $event.stopPropagation();
      $gzAnalytics.eventClick('activate Indigo devices from home page');
      $state.go('subscriptions-edit', {data: {from: 'dashboard'}});
    }

    function assignDeviceToSite(device, $event) {
      $event.stopPropagation();
      $gzAnalytics.eventClick('assign device to site from home page', {label: vm.siteToAssign.siteId});
      return rnDcs.getById(device.deviceId).then(deviceWithAgent => {
        let dcsDevice = {
          device: _.cloneDeep(deviceWithAgent.device),
          agent: {
            createNew: false,
            name: ''
          }
        };
        _.set(dcsDevice, 'device.site.siteId', vm.siteToAssign.siteId);

        return rnDcs.updateDevice(dcsDevice).then(editedDevice => {
          _.set(device, 'site.siteId', vm.siteToAssign.siteId);
          let index = vm.unassignedDevices.findIndex(o => {
            return o.deviceId === device.deviceId;
          });

          if (index !== -1) {
            vm.unassignedDevices.splice(index, 1);
          }

          let toast = $translate.instant('home.home.WhatsNext_DeviceAssigned', [vm.siteToAssign.name]);
          $mdToast.show($mdToast.simple().content(toast));
          // If we are in the Unassigned category we want to reload the dashboard once they have assigned the last device to update the site drop-down
          if (vm.unassignedDevices.length === 0) {
            $window.location.reload();
          }
          else {
            vm.deviceToAddToSite = vm.unassignedDevices[0];
            vm.assignedDevices.push(device);
          }
        })
          .catch(error => {
            let err = error;
          })
          .finally(() => {
            vm.loading = false;
          });
      });
    }

    function refreshPage($event) {
      if ($event) {
        $event.stopPropagation();
      }

      $window.location.reload();
    }

    function downloadAuthFile($event) {
      return rnRegistry.getUrl('psServer', `suppliesAuth/file?endUserID=${vm.euid.key}`).then(downloadURL => {
        // Create a hidden element we'll use to trigger the download
        let temp = document.createElement('a');
        temp.setAttribute('href', downloadURL);
        temp.style.display = 'none';

        // Temporarily add element to the document and trigger download
        document.body.appendChild(temp);
        temp.click();
        document.body.removeChild(temp);
      });
    }

    function showPressSecurityHelp() {
      $gzAnalytics.eventClick('Whats Next - Press Security Help');
      $window.open('https://cdn-public.printos.com/assets/pdfs/v2/Press_UI_upload_authorization_file.pdf', '_blank');
    }

    function drupaIndigoClicked() {
      $gzAnalytics.eventClick('Whats Next - Indigo Drupa');
      $window.$printos.v1.go.toContext('market-all-products', 'application');
    }

    function revealIndigoClicked() {
      $gzAnalytics.eventClick('Whats Next - Indigo Reveal');
      $window.$printos.v1.go.toContext('market-all-products', 'application');
    }

    function learnPremiumClicked() {
      $window.$printos.v1.ga.eventTrack('home', 'Card - Clicked', 'NewsTips - Learn Premium');
      let app = vm.gzCard.API.account.getApplication('university');
      if (!app) {
        return;
      }
      // $window.$printos.v1.go.toContext('market-learn-premium');
      $window.$printos.v1.app.goToApp('university');
    }

    function ppspInfoClicked() {
      let hasPPSP = window.$printos.v1.market.hasProduct('ppsp');
      $window.$printos.v1.ga.eventTrack('home', 'Card - Clicked', 'NewsTips - PPSP');
      if (hasPPSP) {
        $window.$printos.v1.go.toContext('market-product', 'ppsp');
      }
      else {
        $window.$printos.v1.go.toContext('market-product', 'ppsp-trial');
      }
    }

    function ppspTrialClicked() {
      $window.$printos.v1.ga.eventTrack('home', 'Card - Clicked', 'NewsTips - PPSP Trial');
      $window.$printos.v1.go.toContext('market-product', 'ppsp-trial');
    }

    function web2printClicked() {
      $window.$printos.v1.ga.eventTrack('home', 'Card - Clicked', 'NewsTips - Web2Print');
      $window.$printos.v1.app.goToApp('web2print');
    }

    function liveProductionClicked() {
      $window.$printos.v1.ga.eventTrack('home', 'Card - Clicked', 'NewsTips - Live Production');
      $gzAnalytics.eventClick('Whats Next - LF Live Production');
      let app = vm.gzCard.API.account.getApplication('printbeat');
      if (!app) {
        return;
      }
      // $window.$printos.v1.go.toContext('market-live-production');
      $window.$printos.v1.app.goToApp('printbeat');
    }

    function jobsAPIClicked() {
      $window.$printos.v1.ga.eventTrack('home', 'Card - Clicked', 'NewsTips - LF Jobs API');
      $window.$printos.v1.go.toContext('learn-course', '11999');
    }

    function getApplicationName(appId) {
      let app = $window.$printos.v1.app.getAppById(appId);
      return app && app.name ? app.name : 'UNKNOWN';
    }

    function toggleVideo() {
      if(vm.is3d) {
        return;
      }

      vm.showVideo = !vm.showVideo;
      $gzAnalytics.eventClick('Whats Next - PrintOS Video', {show: vm.showVideo});

      if (vm.showVideo) {
        if (_.get(vm.playerAPI, 'play')) {
          vm.playerAPI.play();
          userInteraction = true;

          //Below intervals are specific video time
          if(vm.page){
            if(vm.page.id === 'learnSiteFlow'){
              $interval(stopUserInteraction, 138000);
            }else if(vm.page.id === 'tourPrintOS'){
              $interval(stopUserInteraction, 135000);
            }
          }
        }
      }
      else {
        if (_.get(vm.playerAPI, 'stop')) {
          vm.playerAPI.stop();
          userInteraction = false;
        }
      }
    }

    function stopUserInteraction(){
      userInteraction = false;
    }

    function launchWalkMe($event) {
      $gzAnalytics.eventClick('Whats Next - PrintOS Walk Me');
      $event.stopPropagation();
      $gzWalkMe.startWalkMeByTitle('HP PrintOS Overview');
    }

    function requestInformation($event) {
      $gzAnalytics.eventClick('Whats Next - Learn Site Flow');
      $event.stopPropagation();
      let requestPayload = {
        type: {
          id: 1,
          securityPassword: 'password',
          requestFieldTemplates: [
            {
              id: 1,
              fieldValue: {
                stringValue: clientConfig.entity.id
              }
            },
            {
              id: 2,
              fieldValue: {
                stringValue: clientConfig.context.id
              }
            },
            {
              id: 3,
              fieldValue: {
                // App name
                stringValue: "production"
              }
            },
            {
              id: 4,
              fieldValue: {
                // Number of seconds before request expires
                stringValue: "7776000"   // 90 days in seconds
              }
            },
          ]
        }
      };
      return rnRequest.createRequest(requestPayload).then(() => {
        let toast = $translate.instant('home.home.WhatsNext_SiteFlow_MessageSent');
        $mdToast.show($mdToast.simple().content(toast));
        $gzAnalytics.eventClick('Whats Next - A request for more information about Site Flow has been made');
      }).catch(error => {
        if (error && error.smsError && error.smsError.statusCode && error.smsError.statusCode === 409) {
          let toast = $translate.instant('home.home.WhatsNext_SiteFlow_MessageAlreadySent');
          $mdToast.show($mdToast.simple().content(toast));
          $gzAnalytics.eventClick('Whats Next - A request for more information about Site Flow has already been made');
        }
      });
    }

    function sendMeApp($event) {
      $event.stopPropagation();
      $window.location.href = '/market/#/product/mobileapp';
      $gzAnalytics.eventClick('whats next get mobile app', {label: 'mobileapp'});
    }

    function addUser($event){
      $event.stopPropagation();
      $gzGo.startTask('invite-user', 'users/add', 'home', 'dashboard', {});
    }
  }
}
