import BaseController from "../util/base.controller";
import BrowserHelper from "../util/browser_helper";
import BUHelper from "../util/bu_helper";

const LOCALIZATION = 'translate';
const OK = 'uxf.uxf.OK';
const SUCCESS_MESSAGE = 'uxf.uxf.Send_Question_Success';
const SUCCESS_MESSAGE_THIRDPARTY = 'uxf.uxf.Send_Question_Success_ThirdParty';
const WARNING_TITLE = 'uxf.uxf.Status_Warning';
const ERROR_TITLE = 'uxf.uxf.Error_Title';
const ERROR_MESSAGE = 'uxf.uxf.Send_Question_Error';
const ERROR_MAX_FILES_MESSAGE = 'uxf.uxf.AskTheExpert_Message_MaxFiles';
const ERROR_CONNECTION_MESSAGE = 'uxf.uxf.Send_Question_Error_Connection';
const DEFAULT_PAGE_NAME = '';
const LABEL_EMAIL = 'uxf.uxf.Email';
const LABEL_PHONE = 'uxf.uxf.Phone';
const LABEL_PHONE_DISABLED = 'uxf.uxf.PhoneDisabled';
const REQUESTS_PERMISSIONS_PATH = 'requests/types';
const CONFIG_SEGMENT = 'client/config';
const PORTAL_SERVICE = 'portal_service';
export const SUPPORTAPP_SERVICE = 'supportapp_service';
export const SUPPORTAPP_CONFIGURATIONS_PATH = 'configurations';

const APP_ID_GROUP = ["pwi-sc", "reseller-portal"];
const PWI_SC_SPECIFIC_ID = [
        {bu: "Latex", appId: "sc-latex"},
        {bu: "Scitex", appId: "sc-scitex"},
        {bu: "PWP", appId: "sc-pwp"},
        {bu: "Indigo", appId: "sc-indigo"},
        {bu: "ThreeD", appId: "sc-threed"}
      ];
const CHANNEL_ID = "sc-latex-reseller";

export const moduleName = 'AskTheExpertController';
export default class AskTheExpertController extends BaseController {

  /** @ngInject */
  constructor($filter, $scope, $timeout, $log, $analytics,  $mdDialog, ContextService, questionService,
              Upload, askaquestionService, $rootScope, rnSession, $window, $state, $stateParams, $q, rnHttp) {
    super({
      $filter, $scope, $timeout, $log, $analytics, $mdDialog, ContextService, questionService, Upload,
      askaquestionService, $rootScope, rnSession, $state, $stateParams, $q, rnHttp
    });

    this.isSending = false;
    this.isMaximum = false;
    this.isLoading = false;
    this.showMaxFilesWarning = false;
    this.initScope($scope, Upload, $timeout);
    this.activate($scope, $rootScope);
    this.browserHelper = BrowserHelper.factory($log, $window);
    this.language = {};
    this.isNotEnglish = false;
    this.hasCountry = false;
    this.phoneContactMeEnabled = false;
    this.$window = $window;
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.$q = $q;
    this.userCountry = null;
    this.userDevices = [];
    this.deviceList = [];
    this.addDeviceInformation = false;
    this.priorityInformation = false;
    this.uploadErrors = {};
    this.addManualInformation = false;
    this.orphan = false;
    this.has3Dbu = $printos.v1.org.is3D();
    this.showSymptoms = false;
    this.buHelper = BUHelper.factory();
    this.buList = this.buHelper.getBuNames().map(bu => {return {display: bu, value: bu}});
    this.categorizationsParams = {application: '', bus: []};
    this.permissionsResponse = null;
    this.rnHttp = rnHttp;
    this.isPremium = null;

    this.$scope.displayMyCasesAnchor = true;
    this.$scope.modalTitle = $filter(LOCALIZATION)(SUCCESS_MESSAGE);
  }

  initScope($scope, Upload, $timeout) {
    this.hpidSettingsUrl = window.$printos.v1.client.getRegistryValue('hpidAccountUrl');
    $scope.formInfo = {};
    $scope.data = {};
    $scope.selected = {application: {}, bus: ["Other"]};
    $scope.userSelectedCountry = null;
    $scope.breadcrumbs = [
      {
        state: 'dashboard',
        titleKey: 'home.home.Home_title'
      },
      {
        state: 'settings',
        titleKey: 'home.home.My_Settings'
      },
      {
        state: 'contact-hp',
        titleKey: 'home.home.Contact_Hp'
      },
      {
        state: 'ask-the-expert',
        titleKey: 'uxf.uxf.View_AskTheExpert'
      },
    ];


    $scope.uploadFiles = (files, errFiles) => {
      let $filter = this.$filter;
      this.isSending = true;
      $scope.f = files;
      $scope.errFile = errFiles && errFiles[0];
      this.questionService.getUserInfo().then(userInfo => {
        let data = this.uploadFileData(Upload, files, userInfo.data);

        Upload.upload({
          url: this.urlSend,
          data: data
        }).then(response => {
          if (!!files) {
            $timeout(() => files.result = response.data);
          }
          let params = {
            action: this.GA_ACTION_SUBMIT, application: this.ContextService.currentAppName(),
            page: this.currentPageName, type: 'Ask A Question', title: 'Ask A Question'
          };
          this.sendAnalytics(params, this.$scope.formInfo.title, this.$scope.selected.application.englishName, this.$scope.countryList, this.$scope.userSelectedCountry.isoCode, this.userDevices, this.$scope.selected.symptom)
          this.setStateForModal(response);
          this.showSuccessfulMessageSent();
          this.errorMessageKey = '';
          this.clearForm();
          this.isSending = false;
        }, error => {
          this.handleSendQuestionError(error, $scope);
          this.isSending = false;
        }, evt => {
          if (!!files) {
            files.progress = Math.min(100, parseInt(100.0 * evt.loaded / evt.total));
          }
        });
      });
    };
  }

  disableSend() {
    if (!this.validIsoCode || !this.validApplication || !this.validTitle || !this.validQuestion || !this.validDevices() || !this.validPreferredContact || (!this.validSymptom && this.showSymptoms && this.enableSymptom) || this.isSending) {
      return true;
    }

    return false;
  }

  sendAnalytics(params, title, englishName, countryList, userSelectedCountry, devices, symptom) {
    this.analytics(params);
    this.sendFormValuesToGoogleAnalytics('subject', title);
    this.sendFormValuesToGoogleAnalytics('app', englishName);
    this.sendFormValuesToGoogleAnalytics('country', _.find(countryList, country => {
      return country.value && country.value.isoCode === userSelectedCountry;
    }).value.englishName);

    this.sendFormValuesToGoogleAnalytics('hasDevices', devices.length != 0 ? true:false);
    this.sendFormValuesToGoogleAnalytics('bu', symptom ? symptom.topCategory:false);
    this.sendFormValuesToGoogleAnalytics('symptom', symptom ? symptom.specificSymptom:false);
  }

  uploadFileData(Upload, files, userInfoData) {
    let data = {};
    if (files && files.length) {
      data = {
        case: Upload.jsonBlob(this.prepareFormInfoToSend(this.$scope, userInfoData)),
        attachment01: files[0] ? files[0] : null,
        attachment02: files[1] ? files[1] : null,
        attachment03: files[2] ? files[2] : null,
        attachment04: files[3] ? files[3] : null,
        attachment05: files[4] ? files[4] : null
      };
    }else {
      data = {
        case: Upload.jsonBlob(this.prepareFormInfoToSend(this.$scope, userInfoData))
      };
    }

    for (let key in data) {
      if (data.hasOwnProperty(key)) {
        if (data[key] === null) {
          delete data[key];
        }
      }
    }

    return data;
  }

  sendFormValuesToGoogleAnalytics(field, value) {
    const eventTrack = 'Ask A Question: ' + field;
    const event = {
      category: 'help',
      action: 'Ask A Question: ' + field,
      label: value
    };

    this.$analytics.eventTrack(eventTrack, event);
  }

  activate($scope) {
    this.parseParams();
    this.currentApplicationName = this.ContextService.currentAppName();

    this.validIsoCode = false;
    this.validApplication = false;
    this.validTitle = false;
    this.validQuestion = false;
    this.validPreferredContact = false;
    this.validSymptom = false;

    this.getFeatureFlags();

    let promises = [];
    promises.push(this.getLanguage());
    promises.push(this.getQsUserInfo());
    promises.push(this.getQsUrlSend());
    promises.push(this.getIsAuthenticated());
    promises.push(this.getUserCountry());
    promises.push(this.getApps());
    promises.push(this.getDev());
    promises.push(this.getCountries());
    promises.push(this.getSupportPermissions());
    if (window.$printos.v1.client.hasFeature('lf-coverage-plans')) {
      promises.push(this.getLFPremiumStatus());
    }

    this.$q.all(promises)
      .then(response => {
        this.clearForm();
        this.getCaseAvoidanceTutorials(this.$scope.selected.application);
        this.permissionsResponse = response[7];
      })
      .catch(e => {
        this.handleSendQuestionError(e, this.$scope);
        this.isSending = false;
      })
      .finally(response => {
        this.isLoading = false;
      });
  }

  cancel() {
    this.clearForm();
  }

  handleSendQuestionError(error) {
    let err = !!error && !!error.smsError && !!error.smsError.message ? error.smsError.message :
      this.$filter(LOCALIZATION)(ERROR_MESSAGE);
    if (!this.$window.navigator.onLine) { // Verify connection previously
      err += ' ' + this.$filter(LOCALIZATION)(ERROR_CONNECTION_MESSAGE);
    }
    this.errorMessageKey = err;
    const params = { action: this.GA_ACTION_VIEW, application: this.ContextService.currentAppName(),
      page: this.currentPageName, type: 'Ask A Question Error',  title: 'Ask A Question Error', error: error };
    this.analytics(params);
  }

  isFormEmpty() {
    const subject = this.$scope.formInfo.title;
    const description = this.$scope.formInfo.question;

    const isSubjectEmpty = subject ? subject.trim().length === 0 : true;
    const isDescriptionEmpty = description ? description.trim().length === 0 : true;

    return  isSubjectEmpty || isDescriptionEmpty;
  }

  prepareFormInfoToSend($scope, userInfo) {
    const browserInfo = this.browserHelper.getBrowserInfo();
    const osInfo = this.browserHelper.getOSInfo();
    const devicesInfo = this.addInfoToDevice();
    const sendParams = {
      caseSource: "WEB",
      subject: $scope.formInfo.title,
      type: "ASK_A_QUESTION",
      preferredContactMethod: {
        contactMethodType: $scope.formInfo.preferredContact.id
      },
      userSystemInfo: {browser: browserInfo.objbrowserName, browserVersion: browserInfo.objfullVersion, osVersion: osInfo},
      userEntries: [
        {
          userEntryType: "DESCRIPTION",
          value: $scope.formInfo.question
        },
        {
          userEntryType: "APPLICATION",
          value: $scope.selected.application.englishName
        },
        {
          userEntryType: "APPLICATION_ID",
          value: $scope.selected.application.appId
        },
        {
          userEntryType: "COUNTRY",
          value: $scope.userSelectedCountry.isoCode
        },
        {
          userEntryType: "DEVICES",
          value: devicesInfo
        }
      ]
    };

    if ($scope.selected.symptom && this.enableSymptom) {
      sendParams.userEntries = sendParams.userEntries.concat([
        {
          userEntryType: "BU",
          value: $scope.selected.symptom.topCategory
        },
        {
          userEntryType: "SPECIFIC_SYMPTOM",
          value:  $scope.selected.symptom.specificSymptom
        },
        {
          userEntryType: "REMOTE_DIAG_CODE",
          value:  $scope.selected.symptom.remoteDiagCode
        }
      ]);
    }
    return sendParams;
  }

  clearForm() {
    this.errorMessageKey = '';
    let $scope = this.$scope;
    $scope.selected.application = this.defaultApplication($scope.applicationList);
    //$scope.formInfo = { question: '', preferredContact: this.preferredContactList[0].id };
    delete $scope.formInfo.question;
    $scope.formInfo.preferredContact = this.preferredContactList[0].id;
    $scope.screenshots = null;

    if ($scope.sendForm) {
      $scope.sendForm.$setPristine();
      $scope.sendForm.$setUntouched();
    }
  }

  removeFileButton(fileName) {
    let index = -1;
    for(let i=0; i<this.$scope.screenshots.length; i++) {
      if(fileName === this.$scope.screenshots[i].name) {
        index = i;
      }
    }
    if(index !== -1) {
      this.$scope.screenshots.splice(index, 1);
      if(this.$scope.screenshots.length < 5) {
        this.isMaximum = false;
      }
    }
  }

  selectFiles($files, invalidFiles) {
    this.uploadErrors = {};
    if (invalidFiles && invalidFiles.length > 0) {
      invalidFiles.forEach(invalidFile => {
        this.uploadErrors[invalidFile.$error] = true;
      });
    }
    this.showMaxFilesWarning = false;
    if (this.$scope.screenshots && $files && ((this.$scope.screenshots.length + $files.length) > 5)) {
      this.isMaximum = false;
      this.showMaxFilesWarning = true;
    }

    if ($files && $files.length <= 5) {
      if (this.$scope.screenshots === null) {
        if ($files && $files[0] && $files[0].size < 1) {
          this.uploadErrors.minSize = true;
        } else {
          this.$scope.screenshots = $files;
        }
      } else {
        if (this.$scope.screenshots.length < 5) {
          if ($files && $files.length <= 5 && (this.$scope.screenshots.length + $files.length) <= 5) {
            for (let i = 0; i < $files.length; i++) {
              if ($files[i].size < 1) {
                this.uploadErrors.minSize = true;
              } else {
                this.$scope.screenshots.push($files[i]);
              }
              if (this.$scope.screenshots.length === 5) {
                break;
              }
            }
          }
        }
      }
    }

    this.isMaximumFilesAttached($files);

    if (this.showMaxFilesWarning === true) {
      this.showMaxFilesMessage(this.$filter(LOCALIZATION)(ERROR_MAX_FILES_MESSAGE));
    }
  }

  isMaximumFilesAttached($files) {
    if (this.$scope.screenshots && this.$scope.screenshots.length === 5) {
      this.isMaximum = true;
      this.showMaxFilesWarning = false;
    }

    if($files && $files.length > 5) {
      this.isMaximum = false;
      this.showMaxFilesWarning = true;
    }

    // if(this.$scope.screenshots && $files && ((this.$scope.screenshots.length + $files.length) > 5)) {
    //   this.isMaximum = true;
    // }

  }

  showMaxFilesMessage(message) {
    let title = this.$filter(LOCALIZATION)(WARNING_TITLE);
    let ok = this.$filter(LOCALIZATION)(OK);
    this.$mdDialog.show(
      this.$mdDialog.alert()
        .parent(angular.element(document.body))
        .title(title)
        .textContent(message)
        .ariaLabel(title)
        .ok(ok)
    ).then(function() {
    });
  }

  showErrorMessage(message) {
    let errorTitle = this.$filter(LOCALIZATION)(ERROR_TITLE);
    this.showMessage(errorTitle, message, this.$state, this.myParams.data);
  }

  showSuccessMessage(message) {
    let successTitle = this.$filter(LOCALIZATION)(SUCCESS_MESSAGE);
    this.showMessage(successTitle, message, this.$state, this.myParams.data);
  }

  showMessage(title, message, state, data) {
    let ok = this.$filter(LOCALIZATION)(OK);
    this.$mdDialog.show(
      this.$mdDialog.alert()
        .parent(angular.element(document.body))
        .title(title)
        .textContent(message)
        .ariaLabel(title)
        .ok(ok)
    ).then(function() {
      if (_.get(data, 'source') === 'welcome') {
        state.go('welcome', {data: data});
      }
      else {
        state.go('contact-hp', {data: data});
      }
    });
  }

  showSuccessfulMessageSent() {
    const modal = this.generateModal(require('./asktheexpert_modal.jade'), 'AskTheExpertModalController');
    this.$mdDialog.show(modal).then(() => {
      this.$mdDialog.hide();
    }).catch(() => {
      this.$mdDialog.cancel();
      this.goToContactHp();
    })
      .finally(() => {});
  }

  generateModal(template, controllerName) {
    return {
      parent: angular.element(document.body),
      template,
      controller: controllerName,
      bindToController: true,
      controllerAs: 'vm',
      scope: this.$scope,
    };
  }

  tagNameToTranslate(tagName) {
    if (!!tagName) {
      return 'uxf.uxf.AskTheExpert_' + tagName.trim().toLowerCase().replace(/[^a-z]+/g, '_');
    }
    else {
      return tagName;
    }
  }

  getCurrentPageName() {
    if (!!this.$state && !!this.$state.current) {
      return this.$state.current.name;
    }
    else {
      return DEFAULT_PAGE_NAME;
    }
  }

  getLanguage() {
    return this.askaquestionService.getPreferenceLanguage()
      .then(response => {
        this.language = response;
        let englishLocales = ['en-US','en-GB'];
        this.isNotEnglish = englishLocales.indexOf(this.language.value) < 0;
        return this.$q.resolve();
      });
  }

  getQsUserInfo() {
    return this.questionService.getUserInfo()
      .then(userInfo => {
        if (!this.isNotEnglish && userInfo.data.primaryPhone) {
          this.loadContactPreferredMethodWithPhone();
        } else {
          this.loadContactPreferredMethodWithoutPhone();
        }
        return this.$q.resolve();
      });
  }

  getQsUrlSend() {
    return this.askaquestionService.getUrlSend()
      .then(url => {
        this.urlSend = url;
        return this.$q.resolve();
      });
  }

  getIsAuthenticated() {
    return this.rnSession.isAuthenticated()
      .then(isAuthenticated => {
        if (isAuthenticated) {
          this.isLoading = true;
        }
        return this.$q.resolve();
      });
  }

  getApps() {
    return this.askaquestionService.getApplications()
      .then(apps => {
        if (!!apps) {
          this.$scope.applicationList = apps;

          // sort applications by name
          this.$scope.applicationList.sort((a, b) => {
            return a.display < b.display ? -1 : a.display > b.display ? 1 : 0;
          });

          // remove external applications
          this.$scope.applicationList = this.$scope.applicationList.filter(elem => {
            return elem.value.type !== 'external';
          });

          // remove denied applications
          this.rnHttp.get(SUPPORTAPP_SERVICE, SUPPORTAPP_CONFIGURATIONS_PATH).then(response => {
            const denylist = response.data;

            this.$scope.applicationList = this.$scope.applicationList.filter(application => !denylist.includes(application.value.appId));

            return this.$q.resolve();
          });
        }
      });
  }

  getDev() {
    return this.askaquestionService.getDevices()
      .then(devs =>{
        if (!!devs) {
          this.deviceList = devs;
          // sort devices by name
          this.deviceList.sort((a, b) => {
            return a.display.toLocaleLowerCase().localeCompare(b.display.toLocaleLowerCase());
          });
        }
        this.orphan = devs == undefined ? true : false;
        this.addManualInformation = this.orphan;
      },
      reason => {
        this.orphan = true;
        this.addManualInformation = this.orphan;});

  }

  loadContactPreferredMethodWithPhone() {
    let contactName = `${this.$filter(LOCALIZATION)(LABEL_EMAIL)}`;
    this.preferredContactList = [{display: contactName , value: {id: 'PROFILE_EMAIL', name: contactName}}];
    contactName = `${this.$filter(LOCALIZATION)(LABEL_PHONE)}`;
    this.preferredContactList.push({display: contactName, value: {id: 'PROFILE_PHONE', name: contactName}});
    this.phoneContactMeEnabled = true;
  }

  loadContactPreferredMethodWithoutPhone() {
    let contactName = `${this.$filter(LOCALIZATION)(LABEL_EMAIL)}`;
    this.preferredContactList = [{display: contactName , value: {id: 'PROFILE_EMAIL', name: contactName}}];
    this.phoneContactMeEnabled = false;
  }

  getUserCountry() {
    return this.questionService.getUserInfo().then(userInfo => {
      if(userInfo.data && userInfo.data.primaryAddress && userInfo.data.primaryAddress.country){
        this.hasCountry = userInfo.data.primaryAddress.hasOwnProperty('country');
        this.userCountry = {isoCode: userInfo.data.primaryAddress.country};
        this.$scope.userSelectedCountry = this.userCountry;
        if(this.hasCountry) {
          this.validIsoCode = true;
        }
      }
    });
  }

  getCountries() {
    return this.askaquestionService.getCountries()
      .then(countries => {
        if (countries) {
          this.$scope.countryList = [];
          countries.forEach( country => {
            this.$scope.countryList.push({
              display: country.localizedName,
              value: country
            })
          });

          this.$scope.countryList.sort( (a, b) =>
            (a.display.toLocaleLowerCase() < b.display.toLocaleLowerCase()) ? -1 : (a.display.toLocaleLowerCase() > b.display.toLocaleLowerCase()) ? 1 : 0);
        }
        return this.$q.resolve();
      });
  }

  getSupportPermissions() {
    return this.rnHttp.get(SUPPORTAPP_SERVICE, REQUESTS_PERMISSIONS_PATH)
      .catch(() => {
        return Promise.resolve();
      });
  }

  getLFPremiumStatus() {
    return this.rnHttp.get('lf_ppu', '/pricebook/api/external/v1/is_premium_customer').then((resp) => {
      this.isPremium = !!resp.data.result.hasPremiumPlan;
    }).catch(e => {
      return Promise.resolve();
    });
  }

  defaultApplication(applications) {
    let appId;
    if (this.myParams.appId) {
      switch(this.myParams.appId){
        case APP_ID_GROUP[0]: {
          let bu = this.myParams.data.returnUrl.split("?");
          bu = bu[1].split("=");
          PWI_SC_SPECIFIC_ID.map(obj => {
            if (obj.bu === bu[1]) {
              appId = obj.appId;
            }
          });
          break;
        }
        case APP_ID_GROUP[1]: {
          if (this.myParams.data.returnUrl.includes("hpgraphicspartners")) {
            appId = CHANNEL_ID;
          }
          break;
        }
        default:
          appId = this.myParams.appId;
      }

      if (this.myParams.subject) {
        this.$scope.formInfo.title = this.myParams.subject;
      }
    }

    const matchByPreviousAppId = application => application.value.appId === appId;

    const matchByCurrentAppName = application => (
        application.value.name.toLowerCase() === this.currentApplicationName.toLowerCase()
    );

    let resp;
    if (appId) {
      resp = _.find(applications, matchByPreviousAppId);
    } else {
      resp = _.find(applications, matchByCurrentAppName);
    }
    if(resp) {
      return resp.value;
    }
    return resp;
  }

  static factory($filter, $scope, $timeout, $log, $analytics,  $mdDialog, ContextService, questionService, Upload,
                 askaquestionService, $rootScope, rnSession, $window, $state, $stateParams, $q, rnHttp) {
    'ngInject';
    return new AskTheExpertController($filter, $scope, $timeout, $log, $analytics, $mdDialog, ContextService,
      questionService, Upload, askaquestionService, $rootScope, rnSession, $window, $state, $stateParams, $q, rnHttp);
  }

  parseParams() {
    this.myParams = this.$stateParams;
  }

  myParams(data) {
    this.myParams = {};
    if (data) {
      this.myParams.data = data;
    }
    else {
      return this.myParams.data;
    }
  }

  goToContactHp($event) {
    if ($event) {
      $event.stopPropagation();
    }

    if (this.myParams.from) {
      this.$window.location.href = `/api/portal/v1/go/validate?url=${window.encodeURIComponent(this.myParams.from)}`;
    }
    else if (!this.$window.$printos.v2.go.returnToSender(this.myParams.fromId)) {
      this.$state.go('contact-hp', this.myParams.data);
    }
  }

  countryChanged(){
    this.$scope.userSelectedCountry = this.userCountry;
  }

  setStateForModal(response) {
    let sentToThirdPartyApp = false;
    let hasUserEntries = false;

    if (response && response.data) {
      sentToThirdPartyApp = response.data.sentToThirdPartyApp;
      hasUserEntries = !!response.data.userEntries;
    }

    if (sentToThirdPartyApp) {
      this.$scope.displayMyCasesAnchor = false;

      if (hasUserEntries) {
        const applicationName = response.data.userEntries[1].value;
        this.$scope.modalTitle = this.$filter(LOCALIZATION)(SUCCESS_MESSAGE_THIRDPARTY, [applicationName]);
      }
    } else {
      this.$scope.displayMyCasesAnchor = true;
      this.$scope.modalTitle = this.$filter(LOCALIZATION)(SUCCESS_MESSAGE);
    }
  }

  getCaseAvoidanceTutorials(application) {
    if(application && application.appId) {
      this.askaquestionService.getCaseAvoidanceTutorials(application.appId).then(tutorials => {
        if (tutorials) {
          this.tutorials = tutorials;
        }
      });
    }
  }

  showCaseAvoidance() {
    return this.tutorials && this.tutorials.data && this.tutorials.data.total > 0;
  }

  applicationChanged() {
    this.getCaseAvoidanceTutorials(this.$scope.selected.application);
    this.getCasesCategorization();
  }

  getTutorialIcon(tutorial) {
    switch (tutorial.type) {
      case 'walkthruweb': {
        return 'home/images/ic_directions_walk_black_24px.svg';
      }
      case 'video': {
        return 'home/images/ic_play_circle_outline_black_24px.svg';
      }
      case 'freshdesk':
      case 'pdf': {
        return 'home/images/ic_description_black_24px.svg';
      }
      case 'url': {
        return 'home/images/ic_open_in_browser_black_24px.svg';
      }
    }
  }

  openTutorial(tutorial) {
    if(tutorial.type === 'walkthruweb') {
      this.$window.WalkMeAPI.startWalkthruById(parseInt(tutorial.content));
    } else { // video, pdf, freshdesk, url
      this.$window.open(tutorial.content);
    }
    this.sendFormValuesToGoogleAnalytics('tutorial opened', tutorial.analyticsTitle + '-' + tutorial.type);
  }

  goToRCDA($event) {
    if ($event) {
      $event.preventDefault();
      $event.stopPropagation();
    }
    this.sendFormValuesToGoogleAnalytics('contact support', 'RCDA link clicked');
    this.$state.go('rcda', this.myParams);
  }

  userHasCreateRCDAPermission(response) {
    if (response && response.data && response.data._links) {
      return response.data._links.hasOwnProperty('createRCDA') || response.data._links.hasOwnProperty('createRCDASelf');
    }
    return false;
  }

  shouldRenderRCDA() {
    return this.userHasCreateRCDAPermission(this.permissionsResponse);
  }

  addInfoToDevice() {
    let string =  this.priorityInformation ? "This case is preventing/blocking the user from perform some action" : "";
    if ((this.addDeviceInformation && (this.addManualInformation || !this.orphan))) {
      this.userDevices.forEach(dev =>{
        let verificatedDevice = this.verifyManuallyAddedDevice(dev);
        if (verificatedDevice.length !== 0) {
          string = string + "\n----------------------------------\n";
          string = string + "Device Name = " + verificatedDevice[0].value.name+'\n';
          string = string + "Device ID = " + verificatedDevice[0].value.id+'\n';
          string = string + "Device Model = " + verificatedDevice[0].value.model+'\n';
          string = string + "Device SerialNumber = " + verificatedDevice[0].value.serialNumber;
        }
        else{
          if(dev.value.edit.serialNumber && this.addManualInformation) {
            string = string + "\n----------------------------------\n";
            string = string + "Device SerialNumber = " + dev.value.edit.serialNumber+'\n';
            string = string + "Device Model/Id = " + dev.value.edit.model;
          }
        }
      });
    }
    return string === "" || string === "This case is preventing/blocking the user from perform some action" ? string : string + "\n----------------------------------";
  }

  verifyManuallyAddedDevice(device) {
    if(this.deviceList)
      return this.deviceList.filter(dev => {
        if (device.value.edit.serialNumber !== undefined && dev.value.serialNumber === device.value.edit.serialNumber) {
          return true;
        }
        else {
          return false;
        }
      });
    else
      return [];
  }

  deviceChanged() {
    this.listDevices();
    if (this.enableSymptom){
      this.$scope.selected.bus = this.userDevices.length === 0 || !this.addDeviceInformation ?
        this.$scope.selected.bus = ['Other'] : [...new Set(this.userDevices.map(d=> d.value.edit.bu))].filter(e=>!!e);

      this.getCasesCategorization();
    }
  }

  listDevices() {
    this.userDevices.forEach(device => {
      let lista = this.userDevices.filter(e => device.value.serialNumber !== e.value.serialNumber);
      device.list = this.deviceList.filter( e => !lista.find(d => d.value == e.value))
    })
  }

  getCasesCategorization() {
    if(this.enableSymptom){
      if(this.categorizationsParams.application.appId === this.$scope.selected.application.id)
        if(this.$scope.selected.bus.length === this.categorizationsParams.bus.length)
          if(this.$scope.selected.bus.every(e => this.categorizationsParams.bus.includes(e)))
            return

      this.$scope.symptomsList = [];
      this.showSymptoms = false;
      this.$scope.selected.symptom = null;
      this.categorizationsParams = {
        application: this.$scope.selected.application,
        bus: this.$scope.selected.bus
      };
      if (!this.categorizationsParams.application){
        this.categorizationsParams.application = '';
      }
      if (this.categorizationsParams.bus.length>0 && this.categorizationsParams.application){
        this.askaquestionService.getCasesCategorization(this.categorizationsParams.bus, this.categorizationsParams.application.id).then(data => {
          let categorizationList = data.casesCategorizationList;

          let other = categorizationList.filter(e => e.specificSymptom === "Other");
          if (other.length===1) {
            categorizationList = categorizationList.filter(e => e.specificSymptom !== "Other");
            categorizationList.push(other[0]);
          }

          this.$scope.symptomsList = categorizationList.map(c => {
            let displayname = this.$filter(LOCALIZATION)('admin.admin'+c.symptomTranslationId) != 'admin.admin'+c.symptomTranslationId ?
              this.$filter(LOCALIZATION)('admin.admin'+c.symptomTranslationId) : c.specificSymptom;
            return {
              display : displayname,
              value : c
            }
          });
        }).then(()=>{
          this.showSymptoms = this.$scope.symptomsList.length > 0 ? true : false;
          if(this.$scope.symptomsList.length === 1) {
            this.$scope.selected.symptom = this.$scope.symptomsList[0].value;
          }
        });
      }
    }
  }

  newDevice(button) {
    if(button) {
      this.userDevices.forEach(dev => {
        if (this.verifyManuallyAddedDevice(dev).length == 0) {
          this.removeDevice(dev)
        }
        else
          this.userDevices[this.userDevices.indexOf(dev)] = this.verifyManuallyAddedDevice(dev)[0]
      });

      this.userDevices = this.userDevices.map(d=> this.reset_edit(d));
    }
    if(!button || this.userDevices.length == 0)
      this.userDevices.push({value:{serialNumber:undefined,edit:{serialNumber:undefined}}})
    this.deviceChanged();
  }

  reset_edit(d) {
    d.value.edit.bu = d.value.bu;
    d.value.edit.serialNumber = d.value.serialNumber;
    d.value.edit.model = d.value.model;
    return d
  }

  removeDevice(device) {
    device = this.reset_edit(device);
    this.userDevices = this.userDevices.filter(e => e.value.edit.serialNumber !== device.value.edit.serialNumber)
    if(this.userDevices.length == 0)
      this.newDevice(false)
    this.deviceChanged();
  }

  validDevices(){
    let valid = true;
    if (this.addDeviceInformation && (this.addManualInformation || !this.orphan)){
      valid = this.userDevices.every(e => {
        if (e.value.edit.serialNumber && e.value.edit.serialNumber.length <= 255){
          if (e.value.edit.model && e.value.edit.model.length > 255)
            return false
        }
        else
          return false
        return true
        })
    }
    return valid;
  }

  getFeatureFlags() {
    const config = {
      cache: true,
      version: 2,
    };

    this.rnHttp.get(PORTAL_SERVICE, CONFIG_SEGMENT, config).then(resp => {
      // Reusing the Device Flag
      this.enableSymptom = _.get(resp, 'data.registry.keys.contactHPDeviceEnabled', false)
    });
  }

  question() {
    return this.$filter(LOCALIZATION)('uxf.uxf.AskTheExpert_Details') != 'uxf.uxf.AskTheExpert_Details' ?
      this.$filter(LOCALIZATION)('uxf.uxf.AskTheExpert_Details') : this.$filter(LOCALIZATION)('uxf.uxf.AskTheExpert_Question') ;
  }

}
