/**
 * Created by panda on 9/15/2016.
 */
import * as supportCaseListController from './support_case_list.controller';
import * as contextService from '../util/context.service';
import * as supportCaseListService from './support_case_list.service';

export default angular.module('appHelpPanel.support-case', [
  'gazelle.components.l10n',
  'ngMaterial',
  'ngMessages',
  'gcs.Questions',
  'angulartics'
]).factory(supportCaseListService.moduleName, supportCaseListService.default)
  .factory(contextService.moduleName, contextService.default)
  .controller(supportCaseListController.moduleName, supportCaseListController.default)
  .name;
