import ngMaterial from 'angular-material';

// import modules this module depends on
import angularTranslate from 'angular-translate';
import rnSession from 'rhino/src/components/session/session.module';
import * as settingsAbout from './about.controller';
import gzEula from 'gazelle/src/components/user/eula/eula.module';

// import this modules components

import './about.scss';

// define the module in one place and export the module name
export default angular.module('home.components.about', [
  ngMaterial,
  angularTranslate,
  'md.data.table',
  'ui.router',
  gzEula,
  rnSession
])
  .controller(settingsAbout.name, settingsAbout.default)
  .name;
