import './delete-user-dialog.scss';
import ngMaterial from 'angular-material';
import uiRouter from 'angular-ui-router';
import rnHttp from 'rhino/src/core/http/http.module';
import angularTranslate from 'angular-translate';

import * as DeleteUserDialogController from './delete-user-dialog.controller';
import gazelleAnalytics from 'gazelle/src/core/analytics/analytics.module';

export default angular.module('home.components.deleteUserDialog', [
  rnHttp,
  uiRouter,
  ngMaterial,
  angularTranslate,
  gazelleAnalytics,
])
  .controller(DeleteUserDialogController.name, DeleteUserDialogController.default)
  .name;
