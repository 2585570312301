import rhino from 'rhino';
import angularTranslate from 'angular-translate';
import gazelleAnalytics from 'gazelle/src/core/analytics/analytics.module';

import './supply-orders.scss';
import * as hmSupplyOrdersDirective from './supply-orders.directive';

export default angular.module('home.components.widget.indigoSupplyOrders', [
  rhino,
  angularTranslate,
  gazelleAnalytics,
])
  .directive(hmSupplyOrdersDirective.name, hmSupplyOrdersDirective.default)
  .name;
