import './privacy.scss';

import * as PrivacyController from './privacy.controller';
import rnLocale from 'rhino/src/components/locale/locale.module';
import rnBlue from 'rhino/src/core/blue/blue.module';

export default angular.module('home.components.privacy', [
  'ui.router',
  rnLocale,
  rnBlue
])
  .controller(PrivacyController.name, PrivacyController.default)
  .name;
