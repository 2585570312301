export const name = 'hmAppAnalytics';
export default function hmAppAnalytics() {
  //noinspection BadExpressionStatementJS
  'ngInject';

  let directive = {
    restrict: 'EA',
    scope: {
      gzCard: '=?',
    },
    template: require('./app-analytics.jade'),
    controller: appAnalyticsController,
    controllerAs: 'vm',
    bindToController: true
  };

  return directive;

  /** @ngInject */
  function appAnalyticsController(
    rnHttp,
    $scope,
    $interval,
    $q,
    $log,
    $window
  ) {
    let updateDataPromise;

    let vm = this;
    vm.loading = true;
    vm.abbrOpts = {
      minimumSignificantDigits: 3,
      maximumSignificantDigits: 3
    };
    vm.counts = {
      jobsYield: '--',
      uptime: '--',
      printedVolume: '--',
      packingDensity: '--',
      parts: '--',
    };
    vm.hasData = false;
    vm.actionUrl ='';
    vm.goToDataAnalytics = goToDataAnalytics;

    vm.$onInit = onInit;
    return;

    function onInit() {
      vm.gzCard.API.config.setSiteCallback(siteChanged);
      let promises = [];
      promises.push(getAnalyticsData());
      $q.all(promises).then(() => {
        updateDataPromise = $interval(getAnalyticsData, 5 * 60 * 1000);
        vm.gzCard.API.config.setAppLink(vm.actionUrl);
        $scope.$on('$destroy', () => {
          if (updateDataPromise) { $interval.cancel(updateDataPromise);}
        });
      })
        .finally(() => {
          vm.loading = false;
        });
    }

    function getAnalyticsData() {
      let config = {
        params: {
          site: _.get(vm.gzCard.API.currentSite.getSite(), 'siteId', -1),
        },
      };
      return rnHttp.get('3d_sms_portal', '/productivity/cards', config).then(resp => {
        let analyticsData = _.get(resp, 'data.cards[0].summary', {});
        let jobsYield = _.get(analyticsData, 'jobs_yield', '--');
        let packingDensity = _.get(analyticsData, 'packing_density', '--');
        let parts = _.get(analyticsData, 'parts', '--');
        let printedVolume = _.get(analyticsData, 'printed_volume', '--');
        let uptime = _.get(analyticsData, 'uptime', '--');
        vm.actionUrl = _.get(resp, 'data.cards[0].action.destination', {});

        _.set(vm.counts, 'jobsYield', jobsYield);
        _.set(vm.counts, 'packingDensity', packingDensity);
        _.set(vm.counts, 'parts', parts);
        _.set(vm.counts, 'printedVolume', printedVolume);
        _.set(vm.counts, 'uptime', uptime);

       vm.hasData = (jobsYield !== '--') ||
                     (packingDensity !== '--') ||
                     (parts !== '--') ||
                     (printedVolume !== '--') ||
                     (uptime !== '--');
      })
        .catch(errorResponse => {
          $log.debug("fail to get productivity widget data:", errorResponse);
          //resetting counts to handle API error cases
          vm.counts = undefined;
          return $q.reject();
        });
    }

    function siteChanged() {
      getAnalyticsData();
    }

    function goToDataAnalytics($event) {
      $event.stopPropagation();
      if ($event.ctrlKey) {
        $window.open(vm.actionUrl, '_blank');
      } else {
        $window.location.href = vm.actionUrl;
      }
    }
  }
}
