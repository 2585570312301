import './set-default-app.scss';

import angularMaterial from 'angular-material';
import gazelleAnalytics from 'gazelle/src/core/analytics/analytics.module';
import uiRouter from 'angular-ui-router';
import rhinoPreferences from 'rhino/src/components/preferences/preferences.module';
import rhinoApplication from 'rhino/src/components/application/application.module';

import * as SetDefaultAppController from './set-default-app.controller';

export default angular.module('home.components.settings.setDefaultApp', [
  angularMaterial,
  gazelleAnalytics,
  uiRouter,
  rhinoPreferences,
  rhinoApplication,
])
  .controller(SetDefaultAppController.name, SetDefaultAppController.default)
  .name;
