export const name = 'hmIndigoSupplyOrders';
export default function hmIndigoSupplyOrders() {
  //noinspection BadExpressionStatementJS
  'ngInject';

  let directive = {
    restrict: 'EA',
    scope: {
    },
    template: require('./supply-orders.jade'),
    controller: indigoSupplyOrdersController,
    controllerAs: 'vm',
    bindToController: true
  };

  return directive;

  /** @ngInject */
  function indigoSupplyOrdersController(
    rnHttp,
    $gzAnalytics,
    $scope,
    $window,
    $interval,
    $q
  ) {
    const analyticsOpts = { category: 'Indigo Supply Orders card' };

    let updateDataPromise;
    let defaultLink;
    let registerLink;

    let vm = this;
    vm.loading = true;
    vm.counts = {
      supplies: {
        active: '--',
        enabled: false,
      },
      inks: {
        active: '--',
        enabled: false,
      }
    };

    vm.goLink = goLink;
    vm.goRegister = goRegister;

    vm.$onInit = onInit;
    return;

    function onInit() {
      let promises = [];
      promises.push(getServiceCenterData());
      $q.all(promises).then(() => {
        updateDataPromise = $interval(getServiceCenterData, 5 * 60 * 1000);
        $scope.$on('$destroy', () => {
          if (updateDataPromise) { $interval.cancel(updateDataPromise);}
        });
      })
        .finally(() => {
          vm.loading = false;
        });
    }

    function getServiceCenterData() {
      return rnHttp.get('sms_portal', '/api/gateway/v1/summary').then(resp => {
        let scData = _.get(resp, 'data.data.result.indigoSuppliesOrders', {});
        defaultLink = _.get(scData, 'directLink');
        let supplies = _.get(scData, 'suppliesOrders.active', '--');
        let suppliesEnabled = _.get(scData, 'suppliesOrders.enabled', false);
        let link = _.get(scData, 'suppliesOrders.directLink');
        _.set(vm.counts, 'supplies.active', supplies);
        _.set(vm.counts, 'supplies.enabled', suppliesEnabled);
        _.set(vm.counts, 'supplies.link', link);

        let ink = _.get(scData, 'inkOrders.active', '--');
        let inkEnabled = _.get(scData, 'inkOrders.enabled', false);
        link = _.get(scData, 'inkOrders.directLink');
        _.set(vm.counts, 'inks.active', ink);
        _.set(vm.counts, 'inks.enabled', inkEnabled);
        _.set(vm.counts, 'inks.link', link);

        vm.hasData = suppliesEnabled || inkEnabled;
      })
        .catch(error => {
          let statusCode = _.get(error, 'data.smsError.statusCode');
          if (statusCode === 401) {
            registerLink = _.get(error, 'data.smsError.moreInfoUrl');
            vm.noAccess = true;
            $gzAnalytics.event(`service center not registered`, Object.assign({}, analyticsOpts));
          }
          else {
            vm.errorKey = 'home.home.LoadingError';
          }
        });
    }

    function goLink(link, label) {
      $gzAnalytics.eventClick(`go to service center`, Object.assign({ label }, analyticsOpts));
      $gzAnalytics.intercomTrackEvent('go to CSS', { from: `Home ${label}` });
      if (!link) {
        if (!defaultLink) {
          return;
        }

        $window.location.href = defaultLink;
      }

      $window.location.href = link;
    }


    function goRegister() {
      if (!registerLink) {
        return;
      }

      $gzAnalytics.eventClick(`go to service center register`, Object.assign({}, analyticsOpts));
      $gzAnalytics.intercomTrackEvent('go to SNOW', { from: 'Home supplies orders register' });
      $window.location.href = registerLink;
    }
  }
}
