const CERTIFICATIONS_ERROR_MESSAGE = 'home.home.CertificationsErrorMessage';
export const name = 'SettingsController';
export default function SettingsController(
  rnClient,
  rnUser,
  rnHttp,
  rnPreferences,
  rnLocale,
  $translate,
  $q,
  $mdDialog,
  $window,
  $document,
  $stateParams,
  $location,
  $state,
  $gzTime,
  $gzAnalytics,
  $gzProfileImage,
  $gzSignin,
  $rootScope,
  $timeout,
  rnRegistry,
  $mdToast,
  $gzHpId
) {
  //noinspection BadExpressionStatementJS
  'ngInject';
  const ANALYTICS_CATEGORY = 'My Settings';

  let myParams = {};
  let cc;
  let isExternalUser = false;
  let returnUrl;

  let vm = this;
  vm.loading = true;
  vm.processing = false;
  vm.errorKey = '';
  vm.userInfo = null;
  vm.userPrefs = null;
  vm.isHpInc = null;
  vm.hpEmployeeId = null;
  vm.currentProfileImageSrc = null;
  vm.timeZone = null;
  vm.countries = null;
  vm.locale = null;
  vm.darkTheme = true;
  vm.certifications = null;
  vm.numCertifications = 0;
  vm.showErrorBar = false;
  vm.errorMessage = '';
  vm.childIndex = 0;
  vm.breadcrumbs = [
    {
      state: 'dashboard',
      titleKey: 'home.home.Home_title'
    },
    {
      state: 'settings',
      titleKey: 'home.home.My_Settings'
    }
  ];

  vm.getUserInfo = getUserInfo;
  vm.getPreferences = getPreferences;
  vm.changeProfileImage = changeProfileImage;
  vm.goBack = goBack;
  vm.changeHpAccount = changeHpAccount;
  vm.editHpidAccount = editHpidAccount;
  vm.consentMarketingClicked = consentMarketingClicked;
  vm.consentNonMarketingClicked = consentNonMarketingClicked;
  vm.consentClicked = consentClicked;
  vm.scrollToId = scrollToId;
  vm.toggleTheme = toggleTheme;
  vm.handleAccessError = handleAccessError;
  vm.editCertificationId = editCertificationId;
  vm.editHPEmployeeId = editHPEmployeeId;
  vm.editJobTitle = editJobTitle;
  vm.editJobFunctions = editJobFunctions;
  vm.localeChanged = localeChanged;
  vm.unitSystemChanged = unitSystemChanged;
  vm.timeZoneChanged = timeZoneChanged;
  vm.paperUsageChanged = paperUsageChanged;
  vm.removeOrg = removeOrg;
  vm.handleScroll = handleScroll;
  vm.goPrivacy = goPrivacy;
  vm.goCreateOrg = goCreateOrg;
  vm.goJoinOrg = goJoinOrg;
  vm.requestRemoveUser = requestRemoveUser;
  vm.unitTest = {
    parseParams: parseParams,
    reload: reload,
    checkForChangingHpAccount: checkForChangingHpAccount,
    getCertifications: getCertifications,
  };

  vm.gridOptions = {
    grid: {
      id: 'home-certifications',
      sort: [{colId: 'certificationTitle', sort: 'asc'}],
      idColumn: 'id',
      allowFilters: false,
      allowDragToHide: false,
      headerHeight: 36,
      rowHeight: 32,
    },
    columns:[
      {
        id: 'certificationTitle',
        type: 'text',
        path: 'certificationTitle',
        options: {
          width: 200
        },
        header: {
          key: 'home.home.CertificationTitle'
        },
      },
      {
        id: 'level',
        type: 'text',
        path: 'level',
        options: {
          width: 'auto',
        },
        header: {
          key: 'home.home.CertificationLevel'
        },
      },
      {
        id: 'expirationDate',
        type: 'text',
        path: 'expirationDate',
        options: {
          width: 'auto',
        },
        header: {
          key: 'home.home.CertificationExpirationDate'
        },
      },
      {
        id: 'code',
        type: 'text',
        path: 'certificationCode',
        options: {
          width: 'auto',
          show: false,
        },
        header: {
          key: 'home.home.CertificationCode'
        },
      },
      {
        id: 'family',
        type: 'text',
        path: 'family',
        options: {
          width: 'auto',
          show: false,
        },
        header: {
          key: 'home.home.CertificationFamily'
        },
      },
      {
        id: 'model',
        type: 'text',
        path: 'model',
        options: {
          width: 'auto',
          show: false,
        },
        header: {
          key: 'home.home.CertificationModel'
        },
      },
      {
        id: 'operatorId',
        type: 'text',
        path: 'operatorId',
        options: {
          width: 'auto',
          show: false,
        },
        header: {
          key: 'home.home.CertificationOperatorId'
        },
      },
    ]
  };

  vm.gridCallbacks = {
    grid: {
      translate: $translate.instant,
    },
    data: {
      getData: getCertifications,
      paged: true,
      limit: 250,
      totalPath: 'total',
      path: 'formattedCertifications',
    },
    columns: {},
    actions: [],
  };

  vm.$onInit = onInit;
  return;

  ////////////////
  function onInit() {
    vm.useCertificationId = $window.$printos.v1.client.getRegistryValue('useCertificationId');
    parseParams();
    getClientConfig();
    getPreferences();
    let promises = [];
    if (vm.currentContextId) {
      promises.push(getUserRoles());
    }
    promises.push(getUserInfo());
    promises.push(getHpidAccountUrl());
    return $q.all(promises)
      .then(() => {
        initOptions();
        reload();
      })
      .finally(() => {
        checkForChangingHpAccount();
        vm.loading = false;
        $timeout(() => {
         let scrollContainer = document.getElementById('scroll-container');
          if (scrollContainer) {
            scrollContainer.addEventListener('scroll', handleScroll);
          }
        });
        $timeout(() => {
          if ($stateParams.goto) {
            scrollToId($stateParams.goto);
          }
        }, 500);
      });
  }

  function checkForChangingHpAccount() {
    if (!!$stateParams.changeHpAccount) {
      return changeHpAccount();
    }

    if ($stateParams.hpAccountChanged) {
      $gzAnalytics.event('HP Account - Changed', {category:ANALYTICS_CATEGORY, label: `${$stateParams.statusCode} - ${$stateParams.subCode}`});
      let jadeFile;

      // Successful account change
      if ($stateParams.statusCode === undefined) {
        jadeFile = require('./hpidAccountChangedConfirmation.jade');
      }
      else {
        switch ($stateParams.statusCode) {
          case '400':
            switch ($stateParams.subCode) {
              case '1026':
                jadeFile = require('./hpidAccountChangedFailure.jade');
                vm.hpidErrorMsg = 'home.home.Change_HPID_Account_Error_Title_400_1026';
                break;
              case '1043':
                jadeFile = require('./hpidAccountChangedFailure.jade');
                vm.hpidErrorMsg = 'home.home.Change_HPID_Account_Error_Title_400_1043';
                break;
              default:
                jadeFile = require('./hpidAccountChangedFailure.jade');
                vm.hpidErrorMsg = 'home.home.Change_HPID_Account_Error_Generic';
                break;
            }
            break;
          case '409': // HP ID account already in use
            jadeFile = require('./hpidAccountChangedFailure.jade');
            vm.hpidErrorMsg = 'home.home.Change_HPID_Account_Error_Title_409';
            break;
          default: // Unknown error
            jadeFile = require('./hpidAccountChangedFailure.jade');
            vm.hpidErrorMsg = 'home.home.Change_HPID_Account_Error_Generic';
            break;
        }
      }

      return $mdDialog.show({
        parent: angular.element(document.body),
        clickOutsideToClose: true,
        escapeToClose: true,
        template: jadeFile,
        bindToController: true,
        controllerAs: 'vm',
        locals: {
          userInfo: vm.userInfo,
          hpidErrorMsg: vm.hpidErrorMsg
        },
        controller: function DialogController($mdDialog, userInfo, hpidErrorMsg) {
          let vm = this;
          vm.userInfo = userInfo;
          vm.hpidErrorMsg = hpidErrorMsg;
          vm.closeDialog = ($event) => {
            $event.stopPropagation();
            $mdDialog.hide();
          };
        }
      });
    }
  }

  function getClientConfig(cache = true) {
    cc = $printos.v1.client.getConfig();
    vm.isOrphan = _.get(cc, 'context.type', 'Unknown') === 'Unknown';
    vm.isHP = _.get(cc, 'context.type', 'Unknown') === 'HP';
    vm.showCookieSettings = _.get(cc, 'consent.consentScript');
    vm.currentContextId = _.get(cc, 'context.id');
    vm.jobTitle = _.get(cc, 'entity.persona.jobTitle');
    vm.jobFunctions = _.get(cc, 'entity.persona.myTypes', []);
    vm.jobFunctionOptions = [];
    let personaTypes = _.get(cc, 'entity.persona.types', []);
    personaTypes.forEach(pt => {
      if (pt !== 'Other' && pt !== 'NoValue') {
        vm.jobFunctionOptions.push({
          value: pt,
          display: $translate.instant(`uxf.uxf.Persona_${pt}`),
        });
      }
    });

    vm.jobFunctionOptions = vm.jobFunctionOptions.sort((a,b) => {
      return a.display.trim().toLocaleLowerCase().localeCompare(b.display.trim().toLocaleLowerCase());
    });

    //Special case move Other and NoValue to end
    if (personaTypes.includes('Other')) {
      vm.jobFunctionOptions.push({
        value: 'Other',
        display: $translate.instant('uxf.uxf.Persona_Other'),
      });
    }

    initUnitSystem();
    vm.jobTitle = _.get(cc, 'entity.persona.jobTitle');

    let fn = _.get(cc, 'entity.firstName');
    let ln = _.get(cc, 'entity.lastName');
    if (fn && ln) {
      vm.userInitials = fn.charAt(0).toLocaleUpperCase() + ln.charAt(0).toLocaleUpperCase();
    }

    vm.orgName = _.get(cc, 'context.name');

    vm.currentProfileImageSrc = _.get(cc, 'entity.persona.image', null);

    let roles = _.get(cc, 'entity.roles', []);
    let nonExternalRoles = roles.filter(r => {
      return !r.externalAccess;
    });

    isExternalUser = !vm.isOrphan && !cc.managingContext && nonExternalRoles.length === 0;
  }

  function getUserInfo() {
    let config = {
      params: {
        refreshHPID: true
      }
    };

    return rnHttp.get('aaa', 'users', config).then(response => {
      vm.userInfo = response.data;
      vm.certificationId = _.get(vm.userInfo, 'certificationId');
      vm.isOneHP = vm.userInfo.identityProvider === 'onehp';
      if (vm.userInfo.identityProvider) {
        let display = $translate.instant(`home.home.IdentityProvider_${vm.userInfo.identityProvider}`);
        _.set(vm.userInfo, 'internal.display.identityProvider', display);
      }
    });
  }

  function getUserRoles(){
    vm.accounts = [];
    return rnUser.getSelectedUserRoles().then(resp => {
      vm.userRoles = _.get(resp, 'userRoles', []);
      vm.userRoles.forEach(role => {
        let found = vm.accounts.find(account => {
          return account.accountName === role.context.name;
        });

        if (found) {
          if (!found.roles.includes(role.systemRole.name)) {
            found.roles.push(role.systemRole.name);
          }
        }
        else {
          vm.accounts.push({
            accountName: role.context.name,
            accountId: role.context.id,
            roles: [ role.systemRole.name ],
          });
        }
      });

      // Sort roles for each account
      vm.accounts.forEach(account => {
        account.roles = account.roles.sort((a,b) => {
          return a.toLocaleLowerCase().localeCompare(b.toLocaleLowerCase());
        });
        account.rolesDisplay = account.roles.join(', ');
      });

      // Sort accounts
      vm.accounts = vm.accounts.sort((a,b) => {
        if (a.accountId === vm.currentContextId) { return -1; }
        if (b.accountId === vm.currentContextId) { return 1; }
        return a.accountName.toLocaleLowerCase().localeCompare(b.accountName.toLocaleLowerCase());
      });
    });
  }

  function getPreferences() {
    vm.userPrefs = $printos.v1.preferences.getPreferences();
    vm.locale = $printos.v1.preferences.getLocale();
    vm.localeOptions = [...$printos.v1.preferences.getLocaleOptions()];
    vm.unitSystem = $printos.v1.preferences.getUnitSystem();
    vm.paperUsage = $printos.v1.preferences.getPaperUsage();
    vm.darkTheme = $printos.v1.client.getTheme() === 'dark';
    vm.hpEmployeeId = $printos.v1.preferences.getPreference('employeeId', 'profile');
    vm.timeZone = $printos.v1.preferences.getTimeZone();
    vm.displayName = $printos.v1.user.getFullName();
  }

  function initOptions() {
    vm.timeZoneOptions = $printos.v1.preferences.getTimeZoneOptions();

    vm.paperUsageOptions = [
      {
        display: $translate.instant('home.home.PaperUsage_Equivalent'),
        value: 'EquivalentPages'
      },
      {
        display: $translate.instant('home.home.PaperUsage_SquareArea'),
        value: 'SquareArea'
      }
    ]
  }

  function initUnitSystem() {
    vm.unitSystemOptions = [];
    let unitSystems = _.get(cc, 'preferences.options.unitSystems');
    if (!unitSystems) {
      return;
    }

    unitSystems.forEach(us => {
      vm.unitSystemOptions.push({
        display: us.value,
        value: us.value
      });
    });
  }

  function changeProfileImage($event) {
    if ($event) {
      $event.stopPropagation();
    }

    $gzAnalytics.event('Profile Image - Open', {category:ANALYTICS_CATEGORY});
    $gzProfileImage.changeUserImage().then(() => {
      $gzAnalytics.event('Profile Image - Changed', {category:ANALYTICS_CATEGORY});
      getCurrentProfileImageFromServer(false);
    })
      .catch((error) => {
        //No prop
    });
  }

  function getCurrentProfileImageFromServer(cache = true) {
    return $window.$printos.v1.client.getConfigAsync().then(resp => {
      cc = resp;
      $timeout(() => {
        vm.currentProfileImageSrc = _.get(cc, 'entity.persona.image', null);
      });
    });
  }

  function goBack($event) {
    $gzAnalytics.event('Close Button - Clicked', {category:ANALYTICS_CATEGORY, label:returnUrl});

    if (returnUrl) {
      $window.location.href = `/api/portal/v1/go/validate?url=${$window.encodeURIComponent(returnUrl)}`;
      return;
    }

    $state.go('dashboard');
  }

  function parseParams() {
    myParams = {
      data: $stateParams.data,
    };

    returnUrl = $stateParams.returnUrl || _.get(myParams, 'data.returnUrl');
    if (returnUrl) {
      returnUrl = $window.decodeURIComponent(returnUrl);
      _.set(myParams, 'data.returnUrl', returnUrl);
    }

  }

  function reload() {
    if (_.get(myParams, 'data.reload', false)) {
      delete myParams.data.reload;
      $mdDialog.show(
        $mdDialog.alert()
          .clickOutsideToClose(false)
          .title($translate.instant('home.home.Reload_Title'))
          .textContent($translate.instant('home.home.Reload_Message'))
          .ok($translate.instant('uxf.uxf.Dialog_OK'))
      ).then(() => {
        $window.location.reload();
      });
    }
  }

  function showHPIDHelpClicked() {
    vm.showHPIDHelp = !vm.showHPIDHelp;
  }

  function getHpidAccountUrl() {
    rnRegistry.getKey('hpidAccountUrl').then(value => {
      vm.hpidAccountUrl = value;
    });
  }

  function changeHpAccount() {
    $gzAnalytics.event('Change HP Account - Open', {category:ANALYTICS_CATEGORY});
    let type = 'changeaccount';
    let hpAccountChanged = true;
    let redirectUrl = $gzHpId.createTargetUrl(undefined, { hpAccountChanged });
    return $gzHpId.redirect(type, redirectUrl);
  }

  function editHpidAccount() {
    $window.open(vm.hpidAccountUrl, '_blank');
  }

  function handleScroll(event) {
    $rootScope.$applyAsync(() => {
      let scrollTop = event.target.scrollTop;
      let scrollBottom = scrollTop + event.target.clientHeight;

      let childElements = event.target.children;
      let children = [];
      for (let i = 0; i < childElements.length; i++) {
        let child = childElements[i];
        if (child.id === 'intro') {
          continue;
        }
        //Is visible
        //Start is above or equal Top AND End is below Top
        //Is Start visible
        //Start is below or equal Top AND Start is above Bottom
        children.push({
          isVisible: child.offsetTop <= scrollTop && (child.offsetTop + child.clientHeight) > scrollTop,
          isStartVisible: child.offsetTop >= scrollTop && child.offsetTop < scrollBottom
        });
      }

      vm.childIndex = 0;
      for (let i = 0; i < children.length; i++) {
        if (children[i].isStartVisible) {
          vm.childIndex = i;
          return;
        }
      }

      for (let i = 0; i < children.length; i++) {
        if (children[i].isVisible) {
          vm.childIndex = i;
          break;
        }
      }
    });
  }

  function goPrivacy() {
    $state.go('privacy', {returnUrl: returnUrl});
  }

  function goCreateOrg() {
    $gzAnalytics.event('Create Organization from Settings - Click', {category: 'Create Organization'});
    $state.go('create-org', {from: 'settings', returnUrl: returnUrl});
  }

  function goJoinOrg() {
    $gzAnalytics.event('Join Organization from Settings - Click', {category: 'Join Organization'});
    return $mdDialog.show({
      parent: angular.element(document.body),
      clickOutsideToClose: false,
      escapeToClose: true,
      template: require('../landing-page/cards/all/my-account/org-code/org-code-dialog.jade'),
      controller: 'OrgCodeDialogController',
      bindToController: true,
      controllerAs: 'vm',
      locals: {
      }
    }).catch(() => {
      // user cancelled
      $gzAnalytics.event('Join Organization from Settings - Cancel', {category: 'Join Organization'});
      return Promise.resolve();
    });
  }

  function requestRemoveUser() {
    $gzAnalytics.event('Request Delete PrintOS User - Click', {category: ANALYTICS_CATEGORY});
    return $mdDialog.show({
      parent: angular.element(document.body),
      clickOutsideToClose: false,
      escapeToClose: true,
      template: require('./delete-user/delete-user-dialog.jade'),
      controller: 'DeleteUserDialogController',
      bindToController: true,
      controllerAs: 'vm',
      locals: {
      }
    }).catch(() => {
      // user cancelled
      $gzAnalytics.event('Request Delete PrintOS User - Cancel', {category: ANALYTICS_CATEGORY});
      return Promise.resolve();
    });
  }

  function consentMarketingClicked() {
    return consentClicked('marketing').then(() => {
      showToast('home.home.MarketingConsent');
    });
  }

  function consentNonMarketingClicked() {
    return consentClicked('non-marketing').then(() => {
      showToast('home.home.NonMarketingConsent');
    });
  }

  function consentClicked(consentType) {
    return rnUser.updateUserProfile(vm.userInfo).then(resp => {
      if (consentType === 'marketing') {
        $gzAnalytics.eventToggle("user marketing consent", {
          category: 'preferences',
          label: vm.userInfo.printosMarketingConsent,
        });
      }

      if (consentType === 'non-marketing') {
        $gzAnalytics.eventToggle("user non-marketing consent", {
          category: 'preferences',
          label: vm.userInfo.printosNonMarketingConsent,
        });
      }

      return resp;
    });
  }

  function scrollToId(id) {
    $rootScope.$applyAsync(() => {
      console.log(`Scroll to ID (${id})`);
      let element = document.getElementById(id);
      if (!element) {
        console.log(`Scroll to ID - could not find element (${id})`);
        return;
      }

      element.scrollIntoView({block: "start", inline: "nearest", behavior: "smooth"});
    });
  }

  function toggleTheme(darkTheme) {
    vm.darkTheme = darkTheme;
    $window.$printos.v1.client.setTheme(vm.darkTheme);
  }

  function editCertificationId() {
    $mdDialog.show({
      parent: angular.element(document.body),
      targetEvent: null,
      clickOutsideToClose: false,
      escapeToClose: true,
      template: require('./text-prompt/text-prompt-dialog.jade'),
      controller: 'TextPromptDialogController',
      bindToController: true,
      controllerAs: 'vm',
      locals: {
        titleKey: 'home.home.CertificationId',
        value: vm.certificationId,
        regexKey: "Validator.AAA.CertificationId",
        maxLength: 100,
      }
    })
      .then(newValue => {
        vm.userInfo.certificationId = newValue;
        return rnUser.updateUserProfile(vm.userInfo).then(resp => {
          vm.certificationId = newValue;
          showToast('home.home.CertificationId');
        });
      })
      .catch(() => {});
  }

  function editHPEmployeeId() {
    $mdDialog.show({
      parent: angular.element(document.body),
      targetEvent: null,
      clickOutsideToClose: false,
      escapeToClose: true,
      template: require('./text-prompt/text-prompt-dialog.jade'),
      controller: 'TextPromptDialogController',
      bindToController: true,
      controllerAs: 'vm',
      locals: {
        titleKey: 'home.home.HpEmployeeId',
        value: vm.hpEmployeeId,
        regexKey: "Validator.AAA.NameUnicode",
        maxLength: 10,
      }
    })
      .then(newValue => {
        return $printos.v1.preferences.setPreferenceAsync('employeeId', newValue, 'profile').then(resp => {
          $timeout(() => {
            vm.hpEmployeeId = newValue;
            showToast('home.home.HpEmployeeId');
          });
        });
      })
      .catch(() => {});
  }

  function editJobTitle() {
    $mdDialog.show({
      parent: angular.element(document.body),
      targetEvent: null,
      clickOutsideToClose: false,
      escapeToClose: true,
      template: require('./text-prompt/text-prompt-dialog.jade'),
      controller: 'TextPromptDialogController',
      bindToController: true,
      controllerAs: 'vm',
      locals: {
        titleKey: 'home.home.JobTitle',
        value: vm.jobTitle,
        regexKey: "Validator.AAA.NameUnicode",
        maxLength: 36,
      }
    })
      .then(newValue => {
        return rnHttp.put('portal_service', 'persona', {jobTitle: newValue, myTypes: vm.jobFunctions}, {version: 2}).then(resp => {
          vm.jobTitle = newValue;
          $gzAnalytics.event('Job Title - Changed', { category:ANALYTICS_CATEGORY, label: vm.jobTitle });
          showToast('home.home.JobTitle');
        });
      })
      .catch(() => {});
  }

  function editJobFunctions() {
    $mdDialog.show({
      parent: angular.element(document.body),
      targetEvent: null,
      clickOutsideToClose: false,
      escapeToClose: true,
      template: require('./multi-select-prompt/multi-select-prompt-dialog.jade'),
      controller: 'MultiSelectPromptDialogController',
      bindToController: true,
      controllerAs: 'vm',
      locals: {
        titleKey: 'home.home.JobFunctions',
        value: vm.jobFunctions,
        options: vm.jobFunctionOptions,
        required: true
      }
    })
      .then(newValue => {
        return rnHttp.put('portal_service', 'persona', {jobTitle: vm.jobTitle, myTypes: newValue}, {version: 2}).then(resp => {
          vm.jobFunctions = newValue;
          $gzAnalytics.event('Job Functions - Changed', {category:ANALYTICS_CATEGORY, label: vm.jobFunctions.join(', ')} );
          showToast('home.home.JobFunctions');
        });
      })
      .catch(() => {});
  }

  function localeChanged() {
    $translate.use(vm.locale);
    $printos.v1.ui.startProgress();
    $printos.v1.preferences.setLocaleAsync(vm.locale).then(() => {
      $timeout(() => {
        getPreferences();
        initOptions();
        $window.$printos.v1.app.setAppNameAlias($translate.instant('home.home.My_Settings'));
        $printos.v1.ui.endProgress();
      });
    });
  }

  function timeZoneChanged() {
    return $printos.v1.preferences.setTimeZoneAsync(vm.timeZone).then(() => {
      showToast('home.home.Time_Zone');
    });
  }

  function unitSystemChanged() {
    return $printos.v1.preferences.setUnitSystemAsync(vm.unitSystem).then(() => {
      showToast('home.home.Unit_System');
    });
  }

  function paperUsageChanged() {
    return $printos.v1.preferences.setPaperUsageAsync(vm.paperUsage).then(() => {
      showToast('home.home.Paper_Usage');
    });
  }

  function showToast(key) {
    let property = $translate.instant(key);
    $mdToast.show($mdToast.simple().content($translate.instant('home.home.PropertyUpdated', [property])));
  }

  function removeOrg(account) {
    $gzAnalytics.eventClick('delete account');
    $mdDialog.show({
      parent: angular.element(document.body),
      clickOutsideToClose: false,
      escapeToClose: true,
      template: require('./accounts/account-delete.jade'),
      controller: 'AccountsDeleteController',
      bindToController: true,
      controllerAs: 'vm',
      locals: {account: account}
    })
      .then(function (data) {
        let confirmed = data;
        if (confirmed) {
          rnUser.removeUserAccount(account.accountId)
            .then(() => {
              _.remove(vm.accounts, {accountId: account.accountId});
              if (account.accountId === vm.currentContextId) {
                //current context no longer valid so force them to go through sign-in process to choose context
                $mdDialog.show(
                  $mdDialog.alert()
                    .parent(angular.element(document.body))
                    .title($translate.instant('uxf.uxf.Signin_NoContext_Title'))
                    .textContent($translate.instant('uxf.uxf.Signin_NoContext_Message'))
                    .ariaLabel($translate.instant('uxf.uxf.Signin_NoContext_Message'))
                    .ok($translate.instant('uxf.uxf.Dialog_OK'))
                )
                  .finally(function() {
                    $window.location.href = '/start/#/signin';
                  });
              }
            })
            .catch(function(error) {
              $mdDialog.show(
                $mdDialog.alert()
                  .parent(angular.element(document.body))
                  .title($translate.instant('uxf.uxf.Account_Delete_Failure_Title'))
                  .textContent($translate.instant('uxf.uxf.Account_Delete_Failure_Message'))
                  .ariaLabel($translate.instant('uxf.uxf.Account_Delete_Failure_Message'))
                  .ok($translate.instant('uxf.uxf.Dialog_OK'))
              )
                .finally(function() {
                });
            });
        }
      })
      .catch(function (data) {
        // The user canceled, no action needed.
      })
      .finally(function (data) {
      });
  }

  function getCertifications(page = 0, limit = 250) {
    let config = {
      params : {
        offset: page * limit,
        limit
      }
    };

    return rnHttp.get('supportapp_service', '/certifications/', config).then(resp => {
      let myCertifications = _.get(resp, 'data.certifications', []);
      let formattedCertifications = [];
      if (myCertifications.length > 0) {
        formattedCertifications = myCertifications.map(c => {
          return c.presses.map(certificationPress => {
            certificationPress.operatorId = c.operatorId;
            certificationPress.level = c.level;
            return certificationPress;
          });
        }).reduce((prev, next) => {
          return prev.concat(next);
        });
      }

    resp.formattedCertifications = formattedCertifications;
      return resp;
    }).catch(error => {
      handleAccessError(error);
    });
  }

  function handleAccessError(){
    vm.errorMessage = CERTIFICATIONS_ERROR_MESSAGE;
    vm.showErrorBar = true;
  }
}
