export default angular.module('home.images', [])
  .run(cacheImages)
  .name;

/**
 * Add app icons to the template cache so they may be referenced from apps.json
 */
const HOME_IMAGES_CACHE_PREFIX = 'home/images/';

function cacheImages($templateCache) {
  'ngInject';

  // (Use the html loader to pull the SVG content directly into the template cache.)
  const requireImages = require.context('!html-loader!.', true, /\.svg$/);

  _.forEach(requireImages.keys(), img => {
    // Store icons in cache at app-switcher/icons/Box.svg
    // strip off leading ./
    let imgFileName = img.substr(2);
    $templateCache.put(HOME_IMAGES_CACHE_PREFIX + imgFileName, requireImages(img));
  });
}
