export const name = 'hmWebToPrint';

export default function hmWebToPrint(
  // Directive angular dependencies
) {
  'ngInject';

  let directive = {
    restrict: 'EA',
    scope: {
      gzCard: '=',
    },
    template: require('./webToPrint.jade'),
    controller: webToPrintController,
    controllerAs: 'vm',
    bindToController: true,
  };
  return directive;

  //////////////////////

  /** @ngInject */
  function webToPrintController(
    // Controller angular dependencies
    $q,
    $translate,
    $rootScope,
    $scope,
    $window,
  ) {
    // Constants

    // Variables

    // View Model
    let vm = this;
    vm.data = null;

    vm.goToApp = goToApp;
    vm.goToOrders = goToOrders;
    vm.goToProducts = goToProducts;
    vm.goToSupport = goToSupport;
    vm.$onInit = onInit;
    vm.loading = true;
    vm.enabled = false;

    function onInit() {
      //vm.gzCard.headerAction.clicked = handleHelpClicked;
      $scope.$on('$destroy', () => {
      });

      let web2PrintApp = $window.$printos.v1.app.getAppById('web2print');
      vm.gzCard.API.config.setAppLink(web2PrintApp._links.self);

      if (!$window.$printos.v1.client.hasFeature('web2print-device-enablement')) {
        vm.loading = false;
        return $q.resolve();
      }

      return $window.$printos.v1.http.get('web2print', 'web-to-print-job-queue/v1/dashboard-stats', {credentials: 'include'}).then(resp => {
        console.log('web2print resp', resp);
        vm.data = resp.data;
        if (vm.data && vm.data.orders && vm.data.salesChannel && vm.data.customizableProducts && vm.data.completedTutorials) {
          vm.enabled = true;
        }

        // vm.data = {
        //   "orders": {
        //     "link": "https://web2print.daily.gsbprint.net/web2print/orders",
        //     "enabled": true,
        //     "newOrder": 283
        //   },
        //   "salesChannel": {
        //     "link": "https://web2print.daily.gsbprint.net/web2print/sales-channels",
        //     "enabled": true
        //   },
        //   "customizableProducts": {
        //     "link": "https://web2print.daily.gsbprint.net/web2print/customize",
        //     "enabled": true
        //   },
        //   "completedTutorials": {
        //     "link": "https://web2print.daily.gsbprint.net/web2print?expand-tutorials=true",
        //     "enabled": true,
        //     "completed": false
        //   }
        // };
        // vm.enabled = true;
      })
        .catch(e => {
          console.warn('Error getting web2print card data', e);
        })
        .finally(() => {
          vm.loading = false;
        });
    }


    function goToApp($event) {
      $event.stopPropagation();
      $window.$printos.v1.ga.eventTrack('home', 'Web To Print Card - Clicked', 'Discover Button');
      $window.$printos.v1.app.goToApp('web2print');
    }

    function goToOrders($event) {
      $event.stopPropagation();
      if (!vm.data || !vm.data.orders && !vm.data.orders.enabled) {
        return;
      }

      $window.$printos.v1.ga.eventTrack('home', 'Web To Print Card - Clicked', 'Orders');
      $window.location = vm.data.orders.link;
    }

    function goToProducts($event) {
      $event.stopPropagation();
      if (!vm.data || !vm.data.customizableProducts && !vm.data.customizableProducts.enabled) {
        return;
      }

      $window.$printos.v1.ga.eventTrack('home', 'Web To Print Card - Clicked', 'Products');
      $window.location = vm.data.customizableProducts.link;
    }

    function goToSupport($event) {
      $event.stopPropagation();
      if (!vm.data || !vm.data.completedTutorials && !vm.data.completedTutorials.enabled) {
        return;
      }

      $window.$printos.v1.ga.eventTrack('home', 'Web To Print Card - Clicked', 'Support');
      $window.location = vm.data.completedTutorials.link;
    }
  }
}
