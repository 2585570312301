import ProgressBar from 'progressbar.js';

export const name = 'hmCircleProgress';
/**
 * @ngdoc directive
 * @name home.components.circleProgress.directive:hmCircleProgress
 *
 * @description
 * `<hm-circle-progress>` creates circle progress bar
 *
 * @usage
 * ```html
 * <hm-circle-progress video-id='someId'></gz-you-tube>
 *
 * ```
 */
export default function hmCircleProgress($timeout, $interval) {
  //noinspection BadExpressionStatementJS
  'ngInject';

  const directive = {
    restrict: 'E',
    scope: {
      type: '@',  // semi/full
      mode: '@', // fixed/rotating
      strokeWidth: '@',
      easing: '@',
      duration: '@',
      color: '@',
      trailColor: '@',
      percent: '=',
      diameter: '@'
    },
    link: hmCircleProgressLink,
  };

  return directive;

  //////////////////////
  // Private functions
  function hmCircleProgressLink(scope, element) {
    let type = scope.type ? scope.type : 'Semi';
    let strokeWidth = scope.strokeWidth ? scope.strokeWidth : 10;
    let easing = scope.easing ? scope.easing : 'easeInOut';
    let duration = scope.duration ? scope.duration : 1400;
    let color = scope.color ? scope.color : '#0096d6';
    let trailColor = scope.trailColor ? scope.trailColor : 'transparent';
    let trailWidth = scope.trailWidth ? scope.trailWidth : strokeWidth;
    let diameter = scope.diameter ? scope.diameter : 50;
    let percent = scope.percent ? scope.percent : 0;
    scope.lastPercent = percent;

    element[0].style.width = diameter + 'px';
    element[0].style.height = diameter + 'px';

    if (type === 'Semi') {
      scope.myProgressBar = new ProgressBar.SemiCircle(element[0], {
        strokeWidth: strokeWidth,
        easing: easing,
        duration: duration,
        color: color,
        trailColor: trailColor,
        trailWidth: trailWidth,
        svgStyle: null
      });
    }
    else {
      scope.myProgressBar = new ProgressBar.Circle(element[0], {
        strokeWidth: strokeWidth,
        easing: easing,
        duration: duration,
        color: color,
        trailColor: trailColor,
        trailWidth: trailWidth,
        svgStyle: null
      });
    }

    if (scope.mode === 'rotate') {
      scope.myProgressBar.set(scope.lastPercent / 100);
      element[0].className += ' rotating';
    }
    else if (scope.mode === 'chase') {
      scope.myInterval = $interval(() => {
        scope.lastPercent += 0.5;
        scope.myProgressBar.set(scope.lastPercent / 100);
      }, 50);
    }
    else {
      scope.myProgressBar.animate(percent / 100);
      scope.myListener = scope.$watch('percent', value => {
        if (value !== undefined && value !== null && value !== scope.lastPercent) {
          scope.lastPercent = value;
          scope.myProgressBar.set(scope.lastPercent / 100);
        }
      });

      scope.$on('destroy', () => {
        if (scope.myListener) {
          scope.myListener();
        }

        if (scope.myInterval) {
          $interval.cancel(scope.myInterval);
        }
      });
    }
  }
}
