import ngMaterial from 'angular-material';
import uiRouter from 'angular-ui-router';

// import modules this module depends on
import angularTranslate from 'angular-translate';
import rnUser from 'rhino/src/components/user/user.module';
import rnSession from 'rhino/src/components/session/session.module';
import rnLocale from 'rhino/src/components/locale/locale.module';
import rnHttp from 'rhino/src/core/http/http.module';
import rnPreferences from 'rhino/src/components/preferences/preferences.module';
import rnNotificationService from 'rhino/src/components/notification/notification.module';
import notifications from'./notifications/notifications.module';
import gazelleProfileImage from 'gazelle/src/components/profile-image/profile-image.module';
import gazelleTime from 'gazelle/src/components/time/time.module';
import gazelleAnalytics from 'gazelle/src/core/analytics/analytics.module';
import rnRegistry from 'rhino/src/core/registry/registry.module';
import gzHpid from 'gazelle/src/components/hpid/hpid.module';
import $gzSignin from 'gazelle/src/components/signin/signin.module';
import rnApplication from 'rhino/src/components/application/application.module';
import rnFeature from 'rhino/src/components/feature/feature.module';
import rhinoOrganization from 'rhino/src/components/organization/organization.module';

// import this modules components
import './settings.scss';
import * as settingsController from './settings.controller';
import * as $hmSubscriber from './email-subscriptions/subscriber.service';

// define the module in one place and export the module name
export default angular.module('home.components.settings', [
  angularTranslate,
  ngMaterial,
  rnUser,
  rnHttp,
  rnPreferences,
  rhinoOrganization,
  rnNotificationService,
  notifications,
  gazelleProfileImage,
  gazelleTime,
  uiRouter,
  gazelleAnalytics,
  rnRegistry,
  gzHpid,
  $gzSignin,
  rnApplication,
  rnFeature,
])
  .controller(settingsController.name, settingsController.default)
  .factory($hmSubscriber.name, $hmSubscriber.default)
  .name;
