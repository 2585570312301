import rhino from 'rhino';
import angularTranslate from 'angular-translate';
import angularMoment from 'angular-moment';

import './threeD-devices.scss';
import * as hmThreeDDevicesDirective from './threeD-devices.directive';

export default angular.module('home.components.widget.threeDDevices', [
  rhino,
  angularTranslate,
  angularMoment,
])
  .directive(hmThreeDDevicesDirective.name, hmThreeDDevicesDirective.default)
  .name;
