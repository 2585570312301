export const name = 'HomeController';
export default function HomeController(
  $rootScope,
  $translate,
  $window,
  $timeout
) {
  //noinspection BadExpressionStatementJS
  'ngInject';

  var vm = this;
  vm.showHeader = true;

  vm.$onInit = onInit;
  return;               // <= The rest of this function(AccountController) is private because we return here

  ////////////////////////////
  // Put your public functions here
  // Functions become public by adding them to the public interface (vm.name = name in the constructor)


  ////////////////////////////
  // Put private functions here.

  function onInit() {
    vm.showHeader = true;
    $rootScope.$on('$stateChangeStart', (event, toState, toParams, fromState, fromParams) => {
      printOSHeaderReady().finally(() => {
        switch (toState.name) {
          case 'help':
            $window.$printos.v1.client.hideHeader();
            break;
          default:
            $window.$printos.v1.client.showHeader();
        }
      });
    });
    $rootScope.$on('$stateChangeSuccess', (event, toState, toParams, fromState, fromParams) => {
      printOSHeaderReady().finally(() => {
        udpateAppName(toState);
        $window.$printos.v1.ga.pageTrack(toState.name, toState.url.split('?')[0]);
        $window.dispatchEvent(new window.CustomEvent('posb-view-changed', {detail: { pageId: toState.name }} ));
      });
    });
  }

  function udpateAppName(toState) {
    switch (toState.name) {
      case 'settings':
        $window.$printos.v1.app.setAppNameAlias($translate.instant('home.home.My_Settings'));
        break;
      case 'subscriptions-edit':
        $window.$printos.v1.app.setAppNameAlias($translate.instant('home.home.Notification_Subscriptions'));
        break;
      case 'notifications':
        $window.$printos.v1.app.setAppNameAlias($translate.instant('home.home.MyNotifications'));
        break;
      case 'accounts-edit':
        $window.$printos.v1.app.setAppNameAlias($translate.instant('home.home.Edit_Accounts'));
        break;
      case 'about':
        $window.$printos.v1.app.setAppNameAlias($translate.instant('home.home.About'));
        break;
      case 'privacy':
        $window.$printos.v1.app.setAppNameAlias($translate.instant('home.home.Privacy'));
        break;
      case 'contact-hp':
      case 'rcda':
      case 'rsr':
      case 'ask-the-expert':
      case 'feedback':
      case 'support-case':
      case 'support-case-detail':
        $window.$printos.v1.app.setAppNameAlias($translate.instant('home.home.Contact_Hp'));
        break;
      case 'email-subscriptions':
        $window.$printos.v1.app.setAppNameAlias($translate.instant('home.home.EmailSubscriptionSettings'));
        break;
      default:
        $window.$printos.v1.app.setAppNameAlias(null);
    }
  }

  function printOSHeaderReady() {
    return new Promise((resolve, reject) => {
      let timeout = setTimeout(() => {
        reject();
      }, 180*1000);

      if (window.$printos && window.$printos.readyState === 'complete') {
        clearTimeout(timeout);
        resolve(window.$printos);
      }
      else {
        document.addEventListener('posb-ready', () => {
          clearTimeout(timeout);
          resolve(window.$printos);
        });
      }
    });
  }

} //AccountController
