export const name = 'hmAccountSummary';
export default function hmAccountSummary(
  // Directive angular dependencies
) {
  'ngInject';

  let directive = {
    restrict: 'EA',
    scope: {
        gzCard: '=',
    },
    template: require('./account-summary.jade'),
    link: accountSummaryLink,
    controller: accountSummaryController,
    controllerAs: 'vm',
    bindToController: true,
  };
  return directive;

  //////////////////////

  function accountSummaryLink(scope, element, attrs) {
  }

  /** @ngInject */
  function accountSummaryController(
    rnHttp,
    $q,
    $window
  ) {
    // Constants
    const AAA_SERVICE_NAME = 'aaa';
    const ORGANIZATION_SHARES = 'organizations/grantedshares/';
    const DEFAULT_LIMIT = 1000;

    // View Model
    let vm = this;

    //VM properties
    vm.loading = true;
    vm.sharedDevices = [];
    vm.customers = [];

    //VM methods
    vm.$onInit = onInit;
    vm.goToAccount = goToAccount;
    vm.goToDevices = goToDevices;
    return;

    ///////////////
    // Init
    function onInit() {
      vm.sharedDevices = vm.gzCard.API.account.getSharedDevices();
      reloadCustomerList();
    }

    ///////////////
    // VM methods
    function reloadCustomerList(limit = DEFAULT_LIMIT) {
      return rnHttp.get(AAA_SERVICE_NAME, ORGANIZATION_SHARES, {params: {limit: limit}})
        .then((response) => {
          if(response && response.data && response.data.organizations){
            vm.customers = response.data.organizations;
          }
        })
        .catch((error) => {
          $q.resolve([]);
        })
        .finally(() => {
          vm.loading = false;
        });
    }

    function goToAccount($event){
      $event.stopPropagation();
      $window.location.href = '/account/#/customers';
    }

    function goToDevices($event){
      $event.stopPropagation();
      $window.location.href = '/account/#/devices';
    }
  }
}
