export const moduleName = 'BrowserHelper';
export default class BrowserHelper {

  constructor($log, $window) {
    this.$log = $log;
    this.$window = $window;
  }

  getBrowserInfo() {

    let userAgent = this.$window.navigator.userAgent.toLowerCase();
    let _browsersArray = this.mountBrowsers(userAgent);
    let browserInfo;

    for (let browser in _browsersArray) {
      if (_browsersArray[browser]) {
        browserInfo = this.extractBrowserInfo(
          userAgent, browser, _browsersArray);
        break;
      }
    }

    return {objbrowserName: browserInfo.objbrowserName, objfullVersion: browserInfo.objfullVersion,
      language: this.getBrowserLanguage()};
  }

  extractBrowserInfo(userAgent, browser, _browsersArray) {
    let objbrowserName, objfullVersion, match;

    // microsoft is 'special'
    match = userAgent.match(new RegExp('(' + (browser === 'msie' ? 'msie|edge' : browser) + ')( |\/)([0-9\.]+)'));

    if (match) {
      _browsersArray.version = match[3];
    } else {
      match = userAgent.match(new RegExp('rv:([0-9\.]+)'));
      _browsersArray.version = match ? match[1] : '';
    }

    objbrowserName = (browser === 'opr' ? 'Opera' : browser);
    objfullVersion = (_browsersArray.version ? _browsersArray.version : 'N/A');

    this.deleteBrowserOpera(_browsersArray);
    return {objbrowserName: objbrowserName, objfullVersion: objfullVersion};
  }

  deleteBrowserOpera(_browsersArray) {
    _browsersArray.opera = _browsersArray.opr;
    delete _browsersArray.opr;
  }

  mountBrowsers(uagent) {
    let _browser = [];
    _browser.chrome = /webkit/.test(uagent) && /chrome/.test(uagent) && !/edge/.test(uagent);
    _browser.firefox = /mozilla/.test(uagent) && /firefox/.test(uagent);
    _browser.msie = /msie/.test(uagent) || /trident/.test(uagent) || /edge/.test(uagent);
    _browser.safari = /safari/.test(uagent) && /applewebkit/.test(uagent) && !/chrome/.test(uagent);
    _browser.opr = /mozilla/.test(uagent) && /applewebkit/.test(uagent) &&
    /chrome/.test(uagent) && /safari/.test(uagent) && /opr/.test(uagent);
    _browser.version = '';

    return _browser;
  }

  getOSInfo() {
    let OSName = 'Unknown';
    if (this.$window.navigator.userAgent.indexOf('Windows NT 10.0') !== -1) {OSName = 'Windows 10';}
    if (this.$window.navigator.userAgent.indexOf('Windows NT 6.2') !== -1) {OSName = 'Windows 8';}
    if (this.$window.navigator.userAgent.indexOf('Windows NT 6.1') !== -1) {OSName = 'Windows 7';}
    if (this.$window.navigator.userAgent.indexOf('Windows NT 6.0') !== -1) {OSName = 'Windows Vista';}
    if (this.$window.navigator.userAgent.indexOf('Windows NT 5.1') !== -1) {OSName = 'Windows XP';}
    if (this.$window.navigator.userAgent.indexOf('Windows NT 5.0') !== -1) {OSName = 'Windows 2000';}
    if (this.$window.navigator.userAgent.indexOf('Mac') !== -1) {OSName = 'Mac/iOS';}
    if (this.$window.navigator.userAgent.indexOf('X11') !== -1) {OSName = 'UNIX';}
    if (this.$window.navigator.userAgent.indexOf('Linux') !== -1) {OSName = 'Linux';}
    return OSName;
  }

  getBrowserLanguage() {
    return this.$window.navigator.browserLanguage ? this.$window.navigator.browserLanguage : (this.$window.navigator.language ? this.$window.navigator.language : 'en');
  }

  static factory($log, $window) {
    return new BrowserHelper($log, $window);
  }

}
