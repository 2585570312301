export const name = 'hmQuickLinks';
export default function hmQuickLinks($log) {
  //noinspection BadExpressionStatementJS
  'ngInject';


  let directive = {
    restrict: 'EA',
    scope: {
      gzCard: '=',
    },
    template: require('./quick-links.jade'),
    controller: hpQuickLinksController,
    controllerAs: 'vm',
    bindToController: true
  };

  return directive;

  /** @ngInject */
  function hpQuickLinksController(
    $gzGo
  ) {
    let isReseller;

    let vm = this;
    vm.loading = true;

    vm.gotoAccounts = gotoAccounts;
    vm.gotoRequests = gotoRequests;
    vm.gotoInvitations = gotoInvitations;
    vm.gotoUsers = gotoUsers;

    vm.$onInit = onInit;
    return;
    ////////////////////

    function onInit() {
      vm.loading = false;
    }

    function gotoAccounts() {
      $gzGo.toContext('admin-accounts');
    }

    function gotoRequests() {
      $gzGo.toContext('admin-requests');
    }

    function gotoInvitations() {
      $gzGo.toContext('admin-invitations');
    }

    function gotoUsers() {
      $gzGo.toContext('admin-users');
    }
  }
}
