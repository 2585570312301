import BaseController from "../util/base.controller";
const REQUESTS_PERMISSIONS_PATH = 'requests/types';
const SUPPORT_APP_SERVICE = 'supportapp_service';
const LC_FILTER = 'translate';
const LC_OK = 'uxf.uxf.OK';
const LC_RSR_SUBSCRIBE_SUCCESS = 'home.home.RSR_Subscribe';
const LC_RSR_UNSUBSCRIBE_SUCCESS = 'home.home.RSR_Unsubscribe';
const LC_RSR_SUBSCRIBE_ERROR = 'home.home.RSR_SubscribeError';
const LC_RSR_UNSUBSCRIBE_ERROR = 'home.home.RSR_UnsubscribeError';
const LC_RSR_USERINFO_ERROR = 'home.home.RSR_UserInfoError';

export const moduleName = 'RsrController';
export default class RsrController extends BaseController {
  static factory($analytics, $filter, $log, $mdDialog, $q, $scope, $stateParams, $state, $window, RsrService, rnHttp, ContextService) {
    'ngInject';

    return new RsrController($analytics, $filter, $log, $mdDialog, $q, $scope, $stateParams, $state, $window,
      RsrService, rnHttp, ContextService);
  }

  /** @ngInject */
  constructor($analytics, $filter, $log, $mdDialog, $q, $scope, $stateParams, $state, $window, RsrService, rnHttp, ContextService) {
    super({$analytics, $filter, $log, $mdDialog, $q, $scope, $stateParams, $state, $window, RsrService, rnHttp, ContextService});

    this.initScope($scope);
    this.activate();

    this.$window = $window;
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.$q = $q;
  }

  initScope($scope) {
    $scope.orgOptions = [];
    $scope.orgDisabled = false;
    $scope.valid = {
      org: true,
    };

    $scope.selectedOrg = null;

    $scope.checkingPermissions = true;
    $scope.errorMessage = null;
    $scope.isFormBlocked = false;
    $scope.isFormProcessing = false;

    $scope.breadcrumbs = [
      {
        state: 'dashboard',
        titleKey: 'home.home.Home_title'
      },
      {
        state: 'settings',
        titleKey: 'home.home.My_Settings'
      },
      {
        state: 'contact-hp',
        titleKey: 'home.home.Contact_Hp'
      },
      {
        state: 'rsr',
        titleKey: 'home.home.RSR_View'
      },
    ];
  }

  activate() {
    this.checkPermissions();
    this.$scope.isFormProcessing = true;
    this.isUserSubscribed = false;
    this.userInfo = {};
    this.loadForm();
  }

  goToContactHp(event) {
    if (event) {
      event.stopPropagation();
    }
    this.$state.go('contact-hp', {});
  }

  goToRCDA(event) {
    if (event) {
      event.stopPropagation();
    }
    this.$state.go('rcda', {});
  }

  checkPermissions() {
    this.rnHttp.get(SUPPORT_APP_SERVICE, REQUESTS_PERMISSIONS_PATH).then((response) => {
      if (response && response.data && response.data._links && (response.data._links.hasOwnProperty('createRCDA') || response.data._links.hasOwnProperty('createRCDASelf'))) {
        this.$scope.checkingPermissions = false;
      } else {
        this.goToContactHp();
      }
    });
  }

  disableForm() {
    if (this.$scope.isFormBlocked) {
      return true;
    }
    if (Object.values(this.$scope.valid).includes(false)) {
      return true;
    }
    return false;
  }

  loadForm() {
    let promises = [];
    promises.push(this.RsrService.loadUserInfo());
    promises.push(this.RsrService.loadUserContexts());
    this.$q.all(promises).then(responses => {
      this.userInfo = responses[0].data;
      this.$scope.orgOptions = responses[1].map(c => {
        return {
          display: c.name,
          value: c
        };
      });
      this.$scope.isFormProcessing = false;
    }).catch(() => {
      this.failure(LC_RSR_USERINFO_ERROR)
    });
  }

  changedOrg() {
    if(this.$scope.selectedOrg === null) {
      this.isUserSubscribed = false;
    } else {
      this.$scope.isFormProcessing = true;
      this.RsrService.getSubscribedUsers(this.$scope.selectedOrg.id).then(resp => {
        if(resp.data.filter(u => u.userId === this.userInfo.userId).length > 0){
          this.isUserSubscribed = true;
        } else {
          this.isUserSubscribed = false;
        }
        this.$scope.isFormProcessing = false
      }).catch(() => {
        this.failure(LC_RSR_USERINFO_ERROR)
      });
    }
  }

  subscribe() {
    this.RsrService.subscribe(this.userInfo.userId, this.$scope.selectedOrg.id).then(() => {
      this.success(this.$scope.selectedOrg.name, LC_RSR_SUBSCRIBE_SUCCESS);
    }).catch(() => {
      this.failure(LC_RSR_SUBSCRIBE_ERROR)
    });
  }

  unsubscribe() {
    this.RsrService.unsubscribe(this.userInfo.userId, this.$scope.selectedOrg.id).then(() => {
      this.success(this.$scope.selectedOrg.name, LC_RSR_UNSUBSCRIBE_SUCCESS);
    }).catch(() => {
      this.failure(LC_RSR_UNSUBSCRIBE_ERROR)
    });
  }

  success(orgName, message) {
    this.$scope.isFormBlocked = true;
    this.$scope.isFormProcessing = false;

    let params = {
      action: this.GA_ACTION_SUBMIT, application: this.ContextService.currentAppName(),
      page: this.currentPageName, type: 'RSR', title: 'RSR'
    };
    this.analytics(params);
    this.$mdDialog.show(
      this.$mdDialog.alert()
        .parent(angular.element(document.body))
        .htmlContent("<p>" + this.$filter(LC_FILTER)(message) + " " + orgName + ".</p>")
        .ok(this.$filter(LC_FILTER)(LC_OK))
    ).then(() => {
      this.goToContactHp();
    });
  }

  failure(errorMessage) {
    this.$scope.isFormBlocked = false;
    this.$scope.isFormProcessing = false;
    this.$scope.errorMessage = this.$filter(LC_FILTER)(errorMessage);
  }
}
