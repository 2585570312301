export const name = 'NotificationsController';
export default function NotificationsController(
  $log,
  $gzGo,
  hmNotifications,
  $window,
  $translate,
  $gzAppBroker,
  $state,
  $stateParams
) {
  //noinspection BadExpressionStatementJS
  'ngInject';

  /* jshint validthis: true */
  const NOTIFICATION_LIMIT = 50;

  let myParams = {};
  let returnUrl;

  let vm = this;
  vm.loading = true;
  vm.npService = hmNotifications;
  vm.breadcrumbs = [
    {
      state: 'dashboard',
      titleKey: 'home.home.Home_title'
    },
    {
      state: 'settings',
      titleKey: 'home.home.My_Settings'
    },
    {
      state: 'notifications',
      titleKey: 'home.home.MyNotifications'
    }
  ];

  vm.acknowledge = acknowledge;
  vm.acknowledgeAll = acknowledgeAll;
  vm.goToLink = goToLink;
  vm.goTo= goTo;
  vm.getNotificationLimit = getNotificationLimit;
  vm.close = close;
  vm.unitTest = {
    parseParams: parseParams
  };

  vm.$onInit = onInit;
  return;
  function onInit() {
    parseParams();
    hmNotifications.loadNotifications(NOTIFICATION_LIMIT).then(() => {
    })
      .finally(() => {
        vm.loading = false;
      });
    //vm.buttonText = $translate.instant(key, {'0': vm.npService.counts.total - getNotificationLimit().length});
  }

  function acknowledge(notification, $event) {
    $event.stopPropagation();
    return hmNotifications.acknowledge(notification);
  }

  function acknowledgeAll($event) {
    $event.stopPropagation();
    return hmNotifications.acknowledgeAll();
  }

  function goToLink(notification) {
    if (notification) {
      $gzGo.toContext(notification.linkValue, notification.entityId);
    }
  }

  function goTo($event, location) {
    $event.stopPropagation();
    $gzAppBroker.hide('notification-panel');
    switch (location) {
      case 'home-subscriptions':
      case 'home-notifications':
        $gzGo.toContext(location, $window.encodeURIComponent($window.location.href));
      break;
    }
  }

  function getNotificationLimit() {
    vm.myNotifications = vm.npService.getNotifications();
    if (vm.myNotifications.length > NOTIFICATION_LIMIT) {
      vm.myNotifications = vm.myNotifications.slice(0, NOTIFICATION_LIMIT);
    }
  }

  function parseParams() {
    myParams = {
      data: $stateParams.data,
    };

    returnUrl = $stateParams.returnUrl;
    if (returnUrl) {
      returnUrl = $window.decodeURIComponent(returnUrl);
      _.set(myParams, 'data.returnUrl', returnUrl);
    }
  }

  function close($event) {
    $event.stopPropagation();
    if (returnUrl) {
      $window.location.href = `/api/portal/v1/go/validate?url=${$window.encodeURIComponent(returnUrl)}`;
      return;
    }

    $state.go('settings', myParams);
  }
}

