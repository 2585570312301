import './indigo-customer-scores.scss';

import * as hmIndigoCustomerScoresDirective from './indigo-customer-scores.directive';
import gazelleAnalytics from 'gazelle/src/core/analytics/analytics.module';
import gzGo from 'gazelle/src/components/go/go.module';

export default angular.module('home.components.indigoCustomerScores', [
  gazelleAnalytics,
  gzGo
])
  .directive(hmIndigoCustomerScoresDirective.name, hmIndigoCustomerScoresDirective.default)
  .name;
