const AAA_SERVICE = 'aaa';
const USER_PATH = 'users'
const CONTEXT_PATH = 'users/context';
const LIFESPAN_SUBSCRIBE = "/api/lifeSpanServer/v1/hp-user/subscribe";
const LIFESPAN_ORGANIZATION = "/organization/"
const LIMIT = 1000;

export const moduleName = 'RsrService';
export default class RsrService {
  /** @ngInject */
  constructor($q, rnHttp) {
    this.$q = $q;
    this.rnHttp = rnHttp;
  }

  static factory($q, rnHttp) {
    'ngInject';
    return new RsrService($q, rnHttp);
  }

  loadUserInfo() {
    return this.rnHttp.get(AAA_SERVICE, USER_PATH);
  }

  loadUserContexts() {
    let contexts = []
    let config = {
      params: {
        limit: LIMIT,
        offset: 0
      }
    };
    return this.loadContextWithPagination(config, contexts);
  }

  loadContextWithPagination(config, contexts) {
    return this.rnHttp.get(AAA_SERVICE, CONTEXT_PATH, config).then(response => {
      contexts = contexts.concat(response.data.contexts)
      if (contexts.length < response.data.total){
        config.params.offset += response.data.limit
        return this.loadContextWithPagination(config, contexts);
      }
      else {
        return contexts;
      }
    });
  }

  getSubscribedUsers(orgId) {
    let config = {
      method : 'GET',
      url : LIFESPAN_SUBSCRIBE + LIFESPAN_ORGANIZATION + orgId,
    };

    return this.rnHttp.simpleRequest(config);
  }

  subscribe(userId, orgId) {
    let data = {
      'orgId': orgId,
      'userId': userId
    };
    let config = {
      method : 'PUT',
      url : LIFESPAN_SUBSCRIBE,
      params: {},
      data : data,
      headers: {
        'Content-Type': 'application/json'
      }
    };

    return this.rnHttp.simpleRequest(config);
  }

  unsubscribe(userId, orgId) {
    let data = {
      'orgId': orgId,
      'userId': userId
    };
    let config = {
      method : 'DELETE',
      url : LIFESPAN_SUBSCRIBE,
      params: {},
      data : data,
      headers: {
        'Content-Type': 'application/json'
      }
    };

    return this.rnHttp.simpleRequest(config);
  }

}
