export const name = 'PrivacyController';
export default function PrivacyController(
  // Controller angular dependencies
  $timeout,
  $state,
  $stateParams,
  rnLocale,
  rnBlue,
  $window,
  $q
) {
  'ngInject';
  // Constants
  const MAX_TRIES = 5;
  // Variables
  let locale;
  let tries = 0;


  // View Model
  let vm = this;
  //   VM properties
  //   VM methods
  vm.loading = true;
  vm.goBack = goBack;
  vm.openSettings = openSettings;

  vm.$onInit = onInit;
  return;

  ///////////////
  // Init
  function onInit() {
    let promises = [];
    promises.push(getLocale());
    promises.push(waitForConsentScriptToLoad());
    return $q.all(promises).then(() => {
      $timeout(() => {
        vm.loading = false;
        let consentScriptElement = document.createElement('script');
        consentScriptElement.src = 'https://privacyportal-cdn.onetrust.com/privacy-notice-scripts/otnotice-1.0.min.js';
        consentScriptElement.id = 'otprivacy-notice-script';
        consentScriptElement.setAttribute('type', 'text/javascript');
        consentScriptElement.setAttribute('charset', 'UTF-8');
        consentScriptElement.setAttribute('settings', 'eyJjYWxsYmFja1VybCI6Imh0dHBzOi8vcHJpdmFjeXBvcnRhbC5vbmV0cnVzdC5jb20vcmVxdWVzdC92MS9wcml2YWN5Tm90aWNlcy9zdGF0cy92aWV3cyJ9');

        checkForNoticeApi();
        document.head.append(consentScriptElement);
        vm.showSettings = window.Optanon.ToggleInfoDisplay ? true : false;
      });
    });
  }

  function getLocale() {
    return rnLocale.getLocalePreference().then(resp => {
      locale = resp.value.toLocaleLowerCase();
      switch (locale) {
        case 'zh-hans':
          locale = 'zh-cn';
          break;
        case 'ko-ko':
          locale = 'ko-kr';
          break;
      }
    })
  }

  function waitForConsentScriptToLoad() {
    return window.$printos.v1.client.consentLoadedAsync();
  }

  function checkForNoticeApi() {
    if (!_.get(window, 'OneTrust.NoticeApi.Initialized')) {
      $timeout(checkForNoticeApi, 500);
      return;
    }

    window.OneTrust.NoticeApi.Initialized.then(() => {
      OneTrust.NoticeApi.LoadNotices(["https://privacyportal-cdn.onetrust.com/4abb22ef-0e20-458e-be93-e351ad21c465/privacy-notices/7e61f201-cc1a-4896-bf98-3267ead793ec.json"], true, locale);
      waitForLoad();
    });
  }

  ///////////////
  // VM methods
  function goBack() {
    if ($stateParams.returnUrl) {
      $state.go('settings', {returnUrl: $stateParams.returnUrl});
    }
    else {
      $state.go('settings');
    }
  }

  function openSettings() {
    window.Optanon.ToggleInfoDisplay();
  }
  ///////////////
  // Helpers
  function waitForLoad() {
    let settingsButton =  document.getElementById("ot-sdk-btn");
    if (!settingsButton && tries <= MAX_TRIES) {
      $timeout(waitForLoad, 2000);
      return;
    }

    if (settingsButton) {
      settingsButton.addEventListener('click', event => {
        event.stopPropagation();
        event.preventDefault();
        window.Optanon.ToggleInfoDisplay();
      });
    }
  }
}
