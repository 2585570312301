import rhino from 'rhino';
import angularTranslate from 'angular-translate';
import gazelleGo from 'gazelle/src/components/go/go.module';

import './maintenance-promo.scss';
import * as hmMaintenancePromoDirective from './maintenance-promo.directive';

export default angular.module('home.components.widget.maintenancePromo', [
  rhino,
  angularTranslate,
  gazelleGo,
])
  .directive(hmMaintenancePromoDirective.name, hmMaintenancePromoDirective.default)
  .name;
